import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { getPremiumInfoData } from '../../actions';

const defaultPoints = [
    `10__9000+ Book Summaries (Read | Listen | Watch)`,

    `20__News & Magazines`,

    `30__Sleep Stories`,

    `40__Request Books`,

    `50__Listen offline on mobile + sync web app`
];

export default class PremiumPoints extends Component {
    render() {
        // const { premiumInfoLoc } = this.props;
        const premiumInfoLoc = getPremiumInfoData() || {};
        const mainPoints = premiumInfoLoc['points'] || defaultPoints;
        const sortedPoints = [...mainPoints].sort();
        return (
            <div>

                {(sortedPoints).map((point) => (
                    <p key={point} className='serif' style={{ fontSize: '1.1em' }}>
                        <FontAwesomeIcon style={{ color: 'green' }} icon={'check-circle'} />
                        &nbsp; {point.includes('__') ? point.split('__')[1] : point}</p>
                ))}
            </div>
        )
    }
}

PremiumPoints.propTypes = {
    // premiumInfoLoc: PropTypes.object,
    index: PropTypes.number,
}
