import { Button, message, notification } from 'antd';
import React, { Component } from 'react'
import { getMonthCount, serverApiEurope } from '../../lib/utils';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logPremiumClick } from '../../lib/analyticsEvents';
import { Col, Row, Tooltip } from 'antd';
import SignUp from '../Auth/SignUp';

class ClickPremium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // visible: false,
            showRegister: false,
            // errorObj: {}
        }
    }

    clickPremium = () => {
        // const { options } = this.state;
        const { isLifetime, userDetails, option, email, type, code } = this.props;
        console.dir(option);
        console.dir(code);
        // return;
        if ((!userDetails || !userDetails['uid']) && !email) {
            this.setState({ showRegister: true });
            // notification.info({
            //     message: (<><div>Please sign in or register to purchase premium offer.
            //         <>

            //         </>
            //     </div></>),
            //     description: '',
            //     duration: null
            // });
            return;
        }
        logPremiumClick(type == 'gift' ? 'gift-cards' : 'premium', option.id);
        // const option = options[selectedIndex];
        message.info('Loading...', 2.5);
        const optPOST = {                //http header
            'method': 'post',
            'contentType': 'application/json',
            'muteHttpExceptions': true,
            'body': JSON.stringify({
                "price_id": option.id,
                'term': getMonthCount(option),
                'email': email,
                'success_url': type == 'isOnboard' ? 'onboard-finish' : (type == 'gift' ? 'gift-cards' : ''),
                'cancel_url': type == 'isOnboard' ? 'trial' : (type == 'premiumSocial' ? 'premium-offer-app' : ''),
                'oneTime': isLifetime || type == 'gift',
                'type': type || 'none',
                code
            })
        };
        console.dir(optPOST);
        console.dir(option);
        // return;
        fetch(`${serverApiEurope}stripeCheckoutSession`, optPOST).then(async (dataOrig) => {
            const data = await dataOrig.json();
            console.dir(data);
            if (data.session_url) {
                window.location.href = data.session_url;
            } else {
                notification.error({
                    message: 'There was an error opening payment url. Please try again.',
                    description: '',
                    duration: null
                });
            }
        }).catch(err => {
            notification.error({
                message: 'There was an error opening payment options. Please try again.',
                description: '',
                duration: null
            });
        })
    }

    hasFreeTrial = () => {
        const { option, isPromo } = this.props;
        if (isPromo) {
            return false;
        }
        return option?.recurring?.interval == 'year' && option?.recurring?.interval_count == 1;
    }

    render() {
        const { showRegister } = this.state;
        const { email, type, userDetails } = this.props;
        const isDashboard = type == 'dashboard';

        if (showRegister && (!userDetails || !userDetails['uid'])) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h4>Please sign in or register to purchase premium offer.</h4>
                    <Row>
                        <Col xs={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} xl={{ span: 3, offset: 8 }}
                            style={{ textAlign: 'right', display: `${(!userDetails) ? 'block' : 'none'}` }}>
                            <SignUp key='signUp' typeId='signUp' isAds={false} />
                        </Col>
                        {/* {!userDetails && */}
                        <Col xs={{ span: 10, offset: 1 }} md={{ span: 10, offset: 0 }} xl={{ span: 3, offset: 2 }}
                            style={{ textAlign: 'center', display: `${(!userDetails) ? 'block' : 'none'}` }}>
                            <SignUp key='signIn' typeId='signIn' isAds={false} />
                        </Col>
                        {/* } */}
                    </Row>
                </div>
            )
        }


        return (
            <div>
                {(type == 'gift') ?
                    <Button type="primary" className={`third-button`} style={{ width: '200px', height: '40px', borderRadius: '8px' }} onClick={this.clickPremium}>
                        {(userDetails && userDetails.uid) ? 'Purchase' : (email && email.includes('@')) ? 'Proceed' : 'Purchase as Guest'} <FontAwesomeIcon icon={'arrow-right'}
                            style={{ marginLeft: '8px' }} />
                    </Button>
                    :
                    <Button className={`third-button`} style={{ width: isDashboard ? '100px' : '200px', height: '40px', borderRadius: '8px' }}
                        onClick={this.clickPremium}>
                        {
                            (this.hasFreeTrial() ? 'Start 7 Day Trial' : 'Continue')} {!isDashboard &&
                                <FontAwesomeIcon icon={'arrow-right'}
                                    style={{ marginLeft: '8px' }} />}
                    </Button>}
            </div>
        )
    }
}

ClickPremium.propTypes = {
    // productId: PropTypes.string,
    // initIndex: PropTypes.number,
    isLifetime: PropTypes.bool,
    isPromo: PropTypes.bool,
    email: PropTypes.string,
    code: PropTypes.string,
    type: PropTypes.string,
    // mainTitle: PropTypes.string,
    // subtitle: PropTypes.string,
    userDetails: PropTypes.object,
    option: PropTypes.object,
}
const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        // loadingState: state.loadingState,
    }
}
export default connect(mapStateToProps, null)(ClickPremium);
