import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, message, Modal, notification } from 'antd'
import React, { Component } from 'react'
import { getPremiumInfo, getPremiumInfoData } from '../../actions';
import { logPremiumOpen } from '../../lib/analyticsEvents';
import { getlocationInfo } from '../../lib/fileUtils';
import { colors } from '../../lib/theme';
import PremiumPage from './PremiumPage';

// const options = [
//     // { 'title': '3', 'price_id': 'price_1M1nV3Eui0QeTi3US6GzIZP3' },
//     // { 'title': '1', 'price_id': 'price_1M1nV3Eui0QeTi3UDemgN5hI' },
//     { 'title': '3', 'price_id': 'price_1M8LMGEui0QeTi3UKcSCjByg' },
//     { 'title': '6', 'price_id': 'price_1M1m6AEui0QeTi3UDExxhB2u' },
//     { 'title': '12', 'price_id': 'price_1M1m6AEui0QeTi3UY4Xv19Wg' },
// ];

export default class Premium extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // typeId: props.typeId,
            show: false,
            visible: false,
            // confirmLoading: false,
            forgotPasswordSuccess: false,
            isModalClosable: true,
            keyValue: 1,
            // showWaitlistForm: false,
            // showPrivacyForm: false,
            // showTermsConditionsForm: false,
            errorObj: {}
        }
    }

    componentDidMount() {
        console.dir(window.location);
        if (window.location && window.location.href.includes('premium')) {
            this.setState({ show: false });
        } else {
            this.setState({ show: true });
        }
    }

    showModal = () => {
        logPremiumOpen('subscriptionModal');
        this.setState({
            visible: true,
        });
    }

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setState({
            visible: false, forgotPasswordSuccess: false, errorObj: {}
        });
    }

    render() {
        const { visible, isModalClosable, keyValue, show } = this.state;
        if (!show) {
            return <></>
        }
        const date = new Date().getDate();
        console.dir(date);
        return (
            <div style={{ marginTop: '0' }}>
                <Button className={``} style={{
                    padding: '0 32px', height: '36px', background: `${colors.darkRed}`,
                    color: 'white', border: 'none'
                }}
                    onClick={this.showModal}>
                    <FontAwesomeIcon style={{
                        // position: 'absolute', color: 'white',
                        // right: '8px', top: '8px',
                    }} icon={'gift'} />&nbsp;
                    {/* {date % 2 == 0 ? 'Free Trial' : '50% Off'} */}
                    Free Trial
                </Button>
                <Modal className='premium-modal'
                    // title={typeId == 'signIn' ? type: 'Register'}
                    visible={visible}
                    // onOk={this.handleOk}
                    bodyStyle={{ padding: 0, position: 'relative' }}
                    // confirmLoading={confirmLoading}
                    closable={isModalClosable}
                    onCancel={this.handleCancel}
                    maskClosable={isModalClosable}
                    afterClose={this.afterClose}
                    footer={null}
                >
                    <PremiumPage />
                </Modal>
                <style jsx>
                    {`
                    `}
                </style>
            </div>
        )
    }
}
