import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Button, Modal } from 'antd';

import AuthComponent from './AuthComponent';


import { colors, mainLayoutColor, spacing, leftSideBar, leftSideBarColor, leftSideBarColor1, leftSideBarColor2, minScreenSize, maxScreenSize } from '../../lib/theme';
import PremiumPage from '../Common/PremiumPage';
// import Link from 'next/link';

const background = `${leftSideBarColor1} 0%, ${leftSideBarColor1} 30%,  ${leftSideBarColor2} 80%`;
const leftBarColor = leftSideBarColor;

const authTypes = { 'signUp': 'Register', 'signIn': 'Sign In' };
class SignUp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // typeId: props.typeId,
            visible: false,
            visiblePremium: false,
            // confirmLoading: false,
            forgotPasswordSuccess: false,
            isModalClosable: true,
            keyValue: 1,
            // showWaitlistForm: false,
            // showPrivacyForm: false,
            // showTermsConditionsForm: false,
            errorObj: {}
        }
    }

    componentDidMount() {
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    }
    showPremiumModal = () => {
        // /// isAds can also be podcaster dashboard
        // const { isAds } = this.props;
        // console.dir('in showPremiumModal');
        const pathName = window.location.pathname;
        if (pathName.includes('premium')) {
            this.setState({
                visible: false
            });
            return;
        }

        // /// even though this is called, it will not show for sign in because the parent component is removed by then
        // //// this only shows if someone clicks on Signup and not Signin. Check topbar display: none for this
        this.setState({
            visiblePremium: true,
            visible: false
        });
    }

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setState({
            visible: false, forgotPasswordSuccess: false, errorObj: {}
        });
    }
    handlePremiumCancel = () => {
        // console.log('Clicked cancel button');
        this.setState({
            visiblePremium: false, forgotPasswordSuccess: false, errorObj: {}
        });
    }

    // onChildChanged = (userInfo) => {
    //     // const { typeId } = this.props;
    //     const { typeId } = this.state;
    //     console.dir(userInfo);
    //     this.setState({
    //         confirmLoading: true, errorObj: {}
    //     });
    //     if (typeId === 'signUp') {
    //         createUser(userInfo)
    //             .then((data) => {
    //                 console.log(data);
    //                 this.setState({
    //                     confirmLoading: false,
    //                     visible: false
    //                 });
    //                 this.props.createUserSuccess(data.user);
    //                 //******** create default playlist */
    //                 let playlistInfo = { 'name': 'Listen Later', 'description': 'Default Playlist' };
    //                 createPlaylist(data.user, playlistInfo)
    //                     .then((data) => {
    //                         if (data.success) {
    //                             console.dir(data);
    //                             this.props.createPlaylistPersonal(data.playlistInfo, data.playlistId);
    //                         }
    //                     })
    //                     .catch(err => {
    //                         console.error(err);
    //                     })
    //             })
    //             .catch((error) => {
    //                 console.log(error.message);
    //                 this.setState({
    //                     confirmLoading: false, errorObj: error
    //                 });
    //             })
    //     } else if (typeId === 'signIn') {
    //         if (userInfo['forgotpasswordForm'] && userInfo['forgotpasswordForm'] == true) {
    //             sendPasswordResetEmail(userInfo.email)
    //                 .then(() => {
    //                     // console.log(data);
    //                     this.setState({
    //                         confirmLoading: false,
    //                         forgotPasswordSuccess: true
    //                         // visible: false
    //                     });
    //                 })
    //                 .catch((error) => {
    //                     console.log(error.message);
    //                     this.setState({
    //                         confirmLoading: false, errorObj: error
    //                     });
    //                 })
    //         } else {
    //             signInUser(userInfo)
    //                 .then((data) => {
    //                     console.log(data);
    //                     this.setState({
    //                         confirmLoading: false,
    //                         visible: false
    //                     });
    //                     this.props.signInUserSuccess(data.user);
    //                 })
    //                 .catch((error) => {
    //                     console.log(error.message);
    //                     this.setState({
    //                         confirmLoading: false, errorObj: error
    //                     });
    //                 })
    //         }
    //     }
    // }

    // toggleWaitlist = () => {
    //     this.setState(prevState => ({
    //         showWaitlistForm: !prevState.showWaitlistForm
    //     }));
    // }

    // togglePrivacy = () => {
    //     this.setState(prevState => ({
    //         showPrivacyForm: !prevState.showPrivacyForm
    //     }));
    // }

    // toggleTermsConditions = () => {
    //     this.setState(prevState => ({
    //         showTermsConditionsForm: !prevState.showTermsConditionsForm
    //     }));
    // }

    // toggleFormType = (typeId) => {
    //     this.setState({ typeId: typeId });
    // }

    changeClosable = () => {
        this.setState({ isModalClosable: false });
    }

    afterClose = () => {
        this.setState({ keyValue: this.state.keyValue + 1 });
    }

    render() {
        // const { userDetails } = this.props;
        const { typeId, isAds } = this.props;
        const outline = typeId == 'signIn' && 'outline';
        // let type = authTypes[typeId] || 'Sign In'; // '';

        const { visible, visiblePremium, isModalClosable, keyValue } = this.state;

        return (
            <div>
                <Button className={`third-button ${outline}`} onClick={this.showModal}>
                    {authTypes[typeId]}
                </Button>
                <Modal className='premium-modal'
                    // title={typeId == 'signIn' ? type: 'Register'}
                    visible={visiblePremium}
                    // onOk={this.handleOk}
                    bodyStyle={{ padding: 0, position: 'relative' }}
                    // confirmLoading={confirmLoading}
                    closable={true}
                    onCancel={this.handlePremiumCancel}
                    maskClosable={true}
                    afterClose={this.afterClose}
                    footer={null}
                >
                    <PremiumPage initIndex={0} isOnboard={true} isReferral={true}
                        // isPromo={isReferral}
                        justText={true} />
                    {/* <PremiumPage productId='prod_NaNJuSKZtTGTeH' initIndex={1} isOffer={true}
                        mainTitle={'50% Off Premium + \n 7 day money back guarantee *'}
                        subtitle={'\n'}
                        bottomInfo={'*Money back offer is only valid on this purchase but not on any future purchases.'} /> */}
                </Modal>
                <Modal className='auth-modal'
                    // title={typeId == 'signIn' ? type: 'Register'}
                    visible={visible}
                    // onOk={this.handleOk}
                    bodyStyle={{ padding: 0, position: 'relative' }}
                    // confirmLoading={confirmLoading}
                    closable={isModalClosable}
                    onCancel={this.handleCancel}
                    maskClosable={isModalClosable}
                    afterClose={this.afterClose}
                    footer={null}
                >
                    <AuthComponent key={keyValue} parent='popup' isAds={isAds} typeId={typeId} changeClosable={this.changeClosable}
                        callbackAuthParent={() => { this.showPremiumModal() }} />
                    {/* {!userDetails && visible &&
                        <Row style={{ display: 'flex' }}>
                            <Col span={9} className='left-image'>
                                <div className='left-bar' style={{ padding: `${spacing('md')}` }}>
                                    <div className='empty-state left-bar-background'>
                                        <div className="layer"></div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={24} xl={15} style={{ padding: `${spacing('md')} ${spacing('lg')}`, background: `${mainLayoutColor}`, height: '100%' }}>
                                

                                {showPrivacyForm && <div style={{ maxHeight: '70vh', overflow: 'auto', marginTop: '10%', paddingRight: '5%' }}>
                                    <PrivacyPolicy />
                                    <Button onClick={this.togglePrivacy} className='back-btn-waitlist'>Back</Button>
                                </div>}

                                {showTermsConditionsForm && <div style={{ maxHeight: '70vh', overflow: 'auto', marginTop: '10%', paddingRight: '5%' }}>
                                    <TermsConditions />
                                    <Button onClick={this.toggleTermsConditions} className='back-btn-waitlist'>Back</Button>
                                </div>}

                                {!showWaitlistForm && !showPrivacyForm && !showTermsConditionsForm && <div>
                                    <h2 style={{ marginBottom: `${spacing('md')}` }}>{typeId == 'signIn' ? type : 'Register'}</h2>
                                    {typeId == 'signUp' && <p>Already a memeber? <a className='signIn-btn' onClick={this.toggleFormType.bind(this, 'signIn')} >SignIn</a></p>}
                                    {typeId == 'signIn' && <p>Not a member yet? <a className='signIn-btn' onClick={this.toggleFormType.bind(this, 'signUp')}>Register</a></p>}

                                    {typeId == 'signUp' && <p>This is currently a <i>beta</i> version and access is possible only with a registration code.
                                Please join our <a onClick={this.toggleWaitlist}>waiting list</a> if you are interested.
                                </p>}
                                    <WrappedNormalLoginForm forgotPasswordSuccess={forgotPasswordSuccess} typeId={typeId}
                                        type={type} callbackParent={this.onChildChanged} errorObj={errorObj} confirmLoading={confirmLoading} />

                                    {typeId == 'signUp' && <p>
                                        By signing up, you agree to Accelerated&apos;s <a onClick={this.togglePrivacy}>Privacy Policy</a> and&nbsp;
                                    <a onClick={this.toggleTermsConditions}>Terms and Conditions</a>.
                                </p>}
                                </div>}
                            </Col>
                        </Row>
                    } */}
                </Modal>
                <style jsx>
                    {`
                        
                        :global(.auth-modal .ant-modal-content) {
                        width: 60vw;
                        transform: translate(-9vw,0);
                        border-radius: 180px;
                        // height: 50vh;
                        }
                        :global(.auth-modal .ant-modal-content .ant-modal-body) {
                        border-radius: 180px;
                        }
                        :global(.auth-modal .ant-form-item-label) {
                            text-align: left;
                        }
                        @media ${maxScreenSize.tablet} {
                        :global(.auth-modal .ant-modal-content) {
                            width: 95vw;
                            left: 10vw;
                            // transform: translate(-9vw,0);
                            // height: 50vh;
                        }
                        :global(.top-bar-row) {
                            width: 95vw;
                        }
                        }
                        @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                            :global(.auth-modal .ant-modal-content) {
                                width: 70vw;
                                left: 10vw;
                            }
                        }
                    `}
                </style>
            </div>
        );
    }
}

SignUp.defaultProps = {
    isAds: false
}

SignUp.propTypes = {
    // userDetails: PropTypes.object,
    // type: PropTypes.string,
    typeId: PropTypes.string,
    isAds: PropTypes.bool,
    // signInUserSuccess: PropTypes.func,
    // createPlaylistPersonal: PropTypes.func,
}

// const mapDispatchToProps = (dispatch) => {
//     return {
//         // createPlaylistPersonal: bindActionCreators(createPlaylistPersonal, dispatch),
//         // signInUserSuccess: bindActionCreators(signInUserSuccess, dispatch)
//     }
// };

export default connect(null, null)(SignUp);