import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { spacing, fontSize, colors, fixedSpacing } from '../../lib/theme';
import AppCustomLink from '../Common/AppCustomLink';
import { HomeFilled, HeartOutlined, CrownFilled, FireFilled, TagOutlined, UsergroupAddOutlined, CloudUploadOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PUBLIC } from '../../actions/types';
import slugify from 'slugify';

const iconOptions = {
    'home': <HomeFilled />, 'gift': <FireFilled />, 'crown': <CrownFilled />,
    'yourPodcasts': <FontAwesomeIcon style={{}} icon='podcast' />, 
    'newEpisodes': <FontAwesomeIcon style={{}} icon='list-ul' />,
    'sleep': <FontAwesomeIcon style={{}} icon='spa' />,
    'myFiles': <CloudUploadOutlined />,
    'news': <FontAwesomeIcon icon={['fas', 'book']} style={{ marginLeft: '2px', fontSize: `16px` }} />,
    'curated': <FontAwesomeIcon icon={['fas', 'heart']} style={{ marginLeft: '2px', marginTop: '4px', fontSize: `16px` }} />,
    'favourites': <FontAwesomeIcon icon={['fas', 'clipboard']} style={{ marginLeft: '4px', fontSize: `16px` }} />,
    'tags': <FontAwesomeIcon icon={['fas', 'folder']} style={{ marginLeft: '4px', fontSize: `16px` }} />,
    'insights': <FontAwesomeIcon icon={['fas', 'lightbulb']} style={{ marginLeft: '4px', fontSize: `16px` }} />,
    'bookmarks': <FontAwesomeIcon icon={['fas', 'bookmark']} style={{ marginLeft: '4px', fontSize: `16px` }} />,
    'recently-played': <FontAwesomeIcon style={{ marginLeft: '4px', fontSize: `16px` }} icon='play' />,
    // 'transcript': <FontAwesomeIcon style={{}} icon='microphone' />,
    // 'link': <FontAwesomeIcon style={{}} icon='link' />,
    'account': <FontAwesomeIcon style={{ marginLeft: '4px', fontSize: `16px` }} icon='user' />,
};

class leftBarOption extends React.PureComponent {
    constructor(props) {
        super(props);
        // this.state = { focusClass: '', activeTab: props.activeTab };
    }

    render() {
        const { numEpisodes, name, icon, url, playlistId, isPlaylistCollaborative, isDashboard,
            userPlaylistType, userPlaylistAdvancedType, activeTab, leftBarColor, leftBarText, type } = this.props;
        // const { focusClass } = this.state;
        const focusClass = activeTab ? 'focusClass' : '';
        return (
            <div onClick={this.props.onClick}>
                {/* <div className={`list-item ${focusClass}`} onClick={this.props.onClick}> */}
                {!playlistId && type == 1 && (isDashboard ?
                    <AppCustomLink href={url} style={{}}>
                        <a>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '12px 1em', marginBottom: url == '/podcast-links' ? '60px' : 0 }}
                                className={`list-item ${focusClass}`}>
                                {/* <LegacyIcon style={{ fontSize: `${fixedSpacing('md')}` }} type={icon} theme="filled" /> &nbsp; */}
                                <div style={{ fontSize: '16px', marginLeft: '2px' }}>
                                    {iconOptions[icon]} &nbsp;
                                </div>
                                <h4 style={{ marginBottom: '0px', fontSize: '16px' }}>{name}</h4>
                            </div>
                        </a>
                    </AppCustomLink> :
                    <AppCustomLink href={url}>
                        <a>
                            <div style={{ display: 'flex', alignItems: 'center' }} className={`list-item ${focusClass}`}>
                                {/* <LegacyIcon style={{ fontSize: `${fixedSpacing('md')}` }} type={icon} theme="filled" /> &nbsp; */}
                                <div style={{ fontSize: '17px', marginLeft: '2px' }}>
                                    {iconOptions[icon]} &nbsp;
                                </div>
                                <h4 style={{ marginBottom: '0', fontSize: '16px' }}>{name}</h4>
                            </div>
                        </a>
                    </AppCustomLink>)
                }
                {!playlistId && type == 2 &&
                    <AppCustomLink href={url}>
                        <a rel="nofollow">
                            <div style={{ display: 'flex', alignItems: 'center' }} className={`list-item ${focusClass}`}>
                                <div style={{ fontSize: '18px' }}>
                                    {iconOptions[icon]} &nbsp;
                                </div>
                                <h4 style={{ marginBottom: '0', fontSize: '16px' }}><span>{name}</span></h4>
                            </div>
                        </a>
                    </AppCustomLink>
                }
                {/* as={`/search/${this.state.value}`} */}
                {/* {playlistId &&
                    <>
                        {userPlaylistAdvancedType === 'SmartPlaylistId' ?
                            <AppCustomLink as={`${url}/${playlistId}`} href={`${url}?id=${playlistId}`} >
                                <a rel="nofollow">
                                    <h4 className={`list-item ${focusClass}`}>
                                        <span style={{}}>{name} </span> {userPlaylistAdvancedType === 'SmartPlaylistId' && <span className='smart-playlist'>A</span>}
                                    </h4></a>
                            </AppCustomLink> :
                            <AppCustomLink as={`${url}/${slugify(name)}-i${playlistId}`} href={`${url}?id=${playlistId}`} >
                                <a rel="nofollow">
                                    <h4 className={`list-item ${focusClass}`}>
                                        <span style={{}}>{name} {userPlaylistAdvancedType !== 'SmartPlaylistId' && <>({numEpisodes})</> || ''}</span> {isPlaylistCollaborative && <UsergroupAddOutlined />} {userPlaylistType === PUBLIC && <FontAwesomeIcon icon='users' />}
                                    </h4>
                                </a>
                            </AppCustomLink>
                        }
                    </>
                } */}
                <style jsx>
                    {`
           .list-item{
              max-width: 600px;
              font-size: 0.9em; // ${fontSize()};
              padding-top: calc(0.5 *${spacing()});
              padding-bottom: calc(0.5 *${spacing()});
              padding-left: ${spacing('')};
            //   padding-right: ${spacing()};

              margin-left: 12px;
              margin-right: 12px;
              margin-top: calc(0.2 *${spacing()});
              margin-bottom: calc(0.2 *${spacing()});
            //   background: ${leftBarColor};
              cursor: pointer;
              border-left: 6px solid transparent; // ${leftBarColor};
              color: #eb0028; // ${leftBarText} !important;
           }
           .list-item:hover{
                // border-left: 6px solid ${colors.primary};
                // background: ${colors.primaryDim};
                background: #c5c5c522;
                border-radius: 8px;
           }
           .list-item h4 {
                // color: white !important;
           }
           .smart-playlist {
               color: white;
               background: ${colors.primary};
               padding: 4px 8px;
               border-radius: 50%;
               margin-left: 4px;
           }
           .focusClass {
            // //    text-decoration: underline;
            //     color: ${colors.primary} !important;
            //     border-left: 5px solid ${colors.primary};
            //     // background: ${colors.primaryDim};
                background: #c5c5c522;
                margin-left: 12px;
                margin-right: 12px;
                border-radius: 8px;
           }
           .focusClass h4 {
                // color: ${colors.primary} !important;
           }
        `}
                </style>
            </div>
        );
    }
}

leftBarOption.propTypes = {
    name: PropTypes.string,
    numEpisodes: PropTypes.number,
    type: PropTypes.number,
    activeTab: PropTypes.bool,
    isDashboard: PropTypes.bool,
    isPlaylistCollaborative: PropTypes.bool,
    userPlaylistType: PropTypes.string,
    url: PropTypes.string,
    playlistId: PropTypes.string,
    userPlaylistAdvancedType: PropTypes.string,
    leftBarColor: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
    leftBarText: PropTypes.string,
}

export default leftBarOption;