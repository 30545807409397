import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
// import TextareaAutosize from 'react-autosize-textarea';
// import { updateUserPost, addPost } from '../actions';
import { logOutUser } from '../../actions';
import Router from 'next/router'
import AppCustomLink from '../Common/AppCustomLink';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
// import Link from 'next/link';

import { Avatar, Dropdown, Menu, Row } from 'antd';
import { colors, maxScreenSize } from '../../lib/theme';
import { isArticle, storageUrl } from '../../lib/utils';
import { getIsPremiumUser } from '../../lib/fileUtils';
import ProBadge from '../Common/ProBadge';
class AccountOptions extends React.Component {
    constructor(props) {
        super(props);
        // this.state = { value: '' };
    }
    //When user clicks add post fire the add post event.
    logOut() {
        this.props.logOutUser();
        const isArticlePage = isArticle(location.pathname);
        if (!isArticlePage) {
            Router.push('/top-charts');
        }
    }

    render() {
        const { userDetails } = this.props;
        const menu = (
            <Menu>
                <Menu.Item>
                    <AppCustomLink href='/settings'>
                        <a rel="noopener noreferrer"><p className='mb-0'>Settings</p></a>
                    </AppCustomLink>
                </Menu.Item>
                <Menu.Item>
                    <AppCustomLink href='/account'>
                        <a rel="noopener noreferrer"><p className='mb-0'>Account</p></a>
                    </AppCustomLink>
                </Menu.Item>
                {userDetails && userDetails.hasAdHistory && <Menu.Item>
                    <AppCustomLink href='/ad-history'>
                        <a rel="noopener noreferrer"><p className='mb-0'>Ad Purchases</p></a>
                    </AppCustomLink>
                </Menu.Item>}
                {getIsPremiumUser() &&
                    <Menu.Item>
                        <AppCustomLink href='/payment'>
                            <a rel="noopener noreferrer"><p className='mb-0'>Premium</p></a>
                        </AppCustomLink>
                    </Menu.Item>
                }
                {/* <Menu.Item>
                    <AppCustomLink href='/gift-cards'>
                        <a rel="noopener noreferrer"><p className='mb-0'>Gift Cards</p></a>
                    </AppCustomLink>
                </Menu.Item>
                <Menu.Item>
                    <AppCustomLink href='/search-alert'>
                        <a rel="noopener noreferrer"><p className='mb-0'>Search Alerts</p></a>
                    </AppCustomLink>
                </Menu.Item> */}
                {/* <Menu.Item>
                    <a target="_blank" rel="noopener noreferrer" href="http://www.taobao.com/">Settings</a>
                </Menu.Item> */}
                <Menu.Item>
                    <a target="_blank" onClick={this.logOut.bind(this)}><p className='mb-0'>Log out</p></a>
                </Menu.Item>
            </Menu>
        );

        return (
            <div className="">
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    {/* <div> */}
                    <a>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                            <ProBadge />
                            {userDetails.imgUrl ? <Avatar size={24} src={`${storageUrl}users/${userDetails.imgUrl}`} /> :
                                <Avatar icon={<UserOutlined />} size="small" style={{ backgroundColor: `${colors.primary}`, marginTop: '-2px' }} />
                            }
                        </div>
                        &nbsp;
                        {userDetails.name &&
                            <h4 className='username' style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                {userDetails.name.length > 8 ? userDetails.name.slice(0, 8) + '...' : userDetails.name}
                            </h4>
                        }&nbsp;
                        <DownOutlined />
                    </a>
                    {/* </div> */}
                </Dropdown>
                <style jsx>
                    {`
                    .username{
                        display: inline;
                      }
                    @media ${maxScreenSize.laptop} {
                        .username{
                          display: none;
                        }
                      }
                    `}</style>
            </div>
        );
    }
}

AccountOptions.propTypes = {
    logOutUser: PropTypes.func,
    userDetails: PropTypes.object
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        // userPlaylists: state.userPlaylists,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logOutUser: bindActionCreators(logOutUser, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountOptions);