import React from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { compose } from 'recompose';

import { Provider } from "react-redux";
// import withRedux from "next-redux-wrapper";
// import '../static/css/nprogress.css';
// import '../static/css/plyr.css';

import { Timestamp, getDoc, doc, setDoc } from "firebase/firestore/lite";
import { initStore } from '../store';
import Head from '../components/Head';
import TopBar from '../components/TopBar/TopBar';
import LeftBar from '../components/LeftBar/LeftBar';
import RightBar from '../components/RightBar/RightBar';
import BottomBar from '../components/BottomBar/BottomBar';
import AppOffline from '../components/Common/AppOffline';
// import CommonLayout from '../components/CommonLayout';
import { spacing, leftSideBar, rightSideBar, maxScreenSize, minScreenSize, colors } from '../lib/theme';

import { loadUser, staticAssetsPrefix, isArticle, saveLocally, retrieveLocally, isInStandaloneMode, getIdFromUID, isDarkMode, mainDashboardOptions } from '../lib/utils';
import { LOGIN_ROUTE, FEATURED_ROUTE, BLOG_ROUTE, IMPORT_OPML_ROUTE, AUTH_SUCCESS_ROUTE, IS_DARK_MODE, ADS_ROUTE, TWITTER_SUCCESS_ROUTE } from '../lib/strings';
import { logScreen } from '../lib/analyticsEvents';
// import { getRequiredPodcasts } from '../reducers';
import { setUserDetails, loadingUserDetailsState, createPlaylist, createPlaylistPersonal, toggleDarkTheme, getAppInfo, fetchYourPodcastSettings, setInterests, createUserSuccess, getPremiumInfo, fetchFirebaseToken, signInUserWithToken, getReadingChallenges, getBrowsePodcasts, fetchTopChartsPodcastsRedux } from '../actions';
import { fetchSideBarPodcasts, signInUserSuccess } from '../actions';

// import DefaultSeo from 'next-seo';
// import SEO from '../next-seo.config';
import { NextSeo } from 'next-seo';
import { PageTransition } from 'next-page-transitions';

import NProgress from 'nprogress';
import Router from 'next/router';

import { Button, notification } from 'antd';
import sentry from '../utils/sentry';
const { Sentry, captureException } = sentry({ release: process.env.SENTRY_RELEASE })

import * as gtag from '../lib/gtag';
import TopBarArticle from '../components/TopBar/TopBarArticle';
import { auth, firestore } from '../lib/db';
import TopBarMinimal from '../components/TopBar/TopBarMinimal';
import { fetchSignInMethodsForEmail, getRedirectResult } from 'firebase/auth';
import PremiumBottom from '../components/Common/PremiumBottom';
import { getIsPremiumUser } from '../lib/fileUtils';
import PremiumTop from '../components/Common/PremiumTop';
import CookiePopup from '../components/Common/CookiePopup';
import SendMessage from '../components/Dashboard/SendMessage';
import ShowApps from '../components/Common/ShowApps';

class Layout extends React.Component {
    initAndFetch = async () => {
        // const BranchSDK = (await import('branch-sdk')).default

        // BranchSDK.init('key_live_ed975Za7z4srGQ2wgyPWLcabstou70hs');

        // BranchSDK.data(function (err, data) {
        //     if (err) {
        //         console.dir(err);
        //         console.warn(`Branch failed to resolve link: ${err}`)
        //         return
        //     }
        //     console.dir(data);

        //     // do something with `data`?
        // })
    }

    componentDidMount() {
        this.initAndFetch();
        const { userDetails, currentRoute } = this.props;
        console.log('start loadUser');

        try {
            const urlParams = new URLSearchParams(window.location.search);
            const isReferral = urlParams.get('fpr');
            if (isReferral) {
                saveLocally('isReferral', true, 30 * 24);
            }

            /// if token, sign in automatically
            const tok = urlParams.get('tok');
            if (tok) {
                fetchFirebaseToken(tok).then(customToken => {
                    console.dir(customToken);
                    signInUserWithToken(customToken)
                        .then((data) => {
                            this.props.signInUserSuccess(data.user, 0);
                        });
                });
            }

        } catch (err) {
            console.error(err);
        }

        getPremiumInfo();
        // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        if (isDarkMode()) {
            console.log('isDark: ' + true);
            this.props.toggleDarkTheme(1);
        }
        setTimeout(() => getAppInfo(), 1000);

        getRedirectResult(auth).then(function (result) {
            if (result.credential) {
                // This gives you a Google Access Token. You can use it to access the Google API.
                var token = result.credential.accessToken;
                // ...
            }
            // The signed-in user info.
            var user = result.user;
            return result; // user;
        }).then((data) => {
            console.dir(data);
            if (data.user && data.user.uid) {
                // firebase.firestore().collection('users').doc(data.user.uid)
                //     .get()
                getDoc(doc(firestore, `users`, data.user.uid))
                    .then((docLoc) => {
                        const isFromAds = window.location.hash && window.location.hash.includes("ads-type");
                        if (docLoc.exists()) {
                            // user already exists
                            console.dir('user exists');
                            // resolve({ userData: data, firstSignIn: false });
                            this.props.signInUserSuccess(data.user, 0);
                            console.dir(window.location.hash);
                            if (isFromAds) {
                                Router.push({ pathname: '/ads' });
                            } else {
                                Router.push({ pathname: '/home' });
                            }
                        } else { // new user
                            console.log('new user');
                            const isPWAFromLocalStorage = retrieveLocally('isPWA');
                            setDoc(doc(firestore, `users`, data.user.uid), {
                                uid: data.user.uid,
                                id: getIdFromUID(data.user.uid),
                                name: data.user.displayName || '',
                                signUpType: isPWAFromLocalStorage ? 'windows' : 'web',
                                timestamp: Timestamp.fromDate(new Date())
                            }, { merge: true })
                            // firebase.firestore().collection('users').doc(data.user.uid)
                            //     .set({
                            //         uid: data.user.uid,
                            //         id: getIdFromUID(data.user.uid),
                            //         name: data.user.displayName || '',
                            //         signUpType: 'web',
                            //         timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                            //     });
                            setDoc(doc(firestore, `users/${data.user.uid}/private`, 'details'), { username: data.user.email }, { merge: true })
                            // firebase.firestore().collection('users').doc(data.user.uid).collection('private').doc('details')
                            //     .set({
                            //         username: data.user.email,
                            //     });
                            // resolve({ userData: data, firstSignIn: true });
                            let playlistInfo = { 'name': 'Listen Later', 'description': 'Default Playlist' };
                            createPlaylist(data.user, playlistInfo)
                                .then((playlistData) => {
                                    if (playlistData.success) {
                                        console.dir(playlistData);
                                        this.props.createPlaylistPersonal(playlistData.playlistInfo, playlistData.playlistId);
                                    }
                                })
                                .catch(err => {
                                    console.error(err);
                                });

                            // });
                            this.props.signInUserSuccess(data.user, 0);
                            saveLocally('authUserData', JSON.stringify(data), 24, true);
                            if (isFromAds) {
                                // setInterests({ 'Comedy': true, 'News': true }, data.user);
                                // this.props.createUserSuccess(data.user);

                                // Router.push({ pathname: '/ads' });
                            } else {
                                Router.push(`/login?showInterests=true`);
                            }
                        }
                    });
            }
        }).catch(function (error) {
            console.dir(error);
            // Handle Errors here.
            var errorCode = error.code;
            if (errorCode === 'auth/popup-closed-by-user') {
                return;
            } else if (errorCode == 'auth/web-storage-unsupported') {
                alert('This browser window is not supported to login. If you are using an Incongnito window, please open a normal window and try again.');
                return;
            }
            var errorMessage = error.message;
            if (!('email' in error)) {
                return;
            }
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            fetchSignInMethodsForEmail(auth, email).then(function (methods) {
                console.dir(methods);
                // Step 3.
                // If the user has several sign-in methods,
                // the first method in the list will be the "recommended" method to use.
                if (methods[0] === 'password') {
                    // Asks the user their password.
                    // In real scenario, you should handle this asynchronously.
                    // var password = promptUserForPassword(); // TODO: implement promptUserForPassword.
                    // auth.signInWithEmailAndPassword(email, password).then(function (user) {
                    //     // Step 4a.
                    //     return user.linkWithCredential(pendingCred);
                    // }).then(function () {
                    //     // Google account successfully linked to the existing Firebase user.
                    //     goToApp();
                    // });
                    // reject({
                    //   type: 'duplicateAuth', code: errorCode, message: `An account already exists with this email using 
                    //               the standard email/ password option. Please sign In using that or click Forgot password to reset your password.`});
                    alert(`An account already exists with this email using 
                      the standard email/ password option. Please sign In using that or click Forgot password to reset your password.`);
                    return;
                } else if (methods[0] === 'google.com') {
                    // reject({
                    //   type: 'duplicateAuth', code: errorCode, message: `An account already exists with this email using 
                    //                   google sign In. Please Sign In with Google.`});
                    alert(`An account already exists with this email using 
                          google sign In. Please Sign In with Google.`);
                    return;
                }

            });
        });

        loadUser(userDetails).then((userDetailsInfo) => {
            // console.dir(userDetailsInfo);
            if (userDetailsInfo) {
                const { userDetails, newData } = userDetailsInfo;
                newData && this.props.setUserDetails(userDetails);
                // (!subscribedPodcasts || Object.values(subscribedPodcasts).length < 1) && this.props.fetchUserSubscriptions(userDetails.uid);
                // (!userPlaylists || Object.values(userPlaylists).length < 1) && this.props.fetchPlaylists(userDetails.uid);
                // this.props.getPersonalDetails(userDetails.uid);
                this.props.signInUserSuccess(userDetails);
            }
            this.props.loadingUserDetailsState(false);
        }, (error) => {
            this.props.loadingUserDetailsState(false);
            console.error('error loadUser');
            console.error(error);
        });

        //get top podcasts or sponsored podcasts in future.
        this.props.fetchSideBarPodcasts();
        this.props.fetchTopChartsPodcastsRedux('video-0', 1);

        this.props.fetchYourPodcastSettings();

        this.props.getReadingChallenges();

        // window.addEventListener("beforeunload", (ev) => {
        //     ev.preventDefault();
        //     window.alert('Sure?');
        //     return ev.returnValue = 'Are you sure you want to close?';
        // });

        if (currentRoute.includes('premium-offer')) {
            saveLocally('hidePremiumBottom', true, 30 * 24);
        }
    }

    render() {
        const { children, settingsProp, currentRoute, settingsState } = this.props;
        // console.dir(settingsProp);
        const { leftBarOpen, mainLayoutColor } = settingsState;
        // const leftCloseClass = leftBarOpen ? '' : 'leftCloseClass';
        const leftSideBarValue = leftBarOpen ? leftSideBar : '40px';
        const isFeatured = currentRoute.startsWith(FEATURED_ROUTE);
        const isBlog = currentRoute.startsWith(BLOG_ROUTE);
        const isArticlePage = isArticle(currentRoute);
        const isLogin = currentRoute === LOGIN_ROUTE;
        const isAdsPage = currentRoute.startsWith(ADS_ROUTE);
        const isMinimalPage = currentRoute == IMPORT_OPML_ROUTE || currentRoute == AUTH_SUCCESS_ROUTE || isAdsPage || currentRoute == TWITTER_SUCCESS_ROUTE;
        // console.dir(currentRoute);
        const showRefresh = !isArticlePage;
        const showNavigator = !isArticlePage;
        let showRightBar = false;// !getIsPremiumUser(); // false;
        console.dir(currentRoute);
        const isPremiumPage = currentRoute.includes('premium-offer') || currentRoute.includes('premium-offer-limited') || currentRoute.includes('gift-card');
        console.dir(isPremiumPage);
        const isOnboardPage = currentRoute.includes('features') || currentRoute.includes('onboard-login')
            || currentRoute.includes('onboard-finish') || currentRoute.includes('trial');
        const isPromo = currentRoute.includes('premium-promo');
        const isDashboard = mainDashboardOptions.findIndex(el => currentRoute == el.url) > -1;
        if (isDashboard) {
            showRightBar = false;
        }
        return (
            <div className='layout-style'>
                <Head />
                {/* {!isLogin && !isFeatured && !isBlog && !isMinimalPage && !isPremiumPage && !isDashboard && <PremiumTop />} */}
                {!isLogin && !isFeatured && !isBlog && !isMinimalPage && !isOnboardPage && !isPromo &&
                    <LeftBar isDashboard={isDashboard} style={{ top: '50px' }} />}
                {/* {!isLogin && !isFeatured && !isBlog && !isMinimalPage && showRightBar && <RightBar />} */}
                {isPromo ? <></> : <>
                    {(isMinimalPage || isOnboardPage) && <TopBarMinimal isAdsPage={isAdsPage} />}
                    {!isArticlePage && !isMinimalPage && !isOnboardPage && <TopBar showRefresh={showRefresh && !isDashboard} showNavigator={showNavigator} isDashboard={isDashboard} isPremiumPage={isPremiumPage} isArticlePage={isArticlePage} />}
                    {isArticlePage && !isMinimalPage && <TopBarArticle isArticlePage={isArticlePage} />}
                </>}
                {/* {children} */}
                <div className="main">
                    {/* {!getIsPremiumUser() && !isAdsPage && !isDashboard &&
                        <div className='promo-div'>
                            <img style={{ maxWidth: '100%' }} src={`${staticAssetsPrefix}/static/images/PodcastReview.png`} />
                        </div>
                    } */}
                    {children}
                </div>
                {!isOnboardPage &&
                    <CookiePopup />}
                {!isLogin && !isMinimalPage && !isOnboardPage && !isPromo &&
                    <div style={{ position: 'fixed', bottom: '8em', right: '0' }}>
                        <ShowApps key='rightBar' />
                    </div>
                }
                {/* {!isFeatured && !isBlog && <AppOffline />} */}
                {/* {!isLogin && !isFeatured && !isBlog && !isMinimalPage && !isPremiumPage && <PremiumBottom />} */}
                {!isLogin && !isMinimalPage && !isDashboard && !isOnboardPage && !isPromo && <BottomBar />}
                {/* {isDashboard && <SendMessage />} */}
                <style jsx>
                    {`
                        @media screen {
                            @page {
                                margin: 0;
                                size: 768px 1024px;
                            }
                        }
                        .layout-style {
                            margin-left: ${(isArticlePage || isOnboardPage || isPromo) ? 0 : leftSideBarValue};
                            margin-right: ${!showRightBar ? 0 : (isArticlePage ? 0 : 'min(18vw, 300px)')};
                        }
                        .main {
                            padding: ${spacing('xl')};
                            // max-width: ${isArticlePage ? '680px' : 'auto'};
                            padding-bottom: ${isDashboard ? '8%' : '16%'};
                            padding-top: ${settingsState.topPaddingGlobal + (isPromo ? 0 : 96)}px; // a bit more than top bar height //   ${spacing('lg')};
                            transition: padding-top 1s; 
                            // margin-top: calc(${spacing('lg')} + ${spacing('xl')});
                            // margin-top: 64px; // same as top bar height
                            background-color: ${(isArticlePage || isMinimalPage) ? 'white' : mainLayoutColor};
                            height: auto; // 100vh;
                            min-height: 100vh;
                        }
                        :global(a){
                            color: ${colors.primary};
                        }
                        .promo-div {
                            display: none;
                        }
                        .promo-div img {
                            margin: 1em 0;
                            border-radius: 8px;
                        }
                        @media ${maxScreenSize.tablet} {
                            .promo-div {
                                display: block;
                            }
                            .layout-style{
                                margin-right: 0;
                                margin-left: 0;
                            }
                            .main {
                                padding: 0; // ${spacing('xs')};
                                padding-top: ${isPromo ? '0' : '96px'}; // a bit more than top bar height // 
                                padding-left: 0;// ${spacing('')};
                                padding-bottom: 25%;
                            }
                        }
                        @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                            .promo-div {
                                display: block;
                            }
                            .layout-style{
                                margin-right: 0;
                                margin-left: 0;
                            }
                            .main {
                                padding: ${spacing('lg')};
                                padding-top: ${isPromo ? '0' : '96px'}; // a bit more than top bar height // 
                                padding-left: ${spacing('xl')};
                                padding-bottom: 20%;
                            }
                        }
                    `}
                </style>
            </div>
        )
    }
}

Layout.propTypes = {
    children: PropTypes.element,
    settingsProp: PropTypes.object,
    currentRoute: PropTypes.string,
    loadingUserDetailsState: PropTypes.func,
    toggleDarkTheme: PropTypes.func,
    fetchSideBarPodcasts: PropTypes.func,
    fetchTopChartsPodcastsRedux: PropTypes.func,
    createPlaylistPersonal: PropTypes.func,
    signInUserSuccess: PropTypes.func,
    setUserDetails: PropTypes.func,
    fetchYourPodcastSettings: PropTypes.func,
    getReadingChallenges: PropTypes.func,
    createUserSuccess: PropTypes.func,
    settingsState: PropTypes.object,
    userDetails: PropTypes.object,
}

const store = initStore()
class MyApp extends App {
    // static async getInitialProps({ Component, ctx }) {
    //     try {
    //         let pageProps = {}

    //         if (Component.getInitialProps) {
    //             pageProps = await Component.getInitialProps(ctx)
    //         }

    //         return { pageProps }
    //     } catch (error) {
    //         // Capture errors that happen during a page's getInitialProps.
    //         // This will work on both client and server sides.
    //         const errorEventId = captureException(error, ctx)
    //         return {
    //             hasError: true,
    //             errorEventId
    //         }
    //     }
    // }

    constructor(props) {
        super(props);
        // this.props.loadingUserDetailsState(true);
        this.store = store;
        this.state = {
            hasError: props.hasError || false,
            errorEventId: undefined
        }
    }

    // componentDidMount() {
    //     const { userDetails, subscribedPodcasts, userPlaylists } = this.props;
    //     console.log('start loadUser');
    //     loadUser(userDetails).then((userDetailsInfo) => {
    //         // console.dir(userDetailsInfo);
    //         if (userDetailsInfo) {
    //             const { userDetails, newData } = userDetailsInfo;
    //             newData && this.props.setUserDetails(userDetails);
    //             // (!subscribedPodcasts || Object.values(subscribedPodcasts).length < 1) && this.props.fetchUserSubscriptions(userDetails.uid);
    //             // (!userPlaylists || Object.values(userPlaylists).length < 1) && this.props.fetchPlaylists(userDetails.uid);
    //             // this.props.getPersonalDetails(userDetails.uid);
    //             this.props.signInUserSuccess(userDetails);
    //         }
    //         this.props.loadingUserDetailsState(false);
    //     }, (error) => {
    //         this.props.loadingUserDetailsState(false);
    //         console.error('error loadUser');
    //         console.error(error);
    //     });

    //     //get top podcasts or sponsored podcasts in future.
    //     this.props.fetchSideBarPodcasts();

    //     // window.addEventListener("beforeunload", (ev) => {
    //     //     ev.preventDefault();
    //     //     window.alert('Sure?');
    //     //     return ev.returnValue = 'Are you sure you want to close?';
    //     // });
    // }

    static getDerivedStateFromProps(props, state) {
        // If there was an error generated within getInitialProps, and we haven't
        // yet seen an error, we add it to this.state here
        return {
            hasError: props.hasError || state.hasError || false,
            errorEventId: props.errorEventId || state.errorEventId || undefined
        }
    }

    static getDerivedStateFromError() {
        // React Error Boundary here allows us to set state flagging the error (and
        // later render a fallback UI).
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        const errorEventId = captureException(error, { errorInfo });
        // Store the event id at this point as we don't have access to it within
        // `getDerivedStateFromError`.
        this.setState({ errorEventId });
    }

    render() {
        const { Component, pageProps, router, settingsState } = this.props;
        // console.dir(router);
        // console.dir(pageProps);
        // console.dir(this.state.hasError);
        return this.state.hasError ? (
            <section style={{ textAlign: 'center', marginTop: '10%' }}>
                <div className='no-page-found'></div>
                <h3>There was an unexpected error and our page went missing&#33;&#33;&#33;</h3>
                <p>📣 Please help us find it by <a href='#'
                    onClick={() => Sentry.showReportDialog({ eventId: this.state.errorEventId })}>
                    <span style={{ textDecoration: 'underline', color: `${colors.third}` }}>reporting</span>
                </a> this error
                </p>
                <p>Or, try <a href='#'
                    onClick={() => { window.location.reload(true) }}>
                    <span style={{ textDecoration: 'underline' }}>reloading</span>
                </a> the page
                </p>
                {/* <AppCustomLink href='/home'><Button style={{ background: `${colors.third}`, color: 'white' }} onClick={this.clearError}>Home</Button></AppCustomLink> */}
                <Button style={{ background: `${colors.third}`, color: 'white' }} onClick={() => { window.location.href = '/home' }}>Home</Button>
                <style jsx>
                    {`
                    .no-page-found {
                        text-align: center;
                        height: 50vh;
                        background: url(${staticAssetsPrefix}/static/images/empty-state/page-not-found.svg);
                        background-size: 120% 98%;
                        background-position: center top;
                        background-repeat: no-repeat;
                    }
                `}</style>
            </section>
        ) : (
            // Render the normal Next.js page
            <>
                <NextSeo
                    // title='Home of Podcasts'
                    // description='Best place for Podcasts'
                    // canonical='https://tedapp.ai/'
                    // openGraph={{
                    //     type: 'website',
                    //     locale: 'en_IE',
                    //     url: 'https://tedapp.ai/',
                    //     site_name: 'Accelerated',
                    // }}
                    facebook={{
                        appId: 331590264386487,
                    }}
                // twitter={{
                //     handle: '@tedapp',
                //     site: '@tedapp',
                //     cardType: 'summary',
                // }}
                />
                <Provider store={this.store}>
                    <LayoutComponent currentRoute={router.route}>
                        {(router.route === '/login' || router.route.startsWith('/top-charts')) ? <Component {...pageProps} key={router.asPath} /> :
                            <PageTransition timeout={100} classNames="page-transition">
                                <Component {...pageProps} key={router.asPath} />
                                {/* <Component {...pageProps} /> */}
                            </PageTransition>
                        }
                    </LayoutComponent>
                </Provider>
                <style jsx global>{`
                        .page-transition-enter {
                            opacity: 0;
                        }
                        .page-transition-enter-active {
                            opacity: 1;
                            transition: opacity 300ms;
                        }
                        .page-transition-exit {
                            opacity: 1;
                        }
                        .page-transition-exit-active {
                            opacity: 0;
                            transition: opacity 300ms;
                        }
                    `}</style>
            </>
        )
    }
}

initRouterListeners();

function initRouterListeners() {

    console.log("Init router listeners");

    const routes = [];

    Router.events.on('routeChangeStart', url => {
        // console.log(`Loading: ${url}`)
        NProgress.start()
        pushCurrentRouteInfo();
    })
    Router.events.on('routeChangeComplete', (url) => {
        fixScrollPosition();
        NProgress.done();
        gtag.pageview(url);
        // console.dir(url);
        // logScreen(url + '-web');
    })
    Router.events.on('routeChangeError', () => NProgress.done());

    // Router.events.on('routeChangeStart', (url) => {
    //     pushCurrentRouteInfo();
    // });

    // Router.events.on('routeChangeComplete', (url) => {
    //     fixScrollPosition();
    // });


    // Hack to set scrollTop because of this issue:
    // - https://github.com/zeit/next.js/issues/1309
    // - https://github.com/zeit/next.js/issues/3303

    function pushCurrentRouteInfo() {
        routes.push({ asPath: Router.asPath, scrollY: window.scrollY });
    }

    // TODO: We guess we're going back, but there must be a better way
    // https://github.com/zeit/next.js/issues/1309#issuecomment-435057091
    function isBack() {
        return routes.length >= 2 && Router.asPath === routes[routes.length - 2].asPath;
    }

    function fixScrollPosition() {

        let scrollY = 0;

        if (isBack()) {
            routes.pop(); // route where we come from
            const targetRoute = routes.pop(); // route where we return
            scrollY = targetRoute.scrollY; // scrollY we had before
        }

        console.log("Scrolling to", scrollY);
        setTimeout(() => window.requestAnimationFrame(() => window.scrollTo(0, scrollY)), 600);
        console.log("routes now:", routes);
    }
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        subscribedPodcasts: state.podcastState,
        userPlaylists: state.userPlaylists,
        settingsState: state.settingsState,
        // userPlaylists: state.userPlaylists,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUserDetails: bindActionCreators(setUserDetails, dispatch),
        loadingUserDetailsState: bindActionCreators(loadingUserDetailsState, dispatch),
        // fetchUserSubscriptions: bindActionCreators(fetchUserSubscriptions, dispatch),
        // getPersonalDetails: bindActionCreators(getPersonalDetails, dispatch),
        fetchSideBarPodcasts: bindActionCreators(fetchSideBarPodcasts, dispatch),
        fetchTopChartsPodcastsRedux: bindActionCreators(fetchTopChartsPodcastsRedux, dispatch),
        createPlaylistPersonal: bindActionCreators(createPlaylistPersonal, dispatch),
        // fetchPlaylists: bindActionCreators(fetchPlaylists, dispatch),
        signInUserSuccess: bindActionCreators(signInUserSuccess, dispatch),
        toggleDarkTheme: bindActionCreators(toggleDarkTheme, dispatch),
        fetchYourPodcastSettings: bindActionCreators(fetchYourPodcastSettings, dispatch),
        getReadingChallenges: bindActionCreators(getReadingChallenges, dispatch),
        createUserSuccess: bindActionCreators(createUserSuccess, dispatch),
    }
};

// export default withRedux(initStore)(MyApp);
// export default compose(
//     withRedux(initStore, null, null),
//     connect(mapStateToProps, mapDispatchToProps)
// )(MyApp);
const LayoutComponent = connect(mapStateToProps, mapDispatchToProps)(Layout);
export default MyApp;
