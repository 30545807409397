import { firestore } from '../lib/db';
import { firstSetUrl, updateEnableKeyboardPlayback, updateForwardSeekTime, updateRewindSeekTime } from './index';
var UAParser = require('ua-parser-js');
import {
  cleanFirebaseId, createPodcastObj, serverApiEurope, cdnApi, getIdFromUID,
  setProgress, firestoreApi, sortPersonalValuesDesc, featuredMainUrl, getAutoMarkEpisodesPlayed, retrieveLocally, saveLocally, markPlayedDocName, revenueCatApiKey, serverApi, dashboardOSOptions, dashboardBrowserOptions,
  isConclusion
} from '../lib/utils';
import { Timestamp, collection, collectionGroup, query, where, getDoc, orderBy, limit, startAfter, getDocs, deleteField, doc, setDoc, increment } from "firebase/firestore/lite";
import { getItunesDataByCollectionId } from '../lib/searchItunes';
import {
  LOADING_PODCASTS,
  LOADING_SIDE_PODCASTS,
  LOADING_LATEST_EPISODES,
  LATEST_EPISODES,
  FETCH_SUBSCRIBED_PODCASTS,
  FETCH_DB_PODCASTS,
  // EDIT_DB_PODCASTS_SUSCRIBERS,
  LOADING_DB_PODCASTS,
  FETCH_SIDE_PODCASTS,
  UPDATE_AUDIO_INFO,
  SUBSCRIBE_PODCAST_SUCCESS,
  SUBSCRIBE_PODCAST_FAILURE,
  UNSUBSCRIBE_PODCAST_SUCCESS,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_RECENT_PLAYED_PODCASTS,
  // LAST_PLAYED_EPISODE,
  SET_USER_DETAILS,
  PERSONAL_RATINGS_SUCCESS,
  PERSONAL_VALUES_SUCCESS,
  PERSONAL_VALUES_PROGRESS_SUCCESS,
  FETCH_SUBSCRIBED_PODCASTS_ERROR,
  PERSONAL_VALUES_MARK_PLAYED_ACTION,
  ADD_SKIP_BEGINNING_ACTION,
  FETCH_FOLLOWED_PROFILES,
  UPDATE_YOUR_PODCAST_SETTINGS,
  FETCH_YOUR_PODCAST_SETTINGS,
  UPDATE_CLEAR_BADGES,
  FETCH_PODCAST_EXTRA_DETAILS,
  FORWARD_SEEK_TIME,
  REWIND_SEEK_TIME,
  ENABLE_KEYBOARD_PLAYBACK,
  PERSONAL_READS_SUCCESS_ACTION,
  FETCH_TOP_PODCASTS
}
  from './types';
import fetch from 'isomorphic-unfetch';
import { notification } from 'antd';
import { tokenFetch, tokenFetchPOST } from '../lib/tokenUtils';
import FireStoreParser from 'firestore-parser';
import { logSubscribePodcast } from '../lib/analyticsEvents';
import { setEpisodeAsPlayed } from './EpisodeActions';
import { fetchQueueInfo } from './QueueActions';
import { fetchEpisodeBookmarks } from './BookmarkActions';
import { fetchRelatedPodcasts } from './RelatedActions';
import { fetchPersonalizedPodcasts } from './HomePodcastActions';
import { setIsPremiumUser, setLatestEpisodeLimit, setSkipEndingInSec } from '../lib/fileUtils';
import { RESTORE_STATE_MAP, UPDATE_TIME } from '../lib/strings';

let firstTimeLatestEpisodesFetch = true;


//ACTIONS

export const fetchTopChartsPodcasts = async (genreId, fetchIndex) => {
  const url = firestoreApi + `topPodcasts/${genreId}/${fetchIndex}/podcasts`;
  console.dir(url);
  const res = await fetch(url);

  const json = await res.json();
  const parsedFirestoreJSON = FireStoreParser(json);
  const finalJsonArray = parsedFirestoreJSON?.fields?.podcasts || [];
  return finalJsonArray;
}
export const fetchTopChartsPodcastsRedux = (genreId, fetchIndex) => async dispatch => {
  const url = firestoreApi + `topPodcasts/${genreId}/${fetchIndex}/podcasts`;
  console.dir(url);
  const res = await fetch(url);

  const json = await res.json();
  const parsedFirestoreJSON = FireStoreParser(json);
  const finalJsonArray = parsedFirestoreJSON?.fields?.podcasts || [];
  console.dir(finalJsonArray);
  dispatch({
    type: FETCH_TOP_PODCASTS,
    typeId: genreId,
    podcasts: finalJsonArray
  })
  // return finalJsonArray;
}

export const fetchTopChartsMagazines = async (genreId, fetchIndex) => {
  const url = firestoreApi + `topMagazines/${genreId}/${fetchIndex}/podcasts`;
  console.dir(url);
  const res = await fetch(url);

  const json = await res.json();
  const parsedFirestoreJSON = FireStoreParser(json);
  const finalJsonArray = parsedFirestoreJSON?.fields?.podcasts || [];
  return finalJsonArray;
}

export const fetchSideBarPodcasts = () => async dispatch => {
  dispatch({
    type: LOADING_SIDE_PODCASTS,
    payload: { loadingSidePodcasts: true }
  })
  // let newState = {
  const sidePodcasts = {};

  // fetch(`${cdnApi}popularPodcasts`)
  //   .then(res => res.json())
  //   .then(data => {
  //     const podcastsData = data['podcastsData'];
  //     for (let podcast of podcastsData) {
  //       podcast['topPodcast'] = true;
  //       sidePodcasts[`${podcast.collectionId}`] = podcast;
  //     }
  //     dispatch({
  //       type: FETCH_SIDE_PODCASTS,
  //       payload: sidePodcasts
  //     })
  //     dispatch({
  //       type: LOADING_SIDE_PODCASTS,
  //       payload: { loadingSidePodcasts: false }
  //     })

  //   })
  //   .catch(function (error) {
  //     console.log("Error getting documents: ", error);
  //     dispatch({
  //       type: FETCH_SIDE_PODCASTS,
  //       payload: sidePodcasts
  //     })
  //     dispatch({
  //       type: LOADING_SIDE_PODCASTS,
  //       payload: { loadingSidePodcasts: false }
  //     })
  //   });

  // firebase.firestore().collection('podcasts').orderBy('rating', 'desc').limit(9)
  //   .get()
  //   .then(function (querySnapshot) {
  //     // let sidePodcasts = [];
  //     querySnapshot.forEach(function (doc) {
  //       // doc.data() is never undefined for query doc snapshots
  //       const obj = doc.data();
  //       obj['topPodcast'] = true;
  //       sidePodcasts[`${doc.id}`] = obj;
  //       // sidePodcasts.push(obj);
  //     });
  //     dispatch({
  //       type: FETCH_SIDE_PODCASTS,
  //       payload: sidePodcasts
  //     })
  //     dispatch({
  //       type: LOADING_SIDE_PODCASTS,
  //       payload: { loadingSidePodcasts: false }
  //     })
  //   })
  //   .catch(function (error) {
  //     console.log("Error getting documents: ", error);
  //     dispatch({
  //       type: FETCH_SIDE_PODCASTS,
  //       payload: sidePodcasts
  //     })
  //     dispatch({
  //       type: LOADING_SIDE_PODCASTS,
  //       payload: { loadingSidePodcasts: false }
  //     })
  //   });
};

export const dispatchPremium = () => async dispatch => {
  dispatch({
    type: SET_USER_DETAILS,
    payload: { premium: true } /// dummy just to refresh state
  });
}

//This sets up the listener to fetch posts.
//This pulls back an initial 50 posts but also sets
//a listener so as new posts fill in their are added to the top.
export let premiumBackupData = {};
export let premiumRevenueCatData = {};
export const fetchUserSubscriptions = (userUID) => async (dispatch, getState) => {
  // const db = await loadDB();
  console.log('fetchUserSubscriptions : ' + userUID);

  setTimeout(() => {
    dispatch(fetchQueueInfo());
  }, 1500);


  fetch(`https://api.revenuecat.com/v1/subscribers/` + userUID, {
    method: 'GET',
    headers: {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + revenueCatApiKey
    }
  }).then(async (data) => {
    console.dir(data);
    // const dataFin = await data.json();
    const dataFin = await data.json();
    console.dir(dataFin);
    if (dataFin && dataFin.subscriber && dataFin.subscriber.entitlements) {
      premiumRevenueCatData = dataFin;
      if (Object.keys(dataFin.subscriber.entitlements).length > 0) {
        const expiryDate = new Date(dataFin.subscriber.entitlements.premium.expires_date);
        console.dir(expiryDate.getTime());
        if (expiryDate.getTime() > Date.now()) {
          setIsPremiumUser(true);
          dispatch(fetchPersonalizedPodcasts({}, getState().subscribedPodcasts));
          dispatch(dispatchPremium());
        }
      }
    }
  }).catch(err => {
    console.dir(err);
  });
  getDoc(doc(firestore, `premium`, getIdFromUID(userUID)))
    .then(function (doc) {
      if (doc.exists()) {
        const data = doc.data();
        if (data['premium'] && data['premium']['is'] == true) {
          setIsPremiumUser(true);
          dispatch(fetchPersonalizedPodcasts({}, getState().subscribedPodcasts));
          dispatch(dispatchPremium());
          premiumBackupData = data;
        }
      }
    });

  getDoc(doc(firestore, `lastPlayedEpisodes`, getIdFromUID(userUID)))
    .then(function (doc) {
      var lastPlayedEpisode = {};
      if (doc.exists()) {
        if (typeof doc.data() !== 'undefined') {
          lastPlayedEpisode = doc.data();

          dispatch({
            type: UPDATE_AUDIO_INFO,
            payload: lastPlayedEpisode
          });
          setTimeout(() => {
            dispatch(firstSetUrl(lastPlayedEpisode));
            dispatch(fetchEpisodeBookmarks(lastPlayedEpisode));
          }, 800);
        }
      }
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
  getDoc(doc(firestore, `users`, userUID))
    .then(function (doc) {
      const docData = doc.data();
      let userData = {};
      if (doc.exists()) {
        userData['name'] = docData.name || '';
        userData['imgUrl'] = docData.imgUrl || '';
      }
      // console.dir(newState);
      dispatch({
        type: SET_USER_DETAILS,
        payload: userData
      });
      dispatch({
        type: FETCH_FOLLOWED_PROFILES,
        payload: docData.followingProfiles || {}
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
    });
  dispatch(fetchUserSubscribedPodcasts(userUID));

  const forwardSeekTime = retrieveLocally(FORWARD_SEEK_TIME);
  if (forwardSeekTime) {
    dispatch(updateForwardSeekTime(forwardSeekTime));
  }
  const rewindSeekTime = retrieveLocally(REWIND_SEEK_TIME);
  if (rewindSeekTime) {
    dispatch(updateRewindSeekTime(rewindSeekTime));
  }
  const enableKeyboardPlayback = retrieveLocally(ENABLE_KEYBOARD_PLAYBACK);
  if (enableKeyboardPlayback) {
    dispatch(updateEnableKeyboardPlayback(enableKeyboardPlayback));
  }
};

export const fetchUserSubscribedPodcasts = (userUID) => async dispatch => {
  dispatch({
    type: LOADING_PODCASTS,
    payload: { loadingPodcasts: true }
  })
  dispatch({
    type: FETCH_SUBSCRIBED_PODCASTS_ERROR,
    error: false
  })

  // firebase.firestore().collection('users').doc(userUID).collection('private').doc('details')
  //   // .get()
  //   // .then(function (doc) {
  //   .onSnapshot(function (doc) {
  getDoc(doc(firestore, `users/${userUID}/private`, 'details'))
    .then(function (doc) {
      // let newState = {
      let subscribedPodcasts = [];
      // }
      // let userPlaylists = {};
      let userData = {};
      if (doc.exists()) {
        if (typeof doc.data().podcasts !== 'undefined') {
          // console.dir(Object.values(doc.data().podcasts));
          subscribedPodcasts = doc.data().podcasts;
          userData['ownedPodcasts'] = doc.data().ownedPodcasts || [];
          const clearBadgesMap = {};
          Object.keys(subscribedPodcasts).forEach((collectionId) => {
            setSkipEndingInSec(
              collectionId, subscribedPodcasts[collectionId]['skipEndingInSeconds'] || 0);
            if ('clearBadgeGuid' in subscribedPodcasts[collectionId]) {
              clearBadgesMap[collectionId] = subscribedPodcasts[collectionId]['clearBadgeGuid'];
            }
            const latestEpisodeLimit =
              subscribedPodcasts[collectionId]['latestEpisodeLimit'];
            if (latestEpisodeLimit) {
              setLatestEpisodeLimit(collectionId,
                latestEpisodeLimit);
            }
          });
          dispatch({
            type: UPDATE_CLEAR_BADGES,
            payload: clearBadgesMap
          });
        }
        dispatch({
          type: SET_USER_DETAILS,
          payload: userData
        })
        // if (typeof doc.data().playlistsOwned !== 'undefined') {
        //   userPlaylists = doc.data().playlistsOwned;
        //   // console.dir(userPlaylists);
        // }
      }
      // dispatch(fetchLatestSubscribedEpisodes(Object.values(subscribedPodcasts)));
      dispatch(fetchPersonalizedPodcasts({}, subscribedPodcasts));
      dispatch({
        type: FETCH_SUBSCRIBED_PODCASTS,
        payload: subscribedPodcasts
      })
      // dispatch({
      //   type: FETCH_PLAYLISTS_SUCCESS,
      //   payload: userPlaylists
      // })
      dispatch({
        type: LOADING_PODCASTS,
        payload: { loadingPodcasts: false }
      });
    }, function (err) {
      console.log("Error getting documents: ", err);
      dispatch({
        type: LOADING_PODCASTS,
        payload: { loadingPodcasts: false }
      });
      dispatch({
        type: FETCH_SUBSCRIBED_PODCASTS_ERROR,
        error: true
      });
    }
    );
}

export const fetchLatestSubscribedEpisodes = (subscribedPodcasts, forceRefresh = false) => async (dispatch, getState) => {
  // let newState = {
  // const latestEpisodes = {};
  // }
  // dispatch({
  //   type: LOADING_LATEST_EPISODES,
  //   payload: { loadingLatestEpisodes: true }
  // })

  const fetchErrors = [];
  const latestFetched = 1;
  let fetchDone = 0;
  let latestEpisodes = getState().latestEpisodes;
  // let forceRefresh = false;
  if (subscribedPodcasts.length > 0) {
    // if (false) {
    let episodesFinMap = {};
    if (firstTimeLatestEpisodesFetch || forceRefresh) {
      // console.dir(forceRefresh);
      dispatch({
        type: LOADING_LATEST_EPISODES,
        payload: { loadingLatestEpisodes: true }
      });

      let futures = [];
      let now = new Date(); // DateTime.now();
      let daysOld = 5;
      let limitPerQuery = 15;
      let maxIndex = 5;
      const subscribedPodcastsLength = subscribedPodcasts.length;
      if (subscribedPodcastsLength > 150) {
        limitPerQuery = 10;
        daysOld = 3;
      } else if (subscribedPodcastsLength > 100) {
        limitPerQuery = 12;
        daysOld = 4;
      } else if (subscribedPodcastsLength > 75) {
        limitPerQuery = 15;
        daysOld = 5;
      } else if (subscribedPodcastsLength > 50) {
        limitPerQuery = 10;
        daysOld = 6;
      } else if (subscribedPodcastsLength > 25) {
        limitPerQuery = 15;
        daysOld = 7;
      } else {
        limitPerQuery = 18;
        daysOld = 8;
      }
      maxIndex = Math.min(40, Math.ceil(subscribedPodcastsLength / 10)); // 15;
      const millis =
        Date.now() - (daysOld * 24 * 3600 * 1000);
      // print('reqCollectionIds millis : ' + millis.toString());
      for (let index = 1; index <= maxIndex; index++) {
        let reqCollectionIds = [];
        for (let ind = 10 * (index - 1);
          ind < Math.min(10 * index, subscribedPodcasts.length);
          ind++) {
          let subscribedPodcast = subscribedPodcasts[ind];
          reqCollectionIds
            .push(subscribedPodcast['collectionId'] || -1);
        }
        // print('reqCollectionIds: ' + reqCollectionIds.toString());
        if (reqCollectionIds.length > 0) {
          if (subscribedPodcastsLength > 50) {
            let daysOldLatest = 5;
            if (subscribedPodcastsLength > 350) {
              daysOldLatest = 2;
            } else if (subscribedPodcastsLength > 250) {
              daysOldLatest = 3;
            } else if (subscribedPodcastsLength > 150) {
              daysOldLatest = 5;
            } else if (subscribedPodcastsLength > 75) {
              daysOldLatest = 6;
            } else {
              daysOldLatest = 3;
            }
            const millisLatest =
              Date.now() - (daysOldLatest * 24 * 3600 * 1000);
            console.dir('in 1');
            let futureLatest = getDocs(query(collectionGroup(firestore, "latestEpisode"), where('collectionId', 'in', reqCollectionIds), where('pubDate', '>', millisLatest),
              orderBy('pubDate', 'desc'),
              limit(10)))
              .catch((err) => {
                // print('docData 00 err: ' + err.toString());
                console.dir(err);
                console.error(err);
              });
            futures.push(futureLatest);
          }
          if (subscribedPodcastsLength < 75) {
            console.dir('in 2');
            console.dir(reqCollectionIds);
            if (limitPerQuery > 0) {
              let future = getDocs(query(collection(firestore, "episodes"), where('collectionIdArray', 'array-contains-any', reqCollectionIds), where('pubDate', '>', millis),
                orderBy('pubDate', 'desc'),
                limit(limitPerQuery)))
                .catch((err) => {
                  // print('docData 00 err: ' + err.toString());
                  console.error(err);
                });
              futures.push(future);
            }
          }
        }
      }
      // let podcastExtraDetailsOrg =
      //   podcastExtraDetailsSelector(store.state); // {};
      // final Map podcastExtraDetails = {}..addAll(podcastExtraDetailsOrg);
      let results = await Promise.all(futures);
      for (let result of results) {
        console.dir(result);
        // print('docData 00 result : ' + result.toString());
        if (result?.docs != null) {
          result.forEach((element) => {
            const docData = element.data();
            // print('docData 00 ' + docData.toString());
            let episodeFin = docData;
            // PodcastEpisode.fromJson(docData);
            episodesFinMap[`${episodeFin.guid}`] = episodeFin;
            // if (podcastExtraDetails
            //   .containsKey('${episodeFin.collectionId}')) {
            //   final currentCount =
            //     podcastExtraDetails['${episodeFin.collectionId}']
            //     ['episodeCount'] ??
            //     0;
            //   if (episodeFin.appEpisodeNumber > currentCount) {
            //     podcastExtraDetails['${episodeFin.collectionId}'] = {
            //       'episodeCount': episodeFin.appEpisodeNumber,
            //       'latestReleaseDate': episodeFin.pubDate,
            //       'latestGuid': episodeFin.guid
            //     };
            //     utils.addPodcastExtraToStorage(episodeFin);
            //   }
            // } else {
            //   podcastExtraDetails['${episodeFin.collectionId}'] = {
            //     'episodeCount': episodeFin.appEpisodeNumber,
            //     'latestReleaseDate': episodeFin.pubDate,
            //     'latestGuid': episodeFin.guid
            //   };
            //   utils.addPodcastExtraToStorage(episodeFin);
            // }
          });
        }
      }
      // store.dispatch(FetchPodcastExtraDetailsAction(podcastExtraDetails));
      // // print('podcastExtraDetails 00 results : ' +
      // //     podcastExtraDetails.toString());
      // // print('podcastExtraDetails length : ' +
      // //     podcastExtraDetails.length.toString());
      // store.dispatch(FetchLatestEpisodesAction(episodesFinMap));
      // store.dispatch(
      //   LoadingLatestEpisodesAction({ 'loadingLatestEpisodes': false }));
      dispatch({
        type: LATEST_EPISODES,
        payload: episodesFinMap
      });
      dispatch({
        type: LOADING_LATEST_EPISODES,
        payload: { loadingLatestEpisodes: false }
      });

      const futuresLast = [];
      for (let index = 0; index < subscribedPodcasts.length; index += 10) {
        const reqCollectionIdsSubset = [];
        for (let ind = index;
          ind < Math.min(index + 10, subscribedPodcasts.length);
          ind++) {
          reqCollectionIdsSubset.push(subscribedPodcasts[ind]['collectionId'] || -1);
        }
        console.dir(reqCollectionIdsSubset);
        let futureLatest = getDocs(query(collectionGroup(firestore, "latestEpisode"), where('collectionId', 'in', reqCollectionIdsSubset), where('pubDate', '>', 0),
          orderBy('pubDate', 'desc'),
          limit(10)))
          .catch((err) => {
            // print('docData 00 err: ' + err.toString());
            console.dir(err);
            console.error(err);
          });
        futuresLast.push(futureLatest);
      }
      const resultsLast = await Promise.all(futuresLast);
      const podcastExtraDetailsMap = {};
      for (const resultLast of resultsLast) {
        if (resultLast && resultLast.docs) {
          resultLast.docs.forEach(doc => {
            const episodeFin = doc.data();
            podcastExtraDetailsMap[`${episodeFin.collectionId}`] = episodeFin;
          })
        }
      }
      console.dir(podcastExtraDetailsMap);
      dispatch({
        type: FETCH_PODCAST_EXTRA_DETAILS,
        payload: podcastExtraDetailsMap
      });
    }
    if ((!firstTimeLatestEpisodesFetch && !forceRefresh) ||
      episodesFinMap.length <= 3) {
      let futures = [];
      let maxEpisodes = 10; // forceRefresh ? 30 : 2;
      let currLimit = 0;
      // print('in maxEpisodes');
      for (let ind = 0; ind < subscribedPodcasts.length; ind++) {
        const subscribedPodcast = subscribedPodcasts[ind];
        // print('subscribedPodcast : ' + '${subscribedPodcast['collectionId']}');
        const alreadtPresentIndex = Object.values(latestEpisodes)
          .indexOf((ep) =>
            `${ep.collectionId}` ==
            `${subscribedPodcast['collectionId']}`);
        if (alreadtPresentIndex < 0 && currLimit <= maxEpisodes) {
          currLimit++;
          const future = getDoc(doc(firestore, `podcasts/${subscribedPodcast['collectionId']}/latestEpisode`, 'episode'))
            .catch((err) => {
              console.error(err);
            });
          futures.push(future);
        }
      }
      try {
        let results = await Promise.all(futures);
        // print('latestepisode results : ' + results.toString());
        let latestEpisodeList = [];
        for (let result of results) {
          if (result.data()) {
            // final PodcastEpisode episodeFin =
            //     PodcastEpisode.fromJson(result.data());
            let episodeFin = result.data();
            episodesFinMap[`${episodeFin.guid}`] = episodeFin;
            latestEpisodeList.push(episodeFin);
          }
        }
        // store.dispatch(updatePodcastExtraDetails(latestEpisodeList));
        // store.dispatch(FetchLatestEpisodesAction(episodesFinMap));
        // store.dispatch(
        //     LoadingLatestEpisodesAction({'loadingLatestEpisodes': false}));
        dispatch({
          type: LATEST_EPISODES,
          payload: episodesFinMap
        });
        dispatch({
          type: LOADING_LATEST_EPISODES,
          payload: { loadingLatestEpisodes: false }
        });
      } catch (err) {
        // store.dispatch(
        //   LoadingLatestEpisodesAction({ 'loadingLatestEpisodes': false }));
        dispatch({
          type: LOADING_LATEST_EPISODES,
          payload: { loadingLatestEpisodes: false }
        });
        if (episodesFinMap.length < 1) {
          // print('errorState fetchErrorsLoc inside : ' + err.toString());
          // if (utils.handleError(err).isNotEmpty) {
          //   store.dispatch(ErrorLatestEpisodesAction({
          //     'errorLatestEpisodes': {
          //       'error': true,
          //       'code': utils.handleErrorCode(err),
          //       'message': utils.handleError(err)
          //     }
          //   }));
          // }
        }
      }
    }
    firstTimeLatestEpisodesFetch = false;





    /***************************************************** */
    // // for (let feedUrl of feedUrls) {
    // let futures = [];
    // // for (let subscribedPodcast of subscribedPodcasts) {
    // for (let ind = 0; ind < Math.min(20, subscribedPodcasts.length); ind++) {
    //   let subscribedPodcast = subscribedPodcasts[ind];
    //   if (!(`${subscribedPodcast['collectionId']}` in latestEpisodes)) {
    //     console.dir(subscribedPodcast['collectionId']);
    //     const future = firebase.firestore()
    //       .collection('podcasts')
    //       .doc(`${subscribedPodcast['collectionId']}`)
    //       .collection('latestEpisode')
    //       .doc('episode')
    //       .get()
    //       .catch((err) => {
    //         console.error(err);
    //       });
    //     futures.push(future);
    //   }
    // }
    // let episodesFinMap = {};
    // try {
    //   const results = await Promise.all(futures);
    //   console.dir(results);
    //   for (const result of results) {
    //     const episodeFin = result.data();
    //     if (episodeFin != null) {
    //       // final PodcastEpisode episodeFin =
    //       //     PodcastEpisode.fromJson(result.data);
    //       episodesFinMap[`${episodeFin.collectionId}`] = episodeFin;
    //     }
    //   }
    //   dispatch({
    //     type: LATEST_EPISODES,
    //     payload: episodesFinMap
    //   });
    //   dispatch({
    //     type: LOADING_LATEST_EPISODES,
    //     payload: { loadingLatestEpisodes: false }
    //   });
    // } catch (err) {
    //   dispatch({
    //     type: LOADING_LATEST_EPISODES,
    //     payload: { loadingLatestEpisodes: false }
    //   });
    //   if (episodesFinMap.isEmpty) {
    //     // print('errorState fetchErrorsLoc inside : ' + err.toString());
    //     // if (utils.handleError(err).isNotEmpty) {
    //     //   store.dispatch(ErrorLatestEpisodesAction({
    //     //     'errorLatestEpisodes': {
    //     //       'error': true,
    //     //       'code': utils.handleErrorCode(err),
    //     //       'message': utils.handleError(err)
    //     //     }
    //     //   }));
    //     // }
    //   }
    // }
  } else {
    // allEpisodesLoaded(fetchDone, subscribedPodcasts);
    dispatch({
      type: LOADING_LATEST_EPISODES,
      payload: { loadingLatestEpisodes: false }
    });
  }
  // function allEpisodesLoaded(fetchDone, subscribedPodcasts) {
  //   if (fetchDone == subscribedPodcasts.length) {
  //     dispatch({
  //       type: LOADING_LATEST_EPISODES,
  //       payload: { loadingLatestEpisodes: false }
  //     })
  //   }
  // }
}

export const subscribePodcast = (userUID, title, author, imgSrc, collectionId, feedUrl, genres) => async dispatch => {
  // const db = await loadDB();
  // if (!feedUrl) {
  //   notification['error']({
  //     message: "This podcast doesn't have a valid rss feed. Please try another podcast.",
  //     // description: 'Please try again.',
  //     duration: null
  //   });
  //   return;
  // }
  console.log(userUID);
  const podcastId = parseInt(collectionId);
  console.log(podcastId);
  var podcastsUpdate = {};
  var podcastObj = {
    id: podcastId,
    collectionName: title, //podcastInfo.collectionName || podcastInfo.trackName,
    artistName: author || '', //podcastInfo.artistName,
    artworkUrl600: imgSrc || '', //podcastInfo.artworkUrl600,
    collectionId: podcastId,
    feedUrl: feedUrl || '',
    genres: genres,
    subscribed: true,
    subscribedTime: Timestamp.now() // firebase.firestore.FieldValue.serverTimestamp()
  };
  podcastsUpdate[`${podcastId}`] = podcastObj;
  dispatch({
    type: SUBSCRIBE_PODCAST_SUCCESS,
    payload: podcastsUpdate
  });
  // dispatch({
  //   type: EDIT_DB_PODCASTS_SUSCRIBERS,
  //   payload: { itunesId: podcastId, value: 1 }
  // });
  logSubscribePodcast(podcastId, 1);
  setDoc(doc(firestore, `users/${userUID}/private`, 'details'), { podcasts: podcastsUpdate }, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      // dispatch({
      //   type: SUBSCRIBE_PODCAST_FAILURE,
      //   payload: null
      // });
      notification['error']({
        message: 'Error subscribing to this podcast.',
        description: 'Please try again.',
        duration: null
      });
      dispatch({
        type: UNSUBSCRIBE_PODCAST_SUCCESS,
        payload: podcastId
      });
    });
};

// const logPodcast = (collectionId, action) => new Promise((resolve, reject) => {
//   fetch(`${serverApiEurope}subscribePodcasts?itunesId=${collectionId}&action=${action}`)
//     .then(r => r.json())
//     .then(data => {
//       // console.log(data);
//       resolve(data);
//     })
//     .catch(err => reject(err));
// })

export const unsubscribePodcast = (userUID, podcastId) => async dispatch => {
  // const db = await loadDB();
  console.log(userUID);
  console.log(podcastId);
  var podcastsUpdate = {};
  // podcastsUpdate[`podcasts.${podcastId}`] = deleteField();
  const temp = {};
  temp[`${podcastId}`] = deleteField();
  podcastsUpdate['podcasts'] = temp;
  dispatch({
    type: UNSUBSCRIBE_PODCAST_SUCCESS,
    payload: podcastId
  });
  // dispatch({
  //   type: EDIT_DB_PODCASTS_SUSCRIBERS,
  //   payload: { itunesId: podcastId, value: -1 }
  // });
  logSubscribePodcast(podcastId, 0);
  setDoc(doc(firestore, `users/${userUID}/private`, 'details'), podcastsUpdate, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      // dispatch({
      //   type: SUBSCRIBE_PODCAST_FAILURE,
      //   payload: null
      // });
      notification['error']({
        message: 'Error unsubscribing from this podcast.',
        description: 'Please refresh the page and try again.',
        duration: null
      });
      // dispatch({
      //   type: UNSUBSCRIBE_PODCAST_SUCCESS,
      //   payload: podcastId
      // });
    });
};

export const unsubscribeAllPodcasts = (userUID) => async (dispatch, getState) => {
  const podcastState = getState().podcastState;
  var podcastsUpdate = {};
  // podcastsUpdate[`podcasts.${podcastId}`] = deleteField();
  const temp = {};
  Object.keys(podcastState).forEach(podcastId => {
    temp[`${podcastId}`] = deleteField();
    dispatch({
      type: UNSUBSCRIBE_PODCAST_SUCCESS,
      payload: podcastId
    });
  });
  podcastsUpdate['podcasts'] = temp;
  setDoc(doc(firestore, `users/${userUID}/private`, 'details'), podcastsUpdate, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      notification['error']({
        message: 'Error unsubscribing from this podcast.',
        description: 'Please refresh the page and try again.',
        duration: null
      });
    });
}

export const getPodcastDataDB = async (itunesId) => {
  try {
    const url = firestoreApi + `podcasts/${itunesId}`;
    const response = await fetch(url);
    const json = await response.json();
    const parsedFirestoreJSON = FireStoreParser(json);
    console.log('in main');
    console.dir(parsedFirestoreJSON);
    if (parsedFirestoreJSON?.error) {
      throw 'Error';
    }
    let finalEpisodeJson = parsedFirestoreJSON?.fields || {};
    return finalEpisodeJson;
  } catch (err) {
    console.log('in err');
    throw err;
  }
}

export const getSpotifyLink = async (itunesId) => {
  try {
    const response = await fetch(`${serverApi}spotifyLink?itunesId=${itunesId}`);
    const json = await response.json();
    return json && json.spotifyId || '';
  } catch (err) {
    console.log('in err');
    throw err;
  }
}

export const logOnePodcastData = (podcastData) => {
  console.dir(podcastData);
  if (podcastData.collectionId) {
    getDoc(doc(firestore, `podcasts`, `${podcastData.collectionId}`))
      .then(function (doc) {
        let docData;
        if (doc.exists()) {
          docData = doc.data();
          console.dir(docData);
        } else {
          docData = {};
          logPodcastWithItunesData(podcastData);
        }
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }
}

export const getPodcastData = (itunesId) => async (dispatch, getState) => {
  const prevFetchedDBPodcast = getState().dbPodcastState && getState().dbPodcastState[String(`${itunesId}`)];
  if (!prevFetchedDBPodcast || !prevFetchedDBPodcast.collectionId) {
    const itunesIdStr = String(itunesId);
    dispatch({
      type: LOADING_DB_PODCASTS,
      payload: { loadingDBPodcastData: true }
    });
    getDoc(doc(firestore, `podcasts`, itunesIdStr))
      .then(function (doc) {
        let docData;
        if (doc.exists()) {
          docData = doc.data();
          // resolve(doc.data());
          dispatch(fetchRelatedPodcasts(docData));
          dispatch({
            type: FETCH_DB_PODCASTS,
            payload: { [itunesId]: docData }
          });
        } else {
          docData = {};
          logPodcastData(itunesId);
          // resolve({});
        }
        dispatch({
          type: LOADING_DB_PODCASTS,
          payload: { loadingDBPodcastData: false }
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        dispatch({
          type: LOADING_DB_PODCASTS,
          payload: { loadingDBPodcastData: false }
        });
        // reject(error);
      });
  }
};

function logPodcastData(collectionId) {
  // return getItunesDataByCollectionId(collectionId).then((data) => {
  //   console.dir(data);
  //   logPodcastWithItunesData(data);
  // })
}

export const logPodcastWithItunesData = (itunesData) => {
  const url = serverApiEurope + `logPodcastDataUrl`;
  return fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    // mode: 'cors', // no-cors, *cors, same-origin
    // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    // credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(itunesData) // body data type must match "Content-Type" header
  });
}

export const setEpisodeProgress = (userUID, episodeInfo, progress, save = false) => async (dispatch, getState) => {
  console.dir(episodeInfo);
  // const db = await loadDB();
  // const backupIdCleaned = backupId.replace(/ /g, '_');
  var guidCleaned
  if (typeof episodeInfo.guid !== 'undefined') {
    guidCleaned = cleanFirebaseId(episodeInfo.guid);
  }
  // const episodeId = guidCleaned || backupIdCleaned;
  var personalProgressUpdate = {};
  personalProgressUpdate[`${guidCleaned}`] = {
    progress: setProgress(progress),
    collectionId: episodeInfo.collectionId,
    lastPlayed: Timestamp.now()
  };
  dispatch({
    type: PERSONAL_VALUES_PROGRESS_SUCCESS,
    'guidCleaned': guidCleaned,
    'personalProgressUpdate': personalProgressUpdate[`${guidCleaned}`],
  });
  if (save) {
    // console.log('saving');
    // console.dir(setProgress(progress));
    // console.dir(getAutoMarkEpisodesPlayed());
    if (getAutoMarkEpisodesPlayed() > 10 && Math.ceil(setProgress(progress)) >= getAutoMarkEpisodesPlayed()) {
      const personalMarkPlayedValues = getState().personalMarkPlayedValues;
      const personalMarkPlayedValuesSpecific = personalMarkPlayedValues[`${guidCleaned}`] || false;
      console.dir(personalMarkPlayedValuesSpecific);
      if (!personalMarkPlayedValuesSpecific) {
        dispatch(setEpisodeAsPlayed(episodeInfo, true));
      }
    }
    setDoc(doc(firestore, `users/${userUID}/personal`, 'details'), { values: personalProgressUpdate }, { merge: true })
      .then(() => {
        // console.dir(data);
        // dispatch({
        //   type: PERSONAL_VALUES_PROGRESS_SUCCESS,
        //   payload: personalProgressUpdate
        // });
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        // reject({ error });
      });
  }
};


// ThunkAction<AppState> setEpisodeReadProgress(PodcastEpisode episodeInfo) {
//   return (Store<AppState> store) async {
export const setEpisodeReadProgress = (userUID, episodeInfo) => async (dispatch, getState) => {
  const personalReads = getState().personalReads;
  if (personalReads[episodeInfo.guid]) {
    const currMap = personalReads[episodeInfo.guid];
    // final DateTime lastDateTime =
    // utils.convertToDateTime(currMap['last'], setOldDate: true);
    const lastPlayed = currMap['last'].seconds * 1000;
    console.dir(lastPlayed);

    /// if logged in last 6 hrs, return
    if (Date.now() - lastPlayed <
      6 * 60 * 60 * 1000) {
      return;
    }
    console.dir('lastPlayed 2');
  }
  // final String userUID = utils.getFirebaseUID();
  const personalProgressUpdate = {};
  // final double progressFinal = utils.setProgress(progress);
  personalProgressUpdate[`${episodeInfo.guid}`] = {
    // 'progress': progressFinal,
    'id': episodeInfo.collectionId,
    'last': Timestamp.now()
  };
  if (isConclusion(episodeInfo.title)) {
    personalProgressUpdate[`${episodeInfo.guid}`]['c'] = true;
  }
  const totalUpdate = {
    [UPDATE_TIME]: Date.now(),
    'values': personalProgressUpdate,
  };
  // utils.printLog('set episode readDetails to firestore');
  // FirebaseFirestore.instance
  //   .collection('users')
  //   .doc(userUID)
  //   .collection('personal')
  //   .doc('readDetails')
  //   // .set({'values': personalProgressUpdate},
  //   .set(totalUpdate, SetOptions(merge: true))
  dispatch({
    type: PERSONAL_READS_SUCCESS_ACTION,
    'payload': personalProgressUpdate,
  });
  setDoc(doc(firestore, `users/${userUID}/personal`, 'readDetails'), totalUpdate, { merge: true })
    .then((data) => {
      // console.dir(data);
      // dispatch({
      //   type: PERSONAL_VALUES_PROGRESS_SUCCESS,
      //   payload: personalProgressUpdate
      // });
    }).catch((error) => {
      console.error("Error adding document: " + error.toString());
      // reject({ error });
    });
  // AnalyticsEvents.logReadIdea(episodeInfo.guid, episodeInfo.collectionId);
}

const saveEpisodeProgressFromLocal = (
  userUID, progress, guidCleaned, collectionId) => {
  const personalProgressUpdate = {};
  const progressFinal = setProgress(progress);
  personalProgressUpdate[`${guidCleaned}`] = {
    'progress': progressFinal,
    'collectionId': collectionId,
    'lastPlayed': Timestamp.now()
  };
  const totalUpdate = {
    [UPDATE_TIME]: Date.now(),
    'values': personalProgressUpdate,
    // LISTENING_STATS: listeningStats
  };
  setDoc(doc(firestore, `users/${userUID}/personal`, 'details'), totalUpdate, { merge: true })
    .catch((error) => {
      console.error("Error adding document: " + error.toString());
    });
}

export const getPersonalDetails = (userUID) => async dispatch => {
  console.log('getPersonalDetails : ' + userUID);

  // final SharedPreferences prefs = await SharedPreferences.getInstance();
  const restoreMapString = retrieveLocally(RESTORE_STATE_MAP);
  let restoreMap = {};
  if (restoreMapString) {
    try {
      restoreMap = JSON.parse(restoreMapString);
      // print('restoreMap11: ' + restoreMap.toString());
    } catch (err) {
      // print(err);
    }
  }

  // const db = await loadDB();
  getDoc(doc(firestore, `users/${userUID}/personal`, 'details'))
    .then(function (doc) {
      let personalValues = {};
      if (doc.exists()) {
        if (typeof doc.data().values !== 'undefined') {
          personalValues = doc.data().values || {};
          console.dir(restoreMap);
          Object.entries(personalValues).forEach((entry) => {
            const [key, value] = entry;
            if ('guid' in restoreMap) {
              if (key == cleanFirebaseId(restoreMap['guid'])) {
                const lastPlayed = value['lastPlayed'];
                // convertToDateTime(value['lastPlayed']);
                console.dir(lastPlayed);
                console.dir(restoreMap);
                if (lastPlayed && restoreMap[UPDATE_TIME] >
                  (lastPlayed.seconds * 1000)) {
                  // print('inside restoreMap');
                  value['progress'] = restoreMap['progress'];
                  console.dir(value);
                  saveEpisodeProgressFromLocal(
                    userUID,
                    value['progress'],
                    cleanFirebaseId(restoreMap['guid']),
                    value['collectionId']);
                }
              }
            }
            // setPersonalProgressDetails(
            //     key, value.containsKey('progress') ? value['progress'] : 0.0);
          });
          // getRecentPodcasts(dispatch, personalValues);
        }
      }
      dispatch({
        type: PERSONAL_VALUES_SUCCESS,
        payload: personalValues
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
      dispatch({
        type: PERSONAL_VALUES_SUCCESS,
        payload: null
      });
    });
  let personalMarkPlayedValues = {};
  for (let ind = 1; ind < 5; ind++) {
    getDoc(doc(firestore, `users/${userUID}/personal`, markPlayedDocName(ind)))
      .then(function (doc) {
        console.log('podcastActions 0');
        console.dir(doc);
        if (doc.exists()) {
          console.dir(doc.data());
          if (typeof doc.data().guids !== 'undefined') {
            personalMarkPlayedValues = doc.data().guids;
          }
        }
        dispatch({
          type: PERSONAL_VALUES_MARK_PLAYED_ACTION,
          'personalMarkPlayedUpdate': personalMarkPlayedValues
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        dispatch({
          type: PERSONAL_VALUES_MARK_PLAYED_ACTION,
          'personalMarkPlayedUpdate': {}
        });
      });
  }
  getDoc(doc(firestore, `users/${userUID}/personal`, 'ratings'))
    .then(function (doc) {
      let personalValues = {};
      if (doc.exists()) {
        if (typeof doc.data().values !== 'undefined') {
          personalValues = doc.data().values;
        }
      }
      dispatch({
        type: PERSONAL_RATINGS_SUCCESS,
        payload: personalValues
      });
    })
    .catch(function (error) {
      console.log("Error getting documents: ", error);
      dispatch({
        type: PERSONAL_RATINGS_SUCCESS,
        payload: null
      });
    });
};

// Future getRecentPodcasts(Store<AppState> store, Map personalValues) async {
export const getRecentPodcasts = (personalValues) => async dispatch => {
  // final Map recentPlayedEpisodes = store.state.recentlyPlayedEpisodes;
  let personalValuesSorted = sortPersonalValuesDesc(personalValues);
  // print('personalValuesSorted : ' + personalValuesSorted.toString());

  // let recentPlayedEpisodesSorted = recentPlayedEpisodes ).sort(sortNumericallyLastDesc);
  // console.dir(recentPlayedEpisodesSorted);
  let recentPlayedUniqueCollectionIdsObj = {};
  const maxRecentNumber = 40;
  let collectionIds = [];
  let i = 1;
  let futures = [];
  for (let progressMap of personalValuesSorted) {
    // console.dir(episode);
    if (i > maxRecentNumber) {
      break;
    }
    if ('collectionId' in progressMap) {
      // print('collectionIds 0 i : ' + i.toString());
      let collectionId = progressMap['collectionId'];
      if (!(`${collectionId}` in recentPlayedUniqueCollectionIdsObj)) {
        recentPlayedUniqueCollectionIdsObj[`${collectionId}`] = 1;
        collectionIds.push(collectionId);
        futures.push(getPodcastDataDB(collectionId).catch(err => { return {} }));
        i++;
      }
    }
  }
  // List results = await Future.wait(futures);
  let results = await Promise.all(futures);
  console.dir(recentPlayedUniqueCollectionIdsObj);
  console.dir('results 0 : ');
  console.dir(results);
  let recentPodcasts = [];
  for (let result of results) {
    if ('collectionId' in result) {
      recentPodcasts.push(result);
    }
  }
  console.dir('collectionIds 0 : ');
  console.dir(collectionIds);
  recentPodcasts = recentPodcasts.filter((value, index, array) =>
    array.indexOf(value) === index
  )
  recentPodcasts = recentPodcasts.reverse();
  dispatch({
    type: FETCH_RECENT_PLAYED_PODCASTS,
    payload: recentPodcasts
  });
  // setRecentCollectionIds(collectionIds);
}

export const addCustomRSSFeed = async (rssUrl, username, password) => {
  // ThunkAction<AppState> addCustomRSSFeed(String rssUrl) {
  // return (Store<AppState> store) async {
  let feedUrl = encodeURIComponent(rssUrl); // Uri.encodeComponent(rssUrl);
  try {
    // Dio dio = new Dio();
    // final res = await dio
    //     .get('${utils.serverApiEurope}addCustomRSS?rss_url=${feedUrl}');

    // final result = res.data;
    // return result;
    const body = {};
    let query = `addCustomRSS?rss_url=${feedUrl}`;
    if (username && password) {
      body['username'] = username;
      body['password'] = password;
    }
    // const res = await tokenFetch(`${serverApiEurope}${query}`)
    const res = await tokenFetchPOST(`${serverApiEurope}${query}`, JSON.stringify(body));
    // .then((res) => {
    //   // return ({ success: true });
    console.dir(res);
    const json = await res.json();
    console.dir(json);
    return json;
    // }).catch((error) => {
    //   console.log("Error adding rss: " + error);
    //   throw error;
    // });
  } catch (error) {
    // store.dispatch(LoadingCustomRSSAction({'loadingCustomRSSAction': false}));
    console.log("Error adding rss: " + error);
    throw error;
  }
  // };
}

export const addSkipBeginning = (userUID, collectionId, skipBeginningInSeconds) => async dispatch => {
  const podcastsUpdate = {};
  const podcastObj = { 'skipBeginningInSeconds': parseInt(skipBeginningInSeconds) };
  podcastsUpdate[`${collectionId}`] = podcastObj;
  // store
  //   .dispatch(AddSkipBeginningAction(skipBeginningInSeconds, collectionId));
  dispatch({
    type: ADD_SKIP_BEGINNING_ACTION,
    skipBeginningInSeconds: skipBeginningInSeconds,
    collectionId: collectionId
  });

  // AnalyticsEvents.logSkipSecondsBeginningSeries(
  //     skipBeginningInSeconds, collectionId);
  return setDoc(doc(firestore, `users/${userUID}/private`, 'details'), { 'podcasts': podcastsUpdate }, { merge: true })
    // .then((data) {})
    .catch((error) => {
      // print("Error adding document: " + error.toString());
      console.error(error);
    });
}

// Future addSkipEnding(
//   String userUID, collectionId, int skipEndingInSeconds) async {
// return (Store<AppState> store) async {
export const addSkipEnding = (userUID, collectionId, skipEndingInSecondsInit) => {
  const skipEndingInSeconds = parseInt(skipEndingInSecondsInit);
  const podcastsUpdate = {};
  const podcastObj = { 'skipEndingInSeconds': skipEndingInSeconds };
  podcastsUpdate[`${collectionId}`] = podcastObj;
  // store
  //     .dispatch(AddSkipBeginningAction(skipBeginningInSeconds, collectionId));
  setSkipEndingInSec(`${collectionId}`, skipEndingInSeconds);

  // AnalyticsEvents.logSkipSecondsEndingSeries(skipEndingInSeconds, collectionId);
  return setDoc(doc(firestore, `users/${userUID}/private`, 'details'), { 'podcasts': podcastsUpdate }, { merge: true })
    // .then((data) { })
    .catch((error) => {
      // print("Error adding document: " + error.toString());
      console.error(error);
    });
  // };
}


export const fetchYourPodcastSettings = () => async dispatch => {
  const subscribedSortTypeValue = retrieveLocally('subscribedSortTypeOption') || 0;
  const subscribedBadgeValue = retrieveLocally('subscribedBadgeOption') || 0;
  const ignoreArticlesValue = retrieveLocally('ignoreArticlesOption') || false;
  const yourPodcastSettingsMap = {
    'subscribedSortTypeOption': subscribedSortTypeValue, 'subscribedBadgeOption': subscribedBadgeValue,
    'ignoreArticlesOption': ignoreArticlesValue
  };
  console.dir(subscribedBadgeValue);
  dispatch({
    type: FETCH_YOUR_PODCAST_SETTINGS,
    payload: yourPodcastSettingsMap
  });
}

export const updateYourPodcastSettings = (key, value) => async dispatch => {
  dispatch({
    type: UPDATE_YOUR_PODCAST_SETTINGS,
    payload: { [key]: value }
  });
  saveLocally(key, value, 24 * 1000);
}


export const clearBadgesPodcast = (collectionId) => async (dispatch, getState) => {
  let userDetails = getState().userDetails;
  if (!userDetails || !userDetails.uid) {
    return;
  }

  // const subscribedPodcasts = getState().podcastState;
  const podcastExtraDetails = getState().podcastExtraDetails;
  // final Map podcastExtraDetails =
  //   podcastExtraDetailsSelector(StoreClass.store.state);
  // final Map subscribedPodcasts = subscribedPodcastsSelector(store.state);
  const clearBadgesMap = {};
  // subscribedPodcasts.forEach((collectionId, value) {
  if (podcastExtraDetails[`${collectionId}`] &&
    podcastExtraDetails[`${collectionId}`]['guid']) {
    const guid = podcastExtraDetails[`${collectionId}`]['guid'];
    clearBadgesMap[`${collectionId}`] = guid;

    setDoc(doc(firestore, `users/${userDetails.uid}/private`, 'details'), { podcasts: { 'clearBadgeGuid': guid } }, { merge: true });
  }
  // });
  // utils.printLog('clearBadgesAll');
  // utils.printLog(clearBadgesMap);
  dispatch({
    type: UPDATE_CLEAR_BADGES,
    payload: clearBadgesMap
  });
}

export const clearBadgesAll = () => async (dispatch, getState) => {
  let userDetails = getState().userDetails;
  if (!userDetails || !userDetails.uid) {
    return;
  }
  const subscribedPodcasts = getState().podcastState;
  const podcastExtraDetails = getState().podcastExtraDetails;
  // final Map subscribedPodcasts = subscribedPodcastsSelector(store.state);
  const clearBadgesMap = {};
  console.dir(podcastExtraDetails);

  const podcastsUpdate = {};
  Object.keys(subscribedPodcasts).forEach((collectionId) => {
    if (podcastExtraDetails[`${collectionId}`] &&
      podcastExtraDetails[`${collectionId}`]['guid']) {
      const guid = podcastExtraDetails[`${collectionId}`]['guid'];
      clearBadgesMap[`${collectionId}`] = guid;

      const podcastObj = { 'clearBadgeGuid': guid };
      podcastsUpdate[`${collectionId}`] = podcastObj;
    }
  });
  setDoc(doc(firestore, `users/${userDetails.uid}/private`, 'details'), { podcasts: podcastsUpdate }, { merge: true });
  // utils.printLog('clearBadgesAll');
  // utils.printLog(clearBadgesMap);
  console.dir(clearBadgesMap);
  dispatch({
    type: UPDATE_CLEAR_BADGES,
    payload: clearBadgesMap
  });
}

export const logDashboadLinkViews = (slug, id, type = 1, logType = 1) => {
  const dateString = new Date().toJSON().slice(0, 10);
  const checkKey = dateString + `${logType}`;
  const alreadyLogged = retrieveLocally(checkKey); /// unique views per day
  if (alreadyLogged) {
    return;
  }
  saveLocally(checkKey, true);

  const dataToLog = {
    'total': true,
    'os': {}, // { ios: 1, mac: 2, windows: 1 },
    'browser': {},// { safari: 1, firefox: 2, chrome: 1 },
    'country': {}, // { us: 1, uk: 2 }, // this will be logged in backend due to library size
    'referrer': {}, // { 'google.com': 1, 'yahoo.com': 2 }, /// possiblly large array
    'utm_source': {}, // { 'google.com': 1, 'yahoo.com': 2 }, /// possiblly large array
    'utm_campaign': {}, // { 'google.com': 1, 'yahoo.com': 2 }, /// possiblly large array
    'utm_medium': {}, // { 'google.com': 1, 'yahoo.com': 2 } /// possiblly large array
  }

  try {
    const referrerDomain = new URL(document.referrer).hostname;
    if (referrerDomain) {
      dataToLog['referrer'][referrerDomain] = true;
    }
  } catch (err) { console.error(err) }

  try {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_source = urlParams.get('utm_source');
    const utm_medium = urlParams.get('utm_medium');
    const utm_campaign = urlParams.get('utm_campaign');
    if (utm_source) {
      dataToLog['utm_source'][utm_source] = true;
    }
    if (utm_medium) {
      dataToLog['utm_medium'][utm_medium] = true;
    }
    if (utm_campaign) {
      dataToLog['utm_campaign'][utm_campaign] = true;
    }
  } catch (err) { console.error(err) }

  try {
    var parser = new UAParser();
    let info = parser.getResult();
    if (info.os) {
      let osName = (info.os.name || '').toLowerCase();
      let found = false;
      Object.keys(dashboardOSOptions).forEach(key => {
        if (osName.includes(key)) {
          found = true;
          dataToLog['os'][dashboardOSOptions[key]] = true;
        }
      })
      if (!found) {
        dataToLog['os'][-1] = true; // other
      }
    }
    if (info.browser) {
      let browserName = (info.browser.name || '').toLowerCase();
      let found = false;
      Object.keys(dashboardBrowserOptions).forEach(key => {
        if (browserName.includes(key)) {
          found = true;
          dataToLog['browser'][dashboardBrowserOptions[key]] = true;
        }
      })
      if (!found) {
        dataToLog['browser'][-1] = true; // other
      }
    }
  } catch (err) { console.error(err) }

  // let url = `http://localhost:5001/podcastapp-767c2/europe-west1/logDashboardLink`;
  let url = `${serverApiEurope}logDashboardLink`;
  console.dir(url);
  /// for views
  return fetch(url + `?slug=${encodeURIComponent(slug)}&id=${id}&type=${type}&logType=${logType}`,
    {
      method: 'POST',
      body: JSON.stringify({ dataToLog }),
    });
}


export const editBookDescription = async (collectionId, description, collectionName = '') => {
  try {
    // const collectionId = itunesData['collectionId'];
    // let allPopularPodcasts = getState().popularPodcasts || {};
    // if (allPopularPodcasts[`${collectionId}`]) {
    //     return;
    // }
    // const feedUrl = itunesData['feedUrl'];
    // const genresArray = itunesData['genres'] || [];
    // const genres = encodeURIComponent(genresArray.join(','));

    const urlPOST = `${serverApi}editBookDescription?t=1`;
    // const optPOST = {                //http header
    //   'method': 'post',
    //   'contentType': 'application/json',
    //   'body': JSON.stringify({ collectionId, description, collectionName })
    // };
    //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
    const response = await tokenFetchPOST(urlPOST, JSON.stringify({ collectionId, description, collectionName }));
    // const response = await fetch(
    //     `${cdnApi}editBookEpisodes`);
    const json = await response.json();

    // const episodesJson = json;
    console.dir(json);
    return;
  } catch (e) {
    throw e;
    // return {};
  }
}
export const getTokenRawCount = async (collectionId) => {
  try {
    // const collectionId = itunesData['collectionId'];
    // let allPopularPodcasts = getState().popularPodcasts || {};
    // if (allPopularPodcasts[`${collectionId}`]) {
    //     return;
    // }
    // const feedUrl = itunesData['feedUrl'];
    // const genresArray = itunesData['genres'] || [];
    // const genres = encodeURIComponent(genresArray.join(','));

    const urlPOST = `${serverApi}tokenCount`;
    const optPOST = {                //http header
      'method': 'post',
      'contentType': 'application/json',
      'body': JSON.stringify({ collectionId })
    };
    //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
    console.dir(optPOST);
    const response = await fetch(urlPOST, optPOST);
    // const response = await fetch(
    //     `${cdnApi}editBookEpisodes`);
    console.dir(response);
    const json = await response.json();

    // const episodesJson = json;
    console.dir(json);
    return json;
  } catch (e) {
    // throw e;
    // return {};
  }
}

// export const fetchPodcasts = (podcastName) => async dispatch => {
//   const response = await fetch(`https://itunes.apple.com/search?term=${podcastName}&media=podcast&entity=podcast&limit=10`);
//   // const response = await fetch('https://rss.art19.com/conan-obrien');
//   const json = await response.json();
//   // console.dir(json);
//   let newState = {
//     podcasts: json
//   };
//   dispatch({
//     type: FETCH_PODCASTS,
//     payload: newState
//   })
// };