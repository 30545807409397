import { FETCH_TOP_PODCASTS, GET_AUTO_CHAPTERS_ACTION } from "../actions/types";

// REDUCERS
export const topPodcastsReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case FETCH_TOP_PODCASTS: {
            return Object.assign({}, state, { [action.typeId]: action.podcasts });
            // const userId = action.payload.userID || '';
            // const reviewsMeta = action.payload.reviewsMeta || {};
            // return produce(state, draft => {
            //   draft[userId] = { ...draft[userId], reviewsMeta } // reviews;
            // })
        }
        default:
            return state;
    }
}