import fetchJsonp from 'fetch-jsonp';
import {
  setItunesData, getItunesData,
  elasticCloudSearchBaseApi,
  elasticCloudSearchHeaders,
  isAdmin,
  isPresentable,
  isPresentableInSearch
} from './utils';

export const searchItunes = async (queryText, userDetails = {}, limit = 10, type = []) => {
  /// cloud Search
  const queryString = elasticCloudSearchBaseApi + `/search?query=${queryText}`; // cloudSearchUrl(queryText, limit);
  console.dir(queryString);
  try {
    const body = {
      'search_fields': {
        // 'title': {},
        'title': { 'weight': 10 },
        'artist_name': { 'weight': 10 },
        'description': { 'weight': 0.2 },
        // 'genres': {'weight': 1},
      },
      "filters": {
        "type": [1]
      },
      "boosts": {
        "readiness": [
          {
            "type": "proximity",
            "function": "exponential",
            // "function": "gaussian",
            "center": 40,
            "factor": 60
            // "factor": 125
          }
        ],
        // "type": [
        //   {
        //     "type": "proximity",
        //     "function": "exponential",
        //     "center": 1,
        //     "factor": 10
        //   }
        // ]
      },
      'page': { 'size': limit, 'current': 1 }
    };
    // console.dir(minDays);
    if (type.length > 0) {
      // body['filters'] = {
      //   "type": type[0]
      // }
    }
    console.dir(body);
    let response = await fetch(queryString, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      headers: elasticCloudSearchHeaders,
      body: JSON.stringify(body)
    })
    const json = await response.json();
    // const json = {"results": [
    //     {
    //         "title": {
    //             "raw": "Conan O'Brien"
    //         },
    //         "genres": {
    //             "raw": [
    //                 "Books",
    //                 "Arts",
    //                 "TV & Film"
    //             ]
    //         },
    //         "artwork_url_600": {
    //             "raw": "https://is5-ssl.mzstatic.com/image/thumb/Podcasts113/v4/76/32/0c/76320cb7-7805-5ffc-6d48-18b311dd9be8/mza_18321298089187816075.jpg/600x600bb.jpg"
    //         },
    //         "_meta": {
    //             "id": "b1b97221-8d7a-4de1-a501-d06abb8ae96b",
    //             "engine": "episodes",
    //             "score": 13.770508
    //         },
    //         "id": {
    //             "raw": "b1b97221-8d7a-4de1-a501-d06abb8ae96b"
    //         },
    //         "pub_date": {
    //             "raw": 1.606426252E12
    //         },
    //         "duration_in_sec": {
    //             "raw": 2992.0
    //         }
    //     }]};
    const resultsArray = json['results'];
    console.dir(resultsArray);
    // const resultsArray = resultsArrayInit.filter(el => el._meta.score > (minDays > 0 ? 10 : 1));
    // if (minDays > 0) {
    //     resultsArray.sort((el1, el2) => el2['pub_date']['raw'] - el1['pub_date']['raw']);
    // }
    console.dir(resultsArray);
    const resultsArrayFin = [];
    for (const result of resultsArray) {
      const imageStatus = parseInt(`${result['i']['raw']}`) ?? 0;
      const statusT =
        parseInt(`${result['status_t']['raw'] ?? 0}`) ?? 0;
      const statusV =
        parseInt(`${result['status_v']['raw'] ?? 0}`) ?? 0;
      if (isAdmin(userDetails) || isPresentableInSearch(imageStatus, statusT, statusV)) {
        const resultFin = {
          'collectionId': parseInt(result['id']['raw']),
          'collectionName': result['title']['raw'],
          'genres': result['genres']['raw'],
          'artworkUrl600': result['artwork_url_600']['raw'],
          'artistName': result['artist_name']['raw'],
          // 'collectionName': result['collection_name']['raw'],
          'bgColor': (result['bg_color']['raw']),
          // 'enclosure': { 'duration': result['duration_in_sec']['raw'] },
        };
        resultsArrayFin.push(resultFin);
      }
    }
    // console.dir('resultsArray : ');
    console.dir(resultsArrayFin);
    // saveLocally(searchString(queryText, limit), JSON.stringify(resultsArrayFin), 24 * 5);
    return resultsArrayFin;
  } catch (err) {
    // throw err;
    return [];
  }



  // // const escapedUrl = encodeURIComponent(feedUrl);
  // // const podcastNameCleaned = podcastName.replace(/&/g, ' ').replace(/%/g, ' ');

  // return `https://itunes.apple.com/search?term=${encodeURIComponent(podcastName)}&media=podcast&entity=podcast&limit=${limit}`; //`https://api.rss2json.com/v1/api.json?rss_url=${escapedUrl}&api_key=${apiKey}&count=${count}&offset=${offset}`;
}

export function itunesByCollectionId(collectionId) {
  // const escapedUrl = encodeURIComponent(feedUrl);

  return `https://itunes.apple.com/lookup?id=${collectionId}`;
}

// export const getItunesDataServerByCollectionId = async (collectionId) => {
//   try {
//     console.log('collectionId in getItunesDataServerByCollectionId : ' + collectionId);
//     const response = await fetch(itunesByCollectionId(collectionId));
//     const data = await response.json();
//     const itunesData = (data && data.resultCount > 0 && data.results.length && data.results[0]) || {};
//     return itunesData;
//   } catch (err) {
//     console.log(JSON.stringify(err));
//     throw err;
//   }
// }

// export const getItunesDataByCollectionId = async (collectionId) => {
//   const itunesExistingData = getItunesData(collectionId);
//   if (itunesExistingData) {
//     // console.dir(itunesExistingData);
//     return itunesExistingData;
//   }
//   return fetchJsonp(itunesByCollectionId(collectionId))
//     .then(response => response.json())
//     .then(data => {
//       // console.dir(data);
//       const itunesData = (data && data.resultCount > 0 && data.results.length && data.results[0]) || {};
//       setItunesData(collectionId, itunesData);
//       // itunesData = data;
//       // this.setState({ itunesLoaded: true, itunesData: data && data.resultCount > 0 && data.results.length && data.results[0] || {} })
//       return itunesData;
//     })
//     .catch(err => {
//       console.error(err);
//       // this.setState({ itunesData: {} })
//       return {};
//     })
// }