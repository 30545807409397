import { auth, onAuthStateChanged } from './db';
var UAParser = require('ua-parser-js');
// import { type } from 'os';
var slugify = require('slugify');
// const he = require('he');
const uuidv5 = require('uuid/v5');
const APP_UUID_NAMESPACE = '1b671a64-40d5-491e-99b0-da01ff1f3341';/// this is randomly chosen but same on app and mobile
import { LOGIN_ROUTE, FEATURED_ROUTE, BLOG_ROUTE, SORT_RELEVANCE, SORT_PUB_DATE_NEW_FIRST, SORT_PUB_DATE_OLD_FIRST, SORT_EPISODE_COUNT_DESC, SORT_EPISODE_COUNT_ASC, SORT_EPISODE_DURATION_DESC, SORT_EPISODE_DURATION_ASC, IS_DARK_MODE } from './strings';
import { format, localeFormat } from 'light-date';
import { getIsPremiumUser, getlocationInfo } from './fileUtils';
import { podcastTypesExtra, podcastTypesGenres, superGenres } from './genres';
// export const serverApi = 'http://localhost:3000/api/';
// export const serverApi = 'http://localhost:5002/books-af362/us-central1/';  // for dev
export const serverApi = 'https://us-central1-books-af362.cloudfunctions.net/';
// export const serverApiEurope = 'http://localhost:5002/books-af362/europe-west1/';
export const serverApiEurope = 'https://europe-west1-books-af362.cloudfunctions.net/';
export const cdnApi = 'https://books-af362.firebaseapp.com/';
export const imagesCDNUrl = 'https://books-images.b-cdn.net/';
export const audioCDNUrl = 'https://books-audio.b-cdn.net/';

export const firestoreApi = 'https://firestore.googleapis.com/v1/projects/books-af362/databases/(default)/documents/';

export const storageUrl = 'https://storage.googleapis.com/books-af362.appspot.com/';

export const elasticCloudSearchBaseApi =
    'https://books.ent.us-east1.gcp.elastic-cloud.com/api/as/v1/engines/booksengine';
export const elasticSearchBase = 'https://books.ent.us-east1.gcp.elastic-cloud.com/api/as/v1';
export const elasticSearchKey = 'search-awbpuxzrdj22d4sv5as3anwd';
export const elasticSearchEngine = 'episodes';
export const elasticCloudSearchHeaders = {
    'Authorization': 'Bearer search-awbpuxzrdj22d4sv5as3anwd',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
};

export const adDays = 30;

export const maxCloudStorageInBytes = 10 * 1000 * 1000 * 1000;

export const websiteUrl = 'https://tedapp.ai';
export const websiteUrlOutOfScope = 'https://www.tedapp.ai'; /// Intentionally made it out of scope so that MacOS/windows app opens certain urls in browser like ImportOPML

export const cloudSearchBaseApi = 'https://episodes-search.search.windows.net/indexes/episodes-index';
export const cloudSearchHeaders = {
    'api-key': '175DDE3C7ACD60D7A4E6E01E271699C2',
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
};
export const cloudSearchUrl = (searchTerm, limit = 3) => {
    return cloudSearchBaseApi +
        '/docs?api-version=2019-05-06&search=' +
        encodeURIComponent(searchTerm) +
        `&$top=` +
        limit;
}

export const revenueCatApiKey = 'strp_hNuhqRUuitLBKCftuzClkYyiuJt';
export const revenueCatHeaders = {
    'Content-Type': 'application/json',
    'X-Platform': 'stripe',
    'Authorization': 'Bearer strp_hNuhqRUuitLBKCftuzClkYyiuJt'
}

export const testData = {
    "@odata.context": "https://episodes.search.windows.net/indexes('episodes-search-3')/$metadata#docs(*)",
    "value": [
        {
            "@search.score": 0.87514085,
            "guid": "ade8bca8-9a79-5e01-9004-84fa22412401",
            "artworkUrl600": "https://is1-ssl.mzstatic.com/image/thumb/Podcasts113/v4/e6/b2/07/e6b207fe-abce-2c9c-e77a-688bca6940e5/mza_14393082705490695481.jpg/600x600bb.jpg",
            "genres": [
                "Education",
                "Business"
            ],
            "pubDate": 1581963575000,
            "title": "There’s a science to wealth, and it’s not what you think | Tony Robbins on the meaning of true wealth, plus tips on how to build it"
        }
    ]
};

export function addOpacity(color, opacity) {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
}

export const premiumDashboardContentQueriesLimit = 3;
export const mainDashboardOptions = [
    { url: '/podcaster-tools', name: 'Home', icon: 'home' },
    { url: '/podcast-transcript', name: 'Transcript AI', icon: 'transcript' },
    { url: '/podcast-ideas', name: 'Content Generator', icon: 'gift' },
    { url: '/podcast-links', name: 'Link Tracker', icon: 'link' },
    { url: '/pricing', name: 'Pricing', icon: 'link' },
    { url: '/billing', name: 'Billing', icon: 'account' },
    { url: '/account-host', name: 'Account', icon: 'account' },
];
export const dashboardPlanIds = {
    1: { 'name': 'Free', mins: 60, adDiscount: 0 },
    2: { 'name': 'Basic', mins: 160, adDiscount: 10 },
    3: { 'name': 'Pro', mins: 260, adDiscount: 20 },
    4: { 'name': 'Agency Basic', mins: 560, adDiscount: 30 },
    5: { 'name': 'Agency Standard', mins: 660, adDiscount: 40 },
    6: { 'name': 'Agency Pro', mins: 860, adDiscount: 50 },
};
export const isPremiumAnnual = (premiumObj) => {
    return (premiumObj.endDate - premiumObj.startDate) > 45 * 24 * 3600 * 1000;
}
// function monthDiff(d1, d2) {
//     var months;
//     months = (d2.getFullYear() - d1.getFullYear()) * 12;
//     console.dir(d1.getMonth());
//     console.dir(d2.getMonth());
//     months -= d1.getMonth();
//     months += d2.getMonth();
//     return months <= 0 ? 0 : months;
// }
export const billingStartDate = (actualDate) => {
    if (!actualDate) {
        return '';
    }
    if (typeof actualDate == 'string') {
        actualDate = parseInt(actualDate);
    }
    console.dir(actualDate);
    console.dir(new Date(actualDate));
    return new Date(actualDate);
}
export const currentBillingStartDate = (actualDate) => {
    const dateFin = billingStartDate(actualDate);
    const currDate = dateFin; // new Date();
    console.dir(currDate);

    const dateToday = new Date();
    console.dir(dateToday);

    currDate.setFullYear(dateToday.getFullYear(), dateToday.getMonth());

    // const lastDate = dateFin;
    // lastDate.setMonth(lastDate.getMonth() + 1);
    // console.dir(lastDate);
    // currDate.setDate(dateFin.getDate());
    if (currDate.getTime() > Date.now()) {
        currDate.setMonth(currDate.getMonth() - 1);
    }
    console.dir(currDate);
    // if (currDate.getTime() > lastDate.getTime()) {
    // const monthsPassed = monthDiff(currDate, dateToday);
    // console.dir(monthsPassed);
    // }
    // currDate.setMonth(currDate.getMonth() + monthsPassed);
    return currDate;
}
export const currentBillingEndDate = (actualDate) => {
    const dateFin = currentBillingStartDate(actualDate);
    dateFin.setMonth(dateFin.getMonth() + 1);
    return dateFin;
}
export const currentBillingStartDateDisplay = (actualDate) => {
    const dateFin = currentBillingStartDate(actualDate);
    return dateFin.toLocaleDateString('en-us', { day: 'numeric', month: "short" });
}
export const currentBillingEndDateDisplay = (actualDate) => {
    const dateFin = currentBillingEndDate(actualDate);
    return dateFin.toLocaleDateString('en-us', { day: 'numeric', month: "short" });
}
export const billingStartDateDisplay = (actualDate) => {
    const dateFin = billingStartDate(actualDate);
    return dateFin.toLocaleDateString('en-us', { day: 'numeric', month: "short" });
}
export const billingEndDateDisplay = (actualDate) => {
    const dateFin = billingStartDate(actualDate);
    return dateFin.toLocaleDateString('en-us', { day: 'numeric', month: "short" });
}
export const minsUsed = (actualDate, transcripts) => {
    // console.dir(actualDate);
    const startDate = currentBillingStartDate(actualDate);
    // console.dir(startDate);
    // startDate.setHours(0, 0, 0);
    let secsUsed = 0;
    Object.values(transcripts).forEach(el => {
        if (el.time > startDate.getTime()) {
            secsUsed += el.durationInSec;
        }
    });
    return Math.round(secsUsed / 60);
}

export const reviewsLimit = 8;
export const episodeReviewsLimit = 4;
export const playlistEpisodesLimit = 200;
export const latestEpisodeLimitDefault = 11;

export const bottomBarHeight = '5rem';

export const staticAssetsPrefix = ''; // process.env.NODE_ENV === 'production' ? '/app' : '';
export const domainName = 'https://tedapp.ai/';
export const webHomeUrl = domainName + 'home';
export const supportEmail = 'support@tedapp.ai';
export const testflightUrl = 'https://testflight.apple.com/join/ZgoCybwh';
export const appleStoreUrl = 'https://apps.apple.com/app/id6477714480?platform=iphone'; // 'https://apps.apple.com/us/app/podurama/id1497491520';
export const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.podurama.books';
export const macStoreUrl = 'https://play.google.com/store/apps/details?id=com.podurama.books&platform=mac';
export const windowsStoreUrl = 'https://www.microsoft.com/store/apps/9NNT80B3595S';
export const shortDomainName = '';

export const ipCountryAPI = 'https://api.ipdata.co';

///// premium limits
export const playlistPremiumLimit = 2;
export const tagPremiumLimit = 2;
export const trailerPremiumLimit = 2;
export const bookmarkPremiumLimit = 2;
export const alertPremiumLimit = 1;
export const chatPremiumLimit = 1;
export const bookRequestLimit = 2;
export const sampleChapterUUID = '1f51c2f2-8d3f-501b-8e6f-b25c8ce857d7';

export function podcastSlugUrl(collectionName, itunesRawId) {
    return `/book/${convertToSlug(cleanText(collectionName), true)}-i${itunesRawId}`;
}
export function podcastHrefUrl(itunesRawId) {
    return `/book?PzID=${itunesRawId}`;
}

export function featuredAllUrl() {
    return firestoreApi + 'featured';
}
export function featuredMainUrl(slug) {
    return firestoreApi + 'featured/' + slug + '/final/article';
}
export function blogMainUrl(slug) {
    return firestoreApi + 'blog/' + slug + '/final/article';
}

export function addLinkPrefix(url) {
    return url ? (url.startsWith('http') ? url : 'https://' + url) : '';
}

export function isFeatured(route) {
    return route.startsWith(FEATURED_ROUTE);
}

export function isBlog(route) {
    return route.startsWith(BLOG_ROUTE);
}

export function isArticle(route) {
    return isFeatured(route) || isBlog(route);
}

export function bookVideoThumbFromRaw(imgName) {
    // String imgName = '$collectionId' + '.png';
    // return utils.imagesCDNUrl + 'books/' + 'test11.jpg';
    return imagesCDNUrl + 'videoThumbnail/' + imgName;
}

export function bookVideoFromRawId(videoId) {
    // String imgName = '$collectionId' + '.png';
    // return utils.imagesCDNUrl + 'books/' + 'test11.jpg';
    return `https://vz-9338bb95-d77.b-cdn.net/${videoId}`;
}

export function bookVideoFromRawIdMp4Fallback(videoUrl) {
    // String imgName = '$collectionId' + '.png';
    // return utils.imagesCDNUrl + 'books/' + 'test11.jpg';
    return videoUrl.replace('playlist.m3u8', 'play_720p.mp4');
}

export const quoteImagesDashboard = [
    // { id: '1', color: '#030028', url: 'https://static.vecteezy.com/system/resources/previews/011/404/485/non_2x/paper-note-with-abstract-doodle-and-gradient-frame-background-free-vector.jpg' },
    { id: '1', color: '#030028', url: '1.jpg' },
    // { id: '2', color: '#030028', url: 'https://static.vecteezy.com/system/resources/previews/007/539/639/non_2x/abstract-backgrounds-with-copy-space-for-greeting-card-or-cover-presentation-design-templates-vector.jpg' },
    { id: '2', color: '#030028', url: '2.jpg' },
    // { id: '3', color: '#030028', url: 'https://static.vecteezy.com/system/resources/previews/028/209/627/non_2x/floral-background-with-leaves-and-branches-ornament-vector.jpg' },
    { id: '3', color: '#030028', url: '3.jpg' },
    // { id: '4', color: '#030028', url: 'https://static.vecteezy.com/system/resources/previews/006/849/611/non_2x/abstract-background-with-soft-gradient-color-and-dynamic-shadow-on-background-background-for-wallpaper-eps-10-free-vector.jpg' },
    { id: '4', color: '#030028', url: '4.jpg' },
    // { id: '1', color: 'white', url: 'https://static.vecteezy.com/system/resources/previews/001/849/490/non_2x/dark-blue-cave-abstract-background-free-vector.jpg' },
    // { id: '5', color: 'white', url: 'https://static.vecteezy.com/system/resources/previews/007/736/640/non_2x/3d-modern-luxury-banner-template-design-black-stripes-with-gold-frame-and-golden-lines-light-sparking-on-dark-background-vector.jpg' },
    { id: '5', color: 'white', url: '5.png' },
    // { id: '6', color: 'white', url: 'https://static.vecteezy.com/system/resources/previews/013/938/906/non_2x/luxury-dark-blue-background-with-golden-line-curved-and-lighting-effect-sparkle-free-vector.jpg' },
    { id: '6', color: 'white', url: '6.png' },
    // { id: '1', color: 'white', url: 'https://static.vecteezy.com/system/resources/previews/001/427/161/non_2x/abstract-wave-frame-cut-out-background-free-vector.jpg' },
    // { id: '7', color: 'white', url: 'https://static.vecteezy.com/system/resources/previews/002/617/074/non_2x/night-landscape-with-crescent-moon-forest-and-starry-sky-free-vector.jpg' },
    { id: '7', color: 'white', url: '7.png' },
    // { id: '8', color: 'white', url: 'https://static.vecteezy.com/system/resources/previews/002/743/558/non_2x/meteor-shower-in-the-night-sky-free-vector.jpg' },
    { id: '8', color: 'white', url: '8.png' },
]

export const dashboardOSOptions = {
    'android': 1,
    'ios': 2,
    'mac': 3,
    'windows': 4,
};
export const dashboardBrowserOptions = {
    'firefox': 1,
    'chrome': 2,
    'safari': 3,
    'edge': 4,
};

export const isInStandaloneMode = () =>
    (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://');

export function searchString(str, limit) {
    return 'search-episodes-' + str + '-' + limit;
}
const localMap = {};
export function saveLocally(key, val, expiresInHours = 24 * 3, isTemp = false) {
    if (isLocalStoragePresent() && !isTemp) {
        let now = Date.now();
        let scheduleInMillis = now + (expiresInHours * 60 * 60 * 1000);
        window.localStorage.setItem(key, val);
        window.localStorage.setItem(key + '_expiresIn', scheduleInMillis);
    } else {
        localMap[key] = val;
    }
}
export function retrieveLocally(key, isTemp = false) {
    if (isLocalStoragePresent() && !isTemp) {
        let now = Date.now();
        let expiresInMillis = localStorage.getItem(key + '_expiresIn');
        if (expiresInMillis < now) {
            removeStorage(key);
            return null;
        }
        return window.localStorage.getItem(key);
    } else {
        return localMap[key];
    }
}
function removeStorage(key) {
    try {
        localStorage.removeItem(key);
        localStorage.removeItem(key + '_expiresIn');
    } catch (e) {
        // console.log('removeStorage: Error removing key ['+ key + '] from localStorage: ' + JSON.stringify(e) );
        return false;
    }
    return true;
}

export function isConclusion(title) {
    return ['recap', 'conclusion'].includes(title.toLowerCase());
}

export const superToNormalGenres = (superGenreIds) => {
    const normalGenreIds = [];
    superGenreIds.forEach((superGenreId) => {
        const superGenreMapIndex = superGenres().findIndex(
            (element) => element['id'] == superGenreId);
        if (superGenreMapIndex > -1) {
            console.dir(superGenreMapIndex);
            console.dir(superGenres()[superGenreMapIndex]['genres']);
            normalGenreIds.push(...superGenres()[superGenreMapIndex]['genres']);
            console.dir(normalGenreIds);
        }
    });

    return normalGenreIds;
}

export function getDaysAgo(numDaysAgo) {
    // const numDaysAgo = 31;
    const date = new Date();
    const previous = new Date(date.getTime());
    previous.setDate(date.getDate() - numDaysAgo);
    return previous.getTime(); //  1669882672 * 1000;
}

export function isDarkMode() {
    const isDarkModeVal = retrieveLocally(IS_DARK_MODE);
    return isDarkModeVal == 'true';
}

export function isAutoPlaylist(playlistInfo) {
    return playlistInfo && (playlistInfo.advancedType === 'SmartPlaylistId');
}

export function isPublicPlaylist(playlistInfo) {
    return playlistInfo && (playlistInfo.type === 'public');
}

export function getFirebaseUser() {
    return auth && auth.currentUser;
}

export function isBook(podcastData) {
    return (podcastData.type || 1) == 1;
}
export function isNews(podcastData) {
    return podcastData.type == 2;
}
export function isMagazine(podcastData) {
    return podcastData?.type == 3;
}
export function isSleep(podcastData) {
    return podcastData.type == 4;
}

export function bookImage(podcastData) {
    // console.dir(podcastData);
    const imgName = podcastData.artworkUrl600
        ? podcastData.artworkUrl600
        : (`${podcastData?.collectionId ?? ''}`) + '.png';
    return imagesCDNUrl + 'book/' + imgName;
}

/// playlist is public and is created by someone else i.e not me
export function isExternalPlaylistPublic(playlist) {
    const firebaseUser = auth.currentUser;
    if (!firebaseUser) {
        return false;
    }
    return playlist != null &&
        playlist['type'] == 'public' &&
        playlist['userID'] != firebaseUser.uid;
}

/// playlist is public and is created by someone else i.e not me
export function isMyPlaylistPublic(playlist) {
    const firebaseUser = auth.currentUser;
    if (!firebaseUser) {
        return false;
    }
    return playlist != null &&
        playlist['type'] == 'public' &&
        playlist['userID'] == firebaseUser.uid;
}


export const convertMyFileToEpisode = (fileTitle, fileName, fileSize, duration, guid, fileUrl = '') => {
    const json = {
        'author': '-',
        'collectionName': '-',
        'enclosure': {
            'duration': duration,
            'length': fileSize,
            'link': fileUrl,
            'url': fileUrl,
            'type': fileName.endsWith('mp4') ? 'video/mp4' : 'audio/mpeg'
        },
        'isMyFile': true,
        'guid': guid ?? '',
        'pubDate': Date.now(),
        'thumbnail': '',
        'artworkUrl600': '',
        'description': fileName,
        'title': fileTitle ?? '',
        'genres': [],
        'collectionId': -1,
        'rating': 0,
        'ratedBy': 0
    };
    return json;
}

export function isMyFileUrl(episode) {
    return episode?.enclosure?.link?.includes('://') ?? false;
}


export const getMyFileFullUrl = (fileName) => {
    if (fileName.includes('://')) {
        return fileName;
    }
    const userUID = getFirebaseUser().uid;
    return 'https://media-cdn.tedapp.ai/' + `${userUID}/${fileName}`;
    // return 'https://audio-us.b-cdn.net/' + `${userUID}/${fileName}`;
    // if (appInfo.isNotEmpty &&
    //     appInfo['cdnUrl'] != null &&
    //     appInfo['cdnUrl'] != '') {
    //     return appInfo['cdnUrl'] + '${userUID}/${fileName}';
    // }
    // return 'https://ny.storage.bunnycdn.com/audio-us/${userUID}/${fileName}?AccessKey=8e1017dc-c1c4-4f45-a418cd20ba0d-4609-410f';
}

export const setMyFileDownloadFileNameFinal = (fileNameInit) => {
    const fileName = convertToSlug(fileNameInit);
    const lastIndex = fileName.lastIndexOf('.');
    const randNum = createRandomNumber(4);
    if (lastIndex > -1) {
        return fileName.substring(0, lastIndex) +
            `-${randNum}` +
            fileName.substring(lastIndex);
    }
    return fileName + `-${randNum}` + '.mp3';
}

export function isLocalStoragePresent() {
    try {
        const key = "__some_random_key_27";
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
        return true;
    } catch (e) {
        return false;
    }
}

export const blogAuthors = {
    '1': { 'name': 'Varshita Sher' },
    '2': { 'name': 'Ash Hall' },
    '3': { 'name': 'Jessica Taylor' },
};

let allItunesData = {};
export function setItunesData(collectionId, itunesData) {
    allItunesData[collectionId] = itunesData;
    return;
}
export function getItunesData(collectionId) {
    return allItunesData[collectionId] || '';
}

let currentPodcastData = {};
export function setCurrentPodcastData(podcastData) {
    currentPodcastData[podcastData.collectionId] = podcastData;
    return;
}
export function getCurrentPodcastData(collectionId) {
    return currentPodcastData[collectionId];
}

export function areSameMediaType(audioInfo1, audioInfo2) {
    const type1 = showVideo(audioInfo1);
    const type2 = showVideo(audioInfo2);
    return type1 === type2;
}

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function showVideo(audioInfo) {
    // return isEpisodeVideo(audioInfo) && !playOnlyAudioNotifier.value;
    return isEpisodeVideo(audioInfo);
}

export function isEpisodeVideo(episode) {
    if (!episode || !episode.enclosure) {
        return false;
    }
    const audioUrl = episode.enclosure.link || '';
    const audioType = episode.enclosure.type || '';
    const val = audioUrl.includes('mp4') || audioType.includes('video');
    return val;
}

let currentEpisodeData = {};
export function setCurrentEpisodeData(episodeData) {
    currentEpisodeData = episodeData;
    return;
}
export function getCurrentEpisodeData() {
    return currentEpisodeData;
}

export function compareIndex(a, b) {
    const indexA = parseInt(a.index || 0);
    const indexB = parseInt(b.index || 0);

    let comparison = 0;
    if (indexA > indexB) {
        comparison = 1;
    } else if (indexA < indexB) {
        comparison = -1;
    }
    return comparison;
}

export function compareScore(a, b) {
    const scoreA = a.score || 0;
    const scoreB = b.score || 0;

    let comparison = 0;
    if (scoreA > scoreB) {
        comparison = -1;
    } else if (scoreA < scoreB) {
        comparison = 1;
    }
    return comparison;
}

export function convertToSlug(title, isPodcastTitle = false) {
    if (title) {
        if (isPodcastTitle) {
            title = title.split(':')[0];
        }
        return slugify(title, {
            lower: true, remove: /[^\w\s-]/g
        });
    } else {
        return encodeURIComponent(title);
    }
}

export function bookProgressStage(itunesData) {
    if (itunesData == null) {
        return 100;
    }
    const statusTOrig = itunesData['statusT'] ?? 0;
    const statusVOrig = itunesData['statusV'] ?? 0;
    const statusT = parseFloat(statusTOrig);
    // statusTOrig is int ? statusTOrig.toDouble() : statusTOrig;
    const statusV = parseFloat(statusVOrig);
    // statusVOrig is int ? statusVOrig.toDouble() : statusVOrig;
    if (statusT >= 0.25 && statusT < 1) {
        // isJustStarted
        return 10;
    }
    if (statusT == 1) {
        // isForBetaUsers
        return 20;
    }
    if (statusT > 1.1 && statusV < 1) {
        // isTextDone
        return 30;
    }
    if (statusV >= 2) {
        /// final stage
        return 40;
    }
    return 100;
}

export function limitString(text, limit) {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
}

export function getDescription(podcastInfo) {
    return podcastInfo.description && (podcastInfo.description.length > 180 ?
        podcastInfo.description.substring(0, 180).replace(/<[^>]+>/g, '') : podcastInfo.description.replace(/<[^>]+>/g, '')) || 'Podcast Description';
}

export function createRandomLowercaseString(length) {
    const chars = 'abcdefghijklmnopqrstuvwxyz';

    function getRandomString(length) {
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    return getRandomString(length);
}

export function createRandomShortString(length) {
    var result = '';
    var characters = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function createRandomNumber(len = 7) {
    return parseInt((Math.random() * 9 + 1) * Math.pow(10, len - 1), 10);
}
export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function getRandomEpisodeNum(totalEpisodeCount) {
    /// wrote so that it excludes conclusion i.e last chapter
    let epNum = 1;
    if (totalEpisodeCount > 3) {
        // utils.printLog('alsoGetEpisodes 3');
        epNum = (2 * getRandomInt((Math.floor(totalEpisodeCount) / 2))) + 1;
        // utils.printLog(epNum);
    }
    return epNum;
}

export function shuffle(arr) {
    return arr.sort(() => (Math.random() > 0.5) ? 1 : -1);
}

export function isDashboardShareUrl(uuid) {
    return uuid && uuid.length < 15;
}

export function setEpisodePagination(podcastEpisodes) {
    const totalItemCount = podcastEpisodes && podcastEpisodes.feed && podcastEpisodes.feed.totalItemCount || 0;
    if (totalItemCount > 0) {
        let episodePaginationMap = getEpisodePaginationMap(totalItemCount);
        const episodePaginationArray = Array.from(Object.keys(episodePaginationMap)); // [...episodePaginationMap.keys()]; // Object.keys(episodePaginationObj);
        const currPage = episodePaginationArray && episodePaginationArray[0];
        // this.setState({ episodePaginationMap: episodePaginationMap, currPage: currPage });
        return { episodePaginationMap: episodePaginationMap, currPage: currPage };
    }
}

export function showTagName(tagName) {
    return tagName && tagName.split('_u_')[0] || '';
}

export function isPresentable(imageStatus, statusT, statusV) {
    ///  show statusV = 1 as beta
    return imageStatus >= 1 && imageStatus < 9 && (statusV >= 1);
}

export function isPresentableInSearch(imageStatus, statusT, statusV) {
    ///  show statusV = 1 as beta
    return imageStatus >= 1 && imageStatus < 9;
}

export function isAdmin(userDetails) {
    if (!userDetails || !userDetails.uid) {
        return;
    }
    return userDetails.uid == '1WPBYtJ37nQs6aqaaRR6umgBxJn2' || userDetails.uid == 'tdZPkiLI57US34R1XnUWWfD1mO62';
}

export function isMyReview(userDetails, review) {
    return userDetails && review && review.user && userDetails.uid == review.user.id;
}

export function isMyPlaylist(userDetails, playlist) {
    return userDetails && playlist && (userDetails.uid == playlist.userID || userDetails.uid in (playlist.collaborators || {}));
}

export function isAPlaylistCollaborator(userDetails, playlist) {
    return userDetails && playlist &&
        (playlist['collaborative'] || false) &&
        (userDetails['uid'] == playlist['userID'] ||
            (userDetails['uid'] in (playlist['collaborators'] || {})));
}

export function isOwnedPlaylist(userDetails, playlist) {
    return userDetails && playlist && userDetails.uid == playlist.userID;
}

export function sortUserPlaylists(userPlaylists, userDetails) {
    let userPlaylistsSorted = {};
    Object
        .keys(userPlaylists).sort(function (a, b) {
            return userPlaylists[a].name.localeCompare(userPlaylists[b].name);
        })
        .forEach(function (key) {
            // if (userDetails && userDetails.uid == userPlaylists[key].userID) {
            if (isMyPlaylist(userDetails, userPlaylists[key])) {
                userPlaylistsSorted[key] = userPlaylists[key];
            }
        });
    return userPlaylistsSorted;
}

export function websiteUrlFn(url) {
    return url && url.startsWith('http') ? url : 'https://' + url;
}

export function useAuthPopup() {
    var parser = new UAParser();
    console.dir(parser.getResult());
    let info = parser.getResult();
    if (info.os) {
        let osName = info.os.name || '';
        let osNameLower = osName.toLowerCase();
        if (osNameLower.includes('mac') || osNameLower.includes('ios')) {
            return true;
        }
    }
    if (info.browser) {
        let browserInfo = info.browser;
        let nameLower = browserInfo.name && browserInfo.name.toLowerCase();
        if (nameLower.includes('firefox') || nameLower.includes('safari')) {
            return true;
        }
    }
    return false;
}

export function singular(text, val) {
    if (val === 1) {
        return text;
    } else {
        return text + 's';
    }
}

export function changeTagName(tagName, rand, makePublic = false) {
    let newTagName = tagName;
    // int rand = 0;
    if (tagName.includes('_u_')) {
        if (makePublic) {
            newTagName = tagName.replace('_u_', '_u_p');
        } else {
            newTagName = tagName.replace('_u_p', '_u_');
        }
    } else {
        /// it's new so create the random number and add it.
        // rand = utils.createRandomNumber();
        if (makePublic) {
            newTagName = tagName + `_u_p${rand}`;
        } else {
            newTagName = tagName + `_u_${rand}`;
        }
    }
    return newTagName;
}

export function isTagPublic(tagName) {
    return tagName.includes('_u_p');
}

// export function sharePublicTag(
//     String userUID, String tagName, BuildContext context) {
//   final String tagNameTail = tagName.split('_u_p')[1];
//   shareGeneric(utils.webAppUrl + '/tags/${userUID}_${tagNameTail}',
//       context: context);
// }

export const audioLoadTimeInMillis = 1000;
export const audioInitialVolume = 0.7;
export const playedProgressLimit = 99.9;

export function print(val) {
    console.log(val);
    return;
}

export const countrySlug = (country_code, continent_code) => {
    if (country_code == 'US' || continent_code == 'NA') {
        return 'american-podcasts';
    } else if (country_code == 'GB') {
        return 'uk-podcasts';
    } else if (continent_code == 'EU') {
        return 'european-podcasts';
    } else if (continent_code == 'OC') {
        return 'australian-podcasts';
    } else if (continent_code == 'AS') {
        return 'asian-podcasts';
    }
    return null;
}

export const timeOfDaySlug = () => {
    var d = new Date();
    const hour = d.getHours();
    let slug;
    if (hour >= 3 && hour < 19) {
        slug = 'morning-podcasts';
        // } else if (now.hour >= 14 && now.hour < 19) {
        //   slug = 'evening-podcasts';
    } else {
        slug = 'night-podcasts';
    }
    return slug;
}

export const maxMarkPlayedEntries = 15000;
export function markPlayedDocName(ind) {
    return 'markAsPlayed' + `${ind == 1 ? '' : ind}`;
}

export function getProgressFromGUID(personalValues, guid) {
    let guidCleaned = cleanFirebaseId(guid);
    let progressSlider = personalValues && typeof personalValues[guidCleaned] !== 'undefined' && personalValues[guidCleaned].progress || 0;
    // final double progressSlider = personalValues != null &&
    //         personalValues.containsKey(guidCleaned) &&
    //         personalValues[guidCleaned].containsKey('progress')
    //     ? utils.castToDouble(personalValues[guidCleaned]['progress'])
    //     : 0.0;
    return progressSlider;
}

export function sortPersonalValuesDesc(personalValues) {
    let personalValuesSorted = Object.values(personalValues)
        .sort((ep1, ep2) => {
            let ep1DT = ep1['lastPlayed'] || { '_seconds': 0 };
            let ep2DT = ep2['lastPlayed'] || { '_seconds': 0 };
            // return ep2DT.compareTo(ep1DT);
            return ep2DT['_seconds'] - ep1DT['_seconds'];
        });
    return personalValuesSorted;
}

export function sortPersonalValuesGuidDesc(personalValues,
    limit = 1000) {
    const personalValuesInfo = {};
    Object.entries(personalValues).forEach(entry => {
        const [guidCleaned, val] = entry;
        personalValuesInfo[guidCleaned] = { ...val, 'guid': uncleanFirebaseId(guidCleaned) };
    });
    // print('personalValuesInfo : ' + personalValuesInfo.toString());
    let personalValuesSorted = Object.values(personalValuesInfo); // personalValuesInfo.values.toList()
    console.dir(personalValuesSorted);
    personalValuesSorted.sort((ep1, ep2) => {
        let ep1DT = ep1['lastPlayed'] || { 'seconds': 0 };
        let ep2DT = ep2['lastPlayed'] || { 'seconds': 0 };

        return ep2DT['seconds'] - ep1DT['seconds'];
    });
    const guids = [];
    // personalValuesSorted.forEach((val) {
    for (let i = 0; i < Math.min(limit, personalValuesSorted.length); i++) {
        guids.push(personalValuesSorted[i]['guid']);
    }
    return guids;
}

export function filterPersonalValues(personalValues, collectionId) {
    const newMap = {};
    Object.entries(personalValues).forEach(entry => {
        const [key, val] = entry;
        // console.dir(key);
        // console.dir(val);
        if ('collectionId' in val &&
            val['collectionId'] == collectionId) {
            newMap[key] = val;
        }
    });
    return newMap;
}

export function getPodcastImg(episode) {
    if (episode && episode.artworkUrl600) {
        return episode.artworkUrl600;
    } else if (episode && episode.thumbnail) {
        return episode.thumbnail;
    } else {
        return ''; // return some default image in future.
    }
}

export function setCustomPlaybackRate(collectionId, rate) {
    if (!rate) {
        localStorage.removeItem(`appPlaybackRate_${collectionId}`);
    } else {
        localStorage.setItem(`appPlaybackRate_${collectionId}`, rate);
    }
}

export function getCollectionPlaybackRate(collectionId) {
    const customPlaybackSpeed = localStorage.getItem(`appPlaybackRate_${collectionId}`) || localStorage.getItem(`appPlaybackRate`) || 1.0;
    return parseFloat(customPlaybackSpeed);
}

export function showSubscriberCount(subscribers) {
    if (!isNaN(subscribers)) {
        if (subscribers < 1000) {
            if (subscribers < 1) {
                subscribers = subscribers + 1;
            }
            // return subscribers.toString();
            return (Math.ceil(3 * subscribers / 100) / 10).toFixed(1) + 'K';
            // return subscribers;
        } else {
            return (3 * subscribers / 1000).toFixed(1) + 'K';
        }
    } else {
        return '0.1K';
    }
}

export function getPodcastImgSize(imgUrl, size = 'lg') {
    let sizeVal;
    switch (size) {
        case 'xxs':
            sizeVal = 20;
            break;
        case 'xs':
            sizeVal = 80;
            break;
        case 'sm':
            sizeVal = 100;
            break;
        case 'md':
            sizeVal = 160;
            break;
        case 'lg':
            sizeVal = 300;
            break;
        default:
            sizeVal = 600;
    }

    const testStr = '/600x600';
    if (imgUrl && imgUrl.includes(testStr)) {
        const str = imgUrl.replace(testStr, `/${sizeVal}x${sizeVal}`);
        return str.replace('&amp;', '&');
    } else {
        return imgUrl ? imgUrl.replace('&amp;', '&') : '';
    }

}

export function getFinalEpisodeImg(podcastEpisode, parent, size = 'lg') {
    let imgSrc;
    // if (parent !== 'podcast') {
    imgSrc = getPodcastImgSize(podcastEpisode['artworkUrl600'], size) || getPodcastImgSize(podcastEpisode.thumbnail, size) || '';
    // console.dir(podcastEpisode);
    // console.log('imgSrc : ' + imgSrc);
    // } else {
    //     imgSrc = typeof podcastEpisode.thumbnail !== 'undefined' && podcastEpisode.thumbnail.length > 0 ? podcastEpisode.thumbnail :
    //         getPodcastImgSize(podcastEpisode['artworkUrl600'], size);
    //     // typeof podcastEpisodes !== 'undefined' && typeof podcastEpisodes.feed !== 'undefined' && podcastEpisodes.feed.image !== 'undefined' ? podcastEpisodes.feed.image : '';
    // }
    return imgSrc;
}

export function getIdFromUID(userUID) {
    if (userUID) {
        return userUID;   ///  .slice(-9);  // not in use
    } else {
        return '';
    }
}

export function ratingValue(rating, fixed = 1, type = 'str') {
    if (rating && rating > 0 && type === 'num') {
        let val = parseFloat(rating.toFixed(fixed));
        let val5 = (Math.round(val * 10 / 5) * 5) / 10;
        return val5;
    } else if (rating && rating > 0 && type === 'str') {
        return rating.toFixed(fixed);
    }
    if (type === 'num') {
        return 0;
    } else {
        return '-';
    }
}

export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then((val) => {
            console.log(val);
            hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)
        }
        );
        promise.catch((error) => {
            console.log(error);
            hasCanceled_ ? reject({ isCanceled: true }) : reject(error)
        }
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, source) {
    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach(key => {
            if (isObject(source[key])) {
                if (!(key in target))
                    Object.assign(output, { [key]: source[key] });
                else
                    output[key] = mergeDeep(target[key], source[key]);
            } else {
                Object.assign(output, { [key]: source[key] });
            }
        });
    }
    return output;
}

export function isEpisodeExplicitlyMarkedPlayed(
    guid, personalMarkPlayedValues) {
    if (!guid) {
        return false;
    }
    const guidCleaned = cleanFirebaseId(guid);

    const isMarkedAsPlayed1 =
        personalMarkPlayedValues[guidCleaned] || false;
    return isMarkedAsPlayed1;
}

export function getCurrentAutoChapterIndex(chapters, currentTimeInSec) {
    //// add 1 sec of padding just to be safe
    const currentChapterIndex = chapters.findIndex((chap) =>
        (currentTimeInSec + 1) >= chap['startSec'] &&
        (currentTimeInSec + 1) < chap['endSec']);
    return Math.max(currentChapterIndex, 0);
}

// export function objToArr(obj) {
//     Object.keys(obj).map(function (key) {
//         return [Number(key), obj[key]];
//     });
// }

export function secToTime(seconds, fullText = false, shortText = false) {
    if (typeof seconds === 'undefined' || seconds === 0) {
        return '--:--';
    }
    let secondsNumber = 0;
    if (typeof seconds === 'string' && seconds.includes(':')) {
        secondsNumber = 0;
        let secondsMicroSec = seconds.split('.')[0];
        let secondsList = secondsMicroSec.split(':');
        for (let i = 0; i < secondsList.length; i++) {
            // print('secondsList : ' + secondsList[i].toString());
            secondsNumber += (parseInt(secondsList[i]) || 0) *
                Math.pow(60, secondsList.length - i - 1);
            // print('secondsListNumber : ' + secondsNumber.toString());
        }

        // if (seconds.length > 4 && seconds.startsWith('00:')) {
        //     return seconds.substring(3);
        // }
        // return seconds;
    } else if (typeof seconds === 'string') {
        secondsNumber = parseInt(seconds);
    } else {
        secondsNumber = seconds;
    }
    const hrs = Math.floor(secondsNumber / 3600);
    const min = Math.floor((secondsNumber % 3600) / 60);
    const sec = Math.round(secondsNumber % 60);
    if (fullText) {
        if (shortText) {
            return (hrs > 0 ? (hrs + 'h ') : '') + doubleDigits(min) + 'm';
        } else {
            return (hrs > 0 ? (hrs + ' HR ') : '') + doubleDigits(min) + ' MINS';
        }
    } else {
        return (hrs > 0 ? (hrs + ':') : '') + doubleDigits(min) + ':' + doubleDigits(sec);
    }
}

export function secToDate(seconds) {
    return new Intl.DateTimeFormat('en-GB', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
        // second: '2-digit', // just for now testing
        // }).format(new Date(podcastEpisode.pubDate))}</p>
    }).format(Date.parse(new Date(seconds * 1000).toISOString().replace(" ", "T")))
    //  new Date(seconds * 1000).toISOString();
}

export function secToDateFromPubDate(pubDateParsed, showMinsAgo = false) {
    if (showMinsAgo && pubDateParsed instanceof Date) {
        const timeDiffInmillis = Date.now() - pubDateParsed.getTime();
        const timeDiffInMins = Math.ceil(timeDiffInmillis / (60 * 1000));
        if (timeDiffInMins < 60) {
            return timeDiffInMins + ' mins Ago';
        } else if (timeDiffInMins < 24 * 60) {
            const hrs = Math.round(timeDiffInMins / 60);
            return hrs == 1 ? '1 hr Ago' : hrs + ' hrs Ago';
        } else if (timeDiffInMins < 2 * 24 * 60) {
            return 'Yesterday';
        }
    }
    return pubDateParsed ? format(pubDateParsed, '{dd}') + localeFormat(pubDateParsed, ' {MMM} ') + format(pubDateParsed, '{yyyy}') : '-';
}

export function secToDateDay(seconds) {
    try {
        return new Intl.DateTimeFormat('en-GB', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
            // second: '2-digit', // just for now testing
            // }).format(new Date(podcastEpisode.pubDate))}</p>
        }).format(Date.parse(new Date(seconds * 1000).toISOString().replace(" ", "T")))
        //  new Date(seconds * 1000).toISOString();
    } catch (err) {
        return '-';
    }
}

export function stringToDateDay(str) {
    try {
        const date = new Date(str);

        return localeFormat(date, '{MMM} ') + format(date, '{dd}');
        // return new Intl.DateTimeFormat('en-GB', {
        //     year: 'numeric',
        //     month: 'short',
        //     day: '2-digit'
        //     // second: '2-digit', // just for now testing
        //     // }).format(new Date(podcastEpisode.pubDate))}</p>
        // }).format(str)
        //  new Date(seconds * 1000).toISOString();
    } catch (err) {
        return '-';
    }
}

export function bytesToMB(bytes) {
    if (bytes) {
        return Math.round(bytes / Math.pow(10, 6)) + ' MB';
    }
}

export const loadUser = (userDetails) => new Promise((resolve) => {
    console.log('in loadUser');
    if (userDetails) {
        resolve({ userDetails, newData: false });
    } else {
        console.log('in loadUser 2');
        // return firebase.auth().onAuthStateChanged(user => {
        return onAuthStateChanged(auth, user => {
            console.log('in loadUser 3');
            console.dir(user);
            if (user) {
                resolve({ userDetails: user, newData: true });
            } else {
                resolve(null);
            }
        });
    }
});

export function cleanText(text) {
    if (typeof text === 'undefined') {
        return '';
    }
    // const cleanedText = text.replace(/&amp;/g, '&').replace(/&apos;/g, "'").replace(/&quot;/g, "\"");
    // return cleanedText;
    return text; // he.decode(text);
}
export function cleanTitleText(text) {
    if (typeof text === 'undefined') {
        return '';
    }
    // const cleanedText = text.replace(/&amp;/g, '&').replace(/&apos;/g, "'").replace(/&quot;/g, "\"");
    // return cleanedText;
    return ['conclusion', 'recap'].includes(text.toLowerCase()) ? 'Final Recap' : text; // he.decode(text);
}

export function getUUID(inputString) {
    if (!inputString) {
        return;
    }
    return uuidv5(inputString, APP_UUID_NAMESPACE);
}

export function doubleDigits(val) {
    return val < 10 ? '0' + val : val;
}

export function downloadFile(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

let autoMarkEpisodesPlayed = 99;
export function getAutoMarkEpisodesPlayed() {
    return autoMarkEpisodesPlayed;
}

export function setAutoMarkEpisodesPlayed(val) {
    autoMarkEpisodesPlayed = val;
    return;
}

export function cleanFirebaseId(str) {
    if (str) {
        // const v1 = str.replace(new RegExp('//', 'g'), '__');
        const v2 = str.replace(new RegExp('/', 'g'), '_');
        const v3 = v2.replace(new RegExp('\\.', 'g'), '___');
        const v4 = v3.replace(new RegExp(' '), '');
        return v4;
    } else {
        return '';
    }
}

export function uncleanFirebaseId(str) {
    if (str != null) {
        const v1 = str.replace(new RegExp(' '), '');
        const v2 = v1.replace(new RegExp('___'), '.');
        const v3 = v2.replace(new RegExp('__'), '//');
        const v4 = v3.replace(new RegExp('_'), '/');
        return v4;
    } else {
        return '';
    }
}

export function cleanGUID(episodeInfo) {
    const backupId = episodeInfo.author + '-' + episodeInfo.pubDate;
    const backupIdCleaned = backupId.replace(/ /g, '_');
    let guidCleaned;
    if (typeof episodeInfo.guid !== 'undefined') {
        guidCleaned = cleanFirebaseId(episodeInfo.guid);
    }
    const episodeId = guidCleaned || backupIdCleaned;
    return episodeId;
}

export function setProgress(val) {
    if (!val || val < 0) {
        return 0;
    }
    const places = 2; // limit to 2 decimal places
    let mod = Math.pow(10.0, places);
    let finVal = Math.round(val * mod) / mod;
    if (finVal >= 100) {
        return 99.99;
    }
    return finVal;
}

export function createPodcastObj(podcastInfo) {
    return {
        artistName: podcastInfo.artistName,
        artworkUrl600: podcastInfo.artworkUrl600,
        collectionId: podcastInfo.collectionId,
        collectionName: podcastInfo.collectionName || podcastInfo.trackName,
        feedUrl: podcastInfo.feedUrl,
        genres: podcastInfo.genres,
        id: podcastInfo.collectionId,
        primaryGenreName: podcastInfo.primaryGenreName || ''
    }
}

// export function createPodcastObj(podcastInfo) {
//     return {
//         collectionName: podcastInfo.collectionName || podcastInfo.trackName,
//         artistName: podcastInfo.artistName,
//         artworkUrl600: podcastInfo.artworkUrl600,
//         collectionId: podcastInfo.collectionId,
//         collectionExplicitness: podcastInfo.collectionExplicitness,
//         feedUrl: podcastInfo.feedUrl,
//         genres: podcastInfo.genres,
//         primaryGenreName: podcastInfo.primaryGenreName,
//         trackCount: podcastInfo.trackCount
//     }
// }

const episodesToFetchEachTime = 20;

export const episodesPerFetch = episodesToFetchEachTime;

export function getEpisodePaginationMap(totalItemCount) {
    const episodesPerFetch = episodesToFetchEachTime; // same as that used in cloud functions.
    // console.dir(episodesPerFetch);
    let lastFew;
    let paginationMap = {}; // new Map(); // {};
    if (totalItemCount <= episodesPerFetch) {
        lastFew = totalItemCount;
        // paginationMap.set(`${lastFew} - 1`, { start: lastFew, count: 'all' });
        paginationMap[`${lastFew} - 1`] = { start: lastFew, count: 'all' };
    } else {
        lastFew = totalItemCount % episodesPerFetch;
        if (lastFew <= episodesPerFetch / 2) {
            lastFew = lastFew + episodesPerFetch;
        }
        // console.dir(totalItemCount - lastFew + 1);
        paginationMap[`${totalItemCount} - ${totalItemCount - lastFew + 1}`] = { start: 'latest' };
        let lastEpisode = totalItemCount - lastFew + 1;
        while (lastEpisode > 1) {
            let firstEpisode = lastEpisode - 1;
            lastEpisode = firstEpisode - episodesPerFetch + 1;
            paginationMap[`${firstEpisode} - ${lastEpisode}`] = { start: firstEpisode, count: episodesPerFetch };
        }
    }
    return paginationMap;
}

// export function giveNumberToEpisodes(episodes, episodeFrom, totalItemCount) {
//     if (episodes) {
//         let episodeFirstNum = episodeFrom == 'latest' ? totalItemCount : episodeFrom;
//         for (let episode of episodes) {
//             episode['appEpisodeNumber'] = episodeFirstNum;
//             episodeFirstNum--;
//         }
//     }
//     return episodes;
// }

export function getFirestoreIdFromSlug(userPlaylists, playlistSlugId) {
    console.log(userPlaylists);
    console.log(playlistSlugId);
    // const index = Object.values(userPlaylists).findIndex(item => item.slug === playlistSlugId);
    // const firestoreId = Object.keys(userPlaylists)[index];
    for (let key of Object.keys(userPlaylists)) {
        if (userPlaylists[key].slug === playlistSlugId) {
            return key;
        }
    }
}

export function cleanGenres(genres) {
    if (!genres) {
        return [];
    }
    let newGenres = []; // genres.filter(genre => !genre.includes('odcast'));
    genres.forEach(el => {
        if (podcastTypesExtra()[el]) {
            newGenres.push(podcastTypesExtra()[el]['name']);
        }
    });
    return newGenres;
}

export function createEpisodeObj(episodeInfo) {
    return {
        author: episodeInfo.author || '',
        collectionName: episodeInfo.collectionName || '',
        enclosure: episodeInfo.enclosure || '',
        guid: episodeInfo.guid || '',
        pubDate: episodeInfo.pubDate || '',
        thumbnail: episodeInfo.thumbnail || '',
        artworkUrl600: episodeInfo.artworkUrl600 || '',
        description: episodeInfo.description || episodeInfo.content || '',
        title: episodeInfo.title || '',
        genres: episodeInfo.genres || [],
        collectionId: episodeInfo.collectionId || '',
        isMyFile: episodeInfo.isMyFile || false,
        'rating': 0,
        'ratedBy': 0
    }
}

export function getPodcastGenre(genreId) {
    return genreId.replace('-and-', ' & ').split(',')[0];
}

// export const podcastTypes = [{ id: 'Society-and-Culture', name: 'Society & Culture', imgUrl: 'society1-320.jpg' },
// { id: 'News-and-Politics', name: 'News & Politics', imgUrl: 'news1-320.jpg' },
// { id: 'Business,Technology', name: 'Business & Technology', imgUrl: 'business1-320.jpg' },
// { id: 'Education', name: 'Education', imgUrl: 'education1-320.jpg' },
// { id: 'Comedy', name: 'Comedy', imgUrl: 'comedy1-320.jpg' },
// { id: 'Arts', name: 'Arts', imgUrl: 'art1-320.jpg' },
// { id: 'Health', name: 'Health', imgUrl: 'health1-320.jpg' },
// { id: 'Sports-and-Recreation', name: 'Sports & Recreation', imgUrl: 'sports1-320.jpg' },
// ];

export const podcastMapContainsGenre = (podcastOrEpisode, genre) => {
    if (podcastOrEpisode['genres']) {
        // print('podcastMapContainsGenre00');
        // print(podcastOrEpisode['genres'].toString());
        // print(genre);
        for (const genreLoc of podcastOrEpisode['genres']) {
            if (genreLoc.includes(genre)) {
                return true;
            }
        }
    }
    return false;
}

export const isPodcastIndexEpisode = (podcastEpisodeSearchData) => {
    return podcastEpisodeSearchData['description'];
}

export const getEpisodeDuration = (podcastEpisodeSearchData) => {
    return isPodcastIndexEpisode(podcastEpisodeSearchData)
        ? (podcastEpisodeSearchData['enclosure']['duration'] || 0)
        : (podcastEpisodeSearchData['duration'] || 0);
}

export const searchSortOptions = [
    { 'id': SORT_RELEVANCE, 'name': 'Relevance & Popularity (Default)' },
    { 'id': SORT_PUB_DATE_NEW_FIRST, 'name': 'Release date (Newest first)' },
    { 'id': SORT_PUB_DATE_OLD_FIRST, 'name': 'Release date (Oldest first)' },
    {
        'id': SORT_EPISODE_COUNT_DESC,
        'name': 'Num. of episodes (High to Low)',
        'type': 0
    },
    {
        'id': SORT_EPISODE_COUNT_ASC,
        'name': 'Num. of episodes (Low to High)',
        'type': 0
    },
    {
        'id': SORT_EPISODE_DURATION_DESC,
        'name': 'Episode Duration (Long to Short)',
        'type': 1
    },
    {
        'id': SORT_EPISODE_DURATION_ASC,
        'name': 'Episode Duration (Short to Long)',
        'type': 1
    },
];

export const searchLanguageFilterOptions = [
    { 'id': 'all', 'name': 'All Languages' },
    { 'id': 'zh', 'name': 'Chinese' },
    { 'id': 'en', 'name': 'English' },
    { 'id': 'fr', 'name': 'French' },
    { 'id': 'de', 'name': 'German' },
    { 'id': 'hi', 'name': 'Hindi' },
    { 'id': 'pt', 'name': 'Portugese' },
    { 'id': 'es', 'name': 'Spanish' },
];

export const hasHindiChar = (text) => {
    return text.split("").filter((char) => {
        var charCode = char.charCodeAt(); return charCode >= 2309 && charCode <= 2361;
    }).length > 0;
}

export const hasChineseChar = (text) => {
    const REGEX_CHINESE = /[\u4e00-\u9fff]|[\u3400-\u4dbf]|[\u{20000}-\u{2a6df}]|[\u{2a700}-\u{2b73f}]|[\u{2b740}-\u{2b81f}]|[\u{2b820}-\u{2ceaf}]|[\uf900-\ufaff]|[\u3300-\u33ff]|[\ufe30-\ufe4f]|[\uf900-\ufaff]|[\u{2f800}-\u{2fa1f}]/u;
    // RegExp regExp = new RegExp(
    //     r"[\u3400-\u9FBF]",
    //     // caseSensitive: false,
    //     // multiLine: false,
    // );
    return REGEX_CHINESE.test(text);
}

export const isPodcastRequiredLanguage = (podcastData, languageId) => {
    if (languageId == 'hi') {
        if (podcastData.language && podcastData.language.toLowerCase().startsWith(languageId)) {
            return true;
        } else if (!podcastData.language &&
            hasHindiChar(podcastData.collectionName)) {
            return true;
        } else {
            return false;
        }
    } else if (languageId == 'zh') {
        if (podcastData.language && podcastData.language.toLowerCase().startsWith(languageId)) {
            return true;
        } else if (!podcastData.language &&
            hasChineseChar(podcastData.collectionName)) {
            return true;
        } else {
            return false;
        }
    } else if (languageId == 'en') {
        if (podcastData.language && podcastData.language.isEmpty) {
            return true;
        } else {
            return podcastData.language && podcastData.language.toLowerCase().startsWith(languageId) || false;
        }
    } else {
        return podcastData.language && podcastData.language.toLowerCase().startsWith(languageId) || false;
    }
}

export const podcastTypes = podcastTypesGenres;

export const mergedAdPodcasts = (podcasts, adPodcasts, mergeIndices = [0, 2, 5]) => {
    if (getIsPremiumUser()) {
        return podcasts;
    }
    const newPodcasts = [...podcasts];
    for (let i = 0; i < adPodcasts.length; i++) {
        if (i <= 2) {
            const indexToInsert = mergeIndices[i];
            if (newPodcasts.length > indexToInsert) {
                newPodcasts.splice(indexToInsert, 0, adPodcasts[i]);
            }
        }
    }
    return newPodcasts;
}

export const initialGenreCount = 1;

//// premium utils
export const offerMonths = 60;
const offerMonthsLife = 1200;
export const currSymbolsParam = { 'u': 'USD', 'g': 'GBP', 'a': 'AUD', 'c': 'CAD', 'e': 'EUR' }
const currSymbols = { 'USD': '$', 'GBP': '£', 'AUD': 'AUD', 'CAD': 'CAD', 'EUR': '€' }
const discountValuesMap = { 'USD': 60, 'GBP': 55, 'AUD': 70, 'CAD': 70, 'EUR': 65 }
export const getCurrency = () => {
    const locationInfo = getlocationInfo();
    if (locationInfo.currency && locationInfo.currency.code) {
        return locationInfo.currency.code;
    }
    return 'USD';
}

export function getMonthCount(option, index) {
    if (!option || !option.recurring) {
        console.dir(index);
        console.dir(index == 0 ? offerMonths : offerMonthsLife);
        return offerMonthsLife; ///premium offer
    }
    return (option['recurring']['interval'] == 'year' ? 12 : 1) * option['recurring']['interval_count'];
}
export function getMonthCountDisplay(option, index) {
    return getMonthCount(option, index) < 200 ? getMonthCount(option, index) : 'Lifetime';
}

export function getOriginalPrice(option, discount) {
    console.dir(option);
    if (!option) {
        return 0;
    }
    const currency = getCurrency();
    const price = ((option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount']);
    const origPrice = Math.ceil(price / (1 - (discount / 100))) / 100;
    console.dir(currency);
    return (currSymbols[currency] || currSymbols['USD']) +
        (origPrice).toFixed(2);
}
export function getIntPrice(option) {
    // console.dir(option);
    if (!option) {
        return 0;
    }
    const currency = getCurrency();
    console.dir(currency);
    return ((option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount'] / 100).toFixed(2);
}
export function getWeeklyPrice(option) {
    const fullPrice = getIntPrice(option);
    const pricePerWeek = fullPrice / 52;
    const currency = getCurrency();
    return (currSymbols[currency] || currSymbols['USD']) + pricePerWeek.toFixed(2);
}
export function getPrice(option) {
    // console.dir(option);
    if (!option) {
        return 0;
    }
    const currency = getCurrency();
    console.dir(currency);
    return (currSymbols[currency] || currSymbols['USD']) +
        ((option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount'] / 100).toFixed(2);
}
export function getPriceAfterDiscount(option, discount) {
    // console.dir(option);
    if (!option) {
        return 0;
    }
    const currency = getCurrency();
    console.dir(currency);
    if (discount > 100) {
        return (currSymbols[currency] || currSymbols['USD']) +
            (((option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount'] / 100) - discount / 100).toFixed(2);
    }
    return (currSymbols[currency] || currSymbols['USD']) +
        (((option['currency_options'][currency.toLowerCase()] || option['currency_options']['usd'])['unit_amount'] / 100) * (1 - discount / 100)).toFixed(2);
}
export function currencyIcon() {
    const currency = getCurrency();
    return (currSymbols[currency] || currSymbols['USD']);
}
export const getDisountLimited = () => {
    return discountValuesMap[getCurrency()];
}
//// premium utils


export function getRelevantGenres(subscribedPodcasts) {
    /// fetch relevant podcast genre podcasts for home page.
    ///
    if (subscribedPodcasts == null || Object.keys(subscribedPodcasts).length < 1) {
        let societyGenreMap =
            podcastTypes.find((el) => el['id'] === 'Comedy');
        return []; // [societyGenreMap['name']];
    }
    let genreCount = {};
    for (let podcast of Object.values(subscribedPodcasts)) {
        if ('genres' in podcast) {
            for (let genre of podcast['genres']) {
                genreCount[genre] =
                    genre in genreCount ? genreCount[genre] + 1 : 1;
            }
        }
    }
    console.log('genreCount : ' + genreCount);
    let relevantGenres = {};
    for (let podcastType of podcastTypes) {
        let genreName = getPodcastGenre(podcastType['id']);
        if (genreName in genreCount) {
            relevantGenres[genreName] = genreCount[genreName];
        }
    }
    let sortedGenres = Object.keys(relevantGenres).sort((k1, k2) => relevantGenres[k2] - relevantGenres[k1]);

    let sortedGenresMutable = sortedGenres; // List.from(sortedGenres);

    /// if less than 3 genres, get default genres
    if (sortedGenresMutable.length < initialGenreCount) {
        for (let podcastType of podcastTypes) {
            if (sortedGenresMutable.length < initialGenreCount) {
                let genreName = getPodcastGenre(podcastType['id']);
                if (!sortedGenresMutable.includes(genreName) && genreName !== 'All') {
                    sortedGenresMutable.push(genreName);
                }
            }
        }
    }
    return sortedGenresMutable;
}

export function capitalize(text) {
    if (!text || text.length < 2) {
        return text || '';
    }
    return `${text[0].toUpperCase()}${text.substring(1)}` || '';
}

export function nameWithoutArticles(name) {
    name = name || '';
    const articleList = ['A ', 'An ', 'The '];
    for (let ind = 0; ind < articleList.length; ind++) {
        if (name.startsWith(articleList[ind])) {
            return name.replace(articleList[ind], '');
        }
    }
    return name;
}

export function sortAlphabetically(a, b) {
    return (a.collectionName || '').localeCompare(b.collectionName || '');
}

export function sortAlphabeticallyName(a, b) {
    return (a.name || '').localeCompare(b.name || '');
}

export function sortPubDate(a, b) {
    if (b.pubDate && a.pubDate) {
        return b.pubDate - a.pubDate;
    } else {
        return false;
    }
}

export function sortNumericallyDesc(a, b) {
    return b.dateAdded['_seconds'] - a.dateAdded['_seconds'];
}

export function sortByTimeAsc(a, b) {
    return a['ordinalityDate'] - b['ordinalityDate'];
}
export function sortByTimeDesc(a, b) {
    return b['ordinalityDate'] - a['ordinalityDate'];
}

export function sortLastModifiedDate(a, b) {
    return b.lastModifiedDate['seconds'] - a.lastModifiedDate['seconds'];
}

export function sortNumericallyLastDesc(a, b) {
    return b.lastPlayed['seconds'] - a.lastPlayed['seconds'];
}

export function sortRating(a, b) {
    return b.rating - a.rating;
}

export const authError = {
    'auth/user-not-found': 'Incorrect username or password. Please try again.',
    'auth/email-already-in-use': 'You have already registered. Please click \'Sign In\' above and Forgot Password to reset your password.',
    'auth/weak-password': 'Password should be at least 6 characters.',
    'auth/wrong-password': 'Incorrect password or this email is associated with google/facebook signIn.'
}