import { firestore } from '../lib/db';
import { cleanFirebaseId, createEpisodeObj, getIdFromUID, cdnApi, getUUID, firestoreApi, getProgressFromGUID, sortNumericallyLastDesc, filterPersonalValues, sortPersonalValuesGuidDesc, maxMarkPlayedEntries, markPlayedDocName, getFirebaseUser, serverApi } from '../lib/utils';
import {
    DELETE_FROM_HISTORY, DELETE_FROM_HISTORY_FAILURE, FETCH_RECENT_PLAYED, ADD_TO_RECENT_PLAYED,
    LOADING_RECENT_PLAYED, DELETE_FROM_RECENT_PLAYED, FETCH_RECENT_PLAYED_ERROR, CHANGE_SEARCH_TERM,
    PERSONAL_VALUES_MARK_PLAYED_ACTION, PERSONAL_VALUES_MARK_UNPLAYED_ACTION, PERSONAL_VALUES_MARK_ALL_UNPLAYED_ACTION, ADD_POPULAR_PODCASTS
} from './types';
import { Timestamp, collection, query, where, doc, getDoc, deleteField, increment, orderBy, limit, startAfter, getDocs, setDoc, arrayUnion } from "firebase/firestore/lite";
import FireStoreParser from 'firestore-parser';
import { logPlayAudio } from '../lib/analyticsEvents';
import { addToPlayedCount, autoShareTweet, setPlayedCount } from './PodcastTagActions';
// const FlexSearch = require("flexsearch");
// import * as FlexSearch from 'flexsearch';
import { getMaxMarkPlayedIndex, setMaxMarkPlayedIndex } from '../lib/fileUtils';

export const fetchTopChartsEpisodes = async (genreId, fetchIndex) => {
    // const urlEpisodes = firestoreApi + `topEpisodes/${genreId}/${fetchIndex}/episodes`;
    // const resEpisodes = await fetch(urlEpisodes); // resultsAll[0];

    // const jsonEpisodes = await resEpisodes.json();
    // const parsedFirestoreEpisodesJSON = FireStoreParser(jsonEpisodes);
    // const finalJsonArrayEpisodes = parsedFirestoreEpisodesJSON?.fields?.episodes || [];
    // return finalJsonArrayEpisodes;
    return [];
}
import fetchISO from 'isomorphic-unfetch';
import { tokenFetch, tokenFetchPOST } from '../lib/tokenUtils';

export const getEpisodesRecent = (userUID) => async (dispatch, getState) => {
    // const db = await loadDB();
    console.log(userUID);
    let recentlyPlayed = getState().recentPlayedEpisodes;
    if (!recentlyPlayed || Object.values(recentlyPlayed).length < 1) {
        dispatch({
            type: LOADING_RECENT_PLAYED,
            payload: { loadingRecentPlayed: true }
        });
        dispatch({
            type: FETCH_RECENT_PLAYED_ERROR,
            error: false
        });
        getDoc(doc(firestore, `users/${userUID}/podcastEpisodes`, 'recentlyPlayed'))
            .then(function (docLoc) {
                var recentlyPlayedEpisodes = {};
                if (docLoc.exists()) {
                    // console.log("Document data:", doc.data());
                    // console.dir(Object.values(doc.data().podcasts));
                    if (typeof docLoc.data().recentlyPlayedEpisodes !== 'undefined') {
                        recentlyPlayedEpisodes = docLoc.data().recentlyPlayedEpisodes;
                    }
                }
                // resolve({ success: true, recentlyPlayedEpisodes });
                console.dir(recentlyPlayedEpisodes);
                dispatch({
                    type: FETCH_RECENT_PLAYED,
                    payload: recentlyPlayedEpisodes
                });
                dispatch({
                    type: LOADING_RECENT_PLAYED,
                    payload: { loadingRecentPlayed: false }
                });

                const cutoffLimit = 40; // for webapp
                let recentlyPlayedEpisodesNew = recentlyPlayedEpisodes;
                let recentEpisodesUpdate = {};
                if (Object.keys(recentlyPlayedEpisodesNew).length > cutoffLimit) {
                    /// remove 10 extra episodes on web
                    let numEpisodesToRemove =
                        Object.keys(recentlyPlayedEpisodesNew).length - cutoffLimit + 20;
                    let recentlyPlayedEpisodesNewList = Object.values(recentlyPlayedEpisodesNew);
                    recentlyPlayedEpisodesNewList = recentlyPlayedEpisodesNewList.sort(sortNumericallyLastDesc);

                    /// sorted by latest first
                    let temp = {};
                    for (let i = recentlyPlayedEpisodesNewList.length - 1; i >= recentlyPlayedEpisodesNewList.length - numEpisodesToRemove; i--) {
                        let episodeDeleteGuid =
                            cleanFirebaseId(recentlyPlayedEpisodesNewList[i].guid);
                        temp[`${episodeDeleteGuid}`] = deleteField();
                    }
                    recentEpisodesUpdate['recentlyPlayedEpisodes'] = temp;
                    recentEpisodesUpdate['recentEpisodesLength'] = increment(-numEpisodesToRemove);
                    // console.dir(recentEpisodesUpdate);
                    setDoc(doc(firestore, `users/${userUID}/podcastEpisodes`, 'recentlyPlayed'), recentEpisodesUpdate, { merge: true });
                }
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
                // reject({ success: false, error: error });
                dispatch({
                    type: LOADING_RECENT_PLAYED,
                    payload: { loadingRecentPlayed: false }
                });
                dispatch({
                    type: FETCH_RECENT_PLAYED_ERROR,
                    error: true
                });
            });
        // return makeCancelable(somePromise);
    }
};

export const addEpisodeToRecent = (userUID, episodeInfo) => async (dispatch, getState) => {
    // const db = await loadDB();
    if (!episodeInfo.guid || (episodeInfo.guid + '').length < 3) {
        console.error('guid not found for this episode.');
    }
    const backupId = episodeInfo.author + '-' + episodeInfo.pubDate;
    const backupIdCleaned = backupId.replace(/ /g, '_');
    var guidCleaned;
    if (typeof episodeInfo.guid !== 'undefined') {
        guidCleaned = cleanFirebaseId(episodeInfo.guid);
    }
    const episodeId = guidCleaned || backupIdCleaned;
    var recentEpisodesUpdate = {};
    recentEpisodesUpdate['userUID'] = userUID;
    recentEpisodesUpdate['recentEpisodesLength'] = increment(1);
    let episodeObject = createEpisodeObj(episodeInfo);
    // if (typeof episodeObject.description !== 'undefined') { // dont delete as u may need to add to playlist
    //   delete episodeObject['description'];
    // }
    let recentEpisodesUpdateObj = {
        ...createEpisodeObj(episodeInfo),
        lastPlayed: Timestamp.fromDate(new Date()) // firebase.firestore.FieldValue.serverTimestamp()
    };
    recentEpisodesUpdate['recentlyPlayedEpisodes'] = {
        [`${episodeId}`]: recentEpisodesUpdateObj
    };
    dispatch({
        type: ADD_TO_RECENT_PLAYED,
        payload: { [`${episodeId}`]: recentEpisodesUpdateObj }
    });
    setDoc(doc(firestore, `users/${userUID}/podcastEpisodes`, 'recentlyPlayed'), recentEpisodesUpdate, { merge: true })
        .catch((error) => {
            console.error("Error adding document: ", error);
            // dispatch({
            //   type: SUBSCRIBE_PODCAST_FAILURE,
            //   payload: null
            // });
        });
    setDoc(doc(firestore, `lastPlayedEpisodes`, getIdFromUID(userUID)), {
        ...episodeObject,
        userID: getIdFromUID(userUID),
        lastPlayed: new Date(),
    }, { merge: true });
    let personalValues = getState().personalValues;
    if (!personalValues[guidCleaned]) {
        var personalProgressUpdate = {};
        personalProgressUpdate[`${guidCleaned}`] = {
            progress: 0,
            collectionId: episodeInfo.collectionId,
            lastPlayed: Timestamp.now()
        };
        setDoc(doc(firestore, `users/${userUID}/personal`, 'details'), { values: personalProgressUpdate }, { merge: true })
            // .then(() => {})
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }
    let sliderValue = getProgressFromGUID(personalValues, episodeInfo.guid);
    logPlayAudio(episodeInfo.guid, episodeInfo.collectionId, sliderValue, 1);
};

export const deleteFromHistory = (userUID, guid) => async dispatch => {
    // const db = await loadDB();
    // console.log(episodeInfo);
    var guidCleaned;
    if (typeof guid !== 'undefined') {
        guidCleaned = cleanFirebaseId(guid);
    }
    const recentPlayedUpdate = {};
    // recentPlayedUpdate[`recentlyPlayedEpisodes.${guidCleaned}`] = deleteField();
    const temp = {};
    temp[`${guidCleaned}`] = deleteField();
    recentPlayedUpdate['recentlyPlayedEpisodes'] = temp;

    setDoc(doc(firestore, `users/${userUID}/podcastEpisodes`, 'recentlyPlayed'), recentPlayedUpdate, { merge: true })
        .then(() => {
            // resolve({ success: true });
            dispatch({
                type: DELETE_FROM_RECENT_PLAYED,
                payload: guidCleaned
            });
        })
        .catch((err) => {
            console.dir(err);
            // reject({ err: err });
            dispatch({
                type: DELETE_FROM_RECENT_PLAYED,
                payload: -1
            });
        })


};

export const deleteFromHistoryProgress = (userUID, guid) => async dispatch => {
    // const db = await loadDB();
    console.log(userUID);
    var guidCleaned;
    if (typeof guid !== 'undefined') {
        guidCleaned = cleanFirebaseId(guid);
    }
    const progressUpdate = {};
    // progressUpdate[`values.${guidCleaned}.progress`] = deleteField();
    const temp = {};
    temp[`${guidCleaned}`] = { 'progress': deleteField() };
    progressUpdate['values'] = temp;
    setDoc(doc(firestore, `users/${userUID}/personal`, 'details'), progressUpdate, { merge: true })
        .then(() => {
            dispatch({
                type: DELETE_FROM_HISTORY,
                payload: guidCleaned
            });
        })
        .catch((err) => {
            console.dir(err);
            dispatch({
                type: DELETE_FROM_HISTORY_FAILURE,
                payload: null
            });
        })
};

export const getRecentPlayedCount = (episode_guid) => {
    return 0;
    // return new Promise((resolve, reject) => {
    //     console.log(episode_guid);
    //     return fetch(`${cdnApi}recentPlayedCount?guid=${episode_guid}`)
    //         .then(response =>
    //             response.json().then(data => ({
    //                 data: data,
    //                 status: response.status
    //             })
    //             ))
    //         .then((res) => {
    //             const data = res.data;
    //             resolve({ success: true, count: data.count });
    //         })
    //         .catch((error) => {
    //             console.log("Error: ", error);
    //             reject({ success: false, error: error });
    //         });
    // })
};

export const logEpisodeData = (episodeInfo, forceLogData = false) => {
    return new Promise((resolve, reject) => {
        ///// DONT' DO ANYTHING
    });
}

export const getEpisodesDataFromGUIds = async (guids) => {
    const futures = [];
    //   print('guids : ' + guids.toString());
    for (let guid of guids) {
        const docId = getUUID(guid);
        futures.push(getEpisodeDataFromDocId(docId).catch((err) => console.error(err)));
    }
    const episodes = await Promise.all(futures);
    const episodesFin = [];
    episodes.forEach((episode) => {
        if ((episode?.collectionId ?? null) != null) {
            episodesFin.push(episode);
        }
    });
    //   print('guids episodes data : ' + episodes.toString());
    return episodesFin;
}

export const getEpisodeDataFromDocId = async (docId) => {
    console.dir(docId);
    let docLoc = await getDoc(doc(firestore, `episodes`, docId));
    if (docLoc.exists()) {
        return docLoc.data();
    } else {
        throw 'Error';
    }
}

// Future<PodcastEpisode> getEpisodeDataFromGUIDOffline(String guid) async {
//     try {
//       Map episodeData = {};
//       Dio dio = new Dio();
//       dio.interceptors..add(UrlCache(onlineCacheFetchHours: 7 * 24));
//       final String docId = utils.getUUID(guid);
//       // print('getEpisodeDataFromDocIdOffline docId : ' + docId);
//       final res = await dio.get('${utils.firestoreApi}episodes/${docId}');
//       final Map result = res.data;
//       final Map parsedResult = FireStoreParser(result);
//       if (parsedResult.containsKey('fields')) {
//         final Map data = parsedResult['fields']; // doumentSnapshot.data;
//         // print('getEpisodeDataFromDocIdOffline 1 : ' + data.toString());
//         episodeData = data ?? {};
//       }
//       // print('episodeData 222: ' + episodeData.toString());
//       return PodcastEpisode.fromJson(episodeData);
//     } catch (err) {
//       // print('getEpisodeDataFromDocIdOffline err : ' + err.toString());
//       // rethrow;
//       return null;
//     }
//   }

export const getEpisodeFromFirestore = async (uuid) => {
    console.dir(uuid);
    const url = firestoreApi + `episodes/${uuid}`;
    const response = await fetch(url);
    const json = await response.json();
    const parsedFirestoreJSON = FireStoreParser(json);
    console.dir(parsedFirestoreJSON);
    let finalEpisodeJson = parsedFirestoreJSON?.fields || {};
    return finalEpisodeJson;
}

export const changeSearchTerm = (searchTerm) => async dispatch => {
    dispatch({
        type: CHANGE_SEARCH_TERM,
        payload: searchTerm
    });
}


// Future<void> getPopularEpisodesInSeries(
//     Map itunesData, bool isEpisodesSortReversed) async {
export const getPopularEpisodesInSeries = (itunesData, isEpisodesSortReversed) => async (dispatch, getState) => {
    try {
        const collectionId = itunesData['collectionId'];
        let allPopularPodcasts = getState().popularPodcasts || {};
        if (allPopularPodcasts[`${collectionId}`]) {
            return;
        }
        const feedUrl = itunesData['feedUrl'];
        const genresArray = itunesData['genres'] || [];
        const genres = encodeURIComponent(genresArray.join(','));
        const response = await fetch(
            `${cdnApi}popularEpisodesInSeriesV2?rss_url=${encodeURIComponent(feedUrl)}&collectionId=${collectionId}&genres=${genres}`);
        const json = await response.json();

        const episodesJson = json;
        const episodes = {};
        episodesJson.forEach((element) => {
            if (element['guid']) {
                episodes[element['guid']] = element;
            }
        });
        console.dir(episodes);
        dispatch({
            type: ADD_POPULAR_PODCASTS,
            collectionId: collectionId,
            episodesMap: episodes,
        })
    } catch (e) {
        throw e;
        // return {};
    }
}

// Future<List<PodcastEpisode>> getAllEpisodesInSeries(Map podcastData) async {
export const getAllEpisodesInSeries = async (podcastData) => {
    const collectionId = podcastData['collectionId'];



    return getDoc(doc(firestore, `latestEpisodes`, `${collectionId}`))
        .then(function (docLoc) {
            const data = docLoc.data();
            console.dir(data);
            return data?.rssFeedInJson?.items ?? [];
        })


    // let genresArray = podcastData['genres'] || [];
    // let feedUrl = podcastData['feedUrl'] || '';
    // feedUrl = feedUrl.split('?limit')[0];
    // let genres = encodeURIComponent(genresArray.join(','));
    // let queryString =
    //     `${cdnApi}getRSSFeedV2?rss_url=${encodeURIComponent(feedUrl)}&collectionId=${collectionId}&genres=${genres}&episodeFrom=latest&count=all`;

    // if (parseInt(collectionId) < -1 && getFirebaseUser() && getFirebaseUser().uid) {
    //     queryString += `&userUID=${getFirebaseUser().uid}`
    // }
    // try {
    //     const resultOrig = await fetch(queryString);

    //     const result = await resultOrig.json(); // jsonDecode(response.body);
    //     console.dir(result);
    //     // final PodcastFeed podcastFeed = await compute(
    //     //     parsePodcastFeed, { 'result': result, 'podcastData': podcastData });
    //     return result.items;
    // } catch (e) {
    //     // print(e);
    //     throw e;
    //     // return {};
    // }
}

//   Future<List<PodcastEpisode>> searchInSeries(String searchTerm, String feedUrl,
//       dynamic collectionId, List<String> genresArray, Map podcastData) async {
export const searchInSeries = async (searchTerm, podcastData, limitNumberOfResults) => {

    const allEpisodes =
        await getAllEpisodesInSeries(podcastData);
    let filteredEpisodes = [];
    console.dir(allEpisodes);
    // print(searchTerm);

    // const limitNumberOfResults = 20;
    // console.log(Date.now());
    const FlexSearch = (await import('flexsearch')).default;
    const titleIndex = new FlexSearch();
    const descriptionIndex = new FlexSearch();
    if (typeof allEpisodes !== 'undefined') {
        for (let i = 0, len = allEpisodes.length; i < len; i++) {
            const title = allEpisodes[i].title || '';
            const description = allEpisodes[i].description || '';
            titleIndex.add(i, title);
            descriptionIndex.add(i, description);
        }
        const titleResults = titleIndex.search(searchTerm, limitNumberOfResults);
        // console.log(JSON.stringify(titleResults));
        let descriptionResults = [];
        if (titleResults.length < limitNumberOfResults) {
            const limitNumberOfDescriptionResults = Math.min((limitNumberOfResults - titleResults.length), 4);
            descriptionResults = descriptionIndex.search(searchTerm, limitNumberOfDescriptionResults);
            // console.log('limitNumberOfDescriptionResults : ' + JSON.stringify(limitNumberOfDescriptionResults));
        }
        // console.log('descriptionResults : ' + JSON.stringify(descriptionResults));
        const finalResults = Array.from(new Set(titleResults.concat(descriptionResults)));
        for (const finalResult of finalResults) {
            filteredEpisodes.push(allEpisodes[finalResult]);
        }
    }
    console.dir(filteredEpisodes);

    return filteredEpisodes;
}

// ThunkAction<AppState> setAllEpisodesAsPlayed(Map podcastData,
//     {bool played = true}) {
//   return (Store<AppState> store) async {
export const setAllEpisodesAsPlayed = (podcastData, played) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];

    const personalMarkPlayedValues = getState().personalMarkPlayedValues;

    console.dir(podcastData);
    const allEpisodes =
        await getAllEpisodesInSeries(podcastData);
    console.dir(allEpisodes);
    let personalMarkPlayedUpdate = {};
    let guidsCleanedToDelete = [];
    if (!allEpisodes || allEpisodes.length < 1) {
        return;
    }
    for (const episodeInfo of allEpisodes) {
        const guidCleaned = cleanFirebaseId(episodeInfo.guid);
        if (played) {
            personalMarkPlayedUpdate[guidCleaned] = true;
        } else {
            personalMarkPlayedUpdate[guidCleaned] = deleteField();
            guidsCleanedToDelete.push(guidCleaned);
        }
    }
    if (played) {
        //   store.dispatch(PersonalValuesMarkPlayedAction(personalMarkPlayedUpdate));
        dispatch({
            'type': PERSONAL_VALUES_MARK_PLAYED_ACTION,
            'personalMarkPlayedUpdate': personalMarkPlayedUpdate
        });
        dispatch(
            setPlayedCount(allEpisodes[0].collectionId, allEpisodes.length));
    } else {
        // store.dispatch(PersonalValuesMarkAllUnPlayedAction(guidsCleanedToDelete));
        dispatch(setPlayedCount(allEpisodes[0].collectionId, 0));
        dispatch({
            'type': PERSONAL_VALUES_MARK_ALL_UNPLAYED_ACTION,
            'guidsCleanedToDelete': guidsCleanedToDelete,
        });
        // dispatch(addToPlayedCount(episodeInfo.collectionId, -1));
    }
    console.dir(personalMarkPlayedUpdate);
    if (played) {
        let maxMarkPlayedIndexToAddIn = getMaxMarkPlayedIndex();
        if ((Object.keys(personalMarkPlayedValues).length + allEpisodes.length) >
            (maxMarkPlayedIndexToAddIn * maxMarkPlayedEntries)) {
            maxMarkPlayedIndexToAddIn += 1;
            setMaxMarkPlayedIndex(maxMarkPlayedIndexToAddIn);
        }
        setDoc(doc(firestore, `users/${userUID}/personal`, markPlayedDocName(maxMarkPlayedIndexToAddIn)), { 'guids': personalMarkPlayedUpdate }, { merge: true })
            // .then((data) { })
            .catch((error) => {
                // print("Error adding document: " + error.toString());
                console.error(error);
                // reject({ error });
                throw error;
            });
    } else {
        for (let ind = 1; ind <= getMaxMarkPlayedIndex(); ind++) {
            setDoc(doc(firestore, `users/${userUID}/personal`, markPlayedDocName(ind)), { 'guids': personalMarkPlayedUpdate }, { merge: true })
                // .then((data) { })
                .catch((error) => {
                    // print("Error adding document: " + error.toString());
                    console.error(error);
                    // reject({ error });
                    throw error;
                });
        }
    }
}

// ThunkAction<AppState> setEpisodeAsPlayed(PodcastEpisode episodeInfo,
//   {bool played}) {
// return (Store<AppState> store) async {
export const setEpisodeAsPlayed = (episodeInfo, played) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    const guidCleaned = cleanFirebaseId(episodeInfo.guid);
    const personalMarkPlayedValues = getState().personalMarkPlayedValues;

    // let personalMarkPlayedUpdate = {};
    // personalMarkPlayedUpdate[`${guidCleaned}`] = {
    //     'collectionId': episodeInfo.collectionId,
    //     'markAsPlayed': played
    // };
    let personalMarkPlayedUpdate = {};
    if (played) {
        personalMarkPlayedUpdate[`${guidCleaned}`] = true;
        // store.dispatch(PersonalValuesMarkPlayedAction(personalMarkPlayedUpdate));
        dispatch({
            'type': PERSONAL_VALUES_MARK_PLAYED_ACTION,
            'personalMarkPlayedUpdate': personalMarkPlayedUpdate
        });
        dispatch(addToPlayedCount(episodeInfo.collectionId, 1));

    } else {
        personalMarkPlayedUpdate[`${guidCleaned}`] = deleteField();
        // store.dispatch(PersonalValuesMarkUnPlayedAction(guidCleaned));
        dispatch({
            'type': PERSONAL_VALUES_MARK_UNPLAYED_ACTION,
            'guidCleaned': guidCleaned,
        });
        dispatch(addToPlayedCount(episodeInfo.collectionId, -1));
    }
    // store.dispatch(PersonalValuesMarkPlayedAction(
    //   guidCleaned, personalMarkPlayedUpdate['${guidCleaned}']));
    // dispatch({
    //     'type': PERSONAL_VALUES_MARK_PLAYED_ACTION,
    //     'guidCleaned': guidCleaned,
    //     'personalMarkPlayedUpdate': personalMarkPlayedUpdate[`${guidCleaned}`]
    // });
    if (played) {
        let maxMarkPlayedIndexToAddIn = getMaxMarkPlayedIndex();
        if ((Object.keys(personalMarkPlayedValues).length + [episodeInfo].length) >
            (maxMarkPlayedIndexToAddIn * maxMarkPlayedEntries)) {
            maxMarkPlayedIndexToAddIn += 1;
            setMaxMarkPlayedIndex(maxMarkPlayedIndexToAddIn);
        }
        setDoc(doc(firestore, `users/${userUID}/personal`, markPlayedDocName(maxMarkPlayedIndexToAddIn)), { 'guids': personalMarkPlayedUpdate }, { merge: true })
            .then((data) => {
                autoShareTweet(episodeInfo.guid).then((data2) => {
                    console.dir(data2);
                });
            })
            .catch((error) => {
                console.error("Error adding document: " + error.toString());
                throw error;
                // reject({ error });
            });
    } else {
        for (let ind = 1; ind <= getMaxMarkPlayedIndex(); ind++) {
            setDoc(doc(firestore, `users/${userUID}/personal`, markPlayedDocName(ind)), { 'guids': personalMarkPlayedUpdate }, { merge: true })
                // .then((data) { })
                .catch((error) => {
                    console.error("Error adding document: " + error.toString());
                    // reject({ error });
                    throw error;
                });
        }
    }
};

export const loadMostRecentEpisodeInSeries = (itunesId) => async (dispatch, getState) => {
    try {
        // const { itunesId } = this.props;
        const personalValues = getState().personalValues || {};
        console.dir(personalValues);
        let filteredPersonalValues = filterPersonalValues(
            personalValues, itunesId);
        let sortedPersonalValuesGuids =
            sortPersonalValuesGuidDesc(filteredPersonalValues, 1);
        console.dir(sortedPersonalValuesGuids);
        if (sortedPersonalValuesGuids && sortedPersonalValuesGuids.length > 0) {
            const guid = sortedPersonalValuesGuids[0];
            const docId = getUUID(guid);
            const mostRecentEpisode =
                await getEpisodeDataFromDocId(docId);
            console.dir(mostRecentEpisode);
            if (mostRecentEpisode) {
                // print('mostRecentEpisode: ' + mostRecentEpisode.title);
                // this.setState({ mostRecentEpisode });
                return mostRecentEpisode;
            }
        }
        return null;
    } catch (err) {
        console.dir(err);
        return null;
    }
}

export const getRecentEpisodesInSeries = (itunesId) => async (dispatch, getState) => {
    try {
        const personalValues = getState().personalValues || {};
        const filteredPersonalValues = filterPersonalValues(
            personalValues, itunesId);
        console.dir(filteredPersonalValues);
        const sortedPersonalValuesGuids =
            sortPersonalValuesGuidDesc(filteredPersonalValues, 3);
        // setState(() {
        //   loadingFilteredRecentEpisodes = true;
        // });
        console.dir(sortedPersonalValuesGuids);
        const episodes = await getEpisodesDataFromGUIds(sortedPersonalValuesGuids)
        return episodes;
    } catch (err) {
        console.dir(err);
        return null;
    }
}

export const rewriteBookChapter = async (collectionId, chapInd, newPrompt, title, forceModel = '') => {
    try {
        // const collectionId = itunesData['collectionId'];
        // let allPopularPodcasts = getState().popularPodcasts || {};
        // if (allPopularPodcasts[`${collectionId}`]) {
        //     return;
        // }
        // const feedUrl = itunesData['feedUrl'];
        // const genresArray = itunesData['genres'] || [];
        // const genres = encodeURIComponent(genresArray.join(','));
        let postObj;
        let urlPOST;
        if (title == 'Conclusion') {
            postObj = {
                collectionId,
                "type": "c",
                conclusionPrompt: newPrompt
            }
            urlPOST = `${serverApi}bookMetadata`;
        } else {
            postObj = {
                collectionId, startingChapter: chapInd, lastChapter: chapInd,
                deleteOldEpisodes: false, rewritePrompt: newPrompt,
                forceModel
            }
            console.dir(postObj);

            urlPOST = `${serverApi}summarizeBookFromStorage`;
        }
        const optPOST = {                //http header
            'method': 'post',
            'contentType': 'application/json',
            'body': JSON.stringify(postObj)
        };
        //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
        const response = await fetch(urlPOST, optPOST);
        // const response = await fetch(
        //     `${cdnApi}editBookEpisodes`);
        const json = await response.json();

        // const episodesJson = json;
        console.dir(json);
        return;
    } catch (e) {
        throw e;
        // return {};
    }
}

export const summarizeBookFromStorage = async (collectionId) => {
    let postObj = {
        collectionId,
        deleteOldEpisodes: true,
        forceModel: 'gpt-4o'
    }
    console.dir(postObj);

    const urlPOST = `${serverApi}summarizeBookFromStorage`;

    const optPOST = {                //http header
        'method': 'post',
        'contentType': 'application/json',
        'body': JSON.stringify(postObj)
    };
    //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
    const response = await fetch(urlPOST, optPOST);
    // const response = await fetch(
    //     `${cdnApi}editBookEpisodes`);
    const json = await response.json();

    // const episodesJson = json;
    console.dir(json);
    return {};
}

export const createQuickEpisodes = async (collectionId) => {
    let postObj = {
        collectionId,
        // deleteOldEpisodes: true,
        // forceModel: 'gpt-4o'
    }
    console.dir(postObj);

    const urlPOST = `${serverApi}createQuickEpisodes`;

    const optPOST = {                //http header
        'method': 'post',
        'contentType': 'application/json',
        'body': JSON.stringify(postObj)
    };
    //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
    const response = await fetch(urlPOST, optPOST);
    // const response = await fetch(
    //     `${cdnApi}editBookEpisodes`);
    const json = await response.json();

    // const episodesJson = json;
    console.dir(json);
    return json;
}

export const deleteBooks = async (collectionId) => {
    let postObj = {
        collectionId
    }
    console.dir(postObj);

    const urlPOST = `${serverApi}deleteBooks`;

    const optPOST = {                //http header
        'method': 'post',
        'contentType': 'application/json',
        'body': JSON.stringify(postObj)
    };
    //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
    const response = await fetch(urlPOST, optPOST);
    // const response = await fetch(
    //     `${cdnApi}editBookEpisodes`);
    const json = await response.json();

    // const episodesJson = json;
    console.dir(json);
    return {};
}


export const editBookChapter = async (guid, description, insight = '', title = '') => {
    try {
        // const collectionId = itunesData['collectionId'];
        // let allPopularPodcasts = getState().popularPodcasts || {};
        // if (allPopularPodcasts[`${collectionId}`]) {
        //     return;
        // }
        // const feedUrl = itunesData['feedUrl'];
        // const genresArray = itunesData['genres'] || [];
        // const genres = encodeURIComponent(genresArray.join(','));

        const urlPOST = `${serverApi}editBookEpisodes?t=1`;
        const optPOST = {                //http header
            // 'method': 'post',
            // 'contentType': 'application/json',
            'body': JSON.stringify({ guid, description, insight, title, type: 'e' })
        };
        //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
        const response = await tokenFetchPOST(urlPOST, optPOST['body']);
        // const response = await fetch(
        //     `${cdnApi}editBookEpisodes`);
        const json = await response.json();

        // const episodesJson = json;
        console.dir(json);
        return;
    } catch (e) {
        throw e;
        // return {};
    }
}
export const rewriteBookEpisode = async (text, prompt) => {
    try {
        // const collectionId = itunesData['collectionId'];
        // let allPopularPodcasts = getState().popularPodcasts || {};
        // if (allPopularPodcasts[`${collectionId}`]) {
        //     return;
        // }
        // const feedUrl = itunesData['feedUrl'];
        // const genresArray = itunesData['genres'] || [];
        // const genres = encodeURIComponent(genresArray.join(','));

        const urlPOST = `${serverApi}rewriteBookEpisodes?t=1`;
        const optPOST = {                //http header
            // 'method': 'post',
            // 'contentType': 'application/json',
            // // signal: AbortSignal.timeout(60000),
            'body': JSON.stringify({ text, prompt })
        };
        //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
        const response = await tokenFetchPOST(urlPOST, optPOST['body']);
        // const response = await fetch(
        //     `${cdnApi}editBookEpisodes`);
        const json = await response.json();

        // const episodesJson = json;
        console.dir(json);
        return json.returnText;
    } catch (e) {
        throw e;
        // return {};
    }
}

export const markBookChapterChecked = async (guid) => {
    try {
        // const collectionId = itunesData['collectionId'];
        // let allPopularPodcasts = getState().popularPodcasts || {};
        // if (allPopularPodcasts[`${collectionId}`]) {
        //     return;
        // }
        // const feedUrl = itunesData['feedUrl'];
        // const genresArray = itunesData['genres'] || [];
        // const genres = encodeURIComponent(genresArray.join(','));

        const urlPOST = `${serverApi}markBookEpisodeChecked`;
        const optPOST = {                //http header
            'method': 'post',
            'contentType': 'application/json',
            'body': JSON.stringify({ guid })
        };
        //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
        const response = await fetch(urlPOST, optPOST);
        // const response = await fetch(
        //     `${cdnApi}editBookEpisodes`);
        const json = await response.json();

        // const episodesJson = json;
        console.dir(json);
        return;
    } catch (e) {
        throw e;
        // return {};
    }
}
export const markBookChecked = async (collectionId, type = 1, status = 2) => {
    try {
        // const collectionId = itunesData['collectionId'];
        // let allPopularPodcasts = getState().popularPodcasts || {};
        // if (allPopularPodcasts[`${collectionId}`]) {
        //     return;
        // }
        // const feedUrl = itunesData['feedUrl'];
        // const genresArray = itunesData['genres'] || [];
        // const genres = encodeURIComponent(genresArray.join(','));

        const urlPOST = `${serverApi}markBookChecked?t=1`;
        console.dir(urlPOST);
        const postBody = { collectionId, type: type, status: status };
        console.dir(postBody);
        // const optPOST = {                //http header
        //     'method': 'post',
        //     'contentType': 'application/json',
        //     'body': JSON.stringify(postBody)
        // };
        //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
        const response = await tokenFetchPOST(urlPOST, JSON.stringify(postBody));
        // const response = await fetch(
        //     `${cdnApi}editBookEpisodes`);
        const json = await response.json();

        // const episodesJson = json;
        console.dir(json);
        return json;
    } catch (e) {
        console.error(e);
        throw e;
        // return {};
    }
}
export const updateP = async (collectionId, p) => {
    try {
        // const collectionId = itunesData['collectionId'];
        // let allPopularPodcasts = getState().popularPodcasts || {};
        // if (allPopularPodcasts[`${collectionId}`]) {
        //     return;
        // }
        // const feedUrl = itunesData['feedUrl'];
        // const genresArray = itunesData['genres'] || [];
        // const genres = encodeURIComponent(genresArray.join(','));

        const urlPOST = `${serverApi}updateP?collectionId=${collectionId}&p=${p.trim()}`;
        console.dir(urlPOST);
        // const postBody = { collectionId, type: type, status: status };
        // console.dir(postBody);
        // const optPOST = {                //http header
        //     'method': 'post',
        //     'contentType': 'application/json',
        //     'body': JSON.stringify(postBody)
        // };
        //   var response = UrlFetchApp.fetch(urlPOST, optPOST);
        const response = await tokenFetch(urlPOST);
        // const response = await fetch(
        //     `${cdnApi}editBookEpisodes`);
        const json = await response.json();

        // const episodesJson = json;
        console.dir(json);
        return json;
    } catch (e) {
        console.error(e);
        throw e;
        // return {};
    }
}