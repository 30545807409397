import { doc, getDoc } from "firebase/firestore/lite";
import { tokenFetch } from "../lib/tokenUtils";
import { secToDateDay, serverApi, sleep } from "../lib/utils";
import { retrieveAppInfo } from "./AuthActions";
import { FETCH_HOME_RECOMMENDATIONS, FETCH_USER_BOOK_OF_DAY } from "./types";
import { firestore } from "../lib/db";

const dailyCount = 15;

function getDailyRecommendations(weeklyRecommendations, viewHistory, clickHistory) {
    // Flatten daily history into a single map of shown books
    const shownBooks = { ...viewHistory };

    // Get books not yet shown
    const unshownBooks = weeklyRecommendations.filter(
        (book) => !shownBooks.hasOwnProperty(book.collectionId)
    );

    const unshownRealBooks = unshownBooks.filter((book) => book.type === 1);
    let alsoShuffle = unshownBooks.length === 0 || unshownRealBooks.length === 0;

    // Prioritize unshown books
    let dailyRecommendations = unshownBooks.slice(0, dailyCount);

    // If not enough unshown books, include previously shown books (but not clicked)
    if (dailyRecommendations.length < dailyCount) {
        const previouslyShown = weeklyRecommendations.filter((book) => {
            return shownBooks.hasOwnProperty(book.collectionId) && !clickHistory.hasOwnProperty(book.collectionId);
        });

        dailyRecommendations = dailyRecommendations.concat(
            previouslyShown.slice(0, dailyCount - dailyRecommendations.length)
        );
    }

    // If still not enough, include clicked books based on oldest click date
    if (dailyRecommendations.length < dailyCount) {
        const clickedBooksByOldest = Object.entries(clickHistory).sort(
            (a, b) => new Date(a[1]) - new Date(b[1])
        );

        clickedBooksByOldest.slice(0, dailyCount - dailyRecommendations.length).forEach(([collectionId]) => {
            const additionalBook = weeklyRecommendations.find(
                (book) => book.collectionId === parseInt(collectionId)
            );
            if (additionalBook) {
                dailyRecommendations.push(additionalBook);
            }
        });
    }

    // Shuffle recommendations to make them feel fresh
    if (alsoShuffle) {
        dailyRecommendations = shuffleList(dailyRecommendations);
    }

    return dailyRecommendations;
}

function pickBookOfDay(weeklyRecommendationsInit, booksOfDayThisWeek, viewHistory, clickHistory) {
    const shownBooks = { ...viewHistory };

    // Filter weekly recommendations
    const weeklyRecommendations = weeklyRecommendationsInit.filter(
        (book) =>
            !booksOfDayThisWeek.has(book.collectionId) &&
            book.type === 1
        // &&
        // !blockedPodcastsOnLoad.hasOwnProperty(`${book.collectionId}`) &&
        // !userBookRatingsOnLoad.hasOwnProperty(`${book.collectionId}`)
    );

    // Books not yet clicked and not yet shown as "Book of the Day"
    const unclickedBooks = weeklyRecommendations.filter(
        (book) => !clickHistory.hasOwnProperty(book.collectionId) && !shownBooks.hasOwnProperty(book.collectionId)
    );

    // Fallback: Books shown but not clicked
    const shownButNotClickedBooks = weeklyRecommendations.filter(
        (book) => shownBooks.hasOwnProperty(book.collectionId) && !clickHistory.hasOwnProperty(book.collectionId)
    );

    // Prioritize: Unclicked books first, then shown but not clicked
    const candidates = unclickedBooks.length > 0
        ? unclickedBooks
        : shownButNotClickedBooks.length > 0
            ? shownButNotClickedBooks
            : weeklyRecommendations;

    // Pick a random book
    return pickRandomBook(candidates);
}

// Utility function to pick a random book
function pickRandomBook(books) {
    const randomIndex = Math.floor(Math.random() * books.length);
    return books[randomIndex];
}

// Utility function to shuffle a list
function shuffleList(list) {
    const shuffled = [...list];
    for (let i = shuffled.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
}

export const getRecommendations = (userId) => async (dispatch, getState) => {
    try {
        console.log('Fetching recommendations...');
        // let userDetails = getState().userDetails; // userDetailsStateSelector(store.state);

        // const userId = userDetails && userDetails.uid; // Replace with your logic to get Firebase UID
        // if (!userId) {
        //     return;
        // }

        // const doc = await firebase.firestore().collection('recommendations').doc(utils.getFirebaseUID()).get();
        const docMain = await getDoc(doc(firestore, `recommendations`, userId));


        if (docMain.exists()) {
            let pods = [];
            const dataAll = docMain.data();
            const books = dataAll.books || [];
            if (books.length < 5) {
                dispatch(setBackUpBookOfDay());
                return;
            }

            const now = new Date();
            // const formatted = utils.formattedDate('yyyy-MM-dd', now);
            const formatted = secToDateDay(Date.now() / 1000) + '';
            console.dir(formatted);

            const booksOfDay = dataAll.booksOfDay || {};


            /////////// ignore prev fetches and always fetch here as it might mess with the mobile app
            // const prevFetchDates = dataAll.fetchDates || {};
            // if (prevFetchDates[formatted]) {
            //     console.log('Using cached recommendations...');
            //     pods = utils.getFromHiveBox(HOME_RECOMMENDATIONS_BOX, 'all') || [];
            //     if (pods.length > 5) {
            //         homeRecommendationsNotifier.value = [...homeRecommendationsNotifier.value, ...pods];
            //         const bookOfDayMap = books.find(
            //             (book) => book.collectionId === booksOfDay[formatted]
            //         );
            //         if (bookOfDayMap) userBookOfDayNotifier.value = bookOfDayMap;
            //         return;
            //     }
            // }

            /// don't set fetch date here as it might mess with the mobile app
            // await firebase.firestore().collection('recommendations').doc(utils.getFirebaseUID()).set(
            //     {
            //         fetchDates: { [formatted]: true },
            //     },
            //     { merge: true }
            // );

            const booksFin = books; // utils.parsePodcastsJson(books);
            const viewHistory = dataAll.viewHistory || {};
            const clickHistory = dataAll.clickHistory || {};

            pods = getDailyRecommendations(booksFin, viewHistory, clickHistory);

            // utils.putListInHiveBox(HOME_RECOMMENDATIONS_BOX, 'all', utils.convertPodcastsToJson(pods));

            const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
            const booksOfDayThisWeek = new Set(
                Object.entries(booksOfDay)
                    .filter(([key]) => new Date(key) > oneWeekAgo)
                    .map(([, value]) => value)
            );

            let bookOfDay;
            if (booksOfDay[formatted]) {
                const bookOfDayMap = books.find(
                    (book) => book.collectionId === booksOfDay[formatted]
                );
                bookOfDay = bookOfDayMap;
            } else {
                bookOfDay = pickBookOfDay(booksFin, booksOfDayThisWeek, viewHistory, clickHistory);
            }

            ////// DONT SET BOOK OF DAY AS IT WILL MESS WITH MOBILE APP
            // userBookOfDayNotifier.value = bookOfDay;
            // dispatch({
            //     type: FETCH_USER_BOOK_OF_DAY,
            //     'payload': bookOfDay,
            // });

            // await firebase.firestore().collection('recommendations').doc(utils.getFirebaseUID()).set(
            //     {
            //         booksOfDay: { [formatted]: bookOfDay.collectionId },
            //     },
            //     { merge: true }
            // );

            // homeRecommendationsNotifier.value = [...homeRecommendationsNotifier.value, ...pods];
            console.dir(pods);
            dispatch({
                type: FETCH_HOME_RECOMMENDATIONS,
                'payload': pods,
            });
        } else {
            dispatch(setBackUpBookOfDay());
        }
    } catch (err) {
        console.error(err);
        dispatch(setBackUpBookOfDay());
    }
}

const setBackUpBookOfDay = () => async (dispatch, getState) => {
    // setTimeout(() => {
    //     // userBookOfDayNotifier.value = Podcast.fromJson(bookOfDayNotifier.value);
    //     const appInfoLoc = retrieveAppInfo();

    //     dispatch({
    //         type: FETCH_USER_BOOK_OF_DAY,
    //         'payload': appInfoLoc.bookOfDay,
    //     });
    // }, 2500);

}


export const createNewUserRecommendations = async () => {
    console.dir('createNewUserRecommendations 0');

    const startTime = Date.now(); // Record the start time
    const maxWait = new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Timeout after 10 seconds")), 10000)
    ); // Maximum 10 seconds

    await Promise.race([
        (async () => {
            const result = await tokenFetch(`${serverApi}recommendbookstoauser?firstTime=true`);
            const json = await result.json();
            console.dir('createNewUserRecommendations');
            console.dir(json);

            const elapsedTime = Date.now() - startTime; // Calculate elapsed time
            if (elapsedTime < 5000) {
                await sleep(5000 - elapsedTime); // Wait for the remaining time if less than 5 seconds
            }
            return {}; // Return the API data
        })(),
        maxWait // Enforce a maximum wait time of 10 seconds
    ]);


    return;
}
