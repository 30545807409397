import { auth, firestore } from '../lib/db';
import {
    signInWithEmailAndPassword, sendPasswordResetEmail, useDeviceLanguage, signInWithRedirect, signOut,
    createUserWithEmailAndPassword, updateProfile, reauthenticateWithCredential, getIdToken, sendEmailVerification, updateEmail,
    updatePassword, EmailAuthProvider, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, OAuthProvider, signInWithPopup, fetchSignInMethodsForEmail,
    signInWithCustomToken
} from "firebase/auth";
import { Timestamp, collection, query, where, getDoc, doc, setDoc, arrayUnion, getDocs } from "firebase/firestore/lite";

import {
    CREATE_USER_SUCCESS, SET_USER_DETAILS,
    SIGNIN_USER_SUCCESS, LOG_OUT_USER, LOADING_USER_DETAILS_STATE,
    PERSONAL_VALUES_SUCCESS, UPDATE_AUDIO_INFO,
    UPDATE_USER_SUCCESS,
    TOGGLE_LEFT_BAR,
    TOGGLE_DARK_THEME,
    ADD_TOP_PADDING
} from './types';
import { getFirebaseUser, getIdFromUID, mainDashboardOptions, retrieveLocally, revenueCatApiKey, saveLocally, serverApi, serverApiEurope, useAuthPopup } from '../lib/utils';
import Router from 'next/router';

import { fetchUserSubscriptions, getPersonalDetails, fetchPlaylists, getEpisodesRecent, fetchPodcastTags, setInterests, fetchAllMyFiles, fetchInsights, fetchHighlights, getReadingChallengesUser } from './index';
import { logLogin, logSignUp, setUserIdFn } from '../lib/analyticsEvents';
// import fetch from 'isomorphic-unfetch';
import sentry from '../utils/sentry';
import { setCountForRelated, setMessageForRelated } from '../lib/fileUtils';
import { createPlaylist, fetchCollaborativePlaylists } from './PlaylistActions';
import { fetchHostBilling } from './DashboardActions';
import { getRequestsDetails } from './RequestActions';
import { getRecommendations } from './RecommendationActions';
const { Sentry } = sentry({ release: process.env.SENTRY_RELEASE })

const setSentryUserId = (userDetails) => {
    const uid = userDetails && userDetails['uid'];
    if (uid) {
        // console.dir(Sentry);
        console.dir(userDetails);
        console.dir(uid);
        Sentry.configureScope((scope) => {
            scope.setUser({ 'id': uid })
        });
        // Sentry.setUser({ "id": uid });
    }
    return;
}

const setMainUser = async (userInfo) => {
    setUserIdFn(userInfo.uid);
    setSentryUserId(userInfo);
    // const BranchSDK = (await import('branch-sdk')).default;
    // console.log('calling setMainUser1');
    // BranchSDK.setIdentity(userInfo.uid, function (err, data) {
    //     if (err) {
    //         console.dir(err);
    //         console.warn(`Branch failed to resolve link: ${err}`)
    //         return
    //     }
    //     console.dir(data);
    //     // do something with `data`?
    // });
    // console.log('calling setMainUser2');
}

export const promoCodeSync = (numMonths = 6) => {
    const postData = {
        freesync: { start: Timestamp.fromDate(new Date()), numMonths }
    };
    if (getFirebaseUser()) {
        setDoc(doc(firestore, 'users', getFirebaseUser().uid), postData, { merge: true });
    }
}

export const createUser = (userInfo) => new Promise((resolve, reject) => {
    // const db = await loadDB();
    logSignUp('email');
    return createUserWithEmailAndPassword(auth, userInfo.email, userInfo.password)
        .then((data) => {
            console.dir(data);
            return data;
        })
        .then((data) => {
            // setUserId(data.user.uid);
            const isPWAFromLocalStorage = retrieveLocally('isPWA');
            const postData = {
                uid: data.user.uid,
                id: getIdFromUID(data.user.uid),
                name: userInfo.name || '',
                signUpType: isPWAFromLocalStorage ? 'windows' : 'web',
                timestamp: Timestamp.fromDate(new Date())
            };
            try {
                const pathName = window.location.pathname;
                const isDashboard = mainDashboardOptions.findIndex(el => pathName == el.url) > -1;
                if (isDashboard) {
                    postData['fromDashboard'] = true;
                }
            } catch (e) { }
            setTimeout(async () => {
                // const BranchSDK = (await import('branch-sdk')).default;
                // try {
                //     BranchSDK.lastAttributedTouchData(30, function (err, data2) {
                //         if (err) {
                //             console.dir(err);
                //             console.warn(`Branch failed to resolve link: ${err}`)
                //             return
                //         }
                //         console.dir(data2);
                //         setDoc(doc(firestore, 'users', data.user.uid), { 'attr': data2 }, { merge: true });
                //     })
                // } catch (err) { console.dir(err) }
            }, 1000);
            // getDoc(doc(firestore, `app-info`, 'info'))
            return setDoc(doc(firestore, 'users', data.user.uid), postData, { merge: true })
                .then(() => {
                    return setDoc(doc(firestore, `users/${data.user.uid}/private`, 'details'), { username: userInfo.email }, { merge: true });

                    // // resolve(data);
                })
                .then(() => {
                    var user = auth.currentUser;
                    return updateProfile(user, {
                        displayName: userInfo.name || '',
                        // photoURL: "https://example.com/jane-q-user/profile.jpg"
                    }).then(function () {
                        // Update successful.
                        sendEmailVerification(user).then(function () {
                            // Email sent.
                        });
                    }).catch(function (error) {
                        // An error happened.
                        console.error(error);
                    });

                })
                .then(() => {
                    resolve(data);
                })
                .catch(function (error) {
                    console.error("Error : ", error);
                    reject(error);
                });
        })
        .catch((error) => {
            console.error("Error creating user: ", error);
            reject(error);
        });
});

export const createUserSocialSignIn = (providerName, isAds) => new Promise((resolve, reject) => {
    let provider;
    if (providerName === 'google') {
        logSignUp('google');
        provider = new GoogleAuthProvider();
        // provider.addScope('https://www.googleapis.com/auth/user.birthday.read');

    } else if (providerName === 'fb') {
        logSignUp('facebook');
        provider = new FacebookAuthProvider();
        // provider.addScope('user_birthday');
        // provider.addScope('user_gender');
    } else if (providerName === 'twitter') {
        logSignUp('twitter');
        provider = new TwitterAuthProvider();
        // provider.addScope('user_birthday');
        // provider.addScope('user_gender');
    } else if (providerName === 'apple') {
        logSignUp('apple');
        provider = new OAuthProvider('apple.com');
        // provider.addScope('user_birthday');
        // provider.addScope('user_gender');
    } else {
        return;
    }
    useDeviceLanguage(auth);
    if (isAds) {
        window.location.hash = "ads-type"
    }
    if (!useAuthPopup()) {
        signInWithRedirect(auth, provider);
    } else {
        signInWithPopup(auth, provider).then(function (result) {
            // firebase.auth().signInWithRedirect(provider);
            // return firebase.auth().getRedirectResult().then(function (result) {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // var token = result.credential.accessToken;
            // The signed-in user info.
            // var user = result.user;
            // ...
            // resolve(user);
            return result; // user;
        })
            .then((data) => {
                console.dir(data);
                if (data.user && data.user.uid) {
                    // firebase.firestore().collection('users').doc(data.user.uid)
                    //     .get()
                    getDoc(doc(firestore, `users`, data.user.uid))
                        .then(async (docLoc) => {
                            const isFromAds = window.location.hash && window.location.hash.includes("ads-type");
                            if (docLoc.exists()) {
                                // user already exists
                                console.dir('user exists');
                                resolve({ userData: data, firstSignIn: false });
                                // this.props.signInUserSuccess(data.user, 0);
                                // console.dir(window.location.hash);
                                // if (isFromAds) {
                                //     Router.push({ pathname: '/ads' });
                                // } else {
                                //     Router.push({ pathname: '/home' });
                                // }
                            } else { // new user
                                console.log('new user');
                                const isPWAFromLocalStorage = retrieveLocally('isPWA');
                                await setDoc(doc(firestore, `users`, data.user.uid), {
                                    uid: data.user.uid,
                                    id: getIdFromUID(data.user.uid),
                                    name: data.user.displayName || '',
                                    signUpType: isPWAFromLocalStorage ? 'windows' : 'web',
                                    timestamp: Timestamp.fromDate(new Date())
                                }, { merge: true });
                                // firebase.firestore().collection('users').doc(data.user.uid)
                                //     .set({
                                //         uid: data.user.uid,
                                //         id: getIdFromUID(data.user.uid),
                                //         name: data.user.displayName || '',
                                //         signUpType: 'web',
                                //         timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                                //     });
                                setDoc(doc(firestore, `users/${data.user.uid}/private`, 'details'), { username: data.user.email }, { merge: true })
                                // firebase.firestore().collection('users').doc(data.user.uid).collection('private').doc('details')
                                //     .set({
                                //         username: data.user.email,
                                //     });
                                saveLocally('authUserData', JSON.stringify(data), 24, true);
                                resolve({ userData: data, firstSignIn: true });
                                // let playlistInfo = { 'name': 'Listen Later', 'description': 'Default Playlist' };
                                // createPlaylist(data.user, playlistInfo)
                                //     .then((playlistData) => {
                                //         if (playlistData.success) {
                                //             console.dir(playlistData);
                                //             this.props.createPlaylistPersonal(playlistData.playlistInfo, playlistData.playlistId);
                                //         }
                                //     })
                                //     .catch(err => {
                                //         console.error(err);
                                //     });

                                // // });
                                // this.props.signInUserSuccess(data.user, 0);
                                // if (isFromAds) {
                                //     setInterests({ 'Comedy': true, 'News': true }, data.user);
                                //     this.props.createUserSuccess(data.user);

                                //     Router.push({ pathname: '/ads' });
                                // } else {
                                //     Router.push(`/login?showInterests=true`);
                                // }
                            }
                        });
                }
                // firebase.firestore().collection('users').doc(data.user.uid)
                //     .get()
                //     .then(function (doc) {
                //         if (doc.exists) {
                //             // user already exists
                //             console.log('user exists');
                //             resolve({ userData: data, firstSignIn: false });
                //         } else { // new user
                //             console.log('new user');
                //             firebase.firestore().collection('users').doc(data.user.uid)
                //                 .set({
                //                     uid: data.user.uid,
                //                     id: getIdFromUID(data.user.uid),
                //                     name: data.user.displayName || '',
                //                     signUpType: 'web',
                //                     timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                //                 })
                //                 .then(() => {
                //                     return firebase.firestore().collection('users').doc(data.user.uid).collection('private').doc('details')
                //                         .set({
                //                             username: data.user.email,
                //                         })
                //                 })
                //                 .then(() => {
                //                     resolve({ userData: data, firstSignIn: true });
                //                 })
                //         }
                //     });
            })
            .catch(function (error) {
                console.dir(error);
                // Handle Errors here.
                var errorCode = error.code;
                if (errorCode === 'auth/popup-closed-by-user') {
                    return;
                }
                var errorMessage = error.message;
                if (!('email' in error)) {
                    return;
                }
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                fetchSignInMethodsForEmail(auth, email).then(function (methods) {
                    console.dir(methods);
                    // Step 3.
                    // If the user has several sign-in methods,
                    // the first method in the list will be the "recommended" method to use.
                    if (methods[0] === 'password') {
                        // Asks the user their password.
                        // In real scenario, you should handle this asynchronously.
                        // var password = promptUserForPassword(); // TODO: implement promptUserForPassword.
                        // auth.signInWithEmailAndPassword(email, password).then(function (user) {
                        //     // Step 4a.
                        //     return user.linkWithCredential(pendingCred);
                        // }).then(function () {
                        //     // Google account successfully linked to the existing Firebase user.
                        //     goToApp();
                        // });
                        reject({
                            type: 'duplicateAuth', code: errorCode, message: `An account already exists with this email using 
                                            the standard email/ password option. Please sign In using that or click Forgot password to reset your password.`});
                        return;
                    } else if (methods[0] === 'google.com') {
                        reject({
                            type: 'duplicateAuth', code: errorCode, message: `An account already exists with this email using 
                                                google sign In. Please Sign In with Google.`});
                        return;
                    }

                });
            });
    }

});

export const updateUser = (userDetails, updateInfo) => new Promise((resolve, reject) => {
    // const db = await loadDB();
    setDoc(doc(firestore, `users`, userDetails.uid), { name: updateInfo.name }, { merge: true })
        .then(() => {
            resolve({ success: true });
        })
        .catch(function (error) {
            console.error("Error : ", error);
            reject(error);
        });
});

export const updateUserSuccess = (userInfo) => dispatch => {
    dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: userInfo
    });
};

export const updateUserEmail = (userDetails, updateInfo) => new Promise((resolve, reject) => {
    var user = auth.currentUser;
    console.dir(userDetails);
    var credential = EmailAuthProvider.credential(
        userDetails.email,
        updateInfo.password
    );

    reauthenticateWithCredential(user, credential).then(function () {
        // User re-authenticated.
        updateEmail(user, updateInfo.email).then(function () {
            // Update successful.

            setDoc(doc(firestore, `users/${userDetails.uid}/private`, 'details'), { username: updateInfo.email }, { merge: true })
                .then(() => {
                    resolve({ success: true });
                })
                .catch(function (error) {
                    console.error("Error : ", error);
                    reject(error);
                });
        }).catch(function (error) {
            // An error happened.
            reject(error);
        });
    }).catch(function (error) {
        reject(error);
        // An error happened.
    });
});

export const updateUserPassword = (userDetails, updateInfo) => new Promise((resolve, reject) => {
    var user = auth.currentUser;
    console.dir(userDetails);
    var credential = EmailAuthProvider.credential(
        userDetails.email,
        updateInfo.oldPassword
    );

    reauthenticateWithCredential(user, credential).then(function () {
        // User re-authenticated.
        updatePassword(user, updateInfo.newPassword).then(function () {
            // Update successful.
            resolve({ success: true })
        }).catch(function (error) {
            // An error happened.
            reject(error);
        });
    }).catch(function (error) {
        reject(error);
        // An error happened.
    });
});

export const createUserSuccess = (userInfo) => dispatch => {
    setMainUser(userInfo);
    dispatch({
        type: CREATE_USER_SUCCESS,
        payload: userInfo
    });
    dispatch(fetchUserSubscriptions(userInfo.uid));
    dispatch(getPersonalDetails(userInfo.uid));
    dispatch(fetchPodcastTags(userInfo.uid));
    dispatch(getRequestsDetails(userInfo.uid));
    window.fpr("referral", { uid: userInfo.uid });
};

export const sendPasswordResetEmailFn = (userEmail) => new Promise((resolve, reject) => {
    // const db = await loadDB();
    sendPasswordResetEmail(auth, userEmail)
        .then(() => {
            resolve({ success: true });
        })
        .catch((error) => {
            console.error("Error sending email: ", error);
            reject({ success: false });
        });
});

export const signInUser = (userInfo) => new Promise((resolve, reject) => {
    // const db = await loadDB();
    logLogin();
    signInWithEmailAndPassword(auth, userInfo.email, userInfo.password)
        .then((data) => {
            console.dir(data);
            // setUserId(data.user.uid);
            resolve(data);
        })
        .catch((error) => {
            console.error("Error creating user: ", error);
            console.dir(error);
            reject(error);
        });
});

export const signInUserWithToken = (token) => new Promise((resolve, reject) => {
    return signInWithCustomToken(auth, token)
        .then((data) => {
            console.dir(data);
            // setUserId(data.user.uid);
            resolve(data);
        })
        .catch((error) => {
            console.error("Error creating user: ", error);
            console.dir(error);
            reject(error);
        });
})

export const signInUserSuccess = (user, delayTime = 0) => dispatch => {
    // delay loading this in redux so that other loadings get headstart
    setMainUser(user);
    if (delayTime > 0) {
        setTimeout(() => {
            dispatch({
                type: SIGNIN_USER_SUCCESS,
                payload: user
            });
        }, delayTime);
    } else {
        dispatch({
            type: SIGNIN_USER_SUCCESS,
            payload: user
        });
    }
    dispatch(getPersonalDetails(user.uid));
    dispatch(fetchUserSubscriptions(user.uid));
    dispatch(fetchPlaylists(user.uid));
    dispatch(fetchCollaborativePlaylists(user.uid));
    dispatch(fetchPodcastTags(user.uid));
    console.dir(user.uid);
    dispatch(getRequestsDetails(user.uid));
    dispatch(fetchInsights(user.uid));
    dispatch(fetchHighlights(user.uid));
    dispatch(fetchAllMyFiles(user.uid));
    dispatch(getRecommendations(user.uid));
    dispatch(getReadingChallengesUser(user.uid));
    // dispatch(getEpisodesRecent(user.uid));
    fetchHostBilling(user.uid);
};

export const setUserDetails = (userDetails) => dispatch => {
    dispatch({
        type: SET_USER_DETAILS,
        payload: userDetails
    });
};

export const loadingUserDetailsState = (loadingUserDetails) => dispatch => {
    let newState = {
        loadingUserDetails: loadingUserDetails
    };
    // console.dir(userDetails);
    dispatch({
        type: LOADING_USER_DETAILS_STATE,
        payload: newState
    });
};

export const logOutUser = () => async dispatch => {
    // const db = await loadDB();
    signOut(auth)
        .then(async () => {
            // let newState = {
            //     userDetails: null
            // };
            let userDetails = null;

            // const BranchSDK = (await import('branch-sdk')).default;
            // BranchSDK.logout();
            // console.dir(newState);
            dispatch({
                type: LOG_OUT_USER,
                payload: userDetails
            });
            dispatch({
                type: UPDATE_AUDIO_INFO,
                payload: { audioInfo: undefined }
            })
            // dispatch(setUserDetails(null));
            // dispatch({
            //     type: PERSONAL_VALUES_SUCCESS,
            //     payload: null
            // });
        })
        .catch((error) => {
            console.error("Error signing out user: ", error);
            // dispatch({
            //     type: CREATE_USER_FAILURE,
            //     payload: null
            // });
        });
};

export const toggleLeftBar = () => async dispatch => {
    console.dir('toggleLeftBar');
    dispatch({
        type: TOGGLE_LEFT_BAR,
        // payload: { leftBarOpen: leftBarOpen }
    })
}

export const toggleDarkTheme = (value) => async dispatch => {
    console.dir('toggleDarkTheme');
    dispatch({
        type: TOGGLE_DARK_THEME,
        payload: value
    })
}
export const addTopPadding = (value) => async dispatch => {
    console.dir('addTopPadding');
    dispatch({
        type: ADD_TOP_PADDING,
        payload: value
    })
}

export const getAuthToken = async () => {
    const authToken = await getIdToken(auth.currentUser);
    return authToken;
}

export const importOPML = async (xmlContent, authTokenLoc) => {
    const url = serverApiEurope + 'importOPML';
    let authToken; // = await firebase.auth().currentUser.getIdToken();
    if (authTokenLoc) {
        authToken = authTokenLoc;
    } else {
        authToken = await getIdToken(auth.currentUser);
    }

    // const headers = {
    //     'authorization': `Bearer ${authToken}`,
    //     'content-type': 'text/xml' // or text/xml;charset=utf-8
    // };
    const body = xmlContent;
    try {
        await fetch(
            url, {
            method: 'POST',
            headers: new Headers(
                {
                    'Content-Type': 'text/xml; charset=utf-8',
                    'authorization': `Bearer ${authToken}`
                }),
            body: body
        });
        // window.alert('Success. New podcasts have been added to your subscribed podcasts.');
        if (authTokenLoc) {
            return `Your podcasts were succesfully imported. You can close this page now and go back to the app.`;
        } else {
            return 'Success. New podcasts have been added to your subscribed podcasts.';
        }
    } catch (err) {
        // window.alert('There was an error reading this file. Please check the file and try again.');
        throw err;
    }
}

export const logDesktopAuthToken = async (docId, code, provider) => {
    setDoc(doc(firestore, `auth-codes`, docId), { code, provider }, { merge: true })
}

export let appInfo = {};
export const retrieveAppInfo = () => {
    return appInfo;
}
export const getAppInfo = async () => {
    if (appInfo && Object.keys(appInfo).length > 0) {
        return appInfo;
    }
    getDoc(doc(firestore, `app-info`, 'info'))
        .then((doc) => {
            if (doc.exists()) {
                const data = doc.data();
                appInfo = data;
                console.dir(data);
                if (data['messageForRelated']) {
                    setMessageForRelated(data['messageForRelated']);
                }
                if (data['countForRelated']) {
                    setCountForRelated(data['countForRelated']);
                }
            }
        });
}

let premiumInfo;
export const getPremiumInfoData = () => {
    return premiumInfo;
}
export const getPremiumInfo = async () => {
    if (premiumInfo) {
        return premiumInfo;
    }
    return getDoc(doc(firestore, `app-info`, 'premium'))
        .then((doc) => {
            if (doc.exists()) {
                premiumInfo = doc.data();
                console.dir(premiumInfo);
                return premiumInfo;
            }
        });
}


export const getGiftCards = async (uid) => {
    return getDocs(query(collection(firestore, "gift-cards"), where("uid", "==", uid)))
        .then((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    docs.push(doc.data());
                }
            });
            console.dir(docs);
            return docs;
        })
        .catch((err) => {
            console.error(err);
        })
}

export const setStripeSessionId = async (userId, sessionId) => {
    // var user = auth.currentUser;
    console.dir(userId);
    setTimeout(() =>
        setDoc(doc(firestore, `stripe`, userId), { sessionId, allSessionIds: arrayUnion(sessionId), time: Date.now() },
            { merge: true }).catch(err => { console.dir(err) })
        , 300);
}

export const setPremiumUserFirebase = async (userId, stripeSessionId) => {
    // var user = auth.currentUser;
    console.dir(userId);
    const postData = {
        'premium': { is: true, start: Date.now(), stripeSessionId }
    };
    setDoc(doc(firestore, 'users', userId), postData, { merge: true }).catch(err => { console.dir(err) })
}

export const fetchFirebaseToken = async (tok) => {
    try {
        const res = await fetch(`${serverApi}fetchauthtoken?tok=${tok}`);
        // console.dir('fetchBlogArticle');
        const json = await res.json();
        console.dir(json);
        return json.customToken;
    } catch (err) {
        console.error(err);
        return;
    }
}

export const logBlogAppClick = async (slug, appType) => {
    try {
        const res = await fetch(`${serverApi}logBlogAppClick?slug=${slug}&appType=${appType}`);
        // console.dir('fetchBlogArticle');
        const json = await res.json();
        console.dir(json);
    } catch (err) {
        console.error(err);
        return;
    }
}