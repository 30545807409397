import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Input, message, Modal, notification } from 'antd'
import { getPremiumInfo, getPremiumInfoData } from '../../actions';
import { getlocationInfo } from '../../lib/fileUtils';
import { getCurrency, getMonthCount, getMonthCountDisplay, getPrice, serverApiEurope, singular, sleep } from '../../lib/utils';
import LoadingSpinner from './Loading';
import PremiumCard from '../Premium/PremiumCard';
import PremiumPoints from '../Premium/PremiumPoints';
import ClickPremium from '../Premium/ClickPremium';
import PremiumCardPromo from '../Premium/PremiumCardPromo';
import { colors } from '../../lib/theme';
import PremiumGift from './PremiumGift';


const coupons = {
    '20PROMOTED': { discountPercent: 20, discountLife: 2000 },
    '50PROMOTED': { discountPercent: 50, discountLife: 4000, title: `50% Off Annual Premium` },
    '70PROMOTED': { discountPercent: 70, discountLife: 4000, title: `70% Off Annual Premium` },
    'PODOFF': { discountPercent: 25, discountLife: 4000 },
}

class PremiumPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            premiumInfo: undefined,
            selectedIndex: ('initIndex' in props) ? props.initIndex : 1,
            code: '', codeFin: '', codeApplied: false, codeProcessing: false,
            loadingPrices: true,
            isRecurring: false,
            options: [],
            errorObj: {}
        }
    }

    componentDidMount() {
        this.loadPrices();
        this.loadInitialCode();
        ///let local currencies load.
        setTimeout(() => {
            const currency = getCurrency();
            if (currency != 'USD') {
                this.setState({});
            }
        }, 700);
    }

    componentDidUpdate(prevProps) {
        const { isReferral } = this.props;
        if (prevProps.isReferral != isReferral) {
            this.loadInitialCode();
        }
    }

    loadInitialCode() {
        const { isReferral } = this.props;
        console.dir(isReferral);
        if (isReferral) {
            this.setState({ code: '20PROMOTED', codeFin: '20PROMOTED' });
        }
        const urlParams = new URLSearchParams(window.location.search);
        const codeInUrl = urlParams.get('code');
        if (coupons[codeInUrl]) {
            this.setState({ codeApplied: true, code: codeInUrl, codeFin: codeInUrl });
        }
    }

    loadPrices = () => {
        const { productId, isPromo, isOnboard } = this.props;
        const currency = getCurrency();
        const product = productId || 'prod_QukTJ8zCSryW1V';
        const products = [product, 'prod_QukTl2oi2SMgDx'];
        const promises = [];
        for (const product of products) {
            promises.push(fetch(`${serverApiEurope}stripePricesForCheckout?currency=${currency}&product=${product}`));
        }
        Promise.all(promises)
            // fetch(`${serverApiEurope}stripePricesForCheckout?currency=${currency}&product=${product}`)
            .then(async (results) => {
                const resultArray = [];
                for (const result of results) {
                    const data = await result.json();
                    const optionsLoc = data['data'] || [];
                    resultArray.push(...optionsLoc);
                }
                // const data = await dataOrig.json();
                console.dir(resultArray);
                const optionsLoc = resultArray.sort((p1, p2) => p1.unit_amount - p2.unit_amount) || [];
                this.setState({
                    loadingPrices: false,
                    options: isOnboard ? [optionsLoc[1]] : (isPromo ? optionsLoc.slice(1) : optionsLoc)
                });
            }).catch(err => {
                notification.error({
                    message: 'There was an error loading prices. Please try again.',
                    description: '',
                    duration: null
                });
            })
    }

    choosePremium = (selectedIndex) => {
        this.setState({ selectedIndex });
    }

    isLifetime = (option) => {
        if (!option) {
            return false;
        }
        return (option['currency_options']['usd']['unit_amount'] / 100) > 150;
    }

    onChange = (e) => {
        this.setState({ code: e.target.value });
    }
    applyDiscount = async () => {
        this.setState({ codeProcessing: true });
        await sleep(500);
        const { finalOptions, code } = this.state;
        if (!coupons[code]) {
            notification.error({
                message: 'Invalid promo code. Please try again.',
                description: '',
                duration: null
            });
            this.setState({ codeProcessing: false });
            return;
        }
        this.setState({ codeProcessing: false, codeApplied: true, codeFin: code });
    }

    render() {
        // if (!process.browser) {
        //     return <></>
        // }
        // const urlParams = new URLSearchParams(window.location.search);
        // const emailInUrl = urlParams.get('email');
        // const codeInUrl = urlParams.get('code');

        const { isOffer, mainTitle, bottomInfo, subtitle, discountParent, isPromo, justText, isOnboard, isReferral } = this.props;
        const { selectedIndex, options, loadingPrices, isRecurring, code, codeFin, codeApplied, codeProcessing } = this.state;
        const premiumInfoLoc = getPremiumInfoData() || {};
        let discountFin;
        if (discountParent) {
            discountFin = discountParent;
        } else {
            discountFin = isOffer ? 50 : 1;
        }
        const discountPromo = {};
        let promoTitle = ''
        if (isPromo) {
            if (coupons[codeFin]) {
                // discountFin = 25;
                discountPromo[0] = coupons[codeFin]['discountPercent'];
                discountPromo[1] = coupons[codeFin]['discountLife'];
                promoTitle = coupons[codeFin]['title'];
            } else {
                discountFin = 0;
            }
        }
        console.dir(options);
        console.dir(code);
        console.dir(selectedIndex);
        console.dir(options[selectedIndex]);
        // if (options.length < 1) {
        //     return <></>
        // }
        return (
            <div>
                <div style={{ padding: '2em' }}>
                    {/* Premium features */}
                    {isOffer && <h3 className='text-center' style={{ whiteSpace: 'pre-wrap' }}>🎉 {mainTitle || `Special offer 50% Off`}</h3>}
                    <div className='text-center'>
                        {isReferral ? <h1 className='text-center' style={{ whiteSpace: 'pre-wrap' }}>🎉 20% Off Premium</h1>
                            :
                            (isPromo ? <h1>🎉 {promoTitle || 'Premium Offer'}</h1> : <h3>{subtitle || premiumInfoLoc.main || '...'}</h3>)
                        }
                    </div>
                    <div>
                        <PremiumGift />
                    </div>
                    {/* <br /> */}
                    <PremiumPoints />
                    {loadingPrices ? <div><LoadingSpinner /> </div> : <div>
                        <div className='card-div'>
                            {justText ? <div>
                                <PremiumCardPromo option={options[selectedIndex]} index={0}
                                    isOffer={isOffer} isPromo={isPromo}
                                    // isReferral={isReferral}
                                    selectedIndex={selectedIndex}
                                    options={options}
                                    justText={justText}
                                    discount={isReferral ? 20 : discountPromo[0] || 0}
                                    choosePremium={this.choosePremium} />
                            </div> :
                                options.map((option, index) => (
                                    <div key={index}>
                                        {isPromo ? <PremiumCardPromo option={option} index={index}
                                            isOffer={isOffer} isPromo={isPromo}
                                            selectedIndex={selectedIndex}
                                            options={options}
                                            discount={discountPromo[index] || 0}
                                            choosePremium={this.choosePremium} /> :
                                            <PremiumCard option={option} index={index}
                                                isOffer={isOffer} isPromo={isPromo}
                                                selectedIndex={selectedIndex}
                                                options={options}
                                                discount={index == 2 ? 50 : discountFin}
                                                choosePremium={this.choosePremium} />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                        {isPromo && !isReferral && <div style={{ width: '340px', margin: '2em auto', }}>
                            <Input.Group compact>
                                <Input style={{ width: 'calc(100% - 100px)' }} onChange={this.onChange}
                                    defaultValue={codeFin || ''} placeholder='Enter promo code' />
                                <Button onClick={this.applyDiscount} type="primary">
                                    {codeProcessing ? 'Apply...' : (codeApplied ? 'Applied' : 'Apply')}
                                </Button>
                            </Input.Group>
                        </div>}
                        {options.length > 0 && <div className='text-center' style={{ marginBottom: '8px' }}>
                            <ClickPremium isLifetime={this.isLifetime(options[selectedIndex])}
                                type={isOnboard ? 'isOnboard' : ''}
                                isPromo={isPromo} code={code}
                                option={options[selectedIndex]} />
                            {/* <Button className={`third-button`} onClick={this.clickPremium}>
                                Continue
                            </Button> */}
                        </div>}
                        {options.length > 0 && <div className='text-center'>
                            <p>
                                {/* {getPrice(options[selectedIndex])}&#47; */}
                                {/* {getMonthCountDisplay(options[selectedIndex], selectedIndex)}&nbsp; */}
                                {/* {getMonthCount(options[selectedIndex], selectedIndex) > 200 ? '' : singular('month', getMonthCount(options[selectedIndex], selectedIndex))} */}
                                {!isOffer && !this.isLifetime(options[selectedIndex]) && <span>Cancel any time</span>}
                            </p>
                        </div>}
                        {/* {options.length > 0 && !this.isLifetime(options[selectedIndex]) &&
                            <div className='mt-2 text-center'><h5>{(premiumInfoLoc['bottom'] ?? 'Cancel anytime').replace('{appstore}', '')}</h5></div>
                        } */}
                        {/* <div className='text-center'>
                            <a target='_blank' rel="noopener noreferrer"
                                href='https://tedapp.ai/explore/terms-and-conditions'>Terms & Conditions</a>
                        </div> */}
                        {bottomInfo && <div className='mt-2 text-center'><h4>{bottomInfo}</h4></div>}
                    </div>}
                </div>
                <style jsx>
                    {`
                    .discount {
                        background: green;
                        position: absolute;
                        left: 0;
                        top: 0;
                        border-radius: 4px;
                        font-size: 12px;
                        padding: 2px 4px;
                    }
                .card-div {
                    display: flex;
                    justify-content: center;
                }
                    :global(.third-button) {
                background: ${colors.third};
                border-color: ${colors.third};
                color: white;
            }
            :global(.third-button.outline) {
                background: white; // ${colors.third};
                border-color: ${colors.third};
                color: ${colors.third};
            }
            :global(.third-button:hover) {
                background: #8d2256;
                border-color: #8d2256;
                color: white;
            }
            `}
                </style>
            </div >
        )
    }
}

PremiumPage.propTypes = {
    productId: PropTypes.string,
    initIndex: PropTypes.number,
    discountParent: PropTypes.number,
    isOffer: PropTypes.bool,
    isPromo: PropTypes.bool,
    isOnboard: PropTypes.bool,
    isReferral: PropTypes.bool,
    justText: PropTypes.bool,
    mainTitle: PropTypes.string,
    bottomInfo: PropTypes.string,
    subtitle: PropTypes.string,
    userDetails: PropTypes.object,
}
const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        // loadingState: state.loadingState,
    }
}
export default connect(mapStateToProps, null)(PremiumPage);