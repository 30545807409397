import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, spacing, maxScreenSize } from '../../lib/theme';
import { Col, Row } from 'antd';

export default class InterestOption extends Component {
    render() {
        const { genreMap, selectOption, selectedGenreIds } = this.props;
        return (
            <div>
                <div onClick={selectOption} className='main-div' style={{
                    marginRight: `${spacing('lg')}`, marginBottom: '1em', cursor: 'pointer',
                    borderRadius: '8px', height: '48px'
                }}>
                    <Row>
                        <Col span={3}>
                            <div className='icon'>
                                <FontAwesomeIcon style={{
                                    color: 'white', fontSize: '1.5em',
                                    marginTop: '9px'
                                }} icon={genreMap['icon']} />
                            </div>
                        </Col>
                        <Col span={17}>
                            <p className='main-text' style={{ marginBottom: '0' }}>{genreMap['name']}</p>
                        </Col>
                        <Col span={4} style={{ textAlign: 'center', margin: 'auto' }}>
                            {selectedGenreIds[genreMap['id']] &&
                                <FontAwesomeIcon style={{ color: colors.primary, fontSize: '1.25em', marginBottom: '0px' }} icon='check-circle' />}
                        </Col>
                    </Row>
                </div>
                <style jsx>
                    {`
                        .main-div {
                            width: 90%; // 140px;
                            background: white;
                            // box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
                            box-shadow: 0 10px 20px 0 rgba(0,11,40,.04);
                            padding: 0.25em 1.5em;
                        }
                        .main-text {
                            padding: 0.5em 1.5em;
                        }
                        .icon{
                            background: ${colors.primary};
                            text-align: center;
                            border-radius: 50%;
                            width: 40px;
                            height: 40px;
                        }
                        @media ${maxScreenSize.laptop} {
                            .main-div {
                                width: 90%; // 100px;
                            }
                        }
                    `}
                </style>
            </div>
        )
    }
}

InterestOption.propTypes = {
    genreMap: PropTypes.object,
    selectedGenreIds: PropTypes.object,
    selectOption: PropTypes.func
}