import { firestore } from '../lib/db';
import {
    featuredMainUrl, featuredAllUrl, firestoreApi, print,
    countrySlug, timeOfDaySlug, ipCountryAPI, podcastTypes, shuffle, isEpisodeExplicitlyMarkedPlayed,
    getRandomEpisodeNum
} from '../lib/utils';
import { Timestamp, collection, query, where, getDoc, orderBy, limit, startAfter, getDocs, doc, setDoc } from "firebase/firestore/lite";
import FireStoreParser from 'firestore-parser';
import fetch from 'isomorphic-unfetch';
import {
    FETCH_HOME_PODCASTS, ADD_HOME_PODCASTS,
    FETCH_DB_PODCASTS
}
    from './types';
import { getIsPremiumUser, setlocationInfo, setRecommendedEpisodes } from '../lib/fileUtils';
import { fetchTopChartsPodcasts } from './PodcastActions';
import { FETCH_FOR_YOU_EPISODES } from '../lib/strings';

export const fetchHomeMainPodcasts = () => async (dispatch) => {
    // ThunkAction<AppState> fetchHomeMainPodcasts() {
    // return (Store<AppState> store) async {
    // Future<List<String>> fetchHomeMainPodcasts() async {
    try {
        const res = await fetch(firestoreApi + 'home-podcasts/main');
        const result = await res.json();

        let parsedResult = FireStoreParser(result);
        console.dir(parsedResult);
        if ('fields' in parsedResult) {
            let homePodcastsMain = parsedResult['fields']['types'];
            let allHomePodcastsData = {};
            // homePodcastsMain.forEach((key, val) {
            for (const key in homePodcastsMain) {
                homePodcastsMain[key]['slug'] = key;
                allHomePodcastsData[key] = homePodcastsMain[key]; // Map.from(val);
            }
            console.dir(homePodcastsMain);
            let sortedList = Object.values(homePodcastsMain);
            console.dir(sortedList);
            // final List<Map<String, List<Podcast>>> finalList = [];
            sortedList.forEach((el) => {
                if (!('retired' in el) &&
                    !('extra' in el) &&
                    ('slug' in el)) {
                    getHomePodcastData(dispatch, el['slug'], el['name']);
                }
            });
            print('time1');
            // print(DateTime.now());

            ///******** */ also add time of day podcasts here
            let slug = timeOfDaySlug();
            if (slug != null) {
                sortedList.forEach((el) => {
                    if (!('retired' in el) &&
                        ('slug' in el) &&
                        el['slug'] == slug) {
                        getHomePodcastData(dispatch, slug, el['name']);
                        allHomePodcastsData[slug]['extra'] = false;
                    }
                });
            }
            console.dir(allHomePodcastsData);

            // dispatch({ type: FETCH_HOME_PODCASTS, allHomePodcastsData });

            // *******do location ip later ***********
            let locationSlug = await getLocationSlug();
            console.dir(locationSlug);
            if (locationSlug != null) {
                sortedList.forEach((el) => {
                    if (!('retired' in el) &&
                        ('slug' in el) &&
                        el['slug'] === locationSlug) {
                        getHomePodcastData(dispatch, el['slug'], el['name']);
                        allHomePodcastsData[locationSlug]['extra'] = false;
                    }
                });
                print('locationSlug : ' + locationSlug);
            }
            console.dir(allHomePodcastsData);
            dispatch({ type: FETCH_HOME_PODCASTS, allHomePodcastsData });
        }
        return [];
    } catch (e) {
        print(e);
        throw e;
        // return {};
    }
};

export const fetchHomeMorePodcasts = () => async (dispatch, getState) => {
    // ThunkAction<AppState> fetchHomeMorePodcasts() {
    // return (Store<AppState> store) async {
    let allHomePodcastsData = getState().allHomePodcastsData;
    Object.values(allHomePodcastsData).forEach((el) => {
        if (!('retired' in el) &&
            ('slug' in el) &&
            ('extra' in el) &&
            el['extra'] === true) {
            getHomePodcastData(dispatch, el['slug'], el['name']);
        }
    });
};

let fetchedPremiumPodcasts = false;
let fetchedNormalPodcasts = false;
// ThunkAction<AppState> fetchPersonalizedPodcasts(Map genreIds) {
//   return (Store<AppState> store) async {
export const fetchPersonalizedPodcasts = (genreIds, subscribedPodcastsMap = {}) => async (dispatch, getState) => {
    if (getIsPremiumUser() && fetchedPremiumPodcasts) {
        return;
    }

    //   utils.printLog('fetchPersonalizedPodcasts0');
    if (Object.keys(subscribedPodcastsMap).length > 0 &&
        !fetchedPremiumPodcasts &&
        getIsPremiumUser()) {
        fetchedPremiumPodcasts = true;
        // utils.printLog('fetchPersonalizedPodcasts1');
        console.dir(subscribedPodcastsMap);
        const randomCollectionIds = shuffle(Object.keys(subscribedPodcastsMap));
        console.dir(randomCollectionIds);
        const randomCollectionIdsFin = [];
        for (let randomCollectionId of randomCollectionIds) {
            const idLoc = parseInt(randomCollectionId) || -1;
            if (idLoc > 0) {
                randomCollectionIdsFin.push(idLoc);
            }
        }
        console.dir(randomCollectionIdsFin);
        // FirebaseFirestore.instance
        //     .collection('podcasts')
        //     .where('collectionId',
        //         whereIn: randomCollectionIdsFin.take(8).toList())
        //     .limit(10)
        //     .get()

        getDocs(query(collection(firestore, "podcasts"), where("collectionId", "in", randomCollectionIdsFin.slice(0, 8)),
            limit(10)))
            .then(async (querySnapshot) => {
                const collectionIdsRelated = [];
                querySnapshot.docs.forEach((doc) => {
                    const element = doc.data();
                    const relatedIds = Object.keys(element['related'] || {});
                    for (let relatedId of relatedIds) {
                        const relatedIdInt = parseInt(relatedId) || -1;
                        if (!collectionIdsRelated.includes(relatedIdInt)) {
                            collectionIdsRelated.push(relatedIdInt);
                        }
                    }
                });
                console.dir('all related');
                console.dir(collectionIdsRelated.toString());
                const futures = [];
                for (let index = 1;
                    index <= Math.ceil(collectionIdsRelated.length / 10);
                    index++) {
                    const reqCollectionIds = [];
                    for (let ind = 10 * (index - 1);
                        ind < Math.min(10 * index, collectionIdsRelated.length);
                        ind++) {
                        const collId = collectionIdsRelated[ind];
                        reqCollectionIds.push(parseInt(collId));
                    }
                    futures.push(
                        // FirebaseFirestore.instance
                        // .collection('podcasts')
                        // .where('collectionId', whereIn: reqCollectionIds)
                        // .limit(10)
                        // .get()
                        getDocs(query(collection(firestore, "podcasts"), where("collectionId", "in", reqCollectionIds),
                            limit(10)))
                            .catch((err) => undefined));
                }
                const results = await Promise.all(futures);
                const podcastsLoc = [];
                for (let querySnapshot of results) {
                    if (querySnapshot) {
                        querySnapshot.docs.forEach((doc) => {
                            const element = doc.data();
                            podcastsLoc.push(element);
                        });
                        console.dir('podcastsLoc00');
                        console.dir(podcastsLoc);
                    }
                }
                console.dir('podcastsLoc00 fin');
                console.dir(podcastsLoc);
                podcastsLoc.sort((p1, p2) => p2.subscribers - (p1.subscribers));
                // store.dispatch(AddHomePodcastsAction(RECOMMENDED_FOR_YOU, podcastsLoc));
                dispatch({ type: ADD_HOME_PODCASTS, title: 'Recommended For You', podcastsData: podcastsLoc });
            }).catch((err) => {
                console.dir('per error');
                console.dir(err);
            });
        // return;
    }


    let neededGenres = [];
    podcastTypes.forEach((el) => {
        if (el['id'] in genreIds) {
            neededGenres.push(el['name']);
        }
    });
    let neededGenresFinal = neededGenres;
    if (neededGenres.length > 5) {
        neededGenresFinal = neededGenres.slice(0, 5);
    }

    if (!neededGenresFinal || neededGenresFinal.length < 1) {
        return;
    }
    if (fetchedNormalPodcasts) {
        return;
    }
    fetchedNormalPodcasts = true;
    // print('fetchPersonalizedPodcasts start 1: ' + neededGenresFinal.toString());
    getDocs(query(collection(firestore, "podcasts"), where("genres", "array-contains-any", neededGenresFinal), where("subscribers", ">", 5),
        orderBy('subscribers', 'desc'),
        limit(10)))
        .then((querySnapshot) => {
            let podcasts = [];
            querySnapshot.forEach((doc) => {
                let podcastJson = doc.data();
                // print('fetchPersonalizedPodcasts : ' + podcastJson.toString());
                podcasts.push(podcastJson);
            });
            // store.dispatch(AddHomePodcastsAction(RECOMMENDED_FOR_YOU, podcasts));
            fetchPersonalizedEpisodes(podcasts, getState().personalMarkPlayedValues);
            dispatch({ type: ADD_HOME_PODCASTS, title: 'Recommended For You', podcastsData: podcasts });
            // store.dispatch(FetchPodcastTagsAction(podcastTags));
        }).catch((error) => {
            console.log("Error getting documents: " + error.toString());
        });
}



const fetchPersonalizedEpisodes = async (podcasts, personalMarkPlayedValues) => {
    console.dir(podcasts);
    const podcastsFin = shuffle([...podcasts]);
    //   podcastsFin.shuffle();
    const futures = [];
    podcastsFin.slice(0, 10).forEach((pod) => {
        // final Future<DocumentSnapshot> future = FirebaseFirestore.instance
        //     .collection('podcasts')
        //     .doc('${pod.collectionId}')
        //     .collection('latestEpisode')
        //     .doc('episode')
        //     .get()
        const future = getDoc(doc(firestore, `podcasts/${pod.collectionId}/latestEpisode`, 'episode'))
            .catch((err) => {
                //   print(err);
                return undefined;
            });
        futures.push(future);
    });
    const results = await Promise.all(futures);
    const episodesMap = {};
    for (let result of results) {
        if (result && result.data()) {
            const episodeFin = result.data();
            if (!isEpisodeExplicitlyMarkedPlayed(
                episodeFin.guid, personalMarkPlayedValues)) {
                episodesMap[`${episodeFin.guid}`] = episodeFin;
            }
        }
    }
    console.dir(episodesMap);
    setRecommendedEpisodes(Object.values(episodesMap));
    // recommendedEpisodesNotifier.value = episodesMap;
}

const getLocationSlug = async () => {
    let locationInfo = await getLocationInfoApi();
    console.dir(locationInfo);
    return countrySlug(
        locationInfo['country_code'], locationInfo['continent_code']);
}

export const getLocationInfoApi = async () => {
    try {
        const res = await fetch(ipCountryAPI + '?api-key=3010253779763b6cb8a379d80327ac6672b2a7c3112b81aadf982f97');
        const result = await res.json();
        console.dir(result);
        setlocationInfo(result);
        return result;
    } catch (err) {
        return { 'country_code': '', 'continent_code': '' };
    }
}

export const getBrowsePodcasts = (podcastTypeId, alsoGetEpisodes = false) => async (dispatch, getState) => {
    fetchTopChartsPodcasts(podcastTypeId, 1)
        .then((podcasts) => {
            // const browsePodcastsArray = podcasts;
            console.dir(podcasts);
            podcasts.slice(0, 6).forEach(pod => {
                const totalEpisodeCount = pod['numEpisodes'] ?? 2;
                // utils.printLog('alsoGetEpisodes 2');

                if (alsoGetEpisodes) {
                    dispatch(getEpisodeDataFromNumber(getRandomEpisodeNum(totalEpisodeCount),
                        pod['collectionId']));

                    dispatch({
                        type: FETCH_DB_PODCASTS,
                        payload: { [pod['collectionId']]: pod }
                    });
                }
            })
        });
}


const getEpisodeDataFromNumber = (episodeNumber, collectionId, type = 1) => async (dispatch, getState) => {
    // if (episodeCache.containsKey(docId)) {
    //   return episodeCache[docId];
    // }
    try {
        // utils.printLog('getEpisodeDataFromNumber start');
        const snap = await getDocs(query(collection(firestore, "episodes"), where('collectionId', '==', collectionId), where('pubDate', '==', episodeNumber)), limit(1));

        // const snap = await FirebaseFirestore.instance
        //     .collection('episodes')
        //     .where('collectionId', isEqualTo: collectionId)
        //     .where('pubDate', isEqualTo: episodeNumber)
        //     .limit(1)
        //     .get();
        snap.docs.forEach((doc) => {
            if (doc.exists) {
                // utils.printLog('guids data : ' + doc.data.toString());
                const ep = doc.data();
                // utils.printLog('getEpisodeDataFromNumber guid: ' + ep.guid);
                // episodeCache[docId] = ep;
                // return ep;
                // console.dir(ep);
                if (type == 1) {
                    // forYouHomeEpisodesNotifier.value = {
                    //     ...forYouHomeEpisodesNotifier.value,
                    //     '${ep.guid}': ep
                    // };
                    dispatch({
                        type: FETCH_FOR_YOU_EPISODES,
                        guid: ep.guid,
                        episode: ep
                    });
                } else if (type == 2) {
                    // insightsEpisodesNotifier.value = {
                    //     ...insightsEpisodesNotifier.value,
                    //     ep.collectionId: ep
                    // };
                }
            } else {
                // utils.printLog('getEpisodeDataFromNumber err');
                // throw 'Error';
            }
        });
    } catch (err) {
        console.error(err);
        // utils.printLog('getEpisodeDataFromNumber err 2');
        // utils.printLog(err);
    }
}

//   Future getHomePodcastData(
//       Store<AppState> store, String slug, String name) async {
const getHomePodcastData = async (dispatch, slug, name) => {
    // return (Store<AppState> store) async {
    try {
        // Dio dio = new Dio();
        // dio.interceptors..add(UrlCache(onlineCacheFetchHours: 3 * 24));
        // final res = await dio.get('${utils.firestoreApi}home-podcasts/${slug}');

        const res = await fetch(firestoreApi + 'home-podcasts/' + slug);
        const result = await res.json();

        let parsedResult = FireStoreParser(result);

        // final Map result = res.data;
        // print('getHomePodcastData 0 : ' + result.toString());
        // final Map parsedResult = FireStoreParser(result);
        if ('fields' in parsedResult) {
            let homePodcastData = parsedResult['fields'];
            if ('podcasts' in homePodcastData) {
                let podcasts = homePodcastData['podcasts'];
                // let < Podcast > podcasts =[];
                // podcastsJson.forEach((pod) {
                //     podcasts.add(Podcast.fromJson(pod));
                // });
                console.dir(podcasts);
                // dispatch(AddHomePodcastsAction(name, podcasts));
                dispatch({ type: ADD_HOME_PODCASTS, title: name, podcastsData: podcasts });
            }

        }
        return [];
    } catch (e) {
        print(e);
        throw e;
        // return {};
    }
}


const subgenrePodcasts = {};
export const getAllSubGenrePodcasts = async (genres) => {
    try {
        const genreId = genres[0];
        if (genreId in subgenrePodcasts) {
            return subgenrePodcasts[genreId];
        }
        console.dir(genreId);
        const snapshot = await getDocs(query(collection(firestore, "podcasts"), where("genres", "array-contains-any", genres), where("subscribers", '>', -1), orderBy('subscribers', 'desc'), limit(15)));
        const podcasts = [];
        snapshot.docs.forEach((element) => {
            podcasts.push(element.data());
        });
        if (podcasts.length > 0) {
            subgenrePodcasts[genreId] = podcasts;
        }
        return podcasts;
    } catch (err) {
        throw err;
    }
}

const subgenreEpisodes = {};
export const getAllSubGenreEpisodes = async (genreId, podcasts) => {
    // utils.printLog('getAllSubGenrePodcasts 000011 : ' + genreId.toString());
    // final String genreId = genres[0];
    if (genreId in subgenreEpisodes) {
        return subgenreEpisodes[genreId];
    }
    console.dir('getAllSubGenrePodcasts 0033');
    const chunkSize = 10;
    const collectionIdsGlobal = [];
    for (let i = 0; i < podcasts.length; i += chunkSize) {
        const collectionIdsLoc = podcasts
            .slice(i,
                i + chunkSize > podcasts.length ? podcasts.length : i + chunkSize)
            .map((el) => el.collectionId);

        console.dir(
            'getAllSubGenrePodcasts 0044 : ' + collectionIdsLoc.toString());
        collectionIdsGlobal.push(collectionIdsLoc);
    }
    console.dir(
        'getAllSubGenrePodcasts 0011 : ' + collectionIdsGlobal.toString());
    try {
        const futures = [];
        const episodes = [];
        for (const collectionIds of collectionIdsGlobal) {
            const snapshot = await getDocs(query(collection(firestore, "episodes"), where("collectionId", "in", collectionIds), orderBy('pubDate', 'desc'), limit(10)));
            futures.push(snapshot);
        }
        const snapshots = await Promise.all(futures);
        for (const snapshot of snapshots) {
            snapshot.docs.forEach((element) => {
                episodes.push(element.data());
            });
        }
        if (episodes.length > 0) {
            subgenreEpisodes[genreId] = episodes;
        }
        // utils.printLog('getAllSubGenrePodcasts 11 : ' + episodes.toString());
        return episodes;
    } catch (err) {
        // utils.printLog('getAllSubGenreEpisodes');
        // utils.printLog(err);
        throw err;
    }
}

export const getAllTrendingPodcasts = async (genreIds) => {
    try {
        let podcasts = [];
        let futures = [];
        for (const genreId of genreIds) {
            let future = getTrendingPodcasts(genreId);
            futures.push(future);
        }
        let results = await Promise.all(futures);
        results.forEach((pods) => {
            podcasts = [...podcasts, ...pods];
        });
        console.dir('podcasts trend : ' + podcasts.toString());
        return podcasts;
    } catch (e) {
        console.error(e);
        throw e;
        // return {};
    }
}

export const getTrendingPodcasts = async (genreId) => {
    try {
        const res = await fetch(`${firestoreApi}trending-podcasts/${genreId}`);
        const result = await res.json();

        let parsedResult = FireStoreParser(result);

        console.dir('getHomePodcastData  parsedResult 0 : ' + parsedResult.toString());
        let podcasts = [];
        if ('fields' in parsedResult) {
            let homePodcastData = parsedResult['fields'];
            if ('podcasts' in homePodcastData) {
                podcasts = homePodcastData['podcasts']
                console.dir(
                    'getHomePodcastData  podcastsJson 11 : ' + podcasts.toString());
            }
        }
        return podcasts;
    } catch (e) {
        console.error(e);
        throw e;
        // return {};
    }
}

export const getAllTrendingEpisodes = async (genreIds) => {
    try {
        let episodes = [];
        let futures = [];
        for (const genreId of genreIds) {
            let future = getTrendingEpisodes(genreId);
            futures.push(future);
        }
        const results = await Promise.all(futures);
        results.forEach((eps) => {
            episodes = [...episodes, ...eps];
        });
        return episodes;
    } catch (e) {
        console.error(e);
        throw e;
        // return {};
    }
}

export const getTrendingEpisodes = async (genreId) => {
    try {
        const res = await fetch(`${firestoreApi}trending-episodes/${genreId}`);
        const result = await res.json();

        let parsedResult = FireStoreParser(result);
        console.dir(parsedResult);
        let episodes = [];
        if ('fields' in parsedResult) {
            let homePodcastData = parsedResult['fields'];
            if ('episodes' in homePodcastData) {
                episodes = homePodcastData['episodes']
                console.dir(
                    'getHomePodcastData  podcastsJson 11 : ' + episodes.toString());
            }
        }
        console.dir(episodes);

        return episodes;
    } catch (e) {
        console.dir(e);
        console.error(e);
        throw e;
        // return {};
    }
}