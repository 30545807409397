import {
    SET_USER_DETAILS,
    CREATE_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    // CREATE_USER_FAILURE,
    SIGNIN_USER_SUCCESS,
    UPDATE_USER_IMAGE,
    USER_HAS_ADS,
    USER_DASHBOARD_PLAN,
    ADD_REQUESTED_BOOKS,
    FETCH_REQUESTED_BOOKS,
    // SIGNIN_USER_FAILURE,
    // LOG_OUT_USER
}
    from '../actions/types';

export const requestedBooksReducer = (state = {}, action) => {
    // console.dir(state);
    switch (action.type) {
        case FETCH_REQUESTED_BOOKS:
            return Object.assign({}, state, action.payload);
        case ADD_REQUESTED_BOOKS:
            return Object.assign({}, state, { [action.key]: action.payload });
        default:
            return state;
    }
};