import React, { Component } from 'react'
import dynamic from 'next/dynamic';
const LottieComponent = dynamic(() => import('../Podcast/LottieComponent'), {
    ssr: false, // Set to true if you want server-side rendering
    // loading: () => <p>Loading...</p>, // Optional loading component
});

export default class LoadingIntro extends Component {
    // constructor(props) {
    //     super(props);
    //     this.state = { stage: 1 };
    // }

    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ stage: 2 });
    //     }, 5000);
    // }

    render() {
        // const { stage } = this.state;
        const { stage } = this.props;
        return (
            <div>
                <div style={{ width: `400px`, textAlign: 'center', margin: 'auto' }}>
                    <h3>Great Job!</h3>
                    {stage == 2 &&
                        <LottieComponent image={'loading'} loop={true} />}
                    {stage == 3 &&
                        <LottieComponent image={'check'} loop={false} />
                    }
                    <h3>{stage == 2
                        ? "We're analyzing your interests to create a perfect plan for you..."
                        : "We’ve found 900+ books that match your interests and added them to your recommendations."}</h3>
                </div>
            </div>
        )
    }
}
