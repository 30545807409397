import { firestore } from '../lib/db';
// import { getFirebaseUser } from '../lib/utils';
import { Timestamp, collection, query, where, getDoc, orderBy, limit, startAfter, getDocs, doc, setDoc, deleteField } from "firebase/firestore/lite";
import { ADD_INSIGHT_ACTION, DELETE_INSIGHT_ACTION, FETCH_HIGHLIGHTS_ACTION, FETCH_INSIGHTS_ACTION } from './types';
import { getFirebaseUser } from '../lib/utils';

export const fetchInsights = () => async (dispatch, getState) => {
    try {
        const userDetails = getState().userDetails;
        if (!userDetails || !userDetails['uid']) {
            return;
        }

        const docRef = doc(firestore, 'users', userDetails['uid'], 'insights', 'details');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const docs = data.values || {};

            // Assuming insightsNotifier is a function or a state setter
            // insightsNotifier(docs);
            dispatch({
                type: FETCH_INSIGHTS_ACTION,
                payload: docs
            })
        } else {
            console.log("No such document!");
        }
    } catch (error) {
        console.error("Error getting document:", error);
    }
}
export const addInsights = (guid, collectionId) => async (dispatch, getState) => {
    try {
        const userDetails = getState().userDetails;
        if (!userDetails || !userDetails['uid']) {
            return;
        }
        const data = {
            'collectionId': collectionId,
            'time': Date.now()
        };
        dispatch({
            type: ADD_INSIGHT_ACTION,
            payload: {
                [guid]: data
            }
        })

        const docRef = doc(firestore, 'users', userDetails['uid'], 'insights', 'details');

        await setDoc(docRef, {
            'values': {
                [guid]: data
            }
        }, { merge: true });

        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error setting document:", error);
    }
}
export const deleteInsights = (guid) => async (dispatch, getState) => {
    try {
        const userDetails = getState().userDetails;
        if (!userDetails || !userDetails['uid']) {
            return;
        }
        dispatch({
            type: DELETE_INSIGHT_ACTION,
            guid: guid
        })

        const docRef = doc(firestore, 'users', userDetails['uid'], 'insights', 'details');
        await setDoc(docRef, {
            'values': {
                [guid]: deleteField()
            }
        }, { merge: true });

        console.log("Document successfully updated!");
    } catch (error) {
        console.error("Error setting document:", error);
    }
}


export const fetchHighlights = (userUID) => async (dispatch, getState) => {
    // const userUID = getFirebaseUser() && getFirebaseUser().uid;
    console.dir('fetchHighlights');
    getDoc(doc(firestore, `users/${userUID}/highlights`, 'details')).then(doc => {
        if (doc.exists) {
            const data = doc.data() ?? {};
            const docs = data['values'] ?? {};
            console.dir(docs);
            // highlightsNotifier.value = docs;
            dispatch({
                type: FETCH_HIGHLIGHTS_ACTION,
                payload: docs
            })
        }
    })
}

export const addHighlights = (guid, collectionId, start, end) => {
    // const userDetails = getState().userDetails;
    const userUID = getFirebaseUser() && getFirebaseUser().uid;
    const highlightsMap = {};
    const timeNow = Date.now();
    highlightsMap[`${start}-${end}`] = timeNow;
    const data = {
        'collectionId': collectionId,
        'highlights': highlightsMap
    };
    console.dir(data);
    console.dir(userUID);
    if (!userUID) {
        alert('user not found');
        return;
    }
    // const dataToAdd = { [guid]: data };
    // const existingMainData = highlightsNotifier.value[guid] ?? {};
    // const existingHighlightsData = existingMainData['highlights'] ?? {};
    // existingHighlightsData['$start-$end'] = timeNow;
    // existingMainData['highlights'] = existingHighlightsData;
    // existingMainData['collectionId'] = collectionId;
    // highlightsNotifier.value = {
    //     ...highlightsNotifier.value,
    //     '$guid': existingMainData
    // };
    // utils.printLog(data);
    setDoc(doc(firestore, `users/${userUID}/highlights`, 'details'), { 'values': { [guid]: data } },
        { merge: true });
    // FirebaseFirestore.instance
    //     .collection('users')
    //     .doc(utils.getFirebaseUID())
    //     .collection('highlights')
    //     .doc('details')
    //     .set({
    //         'values': { [guid]: data }
    //     }, SetOptions(merge: true));
}


export const deleteHighlights = (guid, key) => {
    // const userDetails = getState().userDetails;
    const userUID = getFirebaseUser() && getFirebaseUser().uid;
    // const highlightsMap = {};
    // const timeNow = Date.now();
    console.dir(userUID);
    if (!userUID) {
        alert('user not found');
        return;
    }
    const dataToDelete = {
        [guid]: {
            'highlights': { [key]: deleteField() }
        }
    };
    setDoc(doc(firestore, `users/${userUID}/highlights`, 'details'), { 'values': dataToDelete },
        { merge: true });
    // FirebaseFirestore.instance
    //     .collection('users')
    //     .doc(utils.getFirebaseUID())
    //     .collection('highlights')
    //     .doc('details')
    //     .set({
    //         'values': { [guid]: data }
    //     }, SetOptions(merge: true));
}