import { doc, increment, setDoc } from "firebase/firestore/lite";
import { firestore } from "../lib/db";
import { getFirebaseUser } from "../lib/utils";



// export const setGameDataForChapter = async () => {
//     const state = store.getState();
//     const gameDataInit = state.gameData;
//     const level = gameDataInit.level || 1;
//     const xpInit = gameDataInit.xp || newUserXPStart;
//     let gameData = {};

//     const newCoins = coinsPerIdeaBase + (level - 1);
//     gameData.coins = (gameDataInit.coins || 0) + newCoins;

//     const newXP = xpPerIdeaBase;
//     gameData.xp = xpInit + newXP;

//     const xpToNewLevel = getXPToNewLevel(level);
//     if (xpInit + newXP >= xpToNewLevel) {
//         gameData.level = level + 1;
//         gameData.xp = 0;
//     }

//     printLog("gameData", gameData);
//     store.dispatch(updateGameData(gameData));

//     const now = new Date();
//     const formatted = formattedDate("yyyy-MM-dd", now);
//     const coinsToday = state.coinsDays[formatted] || 0;
//     const coinsDays = { [formatted]: coinsToday + newCoins };

//     await setDoc(doc(db, "game", getFirebaseUID()), {
//         game: gameData,
//         coinsDays: { ...state.coinsDays, ...coinsDays }
//     }, { merge: true });
// };

export const consumeCoins = (consumedCoins = 250) => async (dispatch) => {
    // const state = store.getState();
    // const newCoins = (state.gameData.coins || 0) - consumedCoins;

    // store.dispatch(consumeCoinsAction(newCoins));

    await setDoc(doc(firestore, "game", getFirebaseUser().uid), {
        game: { coins: increment(-consumedCoins) }
    }, { merge: true });
};
