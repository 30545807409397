import {
    FETCH_BLOG_LISTS_ACTION,
    FETCH_FEATURED_LISTS_ACTION
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const featuredListsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_FEATURED_LISTS_ACTION:
            return [...action.payload];
        default:
            return state;
    }
};
export const blogListsReducer = (state = [], action) => {
    switch (action.type) {
        case FETCH_BLOG_LISTS_ACTION: {
            const returnArr = [...state];
            console.dir(returnArr);
            const newArr = action.payload;
            console.dir(newArr);
            for (const el of newArr) {
                const ind = state.findIndex(el2 => el2.slug == el.slug);
                if (ind < 0) {
                    returnArr.push(el);
                }
            }
            console.dir(returnArr);
            return returnArr;
            // return Object.assign({}, state, { [action.guid]: action.bookmarkData });
        }
        default:
            return state;
    }
};