import { firestore } from '../lib/db';
import { cleanFirebaseId, createEpisodeObj, createRandomShortString, firestoreApi, getIdFromUID, isMyPlaylist, serverApiEurope } from '../lib/utils';
import { Timestamp, deleteField, collection, query, where, getDoc, addDoc, orderBy, limit, startAfter, arrayUnion, arrayRemove, increment, deleteDoc, getDocs, doc, setDoc } from "firebase/firestore/lite";
import {
  ADD_PLAYLIST_SUCCESS, EDIT_PLAYLIST_SUCCESS, DELETE_PLAYLIST_SUCCESS, SUBSCRIBE_PODCAST_FAILURE,
  CREATING_NEW_PLAYLIST, FETCH_PLAYLISTS_MAIN, LOADING_PLAYLISTS_MAIN, UPDATE_PLAYLISTS_MAIN, FETCH_PLAYLISTS_SUCCESS,
  ADD_TO_PLAYLISTS_MAIN, DELETE_PLAYLISTS_MAIN, DELETE_FROM_PLAYLISTS_MAIN, DELETE_ALL_FROM_PLAYLISTS_MAIN,
  ADD_GUID_TO_PLAYLIST_MAIN, DELETE_GUID_FROM_PLAYLIST_MAIN,
  EPISODE_ORDINALITY_CHANGED, PLAYLIST_SORT_ORDER_CHANGED, FOLLOW_PLAYLIST, UNFOLLOW_PLAYLIST, COLLABORATIVE_PLAYLIST, UNCOLLABORATIVE_PLAYLIST
}
  from './types';
// import fetch from 'isomorphic-unfetch';
import { message, notification } from 'antd';
import { logPlaylist, logEpisodeInPlaylist, logPlaylistPublic, logPlaylistCollaborative } from '../lib/analyticsEvents';
import { tokenFetch, tokenFetchPOST } from '../lib/tokenUtils';
import { getFollowedPlaylistsData, setFollowedPlaylistsData } from '../lib/fileUtils';
import fetch from 'isomorphic-unfetch';
import FireStoreParser from 'firestore-parser';
//ACTIONS

export const fetchPlaylists = (userUID) => async dispatch => {
  // const db = await loadDB();
  console.log('fetchPlaylists : ' + userUID);
  const userId = getIdFromUID(userUID);
  getDocs(query(collection(firestore, "playlists"), where("userID", "==", userId)))
    .then(function (querySnapshot) {
      console.log(querySnapshot);
      let userPlaylists = {};
      // let newState = {
      //   userPlaylists: []
      // }
      querySnapshot.forEach(function (doc) {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        // userPlaylists.push(doc.data());
        userPlaylists[doc.id] = doc.data();
      });
      console.log('userPlaylists');
      console.dir(userPlaylists);
      dispatch({
        type: FETCH_PLAYLISTS_SUCCESS,
        payload: userPlaylists
      })
    })
    .catch(function (error) {
      console.log("Error getting documents: ");
      console.dir(error);
    });
};

export const fetchPlaylistByCollabKey = (collabKey) => async (dispatch, getState) => {
  try {
    const url = serverApiEurope + `playlistByKey?key=${collabKey}`;
    const resData = await fetch(url);
    const json = await resData.json();
    console.dir(json);
    if (json.firestoreId) {
      let userPlaylist = {};
      userPlaylist[`${json.firestoreId}`] = json.playlistData;
      console.dir(userPlaylist);
      dispatch({
        type: ADD_PLAYLIST_SUCCESS,
        payload: userPlaylist
      })
      const playlistObj = { [json.firestoreId]: json.playlistEpisodeData };
      console.dir(playlistObj);
      dispatch({
        type: FETCH_PLAYLISTS_MAIN,
        payload: playlistObj
      });
    } else {
      notification['error']({
        message: "Invalid playlist",
        description: '',
        duration: null
      });
    }
  } catch (err) {
    console.error(err);
    notification['error']({
      message: "Invalid playlist",
      description: '',
      duration: null
    });
  }
}

export const fetchPlaylistInfo = (playlistId) => async (dispatch, getState) => {
  // const db = await loadDB();
  const prevFetchedPlaylistInfo = getState().userPlaylists && getState().userPlaylists[`${playlistId}`];
  if (!prevFetchedPlaylistInfo || !prevFetchedPlaylistInfo.name) {
    let userPlaylist = {};
    getDoc(doc(firestore, `playlists`, playlistId))
      .then(function (doc) {
        let playlistData = {};
        if (doc.exists()) {
          playlistData = doc.data();
          console.log("fetchPlaylistInfo:");
          console.dir(playlistData);
          userPlaylist[`${playlistId}`] = playlistData;
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          userPlaylist[`${playlistId}`] = {};
        }
        // console.dir(userPlaylists);
        dispatch({
          type: ADD_PLAYLIST_SUCCESS,
          payload: userPlaylist
        })
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
        notification['error']({
          message: "Invalid playlist",
          description: '',
          duration: null
        });
      });
  }
};

export const fetchFirestorePlaylist = async (playlistId) => {
  const res = await fetch(`${firestoreApi}playlists/${playlistId}`);
  const json = await res.json();
  const parsedFirestoreJSON = FireStoreParser(json);
  const finalJson = parsedFirestoreJSON?.fields || {};
  return finalJson;
}

export const fetchPlaylistDetails = (playlistId) => async (dispatch, getState) => {
  // const db = await loadDB();
  const prevFetchedPlaylistDetails = getState().userPlaylistsMain && getState().userPlaylistsMain[`${playlistId}`];
  console.dir(prevFetchedPlaylistDetails);
  if (!prevFetchedPlaylistDetails) {
    console.dir('first fetch');
    let loadingPlaylistMainObj = {};
    // loadingPlaylistMainObj[`${playlistId}`] = true;
    loadingPlaylistMainObj['loadingPlaylistMain'] = true;
    dispatch({
      type: LOADING_PLAYLISTS_MAIN,
      payload: loadingPlaylistMainObj
    });
    // console.log(userUID);
    let playlistObj = {};
    getDoc(doc(firestore, `playlists/${playlistId}/details`, 'episodes'))
      .then(function (doc) {
        if (doc.exists()) {
          console.dir(doc.data());
          playlistObj[`${playlistId}`] = doc.data();
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          playlistObj[`${playlistId}`] = {};
        }
        dispatch({
          type: FETCH_PLAYLISTS_MAIN,
          payload: playlistObj
        });
        loadingPlaylistMainObj['loadingPlaylistMain'] = false;
        dispatch({
          type: LOADING_PLAYLISTS_MAIN,
          payload: loadingPlaylistMainObj
        });
      }).catch(function (error) {
        console.log("Error getting document:", error);
        loadingPlaylistMainObj['loadingPlaylistMain'] = false;
        dispatch({
          type: LOADING_PLAYLISTS_MAIN,
          payload: loadingPlaylistMainObj
        });
      });

    // ****************** dont delete this, use this in future with cloud functions when playlists get large. 
    // fetch(`${serverApi}playlist?id=${playlistId}&uid=${userUID}`)
    //   .then(res => res.json())
    //   .then(json => {
    //     if (json.firestoreId) {
    //       playlistObj[`${json.firestoreId}`] = json.playlistObj || {};
    //       dispatch({
    //         type: ADD_TO_PLAYLISTS_MAIN,
    //         payload: playlistObj
    //       });
    //       loadingPlaylistMainObj[`${playlistId}`] = false;
    //       dispatch({
    //         type: LOADING_PLAYLISTS_MAIN,
    //         payload: loadingPlaylistMainObj
    //       });
    //     }
    //     // this.setState({ playlistInfo: json.playlistObj || {}, firestoreId: json.firestoreId || '', triedClientFetch: true });
    //   })
    //   .catch(err => {
    //     console.error(err);
    //     dispatch({
    //       type: LOADING_PLAYLISTS_MAIN,
    //       payload: loadingPlaylistMainObj
    //     });
    //     // this.setState({ triedClientFetch: true });
    //   });
  }
};

export const createPlaylist = (userDetails, playlistInfo, imageUrl) => new Promise((resolve, reject) => {
  // const db = await loadDB();
  const userUID = userDetails.uid || '';
  const userName = userDetails.name || '';
  console.log(userUID);
  console.log(playlistInfo);
  // const now = new Date();
  // const timeAdd = Math.round(now.getTime() / 1000);
  // const userIdAdd = getIdFromUID(userUID);
  // const nameAdd = playlistInfo.name.replace(/ /g, '-');
  // const playlistId = nameAdd + '-' + timeAdd + userIdAdd;
  // const playlistId = timeAdd + userIdAdd;
  var playlistOwnedObj = {
    // slug: playlistId,
    name: playlistInfo.name,
    type: playlistInfo.type || 'private'
  }
  var playlistObj = {
    ...playlistOwnedObj,
    description: playlistInfo.description || '',
    userID: getIdFromUID(userUID),
    userName: userName,
    dateAdded: Timestamp.fromDate(new Date()), // firebase.firestore.FieldValue.serverTimestamp(),
    // active: true,
    guidEpisodeArray: []
    // podcastsSuperset: []
  };

  addDoc(collection(firestore, `playlists`), playlistObj)
    .then((docRef) => {
      logPlaylist(docRef.id, 1);
      const firestoreId = docRef.id;
      if (imageUrl) {
        const imageUploadUrl = `${serverApiEurope}uploadPlaylistImage?firestoreId=${firestoreId}`;
        // const imageUploadUrl = `http://localhost:5000/podcastapp-767c2/europe-west1/uploadPlaylistImage?firestoreId=${firestoreId}`;
        tokenFetchPOST(imageUploadUrl, JSON.stringify({ encodedImage: imageUrl }))
          .then(async (dataInit) => {
            // return ({ success: true });
            const data = await dataInit.json();
            console.dir(data);
            if (data['success']) {
              playlistObj['imageName'] = data.fileName;
              resolve({ success: true, playlistInfo: playlistObj, playlistId: firestoreId });
            } else {
              console.error("Error adding document2: ");
              // reject({ success: false });
              resolve({ success: true, playlistInfo: playlistObj, playlistId: firestoreId });
              notification['error']({
                message: "Image upload error",
                description: data['message'] || 'Playlist was successfully created but there was an error uploading image.',
                duration: null
              });
            }
          })
          .catch((error) => {
            console.error("Error adding document: ", error);
            // reject({ error });
            resolve({ success: true, playlistInfo: playlistObj, playlistId: firestoreId });
            notification['error']({
              message: "Image upload error",
              description: error['message'] || 'Playlist was successfully created but there was an error uploading image.',
              duration: null
            });
          });
      } else {
        resolve({ success: true, playlistInfo: playlistObj, playlistId: firestoreId });
      }
    })
    .catch((error) => {
      // console.error("Error adding document: ", error);
      reject({ error });
    });
});

export const createPlaylistPersonal = (playlistInfo, playlistId) => async dispatch => {
  // const db = await loadDB();
  dispatch({
    type: ADD_PLAYLIST_SUCCESS,
    payload: {
      [`${playlistId}`]: playlistInfo
    }
  });
};

export const editPlaylist = (userUID, playlistInfoInit, firestoreId, imageUrl) => new Promise((resolve, reject) => {
  const playlistInfo = { ...playlistInfoInit };
  // const db = await loadDB();
  console.log(userUID);
  console.dir(playlistInfo);
  let playlistOwnedObj = {};
  if (typeof playlistInfo !== 'undefined' && typeof playlistInfo.name !== 'undefined') {
    // const now = new Date();
    // const timeAdd = Math.round(now.getTime() / 1000);
    // const userIdAdd = getIdFromUID(userUID);
    // const nameAdd = playlistInfo.name.replace(/ /g, '-');
    // const playlistId = nameAdd + '-' + timeAdd + userIdAdd;
    // const playlistId = nameAdd + '-' + timeAdd + userIdAdd;
    playlistOwnedObj = {
      // slug: playlistInfo.slug,
      name: playlistInfo.name
    }
  }
  let loadInt = 0;
  console.dir(imageUrl);
  if (imageUrl) {
    const imageUploadUrl = `${serverApiEurope}uploadPlaylistImage?firestoreId=${firestoreId}`;
    // const imageUploadUrl = `http://localhost:5000/podcastapp-767c2/europe-west1/uploadPlaylistImage?firestoreId=${firestoreId}`;
    tokenFetchPOST(imageUploadUrl, JSON.stringify({ encodedImage: imageUrl }))
      .then(async (dataInit) => {
        // return ({ success: true });
        const data = await dataInit.json();
        console.dir(data);
        if (data['success']) {
          loadInt++;
          if (loadInt >= 2) {
            playlistObj['imageName'] = data.fileName;
            resolve({ success: true, playlistInfo: playlistObj, firestoreId: firestoreId })
          }
        } else {
          console.error("Error adding document2: ");
          reject({ success: false });
          notification['error']({
            message: "Image upload error",
            description: data['message'] || 'Please try uploading image again.',
            duration: null
          });
        }
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        reject({ error });
        notification['error']({
          message: "Image upload error",
          description: error['message'] || 'Please try uploading image again.',
          duration: null
        });
      });
  } else {
    loadInt++;
  }
  var playlistObj = {
    ...playlistOwnedObj,
    description: playlistInfo && playlistInfo.description || '',
    // userUID: userUID,
    dateLastModified: Timestamp.fromDate(new Date()),  //firebase.firestore.FieldValue.serverTimestamp(),
    // active: true,
    // podcastsSuperset: []
  };

  setDoc(doc(firestore, 'playlists', firestoreId), playlistObj, { merge: true })
    .then(() => {
      // console.dir(playlistOwnedObj);
      logPlaylist(firestoreId, 2);
      loadInt++;
      if (loadInt >= 2) {
        resolve({ success: true, playlistInfo: playlistObj, firestoreId: firestoreId })
      }
    })
    .catch((error) => {
      // console.error("Error adding document: ", error);
      reject({ error });
    });
});

export const editPlaylistPersonal = (playlistInfo, playlistId) => async dispatch => {
  // const db = await loadDB();
  console.dir(playlistInfo);

  if (typeof playlistInfo.name !== 'undefined') {
    dispatch({
      type: EDIT_PLAYLIST_SUCCESS,
      payload: {
        [`${playlistId}`]: playlistInfo
      }
    });
  }
};

export const addPlaylistWebsite = (playlistId, website) => dispatch => {
  const playlistInfoEditObj = {
    'website': website,
    'websiteApproved': false
  };
  dispatch({
    type: EDIT_PLAYLIST_SUCCESS,
    payload: {
      [`${playlistId}`]: playlistInfoEditObj
    }
  });
  // dispatch(
  //     EditPlaylistSuccessAction({'${playlistId}': playlistInfoEditObj}));
  setDoc(doc(firestore, 'playlists', playlistId), playlistInfoEditObj, { merge: true });

  tokenFetch(
    `${serverApiEurope}sendWebsiteLinkEmail?firestoreId=${playlistId}`)
    .then((value) => { });
}

export const togglePlaylistPrivacy = (playlistId, type) => async dispatch => {
  // const db = await loadDB();
  const playlistInfoEditObj = { type: type };
  console.dir(playlistInfoEditObj);

  dispatch({
    type: EDIT_PLAYLIST_SUCCESS,
    payload: {
      [`${playlistId}`]: playlistInfoEditObj
    }
  });
  setDoc(doc(firestore, 'playlists', playlistId), playlistInfoEditObj, { merge: true });
  logPlaylistPublic(playlistId, type == 'public' ? 1 : 0);
};

export const togglePlaylistCollaborative = (playlistId, isCollab, collabKey) => async dispatch => {
  // const db = await loadDB();
  const playlistInfoEditObj = { collaborative: isCollab };
  if (collabKey) {
    playlistInfoEditObj['collaborativeKey'] = createRandomShortString(9);
  }
  console.dir(playlistInfoEditObj);

  dispatch({
    type: EDIT_PLAYLIST_SUCCESS,
    payload: {
      [`${playlistId}`]: playlistInfoEditObj
    }
  });
  setDoc(doc(firestore, 'playlists', playlistId), playlistInfoEditObj, { merge: true });
  logPlaylistCollaborative(playlistId, isCollab ? 1 : 0);
};

export const deletePlaylist = (firestoreId) => new Promise((resolve, reject) => {
  console.dir(firestoreId);
  const playlistId = firestoreId;
  logPlaylist(firestoreId, 0);
  deleteDoc(doc(firestore, 'playlists', playlistId))
    .then(() => {
      resolve({ success: true, playlistId: playlistId });
      // dispatch({
      //   type: DELETE_PLAYLIST_SUCCESS,
      //   payload: playlistId
      // });
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      reject({ error })
    });
});

export const deletePlaylistPersonal = (playlistId) => async dispatch => {
  dispatch({
    type: DELETE_PLAYLIST_SUCCESS,
    payload: playlistId
  });
  dispatch({
    type: DELETE_PLAYLISTS_MAIN,
    payload: playlistId
  });
};

export const deletePlaylistImage = (playlistId) => async dispatch => {
  const imageDeleteUrl = `${serverApiEurope}deletePlaylistImage?firestoreId=${playlistId}`;
  dispatch({
    type: EDIT_PLAYLIST_SUCCESS,
    payload: {
      [`${playlistId}`]: { 'imageName': '' }
    }
  });
  tokenFetch(imageDeleteUrl)
    .then(async (dataInit) => {
      // return ({ success: true });
      const data = await dataInit.json();
      console.dir(data);
      if (!data['success']) {
        console.error("Error adding document2: ");
        notification['error']({
          message: "Image deletetion error",
          description: 'Please refresh page and try deleting image again.',
          duration: null
        });
      }
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      notification['error']({
        message: "Image deletetion error",
        description: 'Please refresh page and try deleting image again.',
        duration: null
      });
    });
};

export const addToPlaylist = (episodeInfo, firestoreId) => async (dispatch, getState) => {
  // const db = await loadDB();
  // console.log(episodeInfo);
  // console.dir(firestoreId);
  let userDetails = getState().userDetails;
  let guidCleaned;
  if (typeof episodeInfo.guid !== 'undefined') {
    guidCleaned = episodeInfo.guid; // cleanFirebaseId(episodeInfo.guid);
  } else {
    return;
  }
  const episodeId = guidCleaned;
  const now = new Date();
  // const millisToSec = Math.floor(now.getTime() / 1000);
  let episodeObj = {
    ...createEpisodeObj(episodeInfo),
    dateAdded: Timestamp.fromDate(new Date()), // firebase.firestore.FieldValue.serverTimestamp(),
    ordinalityDate: now.getTime(),
    userUID: userDetails.uid,
    username: userDetails.name
  };
  let episodesUpdate = {};
  let temp = {};
  temp[`${episodeId}`] = episodeObj;
  episodesUpdate = { 'episodes': temp };

  let playlistMainObj = {};
  playlistMainObj[`${firestoreId}`] = episodesUpdate;
  dispatch({
    type: ADD_TO_PLAYLISTS_MAIN,
    payload: playlistMainObj
  });
  dispatch({
    type: ADD_GUID_TO_PLAYLIST_MAIN,
    guid: episodeInfo['guid'],
    firestoreId: firestoreId
  });
  addGUIDToPlaylistMain(episodeInfo.guid, firestoreId, episodeInfo.collectionId);
  logEpisodeInPlaylist(episodeInfo.guid, episodeInfo.collectionId, 1);
  setDoc(doc(firestore, `playlists/${firestoreId}/details`, 'episodes'), episodesUpdate, { merge: true })
    .then(() => {

    })
    .catch((error) => {
      console.error("Error adding document: ", error);
      // dispatch({
      //   type: SUBSCRIBE_PODCAST_FAILURE,
      //   payload: null
      // });
      notification['error']({
        message: 'Error adding adding episode to playlist.',
        description: 'Please try again.',
        duration: null
      });
    });
};

export const addGUIDToPlaylistMain = (guid, firestoreId, collectionId) => {
  let guidArrayUpdate = {};
  // let temp = {};
  // temp[`${episodeId}`] = episodeObj;
  // guidArrayUpdate = { 'guidArray': temp };
  guidArrayUpdate['guidEpisodeArray'] = arrayUnion(guid);
  let temp = {};
  temp[`${collectionId}`] = increment(1);
  guidArrayUpdate['podcastsSuperset'] = temp;
  guidArrayUpdate['podcastsSupersetList'] = arrayUnion(parseInt(collectionId));

  setDoc(doc(firestore, 'playlists', firestoreId), guidArrayUpdate, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
}

export const deleteFromPlaylist = (firestoreId, episodeInfo) => async dispatch => {
  // const db = await loadDB();
  // console.log(firestoreId);
  // console.log(episodeInfo);
  var guidCleaned
  if (typeof episodeInfo.guid !== 'undefined') {
    guidCleaned = episodeInfo.guid; // cleanFirebaseId(episodeInfo.guid);
  }
  const episodeId = guidCleaned; // collectionId + '_episode_' + guidCleaned;
  var episodesUpdate = {};
  let temp = {};
  temp[`${episodeId}`] = deleteField();
  episodesUpdate['episodes'] = temp;
  console.dir(episodesUpdate);
  let collectionId = episodeInfo['collectionId'] || '';
  deleteGUIDFromPlaylistMain(episodeInfo.guid, firestoreId, collectionId);
  dispatch({
    type: DELETE_FROM_PLAYLISTS_MAIN,
    payload: { firestoreId, episodeId }
  });
  dispatch({
    type: DELETE_GUID_FROM_PLAYLIST_MAIN,
    guid: episodeInfo['guid'],
    firestoreId: firestoreId
  });
  logEpisodeInPlaylist(episodeInfo.guid, collectionId, 0);
  setDoc(doc(firestore, `playlists/${firestoreId}/details`, 'episodes'), episodesUpdate, { merge: true })
    .then(() => {
      // deleteGUIDFromPlaylistMain(episodeInfo.guid, firestoreId);
      // dispatch({
      //   type: DELETE_FROM_PLAYLISTS_MAIN,
      //   payload: { firestoreId, episodeId }
      // });
      // dispatch({
      //   type: DELETE_GUID_FROM_PLAYLIST_MAIN,
      //   guid: episodeInfo['guid'],
      //   firestoreId: firestoreId
      // });
    })
    .catch((error) => {
      console.error("Error deleting episode: ", error);
      // reject({ success: false, error: error });
    });
};

export const deleteGUIDFromPlaylistMain = (guid, firestoreId, collectionId) => {
  let guidArrayUpdate = {};
  // let temp = {};
  // temp[`${episodeId}`] = episodeObj;
  // guidArrayUpdate = { 'guidArray': temp };
  guidArrayUpdate['guidEpisodeArray'] = arrayRemove(guid);
  guidArrayUpdate['podcastsSuperset'] = { [`${collectionId}`]: increment(-1) };

  setDoc(doc(firestore, 'playlists', firestoreId), guidArrayUpdate, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
}

export const deleteAllFromPlaylist = (firestoreId) => async dispatch => {
  // var guidCleaned
  // if (typeof episodeInfo.guid !== 'undefined') {
  //   guidCleaned = episodeInfo.guid; // cleanFirebaseId(episodeInfo.guid);
  // }
  // const episodeId = guidCleaned; // collectionId + '_episode_' + guidCleaned;
  let episodesUpdate = {};
  // let temp = {};
  // temp[`${episodeId}`] = firebase.firestore.FieldValue.delete();
  episodesUpdate['episodes'] = deleteField();
  console.dir(episodesUpdate);
  deleteAllGUIDFromPlaylistMain(firestoreId);
  dispatch({
    type: DELETE_ALL_FROM_PLAYLISTS_MAIN,
    payload: firestoreId
  });
  setDoc(doc(firestore, `playlists/${firestoreId}/details`, 'episodes'), episodesUpdate, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error deleting episode: ", error);
      // reject({ success: false, error: error });
    });
}

export const deleteAllGUIDFromPlaylistMain = (firestoreId) => {
  let guidArrayUpdate = {};
  // let temp = {};
  // temp[`${episodeId}`] = episodeObj;
  // guidArrayUpdate = { 'guidArray': temp };
  guidArrayUpdate['guidEpisodeArray'] = deleteField();
  guidArrayUpdate['podcastsSuperset'] = deleteField(); // { [`${collectionId}`]: firebase.firestore.FieldValue.increment(-1) };

  setDoc(doc(firestore, `playlists`, firestoreId), guidArrayUpdate, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error adding document: ", error);
    });
}

export const playlistSortOrderChanged = (firestoreId, sortOrder) => async (dispatch, getState) => {
  const userDetails = getState().userDetails;
  let playlistUpdate = { 'sortOrder': sortOrder };
  dispatch({
    type: PLAYLIST_SORT_ORDER_CHANGED,
    firestoreId: firestoreId,
    sortOrder: sortOrder
  });
  console.dir(playlistUpdate);
  const userPlaylists = getState().userPlaylists;
  let playlistInfo = userPlaylists[`${firestoreId}`];
  if (isMyPlaylist(userDetails, playlistInfo)) {
    setDoc(doc(firestore, `playlists/${firestoreId}/details`, 'episodes'), playlistUpdate, { merge: true })
      .then(() => {
      })
      .catch((error) => {
        console.error("Error deleting episode: ", error);
        // reject({ success: false, error: error });
      });
  }
}

export const playlistEpisodeOrdinalityChanged = (firestoreId, episodeGUID, newOrdinalityDate) => async dispatch => {
  let episodeUpdate = {};
  episodeUpdate[`${episodeGUID}`] = { 'ordinalityDate': newOrdinalityDate };
  dispatch({
    type: EPISODE_ORDINALITY_CHANGED,
    firestoreId: firestoreId,
    episodeGUID: episodeGUID,
    newOrdinalityDate: newOrdinalityDate
  });
  console.dir(episodeUpdate);
  return setDoc(doc(firestore, `playlists/${firestoreId}/details`, 'episodes'), { 'episodes': episodeUpdate }, { merge: true })
    .then(() => {
    })
    .catch((error) => {
      console.error("Error deleting episode: ", error);
      // reject({ success: false, error: error });
    });
}

export const fetchFollowedPlaylists = async (firestoreIds) => {
  const followedPlaylistsData = getFollowedPlaylistsData();
  const promises = [];
  const followedPlaylists = {};
  console.dir(firestoreIds);
  try {
    for (const firestoreId of firestoreIds) {
      if (firestoreId in followedPlaylistsData) {
        followedPlaylists[firestoreId] = followedPlaylistsData[firestoreId] || {};
      } else {
        const docSnapshot = getDoc(doc(firestore, `playlists`, firestoreId)).catch(err => { return {}; });
        promises.push(docSnapshot);
      }
    }
    const docSnapshots = await Promise.all(promises);
    for (const doc of docSnapshots) {
      if (doc.exists()) {
        followedPlaylists[doc.id] = doc.data();
        setFollowedPlaylistsData(doc.id, doc.data());
      }
    }
  } catch (err) {
    console.error(err);
  }
  return followedPlaylists;
}

export const followPlaylist = (userDetails, firestoreId) => async (dispatch) => {
  const userUID = userDetails['uid'];
  let playlistUpdate = {};
  playlistUpdate[`${firestoreId}`] = true;
  dispatch({
    type: FOLLOW_PLAYLIST,
    firestoreId: firestoreId
  });
  console.dir(playlistUpdate);
  setDoc(doc(firestore, 'podcastTags', userUID), { 'followedPlaylists': playlistUpdate }, { merge: true })
    .catch((error) => {
      console.error("Error following playlist: ", error);
      // reject({ success: false, error: error });
    });
  await fetch(`${serverApiEurope}incrementPlaylistFollowers?firestoreId=${firestoreId}&increment=true`);
}
export const unfollowPlaylist = (userDetails, firestoreId) => async (dispatch) => {
  const userUID = userDetails['uid'];
  let playlistUpdate = {};
  playlistUpdate[`${firestoreId}`] = deleteField();
  dispatch({
    type: UNFOLLOW_PLAYLIST,
    firestoreId: firestoreId
  });
  console.dir(playlistUpdate);
  setDoc(doc(firestore, 'podcastTags', userUID), { 'followedPlaylists': playlistUpdate }, { merge: true })
    .catch((error) => {
      console.error("Error following playlist: ", error);
      // reject({ success: false, error: error });
    });
  await fetch(`${serverApiEurope}incrementPlaylistFollowers?firestoreId=${firestoreId}&increment=false`);
}

export const fetchCollaborativePlaylists = () => async dispatch => {
  // try {
  //   // const url = `http://localhost:5000/podcastapp-767c2/europe-west1/getCollaboratePlaylists`;
  //   const url = `${serverApiEurope}getCollaboratePlaylists?t=1`;
  //   console.dir(url);
  //   const res = await tokenFetch(url);
  //   const json = await res.json();
  //   console.dir(json);
  //   if (json.success) {
  //     dispatch({
  //       type: FETCH_PLAYLISTS_SUCCESS,
  //       payload: json.userPlaylists
  //     });
  //   }
  // } catch (err) {
  //   console.dir(err);
  // }
};

const collabsMap = {};
export const fetchCollaboratorsPlaylist = async (collabKey) => {
  if (collabsMap[collabKey]) {
    return collabsMap[collabKey];
  }
  // try {
  //   // const url = `http://localhost:5000/podcastapp-767c2/europe-west1/getCollaboratePlaylists`;
  //   const url = `${serverApiEurope}collaboratorsByKey?key=${collabKey}`;
  //   const res = await fetch(url);
  //   const json = await res.json();
  //   console.dir(json);
  //   if (json.success) {
  //     collabsMap[collabKey] = json.collaborators;
  //     return json.collaborators;
  //   }
  //   throw 'err';
  // } catch (err) {
  //   console.dir(err);
  //   throw 'err';
  // }
};

export const collaboratePlaylist = (userDetails, collabKey) => async (dispatch) => {
  const userUID = userDetails['uid'];
  const hide = message.loading('Please wait...', 0);
  try {
    // const url = `http://localhost:5000/podcastapp-767c2/europe-west1/playlistCollaborateToggle?key=${collabKey}&collaborate=true`;
    const url = `${serverApiEurope}playlistCollaborateToggle?key=${collabKey}&collaborate=true`;
    const res = await tokenFetch(url);
    const json = await res.json();
    hide();
    console.dir(json);
    dispatch({
      type: COLLABORATIVE_PLAYLIST,
      playlistId: json.firestoreId,
      userUID
    });
  } catch (err) {
    hide();
    notification['error']({
      message: err.error || "There was an error adding you as a collaborator.",
      description: '',
      duration: null
    });
  }
}
export const uncollaboratePlaylist = (userDetails, collabKey) => async (dispatch) => {
  const userUID = userDetails['uid'];
  const hide = message.loading('Please wait...', 0);
  try {
    // const url = `http://localhost:5000/podcastapp-767c2/europe-west1/playlistCollaborateToggle?key=${collabKey}&collaborate=false`;
    const url = `${serverApiEurope}playlistCollaborateToggle?key=${collabKey}&collaborate=false`;
    const res = await tokenFetch(url);
    const json = await res.json();
    hide();
    console.dir(json);
    dispatch({
      type: UNCOLLABORATIVE_PLAYLIST,
      playlistId: json.firestoreId,
      userUID
    });
  } catch (err) {
    hide();
    notification['error']({
      message: err.error || "There was an error removing you as a collaborator.",
      description: '',
      duration: null
    });
  }
}