import { doc, getDoc, onSnapshot, setDoc, serverTimestamp } from "firebase/firestore/lite";
// import { db } from "./firebase"; // Ensure you initialize Firebase and export `db` from this file
// import { requestedBooksNotifier, bookRequestNotifier } from "./notifiers"; // Replace with your state management logic
// import utils from "./utils"; // Replace with your utility functions
// import { firestore } from "../lib/db";
import { auth, firestore } from '../lib/db';
import { ADD_REQUESTED_BOOKS, FETCH_REQUESTED_BOOKS } from "./types";

// Function to fetch request details
export const getRequestsDetails = (userId) => async (dispatch, getState) => {
    // let userDetails = getState().userDetails; // userDetailsStateSelector(store.state);

    // const userId = userDetails && userDetails.uid; // Replace with your logic to get Firebase UID
    // if (!userId) {
    //     return;
    // }
    // const userDoc = doc(firestore, "requests", userId);
    console.dir(userId);
    // getDoc(userDoc, (doc) => {
    getDoc(doc(firestore, `requests`, userId)).then(doc => {
        if (doc.exists()) {
            const dataAll = doc.data();
            console.dir(dataAll);
            const requests = dataAll.requests || {};
            let mostRecentUncheckedKey = null;

            if (Object.keys(requests).length > 0) {
                const requestedBooksMap = {};

                Object.entries(requests).forEach(([key, bookMap]) => {
                    const collectionId = bookMap.collectionId ?? -1 * bookMap.time;
                    requestedBooksMap[collectionId] = { ...bookMap, key };

                    // const dateTime = new Date(bookMap.time);
                    // const pastDays = utils.daysInPast(dateTime);

                    const shouldUpdateMostRecent =
                        [2, 3, 9].includes(bookMap.state) &&
                        (Date.now() - bookMap.time < 14 * 24 * 3600 * 1000) &&
                        (!mostRecentUncheckedKey ||
                            bookMap.time > requests[mostRecentUncheckedKey]?.time);

                    if (shouldUpdateMostRecent) {
                        mostRecentUncheckedKey = key;
                    }
                });

                // // Update global state or UI as needed
                // requestedBooksNotifier.setValue({
                //     ...requestedBooksNotifier.getValue(),
                //     ...requestedBooksMap,
                // });
                dispatch({
                    'type': FETCH_REQUESTED_BOOKS,
                    'payload': requestedBooksMap,
                });

                //// ignore this for now
                // if (
                //     mostRecentUncheckedKey &&
                //     !requests[mostRecentUncheckedKey]?.checked
                // ) {
                //     bookRequestNotifier.setValue(requests[mostRecentUncheckedKey]);
                // }
            }
        } else {
            console.dir('no doc');
        }
    }).catch(err => {
        console.dir(err);
    });
};

// Function to add a request detail
export const addToRequestsDetails = (
    collectionName,
    artistName,
    timeNow,
    collectionId, type = 1
) => async (dispatch, getState) => {
    // const userId = utils.getFirebaseUID(); // Replace with your logic to get Firebase UID
    let userDetails = getState().userDetails; // userDetailsStateSelector(store.state);

    const userId = userDetails && userDetails.uid; // Replace with your logic to get Firebase UID
    if (!userId) {
        return;
    }
    console.dir(userId);
    // const userDoc = doc(firestore, "requests", userId);
    const userDoc = doc(firestore, `requests`, userId);
    console.dir(userId);

    const postData = {
        collectionName,
        artistName,
        state: 1,
        type,
        time: timeNow,
    };
    if (collectionId != null) {
        postData['collectionId'] = collectionId;
    }

    const collectionKey = collectionId ?? -1 * timeNow;

    // // Update local/global state
    // requestedBooksNotifier.setValue({
    //     ...requestedBooksNotifier.getValue(),
    //     [collectionKey]: postData,
    // });
    dispatch({
        'type': ADD_REQUESTED_BOOKS,
        'payload': postData,
        'key': collectionKey
    });
    console.dir(userId);

    // Add to Firestore
    await setDoc(
        userDoc,
        {
            requests: {
                [timeNow]: postData,
            },
        },
        { merge: true }
    );
};

// // Function to mark a request as checked
// export const markCheckedRequest = async (key) => {
//     // const userId = utils.getFirebaseUID(); // Replace with your logic to get Firebase UID
//     let userDetails = getState().userDetails; // userDetailsStateSelector(store.state);

//     const userId = userDetails && userDetails.uid; // Replace with your logic to get Firebase UID
//     if (!userId) {
//         return;
//     }
//     const userDoc = doc(firestore, "requests", userId);

//     await setDoc(
//         userDoc,
//         {
//             requests: {
//                 [key]: { checked: true },
//             },
//         },
//         { merge: true }
//     );
// };

// Helper constant for request messages
export const bookRequestMessage = {
    1: "Requested",
    2: "Process Started",
    3: "Completed",
    9: "Rejected",
};