import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import { getFirebaseUser, podcastTypes, sleep } from '../../lib/utils'
import { Col, Row, Button } from 'antd';
import InterestOption from './InterestOption';
import { saveUserInterests } from '../../actions';
import { superGenres } from '../../lib/genres';
import LoadingIntro from './LoadingIntro';
import { createNewUserRecommendations, getRecommendations } from '../../actions/RecommendationActions';

class Interests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGenreIds: { ...props.userInterests }, showError: false,
            stage: 1
        }
    }

    selectOption = (index) => {
        const { selectedGenreIds } = this.state;
        const genreId = superGenres()[index].id;
        if (genreId in selectedGenreIds) {
            delete selectedGenreIds[genreId];
            this.setState({
                selectedGenreIds
            });
        } else {
            selectedGenreIds[genreId] = true;
            this.setState({
                selectedGenreIds
            });
        }
    }

    createInterestOptions = () => {
        const { selectedGenreIds } = this.state;
        let rows = [];
        for (let i = 0; i < superGenres().filter(el => !el.deprecated).length; i++) {
            // const row = <Row key={i}>
            //     <Col span={8}>
            //         <InterestOption selectedGenreIds={selectedGenreIds} genreMap={podcastTypes[i]} selectOption={this.selectOption.bind(this, i)} />
            //     </Col>
            //     <Col span={8}>
            //         <InterestOption selectedGenreIds={selectedGenreIds} genreMap={podcastTypes[i + 1]} selectOption={this.selectOption.bind(this, i + 1)} />
            //     </Col>
            //     <Col span={8}>
            //         <InterestOption selectedGenreIds={selectedGenreIds} genreMap={podcastTypes[i + 2]} selectOption={this.selectOption.bind(this, i + 2)} />
            //     </Col>
            // </Row>;
            const row = <InterestOption selectedGenreIds={selectedGenreIds} genreMap={superGenres()[i]} selectOption={this.selectOption.bind(this, i)} />

            rows.push(row);
        }
        return rows;
    }

    cancel = () => {
        this.props.handleClose();
    }

    handleOk = async () => {
        const { isEdit, userDetails } = this.props;
        const { selectedGenreIds } = this.state;
        console.dir(Object.keys(selectedGenreIds));
        if (Object.keys(selectedGenreIds).length < 2 || Object.keys(selectedGenreIds).length > 4) {
            this.setState({ showError: true });
            return;
        }
        if (isEdit) {
            this.props.saveUserInterests(selectedGenreIds);
        } else {
            // handled in parent
            //// but also doing here for new recommendations
            this.props.saveUserInterests(selectedGenreIds);

            this.setState({ stage: 2 });
            await sleep(1000);
            await createNewUserRecommendations();
            this.setState({ stage: 3 });
            this.props.getRecommendations(getFirebaseUser().uid);
            await sleep(2000);
        }

        this.props.handleClose(selectedGenreIds);
    }

    render() {
        const { isEdit } = this.props;
        const { selectedGenreIds, showError, stage } = this.state;
        console.dir(selectedGenreIds);

        if (stage == 2 || stage == 3) {
            return (<LoadingIntro stage={stage} />)
        }

        return (
            <div style={{
                textAlign: 'left', height: '600px',
                overflow: 'auto'
            }}>
                <Row>
                    <Col xs={16} md={14}>
                        <h4>What are your goals&#63;</h4>
                        <br />
                    </Col>
                    <Col md={5}>
                        {isEdit ? <Button onClick={this.cancel}>Cancel</Button> : ' '}
                    </Col>
                    <Col md={5}>
                        <Button type='primary' style={{ minWidth: '64px' }} onClick={this.handleOk}>Ok</Button>
                    </Col>
                </Row>
                <p style={{ color: showError && 'red' }}>Please choose 2 to 4 goals for precise recommendations.</p>
                {this.createInterestOptions()}
            </div>
        )
    }
}

Interests.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    userDetails: PropTypes.object,
    userInterests: PropTypes.object,
    loadingState: PropTypes.object,
    fetchError: PropTypes.object,
    saveUserInterests: PropTypes.func,
    getRecommendations: PropTypes.func,
    handleClose: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        loadingState: state.loadingState,
        userInterests: state.userInterests,
        fetchError: state.errorsState,
    }
}

// export default withRedux(initStore, null, null)(Personal)
const mapDispatchToProps = (dispatch) => {
    return {
        saveUserInterests: bindActionCreators(saveUserInterests, dispatch),
        getRecommendations: bindActionCreators(getRecommendations, dispatch),
        // getEpisodeReviews: bindActionCreators(getEpisodeReviews, dispatch),
        // getMoreEpisodeReviews: bindActionCreators(getMoreEpisodeReviews, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Interests);