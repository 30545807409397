// import { Howl, Howler } from 'howler';
// import Plyr from 'plyr';
import { sendAudioInfo, playAudioBool, setEpisodeProgress, addEpisodeToRecent } from './index';
import {
    getProgressFromGUID, audioLoadTimeInMillis, cleanGUID, print, isLocalStoragePresent, playedProgressLimit,
    saveLocally, retrieveLocally, sortByTimeAsc, sortByTimeDesc, getCollectionPlaybackRate, showVideo, areSameMediaType, setProgress, getMyFileFullUrl,
    audioCDNUrl
} from '../lib/utils';
// import { throttle } from '../lib/debounce';
// import { notification } from 'antd';
import { AUTO_SKIP_EPISODES, AUTO_PLAY_EPISODES, QUEUE, AUTO_PLAYLIST_ID, RESTORE_STATE_MAP, PLAY_AUTO_NEXT_UP } from '../lib/strings';
import { logPlayAudio } from '../lib/analyticsEvents';
import { getNextEpisodeFromPlaylist, getManualQueueEpisodes, getSkipEndingInSec } from '../lib/fileUtils';
import { addToAutoQueueInfo, deleteFromManualQueueInfo } from './QueueActions';
import { fetchEpisodeBookmarks } from './BookmarkActions';

import sentry from '../utils/sentry';
const { Sentry } = sentry({ release: process.env.SENTRY_RELEASE })

let audioPlayer;
let audioLoadTimer;
export let maxDuration;
let firstTimeAppLoad = true;
let _sliderThrottleVal;
// let _playbackRate = 1;
let firstSetUrlOnline = false;
let audioListenersAreSet = false;
let oldAudioInfo;
// let _volume = 0.7;

let episodesToPlayMain;

let justStartedPlaying = false;

const callbackForRestore = (guid, collectionId) => {
    // utils.printLog('in callback00');
    // final String guid = mediaItem.valueWrapper.value.extras['guid'] ?? '';
    // final String collectionId =
    //     mediaItem.valueWrapper.value.extras['collectionId'] ?? '-1';
    if (audioPlayer && audioPlayer.playing) {
        const progress = setProgress(
            (audioPlayer.currentTime || 0) /
            (maxDuration || 3600) *
            100);
        const toSave = {
            'guid': guid,
            'collectionId': collectionId,
            'progress': progress,
            'updateTime': Date.now()
        };
        // utils.printLog(toSave.toString());
        saveLocally(RESTORE_STATE_MAP, JSON.stringify(toSave));
    }
    //     SharedPreferences.getInstance().then((SharedPreferences prefs) =>
    // prefs.setString(RESTORE_STATE_MAP, jsonEncode(toSave)));
}

// ThunkAction<AppState> initAudioPlayer() {
//     return (Store<AppState> store) async {
export const initAudioPlayer = () => async (dispatch, getState) => {
    if (audioListenersAreSet) {
        return;
    }
    audioListenersAreSet = true;
    // audioPlayer = new Howl({
    //     src: ['sound.mp3']
    // });
    // store.dispatch(initPlatformState());

    /******* audio player listeners */
    console.log('start initAudioPlayer');

    const onLoadeddata = (data) => {
        console.dir('load');
        console.dir(data);
        // _sliderValue = 0;
        let audioInfo = getState().audioState; // audioStateSelector(store.state);
        let subscribedPodcasts = getState().podcastState; // subscribedPodcastsSelector(store.state);
        console.dir(audioInfo);

        /// check for skip beginning
        let skipBeginningInSeconds = 0;
        if (`${audioInfo.collectionId}` in subscribedPodcasts) {
            skipBeginningInSeconds = subscribedPodcasts[`${audioInfo.collectionId}`]['skipBeginningInSeconds'] || 0;
        }
        let personalValues = getState().personalValues; // personalValuesSelector(store.state);
        // print(d.inSeconds);
        // console.log('Max duration maxDurationListened listen : ' +
        //     maxDurationListened);
        // if (!maxDurationListened) {
        // maxDurationListened = true;

        // final String guidCleaned = utils.cleanFirebaseId(audioInfo.guid);
        // final sliderValue = audioInfo != null &&
        //         personalValues != null &&
        //         personalValues.containsKey(guidCleaned) &&
        //         personalValues[guidCleaned].containsKey('progress') &&
        //         personalValues[guidCleaned]['progress'] != null
        //     ? personalValues[guidCleaned]['progress'].toDouble()
        //     : 0.0;
        console.dir(personalValues);
        let sliderValue =
            getProgressFromGUID(personalValues, audioInfo.guid);
        console.dir(sliderValue);
        // maxDuration = d.inSeconds ?? 60 * 60;

        // setState(() {
        maxDuration = audioPlayer.duration; // d.inSeconds ?? 0;
        console.dir(maxDuration);
        if (maxDuration < 1) {
            return;
        }
        if (audioLoadTimer != null) {
            clearTimeout(audioLoadTimer);
        }
        // loadingAudio = false;
        dispatch(
            playAudioBool(null, audioInfo.guid, false, maxDuration));
        // });
        print(`Max duration changed: ${audioInfo.title}`);
        print(`Max duration changed: ${audioInfo.title}`);
        print(`Max duration changed: ${maxDuration}`);
        // print(`Max duration changed sliderValue: ' + sliderValue.toString());

        print('setNotification start');
        let sec = Math.round((sliderValue / 100) * maxDuration);

        /// if less than few seconds remaning, start from beginning
        /// do this only if it's not the first time app load,
        /// so that the current playing audio is not disturbed.
        let secondsRemaining = maxDuration - sec;
        if (!firstTimeAppLoad && secondsRemaining < 10) {
            sec = 0;
        }
        firstTimeAppLoad = false;

        if (sec < skipBeginningInSeconds) {
            sec = skipBeginningInSeconds;
        }

        console.dir(sec);
        if (!justStartedPlaying) {
            console.log('start seek');
            dispatch(seekAudio(sec, sliderValue, true));///TODO *******
            justStartedPlaying = true;
            setTimeout(() => justStartedPlaying = false, 1000);
        }

        // seek(sliderValue);
        // }
    }
    audioPlayer.on('loadeddata', onLoadeddata);

    /// TODO: audioplayer onPause, onEnd(complete) events
    let userDetails = getState().userDetails;

    function callback(_sliderValueParam) {
        // print(sliderThrottleVal);
        let audioInfo = getState().audioState; // audioStateSelector(store.state);
        // setState(() {
        // let _sliderValue = _sliderThrottleVal; // receive / total;
        // });
        // print('new audio sliderValue sliderValue : ' + sliderValue.toString());
        // if (_sliderValue != 0) {

        /// TODO: what if userdetails is null
        dispatch(setEpisodeProgress(userDetails && userDetails['uid'], audioInfo, _sliderValueParam));
        // }
        const timeLeftInSec = maxDuration - (audioPlayer.currentTime || 0);
        if (timeLeftInSec > 4 &&
            timeLeftInSec < getSkipEndingInSec(audioInfo.collectionId)) {
            //// set 4 sec just as a buffer
            endAudio();
        }
    }

    // var throttler = Throttler(
    //           const Duration(milliseconds: 1000), callback, ['throttled'], true);
    let onAudioUpdateInterval;
    let onAudioStateRestoreInterval;
    audioPlayer.on('play', () => {
        // print('Current position: ${p.inSeconds}');
        // if (audioPlayerState == AudioPlayerState.PLAYING) {
        console.log('start playing');
        if (audioPlayer.playing) {
            // final maxDuration = maxDuration ?? 60 * 60;
            /// TODO: throttle later
            // throttler.throttle();

            /// clear any existing old intervals first
            if (onAudioUpdateInterval != null) {
                clearInterval(onAudioUpdateInterval);
            }

            onAudioUpdateInterval = setInterval(() => {
                console.log(audioPlayer.currentTime);
                _sliderThrottleVal = 100 * audioPlayer.currentTime / maxDuration;
                // print('_sliderThrottleVal : ' + _sliderThrottleVal);
                if (!isNaN(_sliderThrottleVal)) {
                    callback(_sliderThrottleVal);
                }
            }, 1000);

            let audioInfo = getState().audioState;
            if (onAudioStateRestoreInterval != null) {
                clearInterval(onAudioStateRestoreInterval);
            }

            onAudioStateRestoreInterval = setInterval(() => {
                console.dir(audioInfo.collectionId);
                callbackForRestore(audioInfo.guid, audioInfo.collectionId);
            }, 10000);
        }
        // print('new audio sliderValue sliderThrottleVal : ' +
        //     sliderThrottleVal.toString());
    });
    audioPlayer.on('pause', () => {
        if (onAudioUpdateInterval != null) {
            clearInterval(onAudioUpdateInterval);
        }
    });
    audioPlayer.on('ended', () => {
        endAudio();
    });

    const endAudio = () => {
        if (onAudioUpdateInterval != null) {
            clearInterval(onAudioUpdateInterval);
        }
        dispatch(pauseAudio(false));
        console.dir('endAudio0');
        // let onComplete = localStorage.getItem(EPISODE_COMPLETE_OPTION_INDEX) !== null ? parseInt(localStorage.getItem(EPISODE_COMPLETE_OPTION_INDEX)) : 0;
        let autoPlay = localStorage.getItem(AUTO_PLAY_EPISODES) !== null ? (localStorage.getItem(AUTO_PLAY_EPISODES) === 'true') : true;
        if (autoPlay) {
            console.dir('endAudio1');
            getNextEpisodeToPlay(dispatch, getState)
                .then((nextEpisodeToPlay) => {
                    if (nextEpisodeToPlay != null) {
                        console.dir('endAudio2');
                        dispatch(playNextAudioAction(nextEpisodeToPlay));
                    } else {
                        dispatch(pauseAudio());
                    }
                }).catch((err) => {
                    console.error(err);
                    dispatch(pauseAudio());
                });
        } else {
            dispatch(pauseAudio());
        }
    }

    audioPlayer.on('error', (error) => {
        // print(`audioPlayer error : ${msg}`);
        onError(error);
    });
    // audioPlayer.on('playerror', () => {
    //     // print('audioPlayer error 22 : $msg');
    //     onError();
    // });
    function onError(error) {
        // dispatch(pauseAudio());
        // notification['error']({
        //     message: "This episode can't be played.",
        //     description: 'Please try another episode or disable VPN and try again (if you use VPN).',
        //     duration: null
        // });
        // Sentry.captureException(error);
        Sentry.captureException(JSON.stringify(error));
        // dispatch(ErrorAudioAction({
        //     'errorAudioAction': {
        //         'error': true,
        //         'code': DEFAULT_ERROR,
        //         'message':
        //             'There was an error playing this episode. Please try another episode or disable VPN if you use one.'
        //     }
        // }));
    }
}

export const setUrlAudio = async (audioInfo, myFiles) => {
    // export async function setUrlAudio(kUrl) {
    // print('Max duration setUrl 2 : ' + kUrl);
    try {
        console.dir('setUrlAudio');
        console.dir(audioInfo?.enclosure?.link);
        console.dir(document.getElementById('main-player'));
        // audioPlayer = null;
        console.dir(audioPlayer);
        const Plyr = (await import('plyr')).default;
        if (!audioPlayer || !areSameMediaType(oldAudioInfo, audioInfo)) {
            console.dir('in main audioPlayer');
            console.dir(audioPlayer);
            audioPlayer = new Plyr('#main-player');
        }
        oldAudioInfo = audioInfo;
        // audioPlayer.options = options;
        console.dir(audioPlayer);

        let uri = audioCDNUrl + audioInfo.enclosure.link;
        if (myFiles[audioInfo.guid]) {
            uri = getMyFileFullUrl(myFiles[audioInfo.guid].enclosure.link);
        }
        audioPlayer.source = {
            type: showVideo(audioInfo) ? 'video' : 'audio',
            // title: 'Example title',
            sources: [
                {
                    src: uri || '', //'/path/to/audio.mp3',
                    // type: 'audio/mp3',
                }
            ],
        };
        // audioPlayer = new Howl({
        //     src: [kUrl],
        //     volume: _volume,
        //     html5: true,
        //     preload: true
        // });
        // dispatch(initAudioPlayer());
        return 1;
    } catch (err) {
        print('Max duration setUrlAudio error : ' + err);
        return 0;
    }
}

export const firstSetUrl = (audioInfo) => async (dispatch, getState) => {
    // export async function firstSetUrl(audioInfo) {
    // let userDetails = getState().userDetails;
    // console.dir(userDetails);
    // if (userDetails && userDetails['uid']) {
    if (audioInfo) {
        // let audioUrl = audioInfo.enclosure && audioInfo.enclosure.link && audioInfo.enclosure.link.replace('&amp;', '&');
        // if (episodeDownloaded) {
        //     final bool isThere = await utils.downloadDirExists();
        //     if (isThere) {
        //         final String localUrl = await utils.localUrl(audioInfo?.guid);
        //         try {
        //             await setUrlAudio(localUrl, isLocal: true);
        //         } catch (err) {
        //             print('running locally error : ' + err.toString());
        //         }
        //         print('running locally result : ');
        //     }
        // } else {
        ////// setUrl for http only if there is internet connection.
        if (audioPlayer) {
            // audioPlayer.stop();
            // audioPlayer = null;
        }
        await setUrlAudio(audioInfo, getState().myFileEpisodes);
        dispatch(initAudioPlayer());

        firstSetUrlOnline = true;
        // }
    }
}

export function setVolume(volume) {
    if (audioPlayer) {
        audioPlayer.volume = volume;
        try {
            localStorage.setItem('appVolume', volume);
        } catch (err) {
            console.error(err);
        }
    }
}
export function muteVolume(boolVal) {
    if (audioPlayer) {
        audioPlayer.muted = boolVal;
    }
}

export function setPlaybackRate(playbackRate) {
    // change playback only if already playing otherwise it starts playing
    // _playbackRate = playbackRate;
    // if (isAudioPlaying) {
    if (audioPlayer && typeof audioPlayer !== 'undefined') {
        audioPlayer.speed = playbackRate;
        saveLocally('appPlaybackRate', playbackRate, 24 * 30);
        // try {
        //     localStorage.setItem('appPlaybackRate', playbackRate);
        // } catch (err) {
        //     console.error(err);
        // }
    }
    // } else {
    //     // await Future.delayed(const Duration(seconds: 0), () { });
    //     return;
    // }
}

export const playAppAudio = (audioInfo) => async (dispatch, getState) => {
    // ThunkAction<AppState> playAppAudio(PodcastEpisode audioInfo) {
    // return (Store<AppState> store) async {
    let audioState = getState().audioState; // audioStateSelector(store.state);
    console.dir('playAppAudio1');
    if (audioState != null && audioState.guid == audioInfo.guid) {
        /// its the same audio, so just resume it
        console.dir('playAppAudio2');
        dispatch(resumeAudio(audioInfo));
    } else {
        /// play the new audio here
        // maxDurationListened = false;

        ///   save old audio progress first
        if (audioState != null && audioState.guid) {
            let isAudioPlaying = getState().audioPlayState['playAudio'] || false;
            //   audioPlayStateSelector(store.state)['playAudio'] ?? false;
            if (isAudioPlaying) {
                await audioPlayer.pause(); // this is needed to avoid jerk in audio
            }

            let userDetails = getState().userDetails; // userDetailsStateSelector(store.state);
            if (userDetails != null && userDetails['uid']) {
                console.log('old slider audio title : ' + audioState.title);
                // final String guidCleaned = utils.cleanFirebaseId(audioState.guid);
                let personalValues = getState().personalValues; // personalValuesSelector(store.state);
                let _sliderValue =
                    getProgressFromGUID(personalValues, audioState.guid);
                dispatch(
                    setEpisodeProgress(userDetails['uid'], audioState, _sliderValue, true));
            }
        }

        /// send new audio to redux store
        dispatch(sendAudioInfo(audioInfo));

        /// get new audio progress value
        if (audioInfo != null && audioInfo.guid) {
            setTimeout(() => {
                dispatch(playNewAudio(audioInfo));
            }, 100);
        } else {
            // setState(() {
            audioInfo = null;
            // _sliderValue = 0;
            // });
        }
    }
}

export const playNewAudio = (audioInfo) => async (dispatch, getState) => {
    // ThunkAction<AppState> playNewAudio(PodcastEpisode audioInfo) {
    // return (Store<AppState> store) async {
    // firstPlaying = false;
    // final PodcastEpisode podcastEpisode = widget.audioInformation;
    // dispatch(ErrorAudioAction({
    //     'errorAudioAction': { 'error': false }
    // }));
    // let result = 0;
    // don't have to check internet connection here because it reaches here only after checking in PlayMain.
    audioLoadTimer = setTimeout(() => {
        dispatch(playAudioBool(null, audioInfo.guid, true));
        //   this.setState({ loadingAudio: true });
    }, audioLoadTimeInMillis);

    // let audioUrl = audioInfo.enclosure && audioInfo.enclosure.link && audioInfo.enclosure.link.replace('&amp;', '&');
    // console.log('playPosition audioUrl : ' + audioUrl);
    try {
        await setUrlAudio(audioInfo, getState().myFileEpisodes);
        // store.dispatch(
        //     PlayAudioBoolAction(playAudio: true, guid: audioInfo?.guid));
        if (!audioListenersAreSet) {
            dispatch(initAudioPlayer());
        }
        firstSetUrlOnline = true;
    } catch (err) {
        console.error(err);
    }
    setTimeout(() => {
        dispatch(fetchEpisodeBookmarks(audioInfo));
    }, 500);
}

export const pauseAudio = (saveEpisode = true) => async (dispatch, getState) => {
    // ThunkAction < AppState > pauseAudio({ bool saveEpisode = true }) {
    // return (Store < AppState > store) async {
    // Future<void> pauseAudio() async {
    if (audioPlayer) {
        await audioPlayer.pause();
        let audioInfo = getState().audioState; // audioStateSelector(store.state);
        // final double progress = _sliderValue;
        // final String guidCleaned = utils.cleanFirebaseId(audioInfo.guid);
        let personalValues = getState().personalValues; // personalValuesSelector(store.state);
        // final double _sliderValue = audioInfo != null &&
        //         personalValues != null &&
        //         personalValues.containsKey(guidCleaned) &&
        //         personalValues[guidCleaned].containsKey('progress') &&
        //         personalValues[guidCleaned]['progress'] != null
        //     ? personalValues[guidCleaned]['progress'].toDouble()
        //     : 0.0;
        let _sliderValue =
            getProgressFromGUID(personalValues, audioInfo.guid);
        console.log('paused audio');
        dispatch(playAudioBool(false, audioInfo.guid));
        let userDetails = getState().userDetails; // userDetailsStateSelector(store.state);
        if (userDetails != null && userDetails['uid'] && saveEpisode) {
            dispatch(setEpisodeProgress(userDetails['uid'], audioInfo, _sliderValue, true));
        }
        logPlayAudio(
            audioInfo.guid, audioInfo.collectionId, _sliderValue, 0);
    }
};


// ThunkAction<AppState> seekAudioToProgress(_sliderValue) {
//     return (Store<AppState> store) async {
export const seekAudioToProgress = (_sliderValue, autoSeek = false) => async (dispatch, getState) => {
    let maxDurationFin = maxDuration ?? 60 * 60;
    let seconds = Math.round((_sliderValue ?? 0) * maxDurationFin / 100);
    dispatch(
        seekAudio(seconds, _sliderValue, autoSeek));
};

export const seekAudio = (seconds, _sliderValue, autoSeek = false) => async (dispatch, getState) => {
    // return (Store < AppState > store) async {
    console.dir(_sliderValue);
    let audioInfo = getState().audioState; // audioStateSelector(store.state);
    let userDetails = getState().userDetails;
    // store.dispatch(PlayAudioProgressAction(
    //     playProgress: _sliderValue, guid: audioInfo?.guid));
    console.dir(_sliderValue);
    dispatch(setEpisodeProgress(userDetails && userDetails['uid'], audioInfo, _sliderValue));

    if (audioPlayer) {
        console.dir(seconds);
        audioPlayer.currentTime = seconds;
    }
    console.log('Max duration playAudio out');
    let isAudioPlaying = getState().audioPlayState['playAudio'] || false;
    // audioPlayStateSelector(store.state)['playAudio'] ?? false;
    // store.state.audioPlayState['playAudio'] ?? false;
    console.dir('isAudioPlaying main0: ' + isAudioPlaying);
    if (isAudioPlaying) {
        console.log('Max duration playAudio in');
        await resumeAudioCode(audioInfo.collectionId, autoSeek);
    }
}

export const resumeAudioCode = async (collectionId, alsoPlay = true) => {
    // if (loadingAudio) {
    //   setState(() {
    //     loadingAudio = false;
    //   });
    // }
    console.dir('resumeAudioCode1');
    if (alsoPlay) {
        console.dir('resumeAudioCode2');
        await audioPlayer.play();
    }
    // print('result fin : ' + result.toString());
    let _playbackRate = getCollectionPlaybackRate(collectionId); // retrieveLocally('appPlaybackRate') || 1;
    setPlaybackRate(_playbackRate);
    // return result;
}

export const resumeAudio = (audioInfo) => async (dispatch) => {
    // ThunkAction < AppState > resumeAudio(PodcastEpisode audioInfo) {
    // return (Store < AppState > store) async {
    // dispatch(ErrorAudioAction({
    //     'errorAudioAction': { 'error': false }
    // }));
    // let result = 0;

    if (!firstSetUrlOnline) {
        // firstSetUrl here if not already set.
        dispatch(firstSetUrl(audioInfo));
    }
    await resumeAudioCode(audioInfo.collectionId);
    dispatch(
        playAudioBool(true, audioInfo.guid));

    // final ConnectivityResult connectivityResult =
    //     await Connectivity().checkConnectivity();
    // if (connectivityResult == ConnectivityResult.wifi) {
    //     if (!firstSetUrlOnline) {
    //         // firstSetUrl here if not already set.
    //         await firstSetUrl(audioInfo, episodeDownloaded);
    //     }
    //     result = await resumeAudioCode();
    //     store.dispatch(
    //         PlayAudioBoolAction(playAudio: true, guid: audioInfo?.guid));
    // } else if (connectivityResult == ConnectivityResult.mobile) {
    //     if (!firstSetUrlOnline) {
    //         // firstSetUrl here if not already set.
    //         await firstSetUrl(audioInfo, episodeDownloaded);
    //     }
    //     // final SharedPreferences prefs = await SharedPreferences.getInstance();
    //     // final bool playUsingData = prefs.getBool(PLAY_USING_DATA) ?? false;
    //     // if (playUsingData) {
    //     result = await resumeAudioCode();
    //     store.dispatch(
    //         PlayAudioBoolAction(playAudio: true, guid: audioInfo?.guid));
    // } else {
    //     store.dispatch(pauseAudio());
    //     store.dispatch(ErrorAudioAction({
    //         'errorAudioAction': {
    //             'error': true,
    //             'code': OFFLINE,
    //             'message':
    //                 'This episode is not in your local Downloads. Please turn on your wifi or data to play this episode.'
    //         }
    //     }));
    //     // showOfflineDialog(
    //     //     'This episode is not in your local Downloads. Please turn on your wifi or data to play this episode.');
    // }
}

/// functions to play next episodes

export function saveEpisodesToPlay(episodesToPlayOrg) {
    // episodes = episodesToPlay;
    let episodesToPlay = [];
    episodesToPlayOrg
        .forEach((el) => episodesToPlay.push(el));
    episodesToPlayMain = episodesToPlay;
    console.dir(episodesToPlayMain);
    if (isLocalStoragePresent()) {
        localStorage.setItem('episodesToPlayMain', JSON.stringify(episodesToPlay));
    }

    // save('episodesToPlay', episodesToPlay).catchError((err) => throw err);
}

async function getNextEpisodeToPlay(dispatch, getState) {
    try {
        const audioInformationCurrent = getState().audioState;
        const autoQueuePlaylistId = getState().autoQueuePlaylistIdNotifier || '';
        if (autoQueuePlaylistId && autoQueuePlaylistId != QUEUE) {
            const userPlaylists = getState().userPlaylists;
            const playlist = userPlaylists[autoQueuePlaylistId];
            if (!playlist) {
                return null;
            }
            const isAutoPlaylist = playlist['advancedType'] == AUTO_PLAYLIST_ID;
            if (isAutoPlaylist) {
                const episodesAutoQueue = getState().episodesAutoQueue || [];
                const nextEpisodeToPlay =
                    episodesAutoQueue.length < 1 ? null : episodesAutoQueue[0];
                if (nextEpisodeToPlay == null) {
                    return null;
                }
                const episodesAutoQueueNew = episodesAutoQueue.filter((element) => element.guid != nextEpisodeToPlay.guid);
                dispatch(addToAutoQueueInfo(episodesAutoQueueNew, autoQueuePlaylistId));

                if (nextEpisodeToPlay != null) {
                    return nextEpisodeToPlay;
                }
            } else {
                const userPlaylistsMain = getState().userPlaylistsMain;
                const playlistEpisodeInfo = userPlaylistsMain[autoQueuePlaylistId] || {};
                let sortOrderMain = playlistEpisodeInfo && playlistEpisodeInfo.sortOrder || 'EARLIEST_FIRST';
                let sortOrder = sortOrderMain === 'EARLIEST_FIRST' ? sortByTimeAsc : sortByTimeDesc;
                const episodes = playlistEpisodeInfo && playlistEpisodeInfo.episodes && Object.values(playlistEpisodeInfo.episodes).sort(sortOrder) || [];
                // const episodes = playlistEpisodesSelector(playlistEpisodeInfo);
                if (episodes.length < 1) {
                    return null;
                }
                const audioInformation = getState().audioState;
                const nextEpisodeToPlay = getNextEpisodeFromPlaylist(episodes, audioInformation);
                if (nextEpisodeToPlay != null) {
                    return nextEpisodeToPlay;
                }
            }
        }
        dispatch(deleteFromManualQueueInfo(audioInformationCurrent,
            (autoQueuePlaylistId != null &&
                autoQueuePlaylistId &&
                autoQueuePlaylistId != QUEUE)
                ? QUEUE
                : null));
        //// this includes when autoQueuePlaylistId == QUEUE
        const episodesManualQueue = getState().episodesManualQueue;
        const sortOrderMain = 'EARLIEST_FIRST';
        const episodes = getManualQueueEpisodes(episodesManualQueue, sortOrderMain);
        if (episodes && episodes.length > 0) {
            return episodes[0];
        } else if (retrieveLocally(PLAY_AUTO_NEXT_UP) !== 'false') {
            console.log('in episodesAutoQueueSelector0');
            const episodesAutoQueue = getState().episodesAutoQueue || [];
            console.log(episodesAutoQueue);
            const nextEpisodeToPlay = episodesAutoQueue.length < 1 ? null : episodesAutoQueue[0];
            console.dir(nextEpisodeToPlay);
            if (nextEpisodeToPlay == null) {
                return null;
            }
            const episodesAutoQueueNew = episodesAutoQueue.filter((element) => element.guid != nextEpisodeToPlay.guid);
            dispatch(addToAutoQueueInfo(episodesAutoQueueNew, autoQueuePlaylistId));
            return nextEpisodeToPlay;
            // return episodesAutoQueue.isEmpty ? null : episodesAutoQueue[0];
        } else {
            return null;
        }






        /*****************old code */
        // let episodesOrg = episodesToPlayMain ||
        //     (isLocalStoragePresent() && localStorage.getItem('episodesToPlayMain') && JSON.parse(localStorage.getItem('episodesToPlayMain')));
        // // final SharedPreferences prefs = await SharedPreferences.getInstance();
        // let autoSkip = localStorage.getItem(AUTO_SKIP_EPISODES) !== null ? (localStorage.getItem(AUTO_SKIP_EPISODES) === 'true') : true;
        // let autoSkipEpisodes = autoSkip; // = prefs.getBool(AUTO_SKIP_EPISODES) ?? AUTO_SKIP_EPISODES_DEFAULT;
        // print('episodesToPlay : ' + episodesOrg);
        // console.dir(episodesOrg);
        // if (episodesOrg) {
        //     // episodesOrg.forEach((episode) {
        //     let ind = 0;
        //     for (let episode of episodesOrg) {
        //         ind++;
        //         let nextEpisode = episode;

        //         let personalValues = getState().personalValues; // personalValuesSelector(store.state);
        //         print('name : ' + nextEpisode.title);
        //         print('guid : ' + nextEpisode.guid);
        //         // final double progressSlider = (personalValues != null &&
        //         //         personalValues.containsKey('$guidCleaned') &&
        //         //         personalValues[guidCleaned].containsKey('progress'))
        //         //     ? personalValues[guidCleaned]['progress'].toDouble()
        //         //     : 0.0;
        //         let progressSlider =
        //             getProgressFromGUID(personalValues, nextEpisode.guid);

        //         /// remove this episode from file and save a new file with remaining episodes
        //         // final List<PodcastEpisode> remainingEpisodes =
        //         // episodesOrg.removeAt(0);

        //         if (!autoSkipEpisodes) {
        //             let remainingEpisodes = episodesOrg.slice(ind);   //.skip(ind).toList();
        //             console.dir(remainingEpisodes);
        //             saveEpisodesToPlay(remainingEpisodes);
        //             // save('episodesToPlay', remainingEpisodes)
        //             //     .catchError((err) => throw err);
        //             return nextEpisode;
        //         } else if (progressSlider < playedProgressLimit) {
        //             // } else if (progressSlider < 50) {
        //             let remainingEpisodes = episodesOrg.slice(ind);   //.skip(ind).toList();
        //             console.dir(remainingEpisodes);
        //             saveEpisodesToPlay(remainingEpisodes);
        //             // save('episodesToPlay', remainingEpisodes)
        //             //     .catchError((err) => throw err);
        //             return nextEpisode;
        //         }
        //     }
        //     return null;
        // } else {
        //     return null;
        // }
    } catch (error) {
        // rethrow;
        print(error);
        return null;
    }
}

export const playNextAudioAction = (audioInfoNew) => async (dispatch, getState) => {
    // ThunkAction < AppState > playNextAudioAction(PodcastEpisode audioInfoNew) {
    // return (Store < AppState > store) async {
    /// audioInfo old/current
    let audioInfo = getState().audioState;
    // audioStateSelector(store.state); // vm.audioState;


    dispatch(playAppAudio(audioInfoNew));

    // const { userDetails, podcastEpisodes } = this.props;
    let userDetails = getState().userDetails;
    // userDetailsStateSelector(store.state); // vm.userDetails;
    // final Map audioInfo = vm.audioState['audioInfo'];

    // vm.sendAudioInfo(audioInfoNew, (podcastEpisodes != null && podcastEpisodes.containsKey('items') ? podcastEpisodes['items'] : []));
    // vm.sendAudioInfo(audioInfoNew);
    // store.dispatch(SendAudioInfoAction(audioInfo: audioInfoNew));

    // let personalValues = getState().personalValues;
    // personalValuesSelector(store.state); // vm.personalValues;
    // if (!audioInfoNew.containsKey('guid')) {
    //   return;
    // }
    // final String guidCleaned = utils.cleanFirebaseId(audioInfoNew.guid);
    // final double progressSlider = personalValues != null &&
    //         personalValues.containsKey(guidCleaned) &&
    //         personalValues[guidCleaned].containsKey('progress')
    //     ? personalValues[guidCleaned]['progress'].toDouble()
    //     : 0.0;
    // let progressSlider =
    //     getProgressFromGUID(personalValues, audioInfoNew.guid);
    // sliderValue = progressSlider;
    // vm.playAudioProgress(sliderValue); // ***** instead do this in PlayMain
    // vm.playAudioBool(true, progressSlider);
    dispatch(
        playAudioBool(true, audioInfoNew?.guid));

    console.log('in addEpisodeToRecent 0 ');
    if (userDetails != null && userDetails['uid']) {
        console.log('in addEpisodeToRecent 05');
        console.log(audioInfo?.guid);
        console.log(audioInfoNew?.guid);
        if (((audioInfo == null || !audioInfo.guid) &&
            audioInfoNew.guid) ||
            ((audioInfo.guid) != audioInfoNew.guid)) {
            // vm.addEpisodeToRecentFn(userDetails['uid'], audioInfoNew);
            console.log('in addEpisodeToRecent 1');
            dispatch(addEpisodeToRecent(userDetails['uid'], audioInfoNew));
        }
    }
}
