const PSYCH = 'Psychology';
const SELF_GROWTH = 'Self growth';
const MINDFULNESS_HAPPINESS = 'Mindfulness & Happiness';
const BUSINESS_CAREER = 'Business & Career';
const MANAGEMENT_LEADERSHIP = 'Management & Leadership';
const ENTREPRENEURSHIP = 'Entrepreneurship';
const MARKETING_SALES = 'Marketing & Sales';
const PRODUCTIVITY = 'Productivity';
const MONEY_INVESTMENTS = 'Money & Investments';
const SEX_RELATIONSHIPS = 'Sex & Relationships';
const HEALTH_NUTRITION = 'Health & Nutrition';
const SPORTS_FITNESS = 'Sports & Fitness';
const SOCIETY_CULTURE = 'Society & Culture';
const BIOGRAPHY_MEMOIR = 'Biography & Memoir';
const RELIGION_SPIRITUALITY = 'Religion & Spirituality';
const FAMILY_KIDS = 'Family & Kids';
const HOME_ENVIRONMENT = 'Home & Environment';
const MOTIVATION_INSPIRATION = 'Motivation & Inspiration';
const TECHNOLOGY_FUTURE = 'Technology & Future';
const CREATIVITY = 'Creativity';
const POLITICS = 'Politics';
const ECONOMICS = 'Economics';
const EDUCATION = 'Education';
const HISTORY = 'History';
const SCIENCE = 'Science';
const PHILOSOPHY = 'Philosophy';

export const podcastTypesGenres = [
    {
        'id': '0',
        'name': 'All',
    },
    {
        'id': '1',
        'name': PSYCH,
    },
    {
        'id': '2',
        'name': SELF_GROWTH,
    },
    {
        'id': '3',
        'name': MINDFULNESS_HAPPINESS,
    },
    {
        'id': '4',
        'name': BUSINESS_CAREER,
    },
    {
        'id': '5',
        'name': MANAGEMENT_LEADERSHIP,
    },
    {
        'id': '6',
        'name': ENTREPRENEURSHIP,
    },
    {
        'id': '7',
        'name': MARKETING_SALES,
    },
    {
        'id': '8',
        'name': PRODUCTIVITY,
    },
    {
        'id': '9',
        'name': MONEY_INVESTMENTS,
    },
    {
        'id': '10',
        'name': SEX_RELATIONSHIPS,
    },
    {
        'id': '11',
        'name': HEALTH_NUTRITION,
    },
    {
        'id': '12',
        'name': SPORTS_FITNESS,
    },
    {
        'id': '13',
        'name': SOCIETY_CULTURE,
    },
    {
        'id': '14',
        'name': BIOGRAPHY_MEMOIR,
    },
    {
        'id': '15',
        'name': RELIGION_SPIRITUALITY,
    },
    {
        'id': '16',
        'name': FAMILY_KIDS,
    },
    {
        'id': '17',
        'name': HOME_ENVIRONMENT,
    },
    {
        'id': '18',
        'name': MOTIVATION_INSPIRATION,
    },
    {
        'id': '19',
        'name': TECHNOLOGY_FUTURE,
    },
    {
        'id': '20',
        'name': CREATIVITY,
    },
    {
        'id': '21',
        'name': POLITICS,
    },
    {
        'id': '22',
        'name': ECONOMICS,
    },
    {
        'id': '23',
        'name': EDUCATION,
    },
    {
        'id': '24',
        'name': HISTORY,
    },
    {
        'id': '25',
        'name': SCIENCE,
    },
    {
        'id': '26',
        'name': PHILOSOPHY,
    }
];

export const bgColorsBooks = {
    ///// bgColor for podcasts
    'blue': 'c2e6f4',
    'bluish-green': 'e7fafc',
    'green': 'b1cab1',
    'orange': 'efd8C6',
    'purple': 'f8e2f7',
    'red': 'f2c9c3',
    'yellow': 'fcecba',
    'backup brownish': 'faf3da',
}

export function podcastTypesExtra(genreIconSize = 18.0) {
    return {
        '1': {
            'name': PSYCH,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': '101',
                    'name': 'Make Better Decisions',
                    'possible': ['Books']
                },
            ]
        },
        '2': {
            'name': SELF_GROWTH,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': '201',
                    'name': 'Create Wealth',
                    'possible': ['Careers']
                },
            ]
        },
        '3': {
            'name': MINDFULNESS_HAPPINESS,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': '301',
                    'name': 'Comedy Interviews',
                    'possible': ['Comedy Interviews']
                },
            ]
        },
        '4': {
            'name': BUSINESS_CAREER,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Courses',
                    'name': 'Courses',
                    'possible': ['Courses']
                },
            ]
        },
        '5': {
            'name': MANAGEMENT_LEADERSHIP,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Comedy Fiction',
                    'name': 'Comedy Fiction',
                    'possible': ['Comedy Fiction']
                },
            ]
        },
        '6': {
            'name': ENTREPRENEURSHIP,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Alternative Health',
                    'name': 'Alternative Health',
                    'possible': ['Alternative Health']
                },
            ]
        },
        '7': {
            'name': MARKETING_SALES,


            'hasFeaturedLists': true
        },
        '8': {
            'name': PRODUCTIVITY,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Education for Kids',
                    'name': 'Education for Kids',
                    'possible': ['Education for Kids']
                },
            ]
        },
        '9': {
            'name': MONEY_INVESTMENTS,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Animation & Manga',
                    'name': 'Animation & Manga',
                    'possible': ['Animation & Manga']
                },
            ]
        },
        '10': {
            'name': SEX_RELATIONSHIPS,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Music Commentary',
                    'name': 'Music Commentary',
                    'possible': ['Music Commentary']
                },
            ]
        },
        '11': {
            'name': HEALTH_NUTRITION,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Business News',
                    'name': 'Business News',
                    'possible': ['Business News']
                },
            ]
        },
        '12': {
            'name': SPORTS_FITNESS,

        },
        '13': {
            'name': SOCIETY_CULTURE,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Astronomy',
                    'name': 'Astronomy',
                    'possible': ['Astronomy']
                },
            ]
        },
        '14': {
            'name': BIOGRAPHY_MEMOIR,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'Documentary',
                    'name': 'Documentary',
                    'possible': ['Documentary']
                },
            ]
        },
        '15': {
            'name': RELIGION_SPIRITUALITY,


            'hasFeaturedLists': true,
            'subGenres': [
                {
                    'id': 'Baseball',
                    'name': 'Baseball',
                    'possible': ['Baseball']
                }
            ]
        },
        '16': {
            'name': FAMILY_KIDS,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '17': {
            'name': HOME_ENVIRONMENT,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '18': {
            'name': MOTIVATION_INSPIRATION,


            'hasFeaturedLists': true,
            'hasBlogLists': true,
            'subGenres': [
                {
                    'id': 'After Shows',
                    'name': 'After Shows',
                    'possible': ['After Shows']
                },
            ]
        },
        '19': {
            'name': TECHNOLOGY_FUTURE,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '20': {
            'name': CREATIVITY,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '21': {
            'name': POLITICS,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '22': {
            'name': ECONOMICS,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '23': {
            'name': EDUCATION,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '24': {
            'name': HISTORY,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '25': {
            'name': SCIENCE,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
        '26': {
            'name': PHILOSOPHY,


            'hasFeaturedLists': true,
            'hasBlogLists': true
        },
    };
}

export const superGenres = (genreIconSize = 18.0) => {
    return [
        {
            'id': '10000',
            'name': 'Be More productive',
            'genres': ['8'],
            'icon': 'chart-line'
        },
        {
            'id': '20000',
            'name': 'Create Wealth',
            'genres': ['9'],
            'icon': 'dollar-sign'
        },
        {
            'id': '30000',
            'name': 'Make Better Decisions',
            'genres': ['1', '22'],
            'icon': 'brain'
        },
        {
            'id': '40000',
            'name': 'For Happier Life',
            'genres': ['3'],
            'icon': 'smile'
        },
        {
            'id': '50000',
            'name': 'For Better Career',
            'genres': ['4', '5', '6', '7'],
            'icon': 'briefcase'
        },
        {
            'id': '60000',
            'name': 'Improve your parenting',
            'genres': ['16'],
            'icon': 'baby-carriage'
        },
        {
            'id': '70000',
            'name': 'Get More Self-Confidence',
            'genres': ['2'],
            'icon': 'fist-raised'
        },
        {
            'id': '80000',
            'name': 'Better Relationships & Sex',
            'genres': ['10'],
            'icon': 'heart'
        },
        {
            'id': '90000',
            'name': 'Live a healthy life',
            'genres': ['11', '12'],
            'icon': 'apple-alt'
        },
        {
            'id': '100000',
            'name': 'For Self improvement',
            'genres': ['2', '13', '14', '26'],
            'icon': 'level-up-alt'
        },
        {
            'id': '110000',
            'name': 'To Acquire Knowledge',
            'genres': ['13', '14', '17', '19', '24', '25', '26'],
            'icon': 'book-open'
        },
        {
            'id': '120000',
            'name': 'Get Inspired',
            'deprecated': true,
            'genres': ['18'],
            'icon': 'lightbulb'
        },
    ];
}
// }