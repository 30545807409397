import {
    FETCH_BOOKMARK_ACTION, ADD_BOOKMARK_ACTION, DELETE_BOOKMARK_ACTION,
    FETCH_INSIGHTS_ACTION,
    DELETE_INSIGHT_ACTION,
    ADD_INSIGHT_ACTION,
    DELETE_HIGHLIGHT_ACTION,
    ADD_HIGHLIGHT_ACTION,
    FETCH_HIGHLIGHTS_ACTION
}
    from '../actions/types';
// import { initialState } from '../store';

// REDUCERS
export const episodeBookmarksReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_BOOKMARK_ACTION:
            return Object.assign({}, state, { [action.guid]: action.bookmarkData });
        case ADD_BOOKMARK_ACTION: {
            const currEpisodeBookmark = state[`${action.guid}`] || {};
            const return1 = { ...currEpisodeBookmark, ...action.bookmarkData };
            return Object.assign({}, state, { [action.guid]: return1 });
        }
        case DELETE_BOOKMARK_ACTION: {
            const currEpisodeBookmark = state[`${action.guid}`] || {};
            const copy = { ...currEpisodeBookmark };
            delete copy[action.timeId];
            return Object.assign({}, state, { [action.guid]: copy });
        }
        default:
            return state;
    }
};

export const insightsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_INSIGHTS_ACTION:
            return Object.assign({}, state, action.payload);
        case ADD_INSIGHT_ACTION: {
            return Object.assign({}, state, action.payload);
        }
        case DELETE_INSIGHT_ACTION: {
            const copy = { ...state };
            delete copy[action.guid];
            return copy;
        }
        default:
            return state;
    }
};

export const highlightsReducer = (state = {}, action) => {
    switch (action.type) {
        case FETCH_HIGHLIGHTS_ACTION:
            return Object.assign({}, state, action.payload);
        case ADD_HIGHLIGHT_ACTION: {
            return Object.assign({}, state, action.payload);
        }
        case DELETE_HIGHLIGHT_ACTION: {
            const copy = { ...state };
            delete copy[action.guid];
            return copy;
        }
        default:
            return state;
    }
};