import { firestore } from '../lib/db';
import { getIdFromUID, cleanFirebaseId, createEpisodeObj, reviewsLimit, cleanGUID } from '../lib/utils';

import {
  LOADING_QUEUE_ACTION, FETCH_QUEUE_MAIN_ACTION, UPDATE_AUTO_QUEUE_MAIN_ACTION, ADD_TO_QUEUE_MAIN_ACTION, DELETE_FROM_QUEUE_MAIN_ACTION,
  DELETE_ALL_FROM_QUEUE_ACTION, EPISODE_ORDINALITY_CHANGED_QUEUE_ACTION, AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION
} from './types';
import { logReview } from '../lib/analyticsEvents';
import { AUTO_PLAYLIST_ID, AUTO_QUEUE_PLAYLIST_ID } from '../lib/strings';
import { fetchPlaylistDetails } from './PlaylistActions';
import { Timestamp, deleteField, collection, query, where, getDoc, doc, setDoc } from "firebase/firestore/lite";
import { notification } from 'antd';
import { getAddToQueueType } from '../lib/fileUtils';

// class FetchQueueMainAction {
//   final Map<String, PodcastEpisode> obj;
//   FetchQueueMainAction(this.obj);
// }

// class AddToQueueMainAction {
//   final PodcastEpisode episodeObj;
//   AddToQueueMainAction(this.episodeObj);
// }

// class DeleteFromQueueMainAction {
//   final String episodeId;
//   DeleteFromQueueMainAction(this.episodeId);
// }

// class DeleteAllFromQueueAction {
//   DeleteAllFromQueueAction();
// }

// class EpisodeOrdinalityChangedQueueAction {
//   final String episodeGUID;
//   final int newOrdinalityDate;
//   EpisodeOrdinalityChangedQueueAction(this.episodeGUID, this.newOrdinalityDate);
// }

// class LoadingQueueAction {
//   final loadingMap;
//   LoadingQueueAction(this.loadingMap);
// }

// ThunkAction<AppState> fetchQueueInfo() {
//   return (Store<AppState> store) async {
export const fetchQueueInfo = () => async (dispatch, getState) => {
  let userDetails = getState().userDetails;
  let userUID = userDetails && userDetails['uid'];
  if (!userUID) {
    return;
  }
  // store.dispatch(LoadingQueueAction({ 'loadingQueue': true }));
  dispatch({
    type: LOADING_QUEUE_ACTION,
    payload: { loadingQueue: true }
  });

  // /***********Backward compatibility changes start */
  // final List episodesOrgInit = await utils
  //     .readEpisodesToPlayFile(); // readFile(EPISODES_TO_PLAY_FILE);
  // if (episodesOrgInit != null && episodesOrgInit.length > 0) {
  //   final List<Map> episodesOrg = List<Map>.from(episodesOrgInit);
  //   final List<PodcastEpisode> prevQueueEpisodes = [];
  //   episodesOrg.forEach((element) {
  //     PodcastEpisode ep = PodcastEpisode.fromJson(element);
  //     if (ep.manuallyAddedToQueue) {
  //       prevQueueEpisodes.add(ep);
  //     }
  //   });
  //   store.dispatch(addBulkToManualQueueInfo(prevQueueEpisodes));
  //   saveJsonEpisodesToPlay([]);
  // }
  // /***********Backward compatibility changes end */

  // Map userPlaylist = {};
  getDoc(doc(firestore, `queues`, userUID))
    .then((doc) => {
      if (doc.exists()) {
        // print('autoQueueEpisodes00');
        // userPlaylist['${playlistId}'] = doc.data();
        const dataAll = doc.data();
        console.dir(dataAll);
        const manualQueue = dataAll['manual'] || {};
        let manualQueueFin = manualQueue; // { };
        // manualQueue.forEach((key, value) => {
        //   manualQueueFin[key] = PodcastEpisode.fromJson(value);
        // });
        // store.dispatch(FetchQueueMainAction(manualQueueFin));
        dispatch({
          type: FETCH_QUEUE_MAIN_ACTION,
          payload: manualQueueFin
        });
        const autoQueue = dataAll['auto'] || [];
        const autoQueueEpisodes = autoQueue; //[];
        // autoQueue.forEach((element) {
        //   autoQueueEpisodes.add(PodcastEpisode.fromJson(element));
        // });
        // print('autoQueueEpisodes1');
        // print(autoQueueEpisodes.toString());
        // store.dispatch(UpdateAutoQueueMainAction(autoQueueEpisodes));
        dispatch({
          type: UPDATE_AUTO_QUEUE_MAIN_ACTION,
          payload: autoQueueEpisodes
        });
        const autoQueuePlaylistId = dataAll[AUTO_QUEUE_PLAYLIST_ID] || '';
        if (autoQueuePlaylistId) {
          const playlist = getState().userPlaylists && getState().userPlaylists[autoQueuePlaylistId];
          if (playlist) {
            const isAutoPlaylist = playlist['advancedType'] == AUTO_PLAYLIST_ID;
            if (!isAutoPlaylist) {
              dispatch(fetchPlaylistDetails(autoQueuePlaylistId));
            }
          }
        }
        dispatch({
          type: AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION,
          payload: autoQueuePlaylistId
        });
        // autoQueuePlaylistIdNotifier.value =
        //   dataAll[AUTO_QUEUE_PLAYLIST_ID] ?? '';
      } else {
        // doc.data() will be undefined in this case
        // userPlaylist['${playlistId}'] = {};
      }
      // store.dispatch(LoadingQueueAction({ 'loadingQueue': false }));
      dispatch({
        type: LOADING_QUEUE_ACTION,
        payload: { loadingQueue: false }
      });
    }).catch((error) => {
      // print("Error getting documents: " + error.toString());
      // store.dispatch(LoadingQueueAction({ 'loadingQueue': false }));
      console.error(error);
      dispatch({
        type: LOADING_QUEUE_ACTION,
        payload: { loadingQueue: false }
      });
    });
};

/// type 0 is manual queue and type 1 is auto queue
// ThunkAction < AppState > addToManualQueueInfo(PodcastEpisode episode) {
//   return (Store < AppState > store) async {
export const addToManualQueueInfo = (episode) => async (dispatch, getState) => {
  const episodesManualQueue = getState().episodesManualQueue;
  const userDetails = getState().userDetails;
  let userUID = userDetails['uid'];
  if (!userUID) {
    return;
  }
  const manualQueue = {};
  const now = new Date();
  const addToQueueType = getAddToQueueType();

  const episodeObj = {
    ...createEpisodeObj(episode),
    'dateAdded':
      Timestamp.fromDate(new Date()), // firebase.firestore.FieldValue.serverTimestamp(),
    'ordinalityDate': addToQueueType * now.getTime(),
  };
  manualQueue[episode.guid] = episodeObj;
  // print('addToManualQueueInfo: ' + episode.toString());
  // store.dispatch(AddToQueueMainAction(PodcastEpisode.fromJson(episodeObj)));
  dispatch({
    type: ADD_TO_QUEUE_MAIN_ACTION,
    payload: episodeObj
  });

  const postData = {};
  postData['userID'] = userUID;
  postData['manual'] = manualQueue;
  // print('addToManualQueueInfo');
  // print(postData.toString());

  setDoc(doc(firestore, 'queues', userUID), postData, { merge: true })
    // .then((doc) => { })
    .catch((error) => {
      // print("Error getting documents: " + error.toString());
      console.error(error);
    });
  const audioInfo = getState().audioState;
  console.dir(audioInfo);
  console.dir(episodesManualQueue);
  if ((!audioInfo || !audioInfo.guid) && Object.keys(episodesManualQueue).length < 1) {
    notification.success({
      duration: 0,
      message: 'Added to Queue!',
      description: 'You can access your queue from the bottom audio player which will show up once you play any audio.',
      onClick: () => {
      },
    });
  }
}

/// type 0 is manual queue and type 1 is auto queue
// ThunkAction < AppState > addBulkToManualQueueInfo(List < PodcastEpisode > episodes) {
//   return (Store < AppState > store) async {
export const addBulkToManualQueueInfo = (episodes) => async (dispatch, getState) => {
  const userDetails = getState().userDetails;
  let userUID = userDetails['uid'];
  if (!userUID) {
    return;
  }
  const manualQueue = {};
  const now = new Date();
  episodes.forEach((episode) => {
    const episodeObj = {
      ...createEpisodeObj(episode),
      'dateAdded':
        Timestamp.fromDate(new Date()), // firebase.firestore.FieldValue.serverTimestamp(),
      'ordinalityDate': now.getTime(),
    };
    manualQueue[episode.guid] = episodeObj;
    // print('addToManualQueueInfo: ' + episode.toString());
    // store.dispatch(AddToQueueMainAction(PodcastEpisode.fromJson(episodeObj)));
    dispatch({
      type: ADD_TO_QUEUE_MAIN_ACTION,
      payload: episodeObj
    });
  });

  const postData = {};
  postData['userID'] = userUID;
  postData['manual'] = manualQueue;
  // print('addToManualQueueInfo');
  // print(postData.toString());

  setDoc(doc(firestore, 'queues', userUID), postData, { merge: true })
    // .then((doc) => { })
    .catch((error) => {
      // print("Error getting documents: " + error.toString());
      console.error(error);
    });
}

/// type 0 is manual queue and type 1 is auto queue
// ThunkAction < AppState > deleteFromManualQueueInfo(PodcastEpisode episode,
//   { String autoQueuePlaylistId }) {
//   return (Store < AppState > store) async {
export const deleteFromManualQueueInfo = (episode, autoQueuePlaylistId) => async (dispatch, getState) => {
  const episodesManualQueue = getState().episodesManualQueue; // episodesManualQueueSelector(store.state);
  if (!episodesManualQueue[episode.guid] &&
    autoQueuePlaylistId == null) {
    return;
  }

  const userDetails = getState().userDetails;
  let userUID = userDetails['uid'];
  if (!userUID) {
    return;
  }
  const manualQueue = {};
  manualQueue[episode.guid] = deleteField();
  // store.dispatch(DeleteFromQueueMainAction(episode.guid));
  dispatch({
    type: DELETE_FROM_QUEUE_MAIN_ACTION,
    payload: episode.guid
  });

  const postData = {};

  if (autoQueuePlaylistId != null) {
    // autoQueuePlaylistIdNotifier.value = autoQueuePlaylistId;
    dispatch({
      type: AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION,
      payload: autoQueuePlaylistId
    });
    postData[AUTO_QUEUE_PLAYLIST_ID] = autoQueuePlaylistId;
  }

  postData['userID'] = userUID;
  postData['manual'] = manualQueue;
  // print('deleteFromManualQueueInfo');
  // print(postData.toString());

  setDoc(doc(firestore, 'queues', userUID), postData, { merge: true })
    // .then((doc) => { })
    .catch((error) => {
      // print("Error getting documents: " + error.toString());
      console.error(error);
    });
}

// /// type 0 is manual queue and type 1 is auto queue
// ThunkAction < AppState > clearManualQueueInfo() {
//   return (Store < AppState > store) async {
export const clearManualQueueInfo = () => async (dispatch, getState) => {
  const userDetails = getState().userDetails;
  let userUID = userDetails['uid'];
  if (!userUID) {
    return;
  }
  // store.dispatch(DeleteAllFromQueueAction());
  dispatch({
    type: DELETE_ALL_FROM_QUEUE_ACTION,
    // payload: episode.guid
  });

  const postData = {};
  postData['userID'] = userUID;
  postData['manual'] = deleteField();
  // print('clearManualQueueInfo');
  // print(postData.toString());

  setDoc(doc(firestore, 'queues', userUID), postData, { merge: true })
    // .then((doc) => { })
    .catch((error) => {
      // print("Error getting documents: " + error.toString());
      console.error(error);
    });
}

// ThunkAction < AppState > queueEpisodeOrdinalityChanged(
//   String episodeGUID, int newOrdinalityDate,
//   { bool saveToCloud = false }) {
//   return (Store < AppState > store) async {
export const queueEpisodeOrdinalityChanged = (episodeGUID, newOrdinalityDate, saveToCloud = false) => async (dispatch, getState) => {
  const userDetails = getState().userDetails;
  let userUID = userDetails['uid'];
  if (!userUID) {
    return;
  }
  const episodeUpdate = {};
  episodeUpdate[episodeGUID] = { 'ordinalityDate': newOrdinalityDate };
  // store.dispatch(
  //   EpisodeOrdinalityChangedQueueAction(episodeGUID, newOrdinalityDate));
  dispatch({
    type: EPISODE_ORDINALITY_CHANGED_QUEUE_ACTION,
    episodeGUID, newOrdinalityDate
  });
  // print('episodeUpdate : ' + episodeUpdate.toString());
  if (saveToCloud) {
    return setDoc(doc(firestore, 'queues', userUID), { 'manual': episodeUpdate }, { merge: true })
      // .then(() {
      // })
      .catch((error) => {
        // print("Error deleting episode: " + error.toString());
        console.error(error);
        // reject({ success: false, error: error });
      });
  }
}

// class UpdateAutoQueueMainAction {
//   final List<PodcastEpisode> episodes;
//   UpdateAutoQueueMainAction(this.episodes);
// }

// /// type 0 is manual queue and type 1 is auto queue
// ThunkAction < AppState > addToAutoQueueInfo(List < PodcastEpisode > episodesOrg,
//   { String autoQueuePlaylistId = '' }) {
//   return (Store < AppState > store) async {
export const addToAutoQueueInfo = (episodesOrg, autoQueuePlaylistId = '') => async (dispatch, getState) => {
  const maxEpisodes = 8; // 10
  const episodesFin = episodesOrg.length <= maxEpisodes
    ? episodesOrg
    : episodesOrg.slice(0, maxEpisodes);
  const userDetails = getState().userDetails;
  let userUID = userDetails && userDetails['uid'] || '';
  if (!userUID) {
    return;
  }
  const autoQueue = [];
  for (const episode of episodesFin) {
    autoQueue.push(createEpisodeObj(episode));
  }
  // store.dispatch(UpdateAutoQueueMainAction(episodesFin));
  dispatch({
    type: UPDATE_AUTO_QUEUE_MAIN_ACTION,
    payload: episodesFin
  });

  const postData = {};
  postData['userID'] = userUID;
  if (autoQueuePlaylistId != null && autoQueuePlaylistId) {
    //// played from manualQueue is already taken care here.
    const userPlaylists = getState().userPlaylists;
    let autoPlaylist = false;
    if (autoQueuePlaylistId in userPlaylists) {
      autoPlaylist = userPlaylists[autoQueuePlaylistId]['advancedType'] ==
        AUTO_PLAYLIST_ID;
    }

    // autoQueuePlaylistIdNotifier.value = autoQueuePlaylistId;
    dispatch({
      type: AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION,
      payload: autoQueuePlaylistId
    });
    postData[AUTO_QUEUE_PLAYLIST_ID] = autoQueuePlaylistId;
    postData['auto'] = autoPlaylist ? autoQueue : deleteField();
  } else {
    // autoQueuePlaylistIdNotifier.value = '';
    dispatch({
      type: AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION,
      payload: ''
    });
    postData[AUTO_QUEUE_PLAYLIST_ID] = deleteField();
    postData['auto'] = autoQueue;
  }
  // print('addToAutoQueueInfo');
  // print(postData.toString());

  setDoc(doc(firestore, 'queues', userUID), postData, { merge: true })
    // .then((doc) => { })
    .catch((error) => {
      // print("Error getting documents: " + error.toString());
      console.error(error);
    });
}
