//These are action types that can be broadcasted out and listened to.
//Currently, ADDING_POST isn't being used by an actions or listened to.
//ADD_POST_FAILURE is dispatched in the actions if adding a post fails but is not
//being listened to in the reducer.
export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_PODCAST_EPISODES = 'FETCH_PODCAST_EPISODES';
export const FETCH_PODCASTS = 'FETCH_PODCASTS';
export const ADD_POST_SUCCESS = 'ADD_POST_SUCCESS';
export const ADD_POST_FAILURE = 'ADD_POST_FAILURE';
export const ADDING_POST = 'ADDING_POST';
export const UPDATE_USER_POST = 'UPDATE_USER_POST';

export const FETCH_HOME_RECOMMENDATIONS = 'FETCH_HOME_RECOMMENDATIONS';
export const FETCH_USER_BOOK_OF_DAY = 'FETCH_USER_BOOK_OF_DAY';

export const FETCH_TOP_PODCASTS = 'FETCH_TOP_PODCASTS';

export const FETCH_SEARCH_ALERTS = 'FETCH_SEARCH_ALERTS';
export const ADD_SEARCH_ALERTS = 'ADD_SEARCH_ALERTS';
export const DELETE_SEARCH_ALERTS = 'DELETE_SEARCH_ALERTS';

export const ADD_TO_EPISODES = 'ADD_TO_EPISODES';
export const FETCH_RECENT_PLAYED_PODCASTS = 'FETCH_RECENT_PLAYED_PODCASTS';

export const FETCH_READING_CHALLENGES = 'FETCH_READING_CHALLENGES';
export const FETCH_READING_CHALLENGES_USER = 'FETCH_READING_CHALLENGES_USER';
export const ADD_READING_CHALLENGES_USER = 'ADD_READING_CHALLENGES_USER';
export const DELETE_READING_CHALLENGES_USER = 'DELETE_READING_CHALLENGES_USER';
export const FETCH_READING_CHALLENGES_USER_COMPLETED = 'FETCH_READING_CHALLENGES_USER_COMPLETED';
export const ADD_READING_CHALLENGES_USER_COMPLETED = 'ADD_READING_CHALLENGES_USER_COMPLETED';

export const FETCH_MY_FILES_ACTION = 'FETCH_MY_FILES_ACTION';
export const ADD_MY_FILES_ACTION = 'ADD_MY_FILES_ACTION';
export const DELETE_MY_FILES_ACTION = 'DELETE_MY_FILES_ACTION';
export const ADD_MY_FILE_UPLOAD_ACTION = 'ADD_MY_FILE_UPLOAD_ACTION';
export const CANCEL_MY_FILE_UPLOAD_ACTION = 'CANCEL_MY_FILE_UPLOAD_ACTION';
export const DELETE_MY_FILE_UPLOAD_ACTION = 'DELETE_MY_FILE_UPLOAD_ACTION';
export const ADD_MY_FILE_UPLOAD_PROGRESS_ACTION = 'ADD_MY_FILE_UPLOAD_PROGRESS_ACTION';
export const DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION = 'DELETE_MY_FILE_UPLOAD_PROGRESS_ACTION';

export const FETCH_PERSONAL_READS_ACTION = 'FETCH_PERSONAL_READS_ACTION';
export const PERSONAL_READS_SUCCESS_ACTION = 'PERSONAL_READS_SUCCESS_ACTION';

export const FETCH_REQUESTED_BOOKS = 'FETCH_REQUESTED_BOOKS';
export const ADD_REQUESTED_BOOKS = 'ADD_REQUESTED_BOOKS';

export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';
export const LOADING_USER_DETAILS_STATE = 'LOADING_USER_DETAILS_STATE';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const LOG_OUT_USER = 'LOG_OUT_USER';
export const ADD_TOP_PADDING = 'ADD_TOP_PADDING';
export const USER_DASHBOARD_PLAN = 'USER_DASHBOARD_PLAN';

export const ENABLE_KEYBOARD_PLAYBACK = 'ENABLE_KEYBOARD_PLAYBACK';
export const FORWARD_SEEK_TIME = 'FORWARD_SEEK_TIME';
export const REWIND_SEEK_TIME = 'REWIND_SEEK_TIME';

export const FETCH_AD_PODCASTS_ACTION = 'FETCH_AD_PODCASTS_ACTION';

export const FETCH_YOUR_PODCAST_SETTINGS = 'FETCH_YOUR_PODCAST_SETTINGS';
export const UPDATE_YOUR_PODCAST_SETTINGS = 'UPDATE_YOUR_PODCAST_SETTINGS';

export const UPDATE_CLEAR_BADGES = 'UPDATE_CLEAR_BADGES';

export const FETCH_PODCAST_EXTRA_DETAILS = 'FETCH_PODCAST_EXTRA_DETAILS';
export const UPDATE_PODCAST_EXTRA_DETAILS = 'UPDATE_PODCAST_EXTRA_DETAILS';

export const FETCH_DB_PODCASTS = 'FETCH_DB_PODCASTS';
// export const EDIT_DB_PODCASTS_SUSCRIBERS = 'EDIT_DB_PODCASTS_SUSCRIBERS';
export const LOADING_DB_PODCASTS = 'LOADING_DB_PODCASTS';

export const FETCH_SUBSCRIBED_PODCASTS = 'FETCH_SUBSCRIBED_PODCASTS';
export const FETCH_SUBSCRIBED_PODCASTS_ERROR = 'FETCH_SUBSCRIBED_PODCASTS_ERROR';
export const LOADING_LATEST_EPISODES = 'LOADING_LATEST_EPISODES';
export const LATEST_EPISODES = 'LATEST_EPISODES';
export const FETCH_SIDE_PODCASTS = 'FETCH_SIDE_PODCASTS';
export const LOADING_PODCASTS = 'LOADING_PODCASTS';
export const LOADING_SIDE_PODCASTS = 'LOADING_SIDE_PODCASTS';
export const SUBSCRIBE_PODCAST_SUCCESS = 'SUBSCRIBE_PODCAST_SUCCESS';
export const SUBSCRIBE_PODCAST_FAILURE = 'SUBSCRIBE_PODCAST_FAILURE';
export const UNSUBSCRIBE_PODCAST_SUCCESS = 'UNSUBSCRIBE_PODCAST_SUCCESS';
export const ADD_SKIP_BEGINNING_ACTION = 'ADD_SKIP_BEGINNING_ACTION';

export const LOADING_BROWSE_PODCASTS = 'LOADING_BROWSE_PODCASTS';
export const LOADING_BROWSE_EPISODES = 'LOADING_BROWSE_EPISODES';

export const UPDATE_AUDIO_INFO = 'UPDATE_AUDIO_INFO';
export const PLAY_AUDIO = 'PLAY_AUDIO';

export const FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS';
export const FETCH_CURRENT_PLAYLIST_SUCCESS = 'FETCH_CURRENT_PLAYLIST_SUCCESS';
export const ADD_PLAYLIST_SUCCESS = 'ADD_PLAYLIST_SUCCESS';
export const EDIT_PLAYLIST_SUCCESS = 'EDIT_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_ALL_FROM_PLAYLISTS_MAIN = 'DELETE_ALL_FROM_PLAYLISTS_MAIN';
export const EPISODE_ORDINALITY_CHANGED = 'EPISODE_ORDINALITY_CHANGED';


export const ADD_GUID_TO_PLAYLIST_MAIN = 'ADD_GUID_TO_PLAYLIST_MAIN';
export const DELETE_GUID_FROM_PLAYLIST_MAIN = 'DELETE_GUID_FROM_PLAYLIST_MAIN';
export const ADD_TO_PLAYLIST_SUCCESS = 'ADD_PLAYLIST_SUCCESS';
export const REMOVE_FROM_PLAYLIST_SUCCESS = 'REMOVE_FROM_PLAYLIST_SUCCESS';
export const CREATING_NEW_PLAYLIST = 'CREATING_NEW_PLAYLIST';

export const ADD_ERROR = 'ADD_ERROR';

export const LAST_PLAYED_EPISODE = 'LAST_PLAYED_EPISODE';
export const PERSONAL_VALUES_SUCCESS = 'PERSONAL_VALUES_SUCCESS';
export const PERSONAL_VALUES_RATING_SUCCESS = 'PERSONAL_VALUES_RATING_SUCCESS';
export const PERSONAL_VALUES_RATING_DELETE = 'PERSONAL_VALUES_RATING_DELETE';
export const PERSONAL_VALUES_PROGRESS_SUCCESS = 'PERSONAL_VALUES_PROGRESS_SUCCESS';

export const PERSONAL_RATINGS_SUCCESS = 'PERSONAL_RATINGS_SUCCESS';

export const DELETE_FROM_HISTORY = 'DELETE_FROM_HISTORY';
export const DELETE_FROM_HISTORY_FAILURE = 'DELETE_FROM_HISTORY_FAILURE';

export const PRIVATE = 'nad'; // temp value 
export const PUBLIC = 'kad'; // temp value 

export const FETCH_RECENT_PLAYED = 'FETCH_RECENT_PLAYED';
export const FETCH_RECENT_PLAYED_ERROR = 'FETCH_RECENT_PLAYED_ERROR';
export const ADD_TO_RECENT_PLAYED = 'ADD_TO_RECENT_PLAYED';
export const DELETE_FROM_RECENT_PLAYED = 'DELETE_FROM_RECENT_PLAYED';
export const LOADING_RECENT_PLAYED = 'LOADING_RECENT_PLAYED';

export const FETCH_REVIEWS = 'FETCH_REVIEWS';
export const FETCH_REVIEWS_ERROR = 'FETCH_REVIEWS_ERROR';
export const FETCH_REVIEW_ERROR = 'FETCH_REVIEW_ERROR';
export const ADD_TO_REVIEWS = 'ADD_TO_REVIEWS';
export const UPDATE_REVIEWS = 'UPDATE_REVIEWS';
export const DELETE_FROM_REVIEWS = 'DELETE_FROM_REVIEWS';
export const LOADING_REVIEWS = 'LOADING_REVIEWS';
export const LOADING_REVIEW = 'LOADING_REVIEW';


export const FETCH_EPISODE_REVIEWS = 'FETCH_EPISODE_REVIEWS';
export const LOADING_EPISODE_REVIEWS = 'LOADING_EPISODE_REVIEWS';

export const ADD_TO_EPISODE_REVIEWS_META = 'ADD_TO_EPISODE_REVIEWS_META';
export const UPDATE_EPISODE_REVIEWS_META = 'UPDATE_EPISODE_REVIEWS_META';

export const ADD_LIKES_REVIEWS = 'ADD_LIKES_REVIEWS';
export const REMOVE_LIKES_REVIEWS = 'REMOVE_LIKES_REVIEWS';
export const ADD_REPORTED_REVIEWS = 'ADD_REPORTED_REVIEWS';
export const REMOVE_REPORTED_REVIEWS = 'REMOVE_REPORTED_REVIEWS';

export const SET_HAS_REPLIES = 'SET_HAS_REPLIES';
export const PIN_REVIEW = 'PIN_REVIEW';
export const UNPIN_REVIEW = 'UNPIN_REVIEW';

export const LOADING_REVIEW_REPLIES = 'LOADING_REVIEW_REPLIES';
export const FETCH_REVIEW_REPLIES = 'FETCH_REVIEW_REPLIES';
export const ADD_REVIEW_REPLY = 'ADD_REVIEW_REPLY';
export const EDIT_REVIEW_REPLY = 'EDIT_REVIEW_REPLY';
export const REPORT_REVIEW_REPLY = 'REPORT_REVIEW_REPLY';
export const UNREPORT_REVIEW_REPLY = 'UNREPORT_REVIEW_REPLY';

export const ADD_TO_REVIEWS_META = 'ADD_TO_REVIEWS_META';
export const UPDATE_REVIEWS_META = 'UPDATE_REVIEWS_META';
export const DELETE_FROM_REVIEWS_META = 'DELETE_FROM_REVIEWS_META';

export const PLAYLIST_SORT_ORDER_CHANGED = 'PLAYLIST_SORT_ORDER_CHANGED';
export const FETCH_PLAYLISTS_MAIN = 'FETCH_PLAYLISTS_MAIN';
export const ADD_TO_PLAYLISTS_MAIN = 'ADD_TO_PLAYLISTS_MAIN';
export const UPDATE_PLAYLISTS_MAIN = 'UPDATE_PLAYLISTS_MAIN';
export const DELETE_FROM_PLAYLISTS_MAIN = 'DELETE_FROM_PLAYLISTS_MAIN';
export const DELETE_PLAYLISTS_MAIN = 'DELETE_PLAYLISTS_MAIN';
export const LOADING_PLAYLISTS_MAIN = 'LOADING_PLAYLISTS_MAIN';
export const FETCH_FOLLOWED_PLAYLISTS = 'FETCH_FOLLOWED_PLAYLISTS';
export const FOLLOW_PLAYLIST = 'FOLLOW_PLAYLIST';
export const UNFOLLOW_PLAYLIST = 'UNFOLLOW_PLAYLIST';
export const COLLABORATIVE_PLAYLIST = 'COLLABORATIVE_PLAYLIST';
export const UNCOLLABORATIVE_PLAYLIST = 'UNCOLLABORATIVE_PLAYLIST';

export const FETCH_FOLLOWED_PROFILES = 'FETCH_FOLLOWED_PROFILES';
export const FOLLOW_PROFILE = 'FOLLOW_PROFILE';
export const UNFOLLOW_PROFILE = 'UNFOLLOW_PROFILE';

export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';
export const USER_HAS_ADS = 'USER_HAS_ADS';

export const TOGGLE_LEFT_BAR = 'TOGGLE_LEFT_BAR';

export const CHANGE_SEARCH_TERM = 'CHANGE_SEARCH_TERM';

export const GET_AUTO_CHAPTERS_ACTION = 'GET_AUTO_CHAPTERS_ACTION';

export const FETCH_HOME_PODCASTS = 'FETCH_HOME_PODCASTS';
export const ADD_HOME_PODCASTS = 'ADD_HOME_PODCASTS';
export const ADD_POPULAR_PODCASTS = 'ADD_POPULAR_PODCASTS';
export const FETCH_PODCAST_TAGS = 'FETCH_PODCAST_TAGS';
export const CREATE_PODCAST_TAGS_ACTION = 'CREATE_PODCAST_TAGS_ACTION';
export const EDIT_PODCAST_TAGS_ACTION = 'EDIT_PODCAST_TAGS_ACTION';
export const DELETE_PODCAST_TAGS_ACTION = 'DELETE_PODCAST_TAGS_ACTION';
export const ADD_PODCAST_TO_TAG_ACTION = 'ADD_PODCAST_TO_TAG_ACTION';
export const REMOVE_PODCAST_FROM_TAG_ACTION = 'REMOVE_PODCAST_FROM_TAG_ACTION';
export const FETCH_FOLLOWED_TAGS = 'FETCH_FOLLOWED_TAGS';
export const FOLLOW_TAG = 'FOLLOW_TAG';
export const UNFOLLOW_TAG = 'UNFOLLOW_TAG';
export const LOADING_TAGS = 'LOADING_TAGS';

export const TOGGLE_DARK_THEME = 'TOGGLE_DARK_THEME';

export const FETCH_BLOG_LISTS_ACTION = 'FETCH_BLOG_LISTS_ACTION';
export const FETCH_FEATURED_LISTS_ACTION = 'FETCH_FEATURED_LISTS_ACTION';
export const FETCH_BOOKMARK_ACTION = 'FETCH_BOOKMARK_ACTION';
export const ADD_BOOKMARK_ACTION = 'ADD_BOOKMARK_ACTION';
export const DELETE_BOOKMARK_ACTION = 'DELETE_BOOKMARK_ACTION';

export const FETCH_INSIGHTS_ACTION = 'FETCH_INSIGHTS_ACTION';
export const ADD_INSIGHT_ACTION = 'ADD_INSIGHT_ACTION';
export const DELETE_INSIGHT_ACTION = 'DELETE_INSIGHT_ACTION';

export const FETCH_HIGHLIGHTS_ACTION = 'FETCH_HIGHLIGHTS_ACTION';
export const ADD_HIGHLIGHT_ACTION = 'ADD_HIGHLIGHT_ACTION';
export const DELETE_HIGHLIGHT_ACTION = 'DELETE_HIGHLIGHT_ACTION';

export const FETCH_RELATED_PRODUCTS_ACTION = 'FETCH_RELATED_PRODUCTS_ACTION';
export const FETCH_SIMILAR_PODCASTS_ACTION = 'FETCH_SIMILAR_PODCASTS_ACTION';

export const USER_INTERESTS_ACTION = 'USER_INTERESTS_ACTION';
export const PERSONAL_VALUES_MARK_PLAYED_ACTION = 'PERSONAL_VALUES_MARK_PLAYED_ACTION';
export const PERSONAL_VALUES_MARK_UNPLAYED_ACTION = 'PERSONAL_VALUES_MARK_UNPLAYED_ACTION';
export const PERSONAL_VALUES_MARK_ALL_UNPLAYED_ACTION = 'PERSONAL_VALUES_MARK_ALL_UNPLAYED_ACTION';

export const LOADING_QUEUE_ACTION = 'LOADING_QUEUE_ACTION';
export const FETCH_QUEUE_MAIN_ACTION = 'FETCH_QUEUE_MAIN_ACTION';
export const UPDATE_AUTO_QUEUE_MAIN_ACTION = 'UPDATE_AUTO_QUEUE_MAIN_ACTION';
export const ADD_TO_QUEUE_MAIN_ACTION = 'ADD_TO_QUEUE_MAIN_ACTION';
export const DELETE_FROM_QUEUE_MAIN_ACTION = 'DELETE_FROM_QUEUE_MAIN_ACTION';
export const DELETE_ALL_FROM_QUEUE_ACTION = 'DELETE_ALL_FROM_QUEUE_ACTION';
export const EPISODE_ORDINALITY_CHANGED_QUEUE_ACTION = 'EPISODE_ORDINALITY_CHANGED_QUEUE_ACTION';
export const AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION = 'AUTO_QUEUE_PLAYLIST_ID_NOTIFIER_ACTION';

