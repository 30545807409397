import { firestore } from '../lib/db';
import { doc, setDoc } from "firebase/firestore/lite";
import { ENABLE_KEYBOARD_PLAYBACK, FORWARD_SEEK_TIME, REWIND_SEEK_TIME } from './types';
import { getFirebaseUser, saveLocally, sleep } from '../lib/utils';
import { createNewUserRecommendations, getRecommendations } from './RecommendationActions';

// Future<void> setInterests(
//     Map genreIds, Map userDetails, bool generalNotifications) {
export const setInterestsFirstTimeOnboard = (genreIds, userDetails) => async (dispatch) => {
    const userUID = userDetails['uid'];
    setDoc(doc(firestore, 'podcastTags', userUID), { 'interestsSuper': genreIds }, { merge: true });
    await sleep(1000);
    await createNewUserRecommendations();

    await sleep(1000);
    dispatch(getRecommendations(getFirebaseUser().uid));
}


export const updateForwardSeekTime = (val) => async (dispatch) => {
    dispatch({
        type: FORWARD_SEEK_TIME,
        payload: parseInt(val),
    });
}
export const updateRewindSeekTime = (val) => async (dispatch) => {
    dispatch({
        type: REWIND_SEEK_TIME,
        payload: parseInt(val),
    });
}
export const updateEnableKeyboardPlayback = (val) => async (dispatch) => {
    dispatch({
        type: ENABLE_KEYBOARD_PLAYBACK,
        payload: val,
    });
}