import { getFirestore, doc, getDoc, collection, query, where, getDocs, setDoc } from "firebase/firestore/lite";
import { firestore } from "../lib/db";
import { firestoreApi, getFirebaseUser } from "../lib/utils";
import FireStoreParser from 'firestore-parser';
import { deleteField } from "firebase/firestore/lite";
import { ADD_READING_CHALLENGES_USER, ADD_READING_CHALLENGES_USER_COMPLETED, DELETE_READING_CHALLENGES_USER, FETCH_READING_CHALLENGES, FETCH_READING_CHALLENGES_USER, FETCH_READING_CHALLENGES_USER_COMPLETED } from "./types";
import { consumeCoins } from "./GameActions";

export const getReadingChallengesUser = (uid) => async (dispatch) => {
    try {
        const userDocRef = doc(firestore, "challengesUser", uid);
        getDoc(userDocRef).then(userDocSnap => {
            if (userDocSnap.exists()) {
                const userData = userDocSnap.data();
                dispatch({ type: FETCH_READING_CHALLENGES_USER, payload: (userData.challenges || {}) });
                dispatch({ type: FETCH_READING_CHALLENGES_USER_COMPLETED, payload: (userData.completed || {}) });
            }
        })
    } catch (err) {
        console.dir("Error fetching reading challenges", err);
    }
};
export const getReadingChallenges = () => async (dispatch) => {
    try {
        // const userDocRef = doc(firestore, "challengesUser", getFirebaseUser().uid);
        // getDoc(userDocRef).then(userDocSnap => {
        //     if (userDocSnap.exists()) {
        //         const userData = userDocSnap.data();
        //         dispatch({ type: FETCH_READING_CHALLENGES_USER, payload: (userData.challenges || {}) });
        //         dispatch({ type: FETCH_READING_CHALLENGES_USER_COMPLETED, payload: (userData.completed || {}) });
        //     }
        // })

        const challengesQuery = query(collection(firestore, "challenges"), where("active", "==", true));
        const snap = await getDocs(challengesQuery);

        let readingChallenges = {};
        snap.docs.forEach((doc) => {
            if (doc.exists()) {
                readingChallenges[doc.id] = { id: doc.id, ...doc.data() };
            }
        });

        dispatch({ type: FETCH_READING_CHALLENGES, payload: readingChallenges });
    } catch (err) {
        console.dir("Error fetching reading challenges", err);
    }
};

export const fetchReadingChallenge = async (challengeId) => {
    try {
        const res = await fetch(`${firestoreApi}challenges/${challengeId}/final/article`);
        const result = await res.json();
        const parsedResult = FireStoreParser(result);
        console.dir(parsedResult);
        return parsedResult?.fields || {};
    } catch (err) {
        console.dir("Error fetching specific challenge", err);
        console.dir(err);
        return {};
    }
};

export const editChallengeUserBook = (challengeId, collectionId) => async (dispatch, getState) => {
    const readingChallenges = getState().readingChallenges;
    const challengesUser = getState().readingChallengesUser ?? {};
    // const challengesUser = state.readingChallengesUser;
    const collectionIds = challengesUser[challengeId] || {};

    // let newChallengesUser = { ...challengesUser };
    console.dir(challengesUser);
    console.dir(collectionIds);
    console.dir(collectionId);
    if (collectionIds[collectionId]) {
        // delete newChallengesUser[challengeId][collectionId];
        // dispatch(updateUserChallenges(newChallengesUser));
        dispatch({ type: DELETE_READING_CHALLENGES_USER, challengeId, collectionId });

        await setDoc(doc(firestore, "challengesUser", getFirebaseUser().uid), {
            challenges: {
                [challengeId]: { [collectionId]: deleteField() }
            }
        }, { merge: true });

        return {};
    } else {
        // newChallengesUser[challengeId] = { ...collectionIds, [collectionId]: true };
        console.dir(challengesUser);
        // dispatch(updateUserChallenges(newChallengesUser));
        dispatch({ type: ADD_READING_CHALLENGES_USER, challengeId, collectionId });

        const uploadData = { challenges: { [challengeId]: { [collectionId]: true } } };

        const readingChallenge = readingChallenges[challengeId] || {};
        const collectionIdsAll = readingChallenge.collectionIds || {};
        console.dir(challengesUser);

        if (Object.keys(collectionIdsAll).length === Object.keys({ ...(challengesUser[challengeId] ?? {}), [collectionId]: true }).length) {
            const completedChallenges = getState().readingChallengesCompleted || {};
            if (!completedChallenges[challengeId]) {
                uploadData.completed = { [challengeId]: true };
                dispatch(consumeCoins(-(readingChallenge.coins || 10)));
                // dispatch(updateCompletedChallenges({ ...completedChallenges, [challengeId]: true }));
                dispatch({ type: ADD_READING_CHALLENGES_USER_COMPLETED, challengeId });

                setDoc(doc(firestore, "challengesUser", getFirebaseUser().uid), uploadData, { merge: true });
                return { finishedChallenge: true };
            }
        }
        console.dir(uploadData);
        console.dir(getFirebaseUser().uid);

        await setDoc(doc(firestore, "challengesUser", getFirebaseUser().uid), uploadData, { merge: true });
        return {};
    }
};
