import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subscribePodcast, unsubscribePodcast } from '../../actions';
import { Button, message, Popconfirm } from 'antd';
import { fixedSpacing, colors, spacing } from '../../lib/theme';
import { showSubscriberCount } from '../../lib/utils';

class SubscribeOption extends Component {
    constructor(props) {
        super(props);
        this.state = { subscribed: undefined, loadingSubscribed: true, loadingConfirm: false };
    }
    onSubscribeClick(event, userDetails, title, author, imgSrc, collectionId, feedUrl, genres) {
        if (!userDetails || !userDetails.uid) {
            message.warning('Please sign in to subscribe to a podcast!');
            event.stopPropagation();
            return;
        }
        this.setState({ loadingConfirm: true });
        // console.dir(podcastInfo);
        this.props.subscribePodcast(userDetails.uid, title, author, imgSrc, collectionId, feedUrl, genres);
        event.stopPropagation();
    }
    onUnsubscribeClick(event, userDetails, podcastId) {
        if (!userDetails || !userDetails.uid) {
            message.warning('Please sign in to unsubscribe to a podcast!'); // may never happen
            event.stopPropagation();
            return;
        }
        this.setState({ loadingConfirm: true });
        this.props.unsubscribePodcast(userDetails.uid, podcastId);
        event.stopPropagation();
    }

    render() {
        return (<></>)
        const { userDetails, itunesId, podcast, subscribedPodcasts, type, showSubscribers, dbPodcastState, subscribersFromParent, onlyshowUnsubscribe } = this.props;
        // console.dir(dbPodcastState);
        const { loadingUserDetails, loadingPodcasts, loadingDBPodcastData } = this.props.loadingState;
        const loadingSubscribers = loadingDBPodcastData;
        let subscribers = 0;
        if (subscribersFromParent != null) {
            subscribers = subscribersFromParent;
        } else {
            subscribers = dbPodcastState[String(itunesId)] && dbPodcastState[String(itunesId)].subscribers || 0;
        }
        // console.dir(subscribers);

        let loadingSubscribed;
        if (loadingPodcasts || loadingUserDetails) {
            loadingSubscribed = true;
        } else {
            loadingSubscribed = false;
        }

        let subscribed = false;
        if (!loadingUserDetails && userDetails && !loadingPodcasts && subscribedPodcasts) {
            if (subscribedPodcasts[`${itunesId}`]) {
                subscribed = true;
            } else {
                subscribed = false;
            }
        } else if (!loadingUserDetails && !userDetails) {
            subscribed = false;
        }

        const collectionId = itunesId;
        const title = podcast.collectionName || podcast.title || '';
        const author = podcast.artistName || podcast.author || '';
        const feedUrl = podcast.feedUrl || podcast.url;
        const imgSrc = podcast['artworkUrl600'] || podcast.image;
        const genres = podcast['genres'] || [];
        // console.dir(podcast);
        // console.dir(itunesId);
        // console.log('subscribed + ' + subscribed);
        return (
            <div>
                {loadingSubscribed && <p>&hellip;</p>}
                {/* {!loadingSubscribed && !loadingPodcasts && userDetails && !subscribed && <Button type="primary" onClick={(event) => { this.onSubscribeClick(event, userDetails, title, author, imgSrc, collectionId, feedUrl) }}
                    loading={loadingConfirm}>SUBSCRIBE &nbsp; {loadingSubscribers ? '...' : subscribers}</Button>} */}
                {!loadingSubscribed && !subscribed && !onlyshowUnsubscribe &&
                    <>
                        {type === 'icon' &&
                            <div style={{ height: '32px', width: '32px' }} onClick={(event) => { this.onSubscribeClick(event, userDetails, title, author, imgSrc, collectionId, feedUrl, genres) }}>
                                <div className='bg-circle'>
                                    <FontAwesomeIcon style={{ fontSize: `${fixedSpacing('sm')}` }} icon='plus' />
                                </div>
                            </div>
                        }
                        {type !== 'icon' && <>
                            <Button shape="round" type='primary' className='btn-sub btn-sub-common' onClick={(event) => { this.onSubscribeClick(event, userDetails, title, author, imgSrc, collectionId, feedUrl, genres) }}
                            >SUBSCRIBE
                                {/* {showSubscribers && subscribers > 0 && <span>&nbsp; {loadingSubscribers ? '...' : (showSubscriberCount(subscribers))}
                                </span>} */}
                            </Button>
                        </>
                        }
                    </>
                }


                {
                    (!loadingSubscribed && subscribed) &&
                    <>
                        {type === 'icon' &&
                            <div style={{ height: '32px', width: '32px' }} onClick={(event) => { this.onUnsubscribeClick(event, userDetails, itunesId) }}>
                                <div className='bg-circle'>
                                    <FontAwesomeIcon style={{ fontSize: `${fixedSpacing('sm')}`, color: 'white' }} icon='check' />
                                </div>
                            </div>
                        }
                        {type !== 'icon' && <>
                            <Popconfirm title='Unsubscribe?' onConfirm={(event) => { this.onUnsubscribeClick(event, userDetails, itunesId) }}
                                onCancel={(e) => { e.stopPropagation(); }} okText="Unsubscribe" cancelText="Cancel">
                                <Button shape="round" className='btn-unsub btn-sub-common'>UNSUBSCRIBE
                                    {/* {showSubscribers && <span>&nbsp;{loadingSubscribers ? '...' : showSubscriberCount(subscribers)}
                                    </span>} */}
                                </Button>
                            </Popconfirm></>
                        }
                    </>
                }
                <style jsx>
                    {`
                    :global(.btn-sub) {
                        background: ${colors.primary};
                        color: white;
                        // width: 11vw;
                        // min-width: calc(1.5*100px);
                    }
                    :global(.btn-sub-common) {
                        // border-radius: ${spacing('md')};
                        // font-size: 0.8em;
                        font-size: 10px;
                        padding: 4px 12px;
                        width: 100%;
                    }
                    .bg-circle {
                        border-radius: 16px;
                        height: 24px;
                        width: 24px;
                        background: rgba(51,51,51, 0.7);;
                        color: white;
                        margin: auto;
                        text-align: center;
                    }
           :global(.btn-unsub) {
            background: hsl(0, 0%, 93.3%);
            color: hsla(0, 0%, 6.7%, .6);
            font-weight: 500;
           }
                `}
                </style>
            </div >
        )
    }
}

SubscribeOption.propTypes = {
    userDetails: PropTypes.object,
    subscribedPodcasts: PropTypes.object,
    loadingState: PropTypes.object,
    podcast: PropTypes.object,
    type: PropTypes.string,
    itunesId: PropTypes.number,
    loadingSubscribers: PropTypes.bool,
    loadingUserDetails: PropTypes.bool,
    loadingPodcasts: PropTypes.bool,
    showSubscribers: PropTypes.bool,
    dbPodcastState: PropTypes.object,
    loadingDBPodcastData: PropTypes.bool,
    onlyshowUnsubscribe: PropTypes.bool,
    subscribers: PropTypes.number,
    subscribePodcast: PropTypes.func,
    unsubscribePodcast: PropTypes.func,
    subscribersFromParent: PropTypes.number
}
SubscribeOption.defaultProps = {
    type: 'icon',
    onlyshowUnsubscribe: false
}

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails,
        subscribedPodcasts: state.podcastState,
        loadingState: state.loadingState,
        dbPodcastState: state.dbPodcastState
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        subscribePodcast: bindActionCreators(subscribePodcast, dispatch),
        unsubscribePodcast: bindActionCreators(unsubscribePodcast, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeOption);
