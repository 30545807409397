import { firestore } from '../lib/db';
import {
    ADD_PODCAST_TO_TAG_ACTION,
    ADD_SEARCH_ALERTS,
    CREATE_PODCAST_TAGS_ACTION,
    DELETE_PODCAST_TAGS_ACTION,
    DELETE_SEARCH_ALERTS,
    EDIT_PODCAST_TAGS_ACTION,
    FETCH_FOLLOWED_PLAYLISTS,
    FETCH_FOLLOWED_TAGS,
    FETCH_SEARCH_ALERTS,
    FOLLOW_TAG,
    LOADING_TAGS,
    REMOVE_PODCAST_FROM_TAG_ACTION,
    UNFOLLOW_TAG,
    USER_HAS_ADS,
    USER_INTERESTS_ACTION
}
    from './types';
import { Timestamp, collection, query, where, increment, deleteField, getDoc, doc, setDoc, getDocs, deleteDoc } from "firebase/firestore/lite";
import {
    FETCH_PODCAST_TAGS
}
    from './types';
import { fetchPersonalizedPodcasts, fetchAds, userHasAds, getBrowsePodcasts, getLocationInfoApi } from './index';
import { changeTagName, createRandomNumber, createRandomShortString, getFirebaseUser, serverApi, serverApiEurope, setAutoMarkEpisodesPlayed, shuffle, superToNormalGenres, websiteUrl } from '../lib/utils';
import fetch from 'isomorphic-unfetch';
import { setAddToQueueType, setForYouAdGenre } from '../lib/fileUtils';
import { notification } from 'antd';
import { tokenFetch } from '../lib/tokenUtils';
import { logTagPublic } from '../lib/analyticsEvents';

// ThunkAction<AppState> fetchPodcastTags() {
//     return (Store<AppState> store) async {
export let podcastAddToQueueGlobal = {};
export let myFilesSortType = 1;

export const pushPodcastTags = (docId, podcastTag) => async (dispatch, getState) => {
    dispatch({
        type: FETCH_PODCAST_TAGS,
        payload: { [docId]: podcastTag }
    });
}

export const fetchPodcastTags = (userUID) => async (dispatch, getState) => {
    getLocationInfoApi();
    getDocs(query(collection(firestore, "tags"), where("userID", "==", userUID)))
        .then((querySnapshot) => {
            let podcastTags = {};
            querySnapshot.forEach((doc) => {
                if (doc.exists()) {
                    podcastTags[doc.id] = doc.data();
                }
            });
            console.dir(podcastTags);
            dispatch({
                type: FETCH_PODCAST_TAGS,
                payload: podcastTags
            });
        })
        .catch((err) => {
            console.error(err);
        })
    getDoc(doc(firestore, `ad-history`, userUID))
        .then((doc) => {
            if (doc.exists()) {
                dispatch(userHasAds());
            }
        });
    dispatch({
        type: LOADING_TAGS,
        payload: {
            loadingTags: true
        }
    })
    let popularGenreIds = {
        'Comedy': true,
        'News': true,
        'Society-and-Culture': true
    };
    // let userDetails = getState().userDetails;
    // let userUID = userDetails['uid'];
    getDoc(doc(firestore, `podcastTags`, userUID))
        .then((doc) => {
            let followedPodcastTags = {};
            let followedPlaylists = {};
            let genre = '';
            let allgenreIds = [];
            let searchAlerts = {};

            if (doc.exists()) {
                const docData = doc.data();
                // podcastTags = docData['tags'] || {};
                followedPodcastTags = docData['followedTags'] || {};
                followedPlaylists = docData['followedPlaylists'] || {};
                console.dir(docData);
                if (docData['podcastAddToQueue']) {
                    podcastAddToQueueGlobal = docData['podcastAddToQueue'];
                }
                if (docData['interestsSuper']) {
                    let genreIds = docData['interestsSuper'];
                    console.dir(genreIds);
                    dispatch({ type: USER_INTERESTS_ACTION, payload: genreIds });
                    // print('podcastTags 22 genreIds : ' + genreIds.toString());
                    allgenreIds = Object.keys(genreIds);
                    //   allgenreIds.shuffle();
                    genre = allgenreIds[0];
                    if (allgenreIds.length > 1) {
                        setForYouAdGenre(allgenreIds[1]);
                    }
                    // store.dispatch(getBrowsePodcasts(genre));
                    dispatch(fetchPersonalizedPodcasts(genreIds));
                } else {
                    genre = 'Comedy';
                    allgenreIds.push(genre);
                    allgenreIds.push('News');
                    // dispatch(getBrowsePodcasts(genre));
                    dispatch(fetchPersonalizedPodcasts(popularGenreIds));
                }
                if ('autoMarkEpisodesPlayed' in docData) {
                    setAutoMarkEpisodesPlayed(docData['autoMarkEpisodesPlayed']);
                }
                if ('addToQueueType' in docData) {
                    setAddToQueueType(docData['addToQueueType']);
                }
                searchAlerts = docData['searchAlerts'] || {};
                myFilesSortType = docData['myFilesSortType'] || 1;
            }
            // console.dir(podcastTags);
            // store.dispatch(FetchPodcastTagsAction(podcastTags));
            console.dir(allgenreIds);

            // for (let i = 0; i < allgenreIds.length; i++) {
            //     dispatch(fetchAds(allgenreIds[i], i <= 1));
            // }
            let allgenreIdsLimit = superToNormalGenres(allgenreIds);
            console.dir(allgenreIdsLimit);
            allgenreIdsLimit = shuffle(allgenreIdsLimit);
            console.dir(allgenreIdsLimit);
            // utils.printLog(allgenreIdsLimit);
            ///// always add 'Health' Genre
            const genresFin = ['11', ...allgenreIdsLimit].slice(0, 5);
            console.dir(genresFin);
            // utils.printLog(genresFin);
            // final List<Map> relevantGenres = genres
            //     .superGenres()
            //     .where((el) => allgenreIdsLimit.contains(el['id']))
            //     .toList();
            genresFin.forEach((elementId) => {
                dispatch(getBrowsePodcasts(elementId, true));
            });
            dispatch({
                type: FETCH_SEARCH_ALERTS,
                payload: searchAlerts
            });
            // dispatch({
            //     type: FETCH_PODCAST_TAGS,
            //     payload: podcastTags
            // });
            dispatch({
                type: FETCH_FOLLOWED_TAGS,
                payload: followedPodcastTags
            });
            dispatch({
                type: FETCH_FOLLOWED_PLAYLISTS,
                payload: followedPlaylists
            });
            let newState = {
                loadingTags: false
            };
            // console.dir(userDetails);
            setTimeout(() =>
                dispatch({
                    type: LOADING_TAGS,
                    payload: newState
                }), 1000);
        }).catch((error) => {
            console.log("Error getting documents: " + error.toString());
        });
}

export const setAddToQueueTypeGlobal = (addToQueueType, userDetails) => {
    setAddToQueueType(addToQueueType);
    setDoc(doc(firestore, 'podcastTags', userDetails['uid']), { 'addToQueueType': parseInt(addToQueueType) }, { merge: true });
}

// ThunkAction<AppState> saveUserInterests(Map genreIds) {
//   return (Store<AppState> store) async {
export const saveUserInterests = (genreIds) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails != null ? userDetails['uid'] : getFirebaseUser().uid;
    // store.dispatch(UserInterestsAction(genreIds));
    dispatch({ type: USER_INTERESTS_ACTION, payload: genreIds });
    await setDoc(doc(firestore, 'podcastTags', userUID), { 'interestsSuper': deleteField() }, { merge: true });
    setDoc(doc(firestore, 'podcastTags', userUID), { 'interestsSuper': genreIds }, { merge: true });
}

export const autoShareTweet = async (guid, type = 1) => {
    tokenFetch(
        `${serverApi}autoShareTweet?guid=${encodeURIComponent(guid)}&type=${type}`)
        .then((value) => { });
}


export const createPodcastTag = (tagId, tagName, collectionsIdsMap) => async (dispatch, getState) => {
    // final Map userDetails = userDetailsStateSelector(store.state);
    // final String userUID = userDetails['uid'];
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    const userID = userDetails['uid'];
    let podcastsUpdate = {};
    let podcastTagObj = collectionsIdsMap;

    /// {1234: true, etc}
    podcastsUpdate[`${tagName}`] = podcastTagObj;
    dispatch({ type: CREATE_PODCAST_TAGS_ACTION, tagId, tagName, userID, collectionsIdsMap });
    setDoc(doc(firestore, 'tags', tagId), {
        'podcasts': collectionsIdsMap,
        'name': tagName,
        'userID': userID,
        'created': Date.now()
    }, { merge: true });
}

export const editPodcastTag = (tagId, newTagName) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    const userUID = userDetails['uid'];

    dispatch({ type: EDIT_PODCAST_TAGS_ACTION, tagId, newTagName });
    console.dir(tagId);
    setDoc(doc(firestore, 'tags', tagId), { 'name': newTagName }, { merge: true });
}

export const togglePodcastTagPrivacy = (tagName, rand, makePublic = false) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    const userUID = userDetails['uid'];
    // print('newTagName : ' + newTagName);
    // print('oldTagName : ' + oldTagName);
    console.dir(rand);
    const randFin = rand || createRandomNumber();
    console.dir(randFin);
    const newTagName = changeTagName(tagName, randFin, makePublic); // tagName;
    console.dir(tagName);
    console.dir(newTagName);
    // final String tagRoot = tagName.split('_u_')[0];

    if (makePublic && newTagName.includes('_u_p')) {
        // final String tagNameTail = newTagName.split('_u_p')[1];
        // utils
        //     .shareGeneric(utils.webAppUrl + '/tags?id=${userUID}_${tagNameTail}');
        // utils.sharePublicTag(userUID, newTagName, context);
        const tagNameTail = newTagName.split('_u_p')[1];
        notification.info(
            {
                duration: 0,
                message: 'Share this url: ' + websiteUrl + `/collections/${userUID}_${tagNameTail}`
            });
    }
    // print('newTagName: ' + newTagName);
    const podcastTags = getState().podcastTags; // podcastTagsSelector(store.state);
    console.dir(podcastTags);
    const podcastsUpdate = {};
    // podcastsUpdate['tags'] = {};
    console.dir(podcastTags[`${tagName}`]);
    podcastsUpdate[`${newTagName}`] = podcastTags[`${tagName}`] || {};
    console.dir(podcastsUpdate[`${newTagName}`]);
    // console.dir(podcastsUpdate[`${tagName}`]);
    podcastsUpdate[`${tagName}`] = deleteField();
    console.dir(podcastsUpdate[`${tagName}`]);
    console.dir(podcastsUpdate[`${newTagName}`]);

    dispatch({ type: EDIT_PODCAST_TAGS_ACTION, oldTagName: tagName, newTagName });

    setDoc(doc(firestore, 'podcastTags', userUID), { 'tags': podcastsUpdate }, { merge: true });
    logTagPublic(`${1}`, userUID, makePublic ? 1 : 0);
}

export const deletePodcastTag = (tagId) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    console.dir(tagId);
    // const userUID = userDetails['uid'];
    // const podcastsUpdate = {};
    // // podcastsUpdate['tags'] = {};
    // podcastsUpdate[`${tagId}`] = deleteField();
    // setDoc(doc(firestore, 'podcastTags', userUID), { 'tags': podcastsUpdate }, { merge: true });
    deleteDoc(doc(firestore, "tags", tagId));
    dispatch({ type: DELETE_PODCAST_TAGS_ACTION, tagId });
}

export const addPodcastToTag = (tagId, collectionsIdsMap) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    const userUID = userDetails['uid'];
    if (Object.keys(collectionsIdsMap).length > 0) {
        // const podcastsUpdate = {};
        // final Map map1 = {};
        // podcastsUpdate[`${tagName}`] = collectionsIdsMap;
        // podcastsUpdate['tags'] = map1;
        // print('podcastsUpdate : ' + podcastsUpdate.toString());
        dispatch({ type: ADD_PODCAST_TO_TAG_ACTION, tagId, collectionsIdsMap });
        setDoc(doc(firestore, 'tags', tagId), { 'podcasts': collectionsIdsMap }, { merge: true });
    }
}

export const removePodcastFromTag = (tagId, collectionId) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    if (!userDetails || !userDetails['uid']) {
        return;
    }
    const userUID = userDetails['uid'];
    // const podcastsUpdate = {};
    const deleteMap = {};
    deleteMap[`${collectionId}`] = deleteField();
    // podcastsUpdate[`${tagName}`] = deleteMap;
    dispatch({ type: REMOVE_PODCAST_FROM_TAG_ACTION, tagId, collectionId });
    setDoc(doc(firestore, 'tags', tagId), { 'podcasts': deleteMap }, { merge: true });
}

export const addPodcastToQueueSetting = (collectionId, val) => {
    podcastAddToQueueGlobal[collectionId] = { 'enabled': true, 'type': val };
    // const tempLocMap = {
    //     [`${collectionId}`]: { 'enabled': true, 'type': val }
    // };
    // podcastAddToQueueNotifier.value = {}
    //   ..addAll(podcastAddToQueueNotifier.value)
    //   ..addAll(tempLocMap);
    const newMapToAdd = {};
    const finalMap = {};
    if (val == 0) {
        newMapToAdd[`${collectionId}`] = deleteField();
    } else {
        newMapToAdd[`${collectionId}`] = { 'enabled': true, 'type': val };
    }
    finalMap['podcastAddToQueue'] = newMapToAdd;
    setDoc(doc(firestore, 'podcastTags', getFirebaseUser().uid), finalMap, { merge: true });
    // FirebaseFirestore.instance
    //     .collection('podcastTags')
    //     .doc(FirebaseAuth.instance.currentUser.uid)
    //     .set(finalMap, SetOptions(merge: true));
}

// ThunkAction<AppState> addToPlayedCount(collectionId, int count) {
//     return (Store<AppState> store) async {
export const addToPlayedCount = (collectionId, count) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    let collectionsIdMap = {};
    collectionsIdMap[`${collectionId}`] = increment(count);
    let podcastsUpdate = {};
    podcastsUpdate['playedCount'] = collectionsIdMap;
    //   print('podcastsUpdate 1: ' + podcastsUpdate.toString());
    //   store.dispatch(AddPlayedCountAction('$collectionId', count));
    setDoc(doc(firestore, 'podcastTags', userUID), podcastsUpdate, { merge: true });
    //       .catchError((error) {
    //     print("Error getting documents: " + error.toString());
    //   });
}

//   ThunkAction<AppState> setPlayedCount(collectionId, int finalVal) {
//     return (Store<AppState> store) async {
export const setPlayedCount = (collectionId, finalVal) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    let collectionsIdMap = {};
    collectionsIdMap[`${collectionId}`] = finalVal;
    let podcastsUpdate = {};
    podcastsUpdate['playedCount'] = collectionsIdMap;
    //   print('podcastsUpdate 2: ' + podcastsUpdate.toString());
    //   store.dispatch(SetPlayedCountAction('$collectionId', finalVal));
    setDoc(doc(firestore, 'podcastTags', userUID), podcastsUpdate, { merge: true });
    //       .catchError((error) {
    //     print("Error getting documents: " + error.toString());
    //   });
}

export const getPublicTagDetails = async (tagID) => {
    try {
        // const query = 'http://localhost:5002/books-af362/europe-west1/' + `getPublicTag?tagID=${tagID}`;
        const query = serverApiEurope + `getPublicTag?tagID=${tagID}`;
        console.log('query00: ' + query);
        const results = await fetch(query);
        const json = results.json();
        return json;
    } catch (err) {
        return {};
    }
}
export const followTag = (tagId, tagName, tagUsername, tagUserUID) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    let tagMap = {};
    tagMap[`${tagId}`] = { name: tagName, user: tagUsername, userUID: tagUserUID };
    let tagUpdate = {};
    tagUpdate['followedTags'] = tagMap;
    dispatch({ type: FOLLOW_TAG, tagId: tagId, payload: tagMap[`${tagId}`] });
    setDoc(doc(firestore, 'podcastTags', userUID), tagUpdate, { merge: true });
    tokenFetch(
        `${serverApi}followTagTrending?tagId=${tagId}&tagUserId=${tagUserUID}&type=follow`)
        .then((value) => null);
}
export const unfollowTag = (tagId) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    let tagMap = {};
    tagMap[`${tagId}`] = deleteField();
    let tagUpdate = {};
    tagUpdate['followedTags'] = tagMap;
    dispatch({ type: UNFOLLOW_TAG, tagId: tagId });
    setDoc(doc(firestore, 'podcastTags', userUID), tagUpdate, { merge: true });
    tokenFetch(
        `${serverApi}followTagTrending?tagId=${tagId}&tagUserId=${tagUserUID}&type=unfollow)`)
        .then((value) => null);
}


export const addSearchAlert = (alertInfo) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    let tagUpdate = {};
    if (alertInfo.id) {
        tagUpdate['searchAlerts'] = {};
        tagUpdate['searchAlerts'][alertInfo.id] = alertInfo;
    } else {
        const randId = createRandomShortString(5);
        tagUpdate['searchAlerts'] = { [randId]: { ...alertInfo, id: randId } };
        alertInfo['id'] = randId;
    }
    if (alertInfo.emailInterval == 1 || alertInfo.notificationInterval == 1) {
        tagUpdate['searchAlertsDaily'] = true;
    }
    if (alertInfo.emailInterval == 7 || alertInfo.notificationInterval == 7) {
        tagUpdate['searchAlertsWeekly'] = true;
    }
    dispatch({ type: ADD_SEARCH_ALERTS, alertId: alertInfo.id, payload: tagUpdate['searchAlerts'][alertInfo.id] });
    setDoc(doc(firestore, 'podcastTags', userUID), tagUpdate, { merge: true }).catch(err => {
        notification.error({
            message: 'There was an error adding the search alert. Please try again.',
            description: '',
            duration: null
        })
    });
}

export const deleteSearchAlert = (alertId) => async (dispatch, getState) => {
    const userDetails = getState().userDetails;
    const userUID = userDetails['uid'];
    // let tagMap = {};
    let tagUpdate = {};
    tagUpdate['searchAlerts'] = {};
    tagUpdate['searchAlerts'][alertId] = deleteField();
    console.dir(tagUpdate);
    dispatch({ type: DELETE_SEARCH_ALERTS, alertId: alertId });
    setDoc(doc(firestore, 'podcastTags', userUID), tagUpdate, { merge: true }).catch(err => {
        notification.error({
            message: 'There was an error deleting the search alert. Please try again.',
            description: '',
            duration: null
        })
    });
}