import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { authError, retrieveLocally, saveLocally, staticAssetsPrefix } from '../../lib/utils';

import { LockOutlined, UserOutlined, AppleFilled } from '@ant-design/icons';

// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Button, Col, Form, Input, Row } from 'antd';
import { colors, spacing, mainLayoutColor } from '../../lib/theme';
import AppAlertDiv from '../Common/AppAlertDiv';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 18 },
    },
};
class WrappedForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            forgotPasswordForm: false,
            showSocialAuth: true,
        };
    }

    componentDidMount() {
        const isPWAFromLocalStorage = retrieveLocally('isPWA');
        // const isPWA = urlParams.get('source') === 'pwa' || isPWAFromLocalStorage;
        if (isPWAFromLocalStorage) {
            this.setState({ showSocialAuth: false });
            saveLocally('isPWA', true, 24 * 30, false);
        }
    }

    handleSubmit = (values) => {
        const { forgotPasswordForm } = this.state;
        // e.preventDefault();
        if (forgotPasswordForm) {
            // this.props.form.validateFields((err, values) => {
            //     if (!err) {
            console.log('Received values of form: ', values);
            console.log(values.email);
            values['forgotpasswordForm'] = true;
            // sendPasswordResetEmail(values.email);
            this.props.callbackParent(values);
            //     }
            // });
        } else {
            // this.props.form.validateFields((err, values) => {
            //     if (!err) {
            console.log('Received values of form: ', values);
            this.props.callbackParent(values);
            //     }
            // });
        }
    }

    validateRegistrationCode = (rule, value, callback) => {
        // const form = this.props.form;
        if (value && value !== '2yRSATgZ') {
            callback('The registration code is not valid!');
        } else {
            callback();
        }
    };

    validatePassword = (rule, value, callback) => {
        // const form = this.props.form;
        if (value && value.length < 6) {
            callback('Password must be atleast six characters!');
        } else {
            callback();
        }
    };

    forgotPasswordFormStart = () => {
        this.setState({ forgotPasswordForm: true });
    }

    socialSignIn = (providerName) => {
        this.props.callbackParentSocialAuth(providerName);
    }

    // handleSearch = (value) => {
    //     let result = [];
    //     // const emailProviders = ['@gmail.com', '@yahoo.com']
    //     if (value.indexOf('@g') >= 0) {
    //         result.push(value.replace('@g', '@gmail.com')); //.map(domain => `${value}@${domain}`);
    //     } else {
    //         // result = [];
    //     }
    //     this.setState({ result });
    // }

    render() {
        // console.dir(this.props);
        const { forgotPasswordForm, result, showSocialAuth } = this.state;
        console.dir(forgotPasswordForm);
        const { type, typeId, errorObj, confirmLoading, forgotPasswordSuccess, settingsState } = this.props;
        // const { getFieldDecorator } = this.props.form;
        console.dir(errorObj);
        return (
            <Form onFinish={this.handleSubmit} className="login-form" layout='horizontal'>
                <Row style={{ justifyContent: 'center' }}>
                    <Col md={24} xl={11} style={{ padding: `${spacing('xxs')}`, margin: '0 8px', width: '100%' }}>
                        <Button onClick={this.socialSignIn.bind(this, 'google')}
                            style={{ boxShadow: '0 20px 40px 0 rgba(0, 11, 40, .1)' }}
                            className="login-form-button social-button social-btn-google">
                            <svg style={{ marginRight: '12px', transform: 'translate(40%, 0)' }} className="social-svg svgIcon-use" width="25" height="25"><g fill="none" fillRule="evenodd"><path d="M20.66 12.693c0-.603-.054-1.182-.155-1.738H12.5v3.287h4.575a3.91 3.91 0 0 1-1.697 2.566v2.133h2.747c1.608-1.48 2.535-3.65 2.535-6.24z" fill="#4285F4"></path><path d="M12.5 21c2.295 0 4.22-.76 5.625-2.06l-2.747-2.132c-.76.51-1.734.81-2.878.81-2.214 0-4.088-1.494-4.756-3.503h-2.84v2.202A8.498 8.498 0 0 0 12.5 21z" fill="#34A853"></path><path d="M7.744 14.115c-.17-.51-.267-1.055-.267-1.615s.097-1.105.267-1.615V8.683h-2.84A8.488 8.488 0 0 0 4 12.5c0 1.372.328 2.67.904 3.817l2.84-2.202z" fill="#FBBC05"></path><path d="M12.5 7.38c1.248 0 2.368.43 3.25 1.272l2.437-2.438C16.715 4.842 14.79 4 12.5 4a8.497 8.497 0 0 0-7.596 4.683l2.84 2.202c.668-2.01 2.542-3.504 4.756-3.504z" fill="#EA4335"></path></g></svg>
                            <span style={{ paddingTop: '2px' }}> Continue with Google</span>
                        </Button>
                    </Col>
                    {/* <Col style={{ padding: `${spacing('xxs')}`, margin: '0 8px' }} xl={3}>
                                    <Button onClick={this.socialSignIn.bind(this, 'fb')} className="login-form-button social-button">
                                        <svg style={{ marginRight: '12px', transform: 'translate(40%, 0)' }} className="social-svg svgIcon-use" width="25" height="25"><path style={{ color: '#3b5998', fill: '#3b5998' }} d="M20.292 4H4.709A.709.709 0 0 0 4 4.708v15.584c0 .391.317.708.709.708h8.323v-6.375h-2.125v-2.656h2.125V9.844c0-2.196 1.39-3.276 3.348-3.276.938 0 1.745.07 1.98.1v2.295h-1.358c-1.066 0-1.314.507-1.314 1.25v1.756h2.656l-.531 2.656h-2.125L15.73 21h4.562a.708.708 0 0 0 .708-.708V4.708A.708.708 0 0 0 20.292 4" fillRule="evenodd"></path></svg>
                                    </Button>
                                </Col> */}
                    <Col md={24} l xl={11} style={{ padding: `${spacing('xxs')}`, margin: '0 8px', width: '100%' }}>
                        <Button onClick={this.socialSignIn.bind(this, 'apple')}
                            style={{ textAlign: 'center', boxShadow: '0 20px 40px 0 rgba(0, 11, 40, .1)' }}
                            className="login-form-button social-button">
                            <AppleFilled style={{
                                fontSize: '1.25em',
                            }} />
                            <span style={{ paddingTop: '2px' }}> Continue with Apple</span>
                        </Button>
                    </Col>
                </Row>

                <div style={{ marginBottom: '48px' }}>
                    <p className='social-text'><span>or</span></p>

                </div>
                <Form.Item {...formItemLayout} label="Email" name="email" validateTrigger='onSubmit' rules={[{
                    type: 'email', message: 'The input is not valid E-mail!',
                }, {
                    required: true, message: 'Please input your E-mail!',
                }]}>
                    {/* {getFieldDecorator('email', {
                        validateTrigger: 'onSubmit',
                        rules: [{
                            type: 'email', message: 'The input is not valid E-mail!',
                        }, {
                            required: true, message: 'Please input your E-mail!',
                        }],
                    })( */}
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email" />
                    {/* )
                    } */}
                </Form.Item>
                {!forgotPasswordForm && <Form.Item {...formItemLayout} label='Password' name='password' validateTrigger='onSubmit'
                    rules={[{ required: true, message: 'Please input your Password!' }, { validator: this.validatePassword }]}>
                    {/* {getFieldDecorator('password', {
                        validateTrigger: 'onSubmit',
                        rules: [{ required: true, message: 'Please input your Password!' }, { validator: this.validatePassword }],
                    })( */}
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                    {/* )} */}
                </Form.Item>}
                {typeId === 'signUp' && <Form.Item {...formItemLayout} label='Name' name='name' validateTrigger='onSubmit'
                    rules={[{ required: true, message: 'Please input your Name!' }]}>
                    {/* {getFieldDecorator('name', {
                        rules: [{ required: true, message: 'Please input your Name!' }],
                    })( */}
                    <Input prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="What should we call you :)" />
                    {/* )} */}
                </Form.Item>}
                {/* {typeId === 'signUp' && <Form.Item {...formItemLayout} label={<span>
                    Code&nbsp;
              <Tooltip title="This is currently a beta version and access is possible only with a registration code.">
                        <Icon type="question-circle-o" />
                    </Tooltip>
                </span>}>
                    {getFieldDecorator('code', {
                        validateTrigger: 'onSubmit',
                        rules: [{
                            required: true, message: 'Please input the registration code.',
                        }, {
                            validator: this.validateRegistrationCode,
                        }
                        ],
                    })(
                        <Input prefix={<Icon type="number" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Registration Code" />
                    )
                    }
                </Form.Item>} */}
                {errorObj.code &&
                    // <Alert
                    //     message={authError[errorObj.code] || errorObj.message}
                    //     // description="Error Description Error Description Error Description Error Description Error Description Error Description"
                    //     type="error"
                    //     closable
                    // // onClose={onClose}
                    // />
                    <AppAlertDiv message={authError[errorObj.code] || errorObj.message} type="error"
                        closable />
                    // <p>{authError[errorObj.code]}</p>
                }
                {typeId === 'signIn' && !forgotPasswordForm && <div style={{ textAlign: 'right', marginBottom: `${spacing('md')}` }}><a onClick={this.forgotPasswordFormStart}>Forgot password&#63;</a></div>}
                <br />
                <Form.Item>
                    {/* {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(
                        <Checkbox>Remember me</Checkbox>
                    )} */}
                    {/* <br /> */}
                    {!forgotPasswordForm && <div>
                        <Button htmlType="submit" loading={confirmLoading} className="third-button login-form-button" style={{ textAlign: 'center' }}>
                            {type}
                        </Button>
                        {/* {showSocialAuth && */}
                        {/* // } */}
                    </div>}
                    {forgotPasswordForm && !forgotPasswordSuccess &&
                        <Button type="primary" htmlType="submit" loading={confirmLoading}
                            className="login-form-button" style={{ textAlign: 'center' }}>
                            Send Password Reset Email
                        </Button>}
                    {forgotPasswordSuccess && <p>Please check your email to reset your password.</p>}
                    {/* Or <a href="">register now!</a> */}
                </Form.Item>
                <style jsx>
                    {`
            .login-form-forgot {
                float: right;
              }
              :global(.login-form-button) {
                width: 100%;
                // margin-left: 10%;
                border-radius: ${spacing('md')};
                height: ${spacing('xl')};
                text-align: left;
                padding: 0;
              }
              :global(.social-button) {
                width: 100%; //280px;
                text-align: center;
                display: flex !important;
                justify-content: center;
                align-items: center;
              }
              :global(.social-btn-google) {
                background: white;
                color: black;
              }
              .social-svg {
                // float: left;
                // transform: translate(0, -2px);
              }
              .social-text {
                width: 95%;
                text-align: center;
                border-bottom: 1px solid hsla(198, 8%, 32%, 1);
                line-height: 0.1em;
                margin: ${spacing('xl')} ${spacing('')} 2em ${spacing('')};
              }
              .social-text span{
                background: ${settingsState.mainLayoutColor};
                padding: 0 10px;
              }
                :global(.third-button) {
                background: ${colors.third};
                border-color: ${colors.third};
                color: white;
            }
            :global(.third-button.outline) {
                background: white; // ${colors.third};
                border-color: ${colors.third};
                color: ${colors.third};
            }
            :global(.third-button:hover) {
                background: #8d2256;
                border-color: #8d2256;
                color: white;
            }
          `}
                </style>
            </Form>
        );
    }
}

WrappedForm.propTypes = {
    form: PropTypes.object,
    errorObj: PropTypes.object,
    settingsState: PropTypes.object,
    confirmLoading: PropTypes.bool,
    getFieldDecorator: PropTypes.func,
    type: PropTypes.string,
    typeId: PropTypes.string,
    forgotPasswordSuccess: PropTypes.bool,
    callbackParent: PropTypes.func,
    callbackParentSocialAuth: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        settingsState: state.settingsState,
    }
}

// const WrappedNormalLoginForm = Form.create({name: 'normal_login' })(WrappedForm);

// ReactDOM.render(<WrappedNormalLoginForm />, mountNode);
// const mapDispatchToProps = (dispatch) => {
//     return {
//     }
// };

export default connect(mapStateToProps, null)(WrappedForm);