import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Router from 'next/router';
import PrivacyPolicy from '../Admin/PrivacyPolicy';
import TermsConditions from '../Admin/TermsConditions';

// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';

import { Button, Col, Row } from 'antd';

import WrappedForm from './WrappedLoginForm';
const WrappedNormalLoginForm = WrappedForm; // Form.create({ name: 'normal_login' })(WrappedForm);

import { createUser, createUserSocialSignIn, createUserSuccess, signInUser, signInUserSuccess, setInterests, setInterestsFirstTimeOnboard } from '../../actions';
import { sendPasswordResetEmailFn, createPlaylist, createPlaylistPersonal } from '../../actions';

import { colors, mainLayoutColor, spacing, leftSideBar, leftSideBarColor, leftSideBarColor1, leftSideBarColor2, minScreenSize, maxScreenSize } from '../../lib/theme';
import { retrieveLocally, saveLocally, staticAssetsPrefix, websiteUrl } from '../../lib/utils';
import Interests from '../Common/Interests';


class AuthComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            typeId: props.typeId,
            confirmLoading: false,
            forgotPasswordSuccess: false,
            showWaitlistForm: false,
            errorObj: {}, showInterests: props.showInterests || false,
            userData: props.userData || {}
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showInterests != this.props.showInterests) {
            this.setState({ showInterests: this.props.showInterests });
        }
        if (prevProps.userData != this.props.userData) {
            this.setState({ userData: this.props.userData });
        }
    }

    onChildChanged = (userInfo) => {
        const { parent, isAds } = this.props;
        const { typeId } = this.state;
        // console.dir(userInfo);
        this.setState({
            confirmLoading: true, errorObj: {}
        });
        if (typeId === 'signUp') {
            createUser(userInfo)
                .then((data) => {
                    console.dir(data);
                    this.setState({
                        confirmLoading: false,
                        // visible: false
                    });

                    //******** create default playlist */
                    let playlistInfo = { 'name': 'Listen Later', 'description': 'Default Playlist' };
                    createPlaylist(data.user, playlistInfo)
                        .then((data) => {
                            if (data.success) {
                                console.dir(data);
                                this.props.createPlaylistPersonal(data.playlistInfo, data.playlistId);
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        });
                    if (parent === 'loginPage') {
                        // Router.push(`/login?showInterests=true&userData=${JSON.stringify(data)}`);
                        saveLocally('authUserData', JSON.stringify(data), 24, true);
                        Router.push(`/login?showInterests=true`);
                    } else if (parent === 'onboardLoginPage') {
                        saveLocally('authUserData', JSON.stringify(data), 24, true);

                        const interestsString = retrieveLocally('selectedGenreIds');
                        if (interestsString) {
                            const interestsMap = JSON.parse(interestsString);
                            this.props.setInterestsFirstTimeOnboard(interestsMap, data.user);
                        }

                        this.props.createUserSuccess(data.user);
                        Router.push(`/trial`);
                    } else {
                        if (isAds) {
                            // this.setState({ userData: data });
                            // this.handleInterestsDone({ 'Comedy': true, 'News': true });
                        } else {
                            this.props.changeClosable();
                            this.setState({ showInterests: true, userData: data });
                        }
                    }

                })
                .catch((error) => {
                    console.log(error.message);
                    console.dir(error);
                    this.setState({
                        confirmLoading: false, errorObj: error
                    });
                })
        } else if (typeId === 'signIn') {
            if (userInfo['forgotpasswordForm'] && userInfo['forgotpasswordForm'] == true) {
                sendPasswordResetEmailFn(userInfo.email)
                    .then(() => {
                        // console.log(data);
                        this.setState({
                            confirmLoading: false,
                            forgotPasswordSuccess: true
                            // visible: false
                        });
                    })
                    .catch((error) => {
                        console.log(error.message);
                        this.setState({
                            confirmLoading: false, errorObj: error
                        });
                    })
            } else {
                signInUser(userInfo)
                    .then((data) => {
                        if (parent === 'onboardLoginPage') {
                            this.props.signInUserSuccess(data.user, 1500);
                            Router.push(`/trial`);
                        } else {
                            this.props.callbackAuthParent({ 'visible': false });
                            this.props.signInUserSuccess(data.user, 1500);
                        }
                    })
                    .catch((error) => {
                        console.log(error.message);
                        this.setState({
                            confirmLoading: false, errorObj: error
                        });
                    })
            }
        }
    }

    onChildChangedSocialAuth = (providerName) => {
        const { isAds } = this.props;
        createUserSocialSignIn(providerName, isAds)
            .then((data) => {
                console.dir(data);
                this.setState({
                    confirmLoading: false,
                    // visible: false
                });
                let userData = data.userData;
                let firstSignIn = data.firstSignIn;
                //******** create default playlist */
                if (firstSignIn === true) {

                    // this.props.createUserSuccess(userData.user);
                    // this.props.callbackAuthParent({ 'visible': false });
                    let playlistInfo = { 'name': 'Listen Later', 'description': 'Default Playlist' };
                    createPlaylist(userData.user, playlistInfo)
                        .then((playlistData) => {
                            if (playlistData.success) {
                                console.dir(playlistData);
                                this.props.createPlaylistPersonal(playlistData.playlistInfo, playlistData.playlistId);
                            }
                        })
                        .catch(err => {
                            console.error(err);
                        });

                    if (parent === 'loginPage') {
                        Router.push(`/login?showInterests=true&userData=${JSON.stringify(userData)}`);
                    } else if (parent === 'onboardLoginPage') {
                        const interestsString = retrieveLocally('selectedGenreIds');
                        if (interestsString) {
                            const interestsMap = JSON.parse(interestsString);
                            this.props.setInterestsFirstTimeOnboard(interestsMap, data.user);
                        }

                        this.props.createUserSuccess(userData.user);
                        Router.push(`/trial`);
                    } else {
                        this.props.changeClosable();
                        this.setState({ showInterests: true, userData: userData });
                    }
                } else {
                    if (parent === 'onboardLoginPage') {
                        this.props.signInUserSuccess(userData.user, 1500);
                        Router.push(`/trial`);
                    } else {
                        this.props.callbackAuthParent({ 'visible': false });
                        this.props.signInUserSuccess(userData.user, 1500);
                    }
                }
            })
            .catch((error) => {
                console.dir(error);
                if (error.type === 'duplicateAuth') {
                    this.setState({
                        confirmLoading: false, errorObj: error
                    });
                    return;
                }
                console.log(error.message);
                this.setState({
                    confirmLoading: false, errorObj: error
                });
            })
    }

    handleInterestsDone = (selectedGenreIds) => {
        const { userData } = this.state;
        console.dir(userData);
        console.dir(userData.user);
        if (selectedGenreIds) {
            //// no need to do here anymore
            // setInterests(selectedGenreIds, userData.user);
        }
        this.props.callbackAuthParent({ 'visible': false });
        this.props.createUserSuccess(userData.user);
    }

    toggleFormType = (typeId) => {
        this.setState({ typeId: typeId });
    }

    render() {
        const { settingsState, isAds, parent } = this.props;
        const { typeId } = this.state;

        let type = '';
        if (typeId == 'signIn') {
            type = 'Sign In';
        } else if (typeId == 'signUp') {
            type = 'Register';
        }

        // const isOnboarding = parent == 'onboardLoginPage';

        const { confirmLoading, errorObj, forgotPasswordSuccess, showInterests } = this.state;

        return (
            <div>
                {showInterests && <div className='interests-div'><Interests isEdit={false} handleClose={this.handleInterestsDone} /></div>}
                {!showInterests && <Row style={{
                    display: 'flex',
                    boxShadow: '0 20px 40px 0 rgba(0,11,40,.04)'
                }}>
                    <Col xs={0} md={10} xl={9} className='' style={{ background: colors.brown, borderRadius: '12px 0 0 12px' }}>
                        <div className='left-bar' style={{ padding: `${spacing('md')}` }}>
                            {/* <div className='empty-state left-bar-background'>
                                <div className="layer">
                                </div>
                            </div> */}
                            <div className='left-div'>
                                <img style={{ width: '160px', marginBottom: '2em' }} src={`${staticAssetsPrefix}/static/images/login/logoFull.png`} />
                                <h1 className='serif' style={{ marginBottom: '1.5em' }}>Better Career,<br />
                                    Health & Life<br />
                                    in 15 min a day </h1>
                                <div className='' style={{
                                    marginBottom: '2em'
                                }}>
                                    {/* <a href={`${websiteUrl}`} style={{ display: 'flex', alignItems: 'center' }} target='_blank' rel="noopener noreferrer"> */}
                                    <img style={{ width: '100%', maxWidth: '250px' }} src={`${staticAssetsPrefix}/static/images/left-bar.png`} alt='Accelerated main' />
                                    {/* <h3 style={{ fontWeight: '700', marginBottom: '0', fontSize: '2em' }}>&nbsp;
                                        <span style={{ color: 'white' }}>Accelerated</span></h3> */}
                                    {/* </a> */}
                                </div>
                                {!isAds && <div className=''>
                                    <h2>
                                        Making non-fiction<br />
                                        fast & fun<br />
                                        <span style={{ color: colors.darkRed }}>8000+</span> summaries
                                    </h2>
                                    {/* <p>Listen on unlimited devices</p> */}
                                </div>}
                            </div>
                            {/* <h3 style={{ textAlign: 'center' }}>Come join us&#33;</h3> */}
                        </div>
                    </Col>
                    <Col xs={24} md={14} xl={15} style={{
                        padding: `${spacing('md')} ${spacing('lg')}`,
                        background: `${settingsState.mainLayoutColor}`, borderRadius: '0 12px 12px 0', height: '100%', minHeight: '70vh'
                    }}>
                        <div>
                            <h2 style={{ marginBottom: `${spacing('md')}` }}>{typeId == 'signIn' ? type : 'Register'}</h2>
                            {typeId == 'signUp' && <p>Already a member? <a className='signIn-btn' onClick={this.toggleFormType.bind(this, 'signIn')} >Sign In</a></p>}
                            {typeId == 'signIn' && <p>Not a member yet? <a className='signIn-btn' onClick={this.toggleFormType.bind(this, 'signUp')}>Register</a></p>}
                            <br />
                            <WrappedNormalLoginForm forgotPasswordSuccess={forgotPasswordSuccess} typeId={typeId}
                                type={type} callbackParent={this.onChildChanged} callbackParentSocialAuth={this.onChildChangedSocialAuth}
                                errorObj={errorObj} confirmLoading={confirmLoading} />

                            {typeId == 'signUp' && <p>
                                {/* By signing up, you agree to Accelerated&apos;s <a href={`${websiteUrl}/explore/privacy-policy`} target='_blank' rel="noopener noreferrer">Privacy Policy</a> and&nbsp;
                                    <a href={`${websiteUrl}/explore/terms-and-conditions`} target='_blank' rel="noopener noreferrer">Terms and Conditions</a>. */}
                            </p>}
                        </div>
                    </Col>
                </Row>}
                {/* } */}
                <style jsx>
                    {`
                        .interests-div {
                            padding: 2em 10%;
                            background: ${settingsState.mainLayoutColor};
                        }
                        .waitlist {
                            display: none;
                        }
                        :global(.back-btn-waitlist) {
                            top: 2%;
                            position: absolute;
                            left: 5%;
                            color: ${colors.primary};
                            border: 1px solid ${colors.primary};
                        }
                      .left-bar-background {
                            background-image:url(${staticAssetsPrefix}/static/images/login-left.png);
                            position: absolute;
                            z-index: 21;
                            height: 100%; // 30vh; // 95%;
                            width: 100%;
                            display: block;
                            top: 0;
                            left: -1px; // 0;
                            background-size: 100% 100%;
                            background-position: center center;
                            background-repeat: no-repeat;
                        }
                        .layer {
                            // background-color: rgba(0, 0, 0, 0.1);
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                        }
                        .left-div {
                            z-index: 50;
                            position: relative;
                            text-align: center;
                        }
                        .signIn-btn{
                            color: ${colors.third} !important;
                            // text-decoration: underline;
                        }
                        .left-info {
                            margin-top: 80%;
                            width: 80%;
                            margin-left: 10%;
                        }
                        .left-info h2 {
                            color: #EAF6FD;
                        }
                        .left-info p {
                            color: #B3BBC5;
                        }
                        @media ${maxScreenSize.tablet} {
                            .interests-div {
                                padding: 1em;
                            }
                        }
                        @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
                        }
                    `}
                </style>
            </div>
        )
    }
}

AuthComponent.defaultProps = {
    isAds: false
}

AuthComponent.propTypes = {
    // userDetails: PropTypes.object,
    // type: PropTypes.string,
    typeId: PropTypes.string,
    parent: PropTypes.string,
    showInterests: PropTypes.bool,
    isAds: PropTypes.bool,
    userData: PropTypes.object,
    settingsState: PropTypes.object,
    signInUserSuccess: PropTypes.func,
    createUserSuccess: PropTypes.func,
    createPlaylistPersonal: PropTypes.func,
    callbackAuthParent: PropTypes.func,
    changeClosable: PropTypes.func,
    setInterestsFirstTimeOnboard: PropTypes.func,
}

const mapStateToProps = (state) => {
    return {
        settingsState: state.settingsState,
        // loadingUserDetails: state.loadingState.loadingUserDetails,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createPlaylistPersonal: bindActionCreators(createPlaylistPersonal, dispatch),
        createUserSuccess: bindActionCreators(createUserSuccess, dispatch),
        signInUserSuccess: bindActionCreators(signInUserSuccess, dispatch),
        setInterestsFirstTimeOnboard: bindActionCreators(setInterestsFirstTimeOnboard, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthComponent);
