import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { colors, rightSideBar } from '../../lib/theme'
import { connect } from 'react-redux';
import AppCustomLink from '../../components/Common/AppCustomLink';
var UAParser = require('ua-parser-js');
import { notification, Tooltip } from 'antd';
import { supportEmail, isArticle, isInStandaloneMode, webHomeUrl, appleStoreUrl, playStoreUrl, saveLocally, retrieveLocally, macStoreUrl, windowsStoreUrl, websiteUrlOutOfScope, staticAssetsPrefix } from '../../lib/utils';
import Router from 'next/router';
// import { logDesktopAppDownloadStart, logDesktopAppDownloadValid, logDesktopAppDownloadSuccess } from '../../lib/analyticsEvents';
import * as gtag from '../../lib/gtag';
import { CloseOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { logBlogAppClick } from '../../actions';

const MAC_CHROME_PWA_VERSION = 73;
const EDGE_PWA_VERSION = 79; // 79.0.309 
const chromeUrl = 'https://www.google.com/chrome/';
const edgeUrl = 'https://support.microsoft.com/en-in/help/4501095/download-the-new-microsoft-edge-based-on-chromium';

class ShowApps extends Component {
    constructor(props) {
        super(props);
        this.state = { showDownload: true, defaultVisible: false, loadingPage: true, animCSS: '' };
        this.deferredPrompt = undefined;
    }
    componentDidMount() {
        // if (!isInStandaloneMode()) {
        //     this.setState({ showDownload: true });
        // }
        // let deferredPrompt;

        // const isArticlePage = isArticle(location.pathname);
        console.dir(location);
        const urlParams = new URLSearchParams(window.location.search);
        // if (!isArticlePage) {
        window.addEventListener('beforeinstallprompt', this.handleEvent);
        const downloadApp = urlParams.get('downloadApp');
        console.dir(downloadApp);
        if (downloadApp === 'true') {
            console.dir(downloadApp);
            // this.handleDownload();
            this.setState({ defaultVisible: true });
        }
        if (retrieveLocally('HIDE_DOWNLOAD') == 'true' || window.innerWidth < 700) {
            this.setState({ showDownload: false });
        }


        const isPWAFromLocalStorage = retrieveLocally('isPWA');
        const isPWA = urlParams.get('source') === 'pwa' || isPWAFromLocalStorage;
        if (isPWA) {
            // this.setState({ showDownload: false });
            saveLocally('isPWA', true, 24 * 30, false);
        }
        // }
        this.setState({ loadingPage: false });
        setTimeout(() => {
            this.setState({ animCSS: 'anim' })
        }, 300);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeinstallprompt', this.handleEvent);
    }

    handleEvent = (e) => {
        // Prevent Chrome 67 and earlier from automatically showing the prompt
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
        console.dir(this.deferredPrompt);
    }

    handleDownload = () => {
        console.dir(this.deferredPrompt);
        // logDesktopAppDownloadStart();
        gtag.basicEvent('logDesktopAppDownloadStart')
        if (this.deferredPrompt) {
            // Show the prompt
            console.dir(this.deferredPrompt);
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            // logDesktopAppDownloadValid();
            gtag.basicEvent('logDesktopAppDownloadValid')
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    // logDesktopAppDownloadSuccess();
                    gtag.basicEvent('logDesktopAppDownloadSuccess')
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                // this.deferredPrompt = null;
            });
        } else {
            var parser = new UAParser();
            console.dir(parser.getResult());
            let info = parser.getResult();
            if (info.browser) {
                let browserInfo = info.browser;
                if (browserInfo.name && browserInfo.name.includes('Chrome')) {
                    /// check for browser major version
                    if (browserInfo.major && parseInt(browserInfo.major) > MAC_CHROME_PWA_VERSION) {
                        /// contact support
                        notification.warning({
                            duration: 0,
                            message: 'Download Error',
                            description:
                                `There was an error downloading the app. Please try again or contact support - ${supportEmail}`,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    } else {
                        /// too low chrome
                        notification.warning({
                            duration: 0,
                            message: 'Update chrome to download',
                            description:
                                <p>Your chrome browser version is too old to support this download. Please update to the <a href={chromeUrl} target='_blank' rel="noopener noreferrer">latest version</a>.</p>,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    }
                } else if (browserInfo.name && browserInfo.name.includes('Edge')) {
                    /// check for browser major version
                    if (browserInfo.major && parseInt(browserInfo.major) >= EDGE_PWA_VERSION) {
                        /// contact support
                        notification.warning({
                            duration: 0,
                            message: 'Download Error',
                            description:
                                `There was an error downloading the app. Please try again or contact support - ${supportEmail}`,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    } else {
                        /// too low chrome
                        notification.warning({
                            duration: 0,
                            message: 'Update browser to download',
                            description:
                                <p>Your Edge browser version is too old to support this download. Please update to the <a href={edgeUrl} target='_blank' rel="noopener noreferrer">latest version</a>.</p>,
                            onClick: () => {
                                console.log('Notification Clicked!');
                            },
                        });
                    }
                } else {
                    /// other browser, install chrome
                    notification.warning({
                        duration: 0,
                        message: 'Browser not supported for download',
                        description:
                            <p>This app can only be installed through the latest <a href={chromeUrl} target='_blank' rel="noopener noreferrer">Chrome</a> or <a href={edgeUrl} target='_blank' rel="noopener noreferrer">Edge</a> browsers. Apologies for the inconvenience.</p>,
                        onClick: () => {
                            console.log('Notification Clicked!');
                        },
                    });
                }
            }
        }
    }

    openMacPWA = () => {
        var parser = new UAParser();
        console.dir(parser.getResult());
        let info = parser.getResult();
        if (info.os) {
            let osName = info.os.name || '';
            if (osName.toLowerCase().includes('mac')) {
                /// mac PWA
                this.handleDownload();
            } else {
                notification.open({
                    message: 'You are not on Mac OS',
                    description:
                        'To install this app on Mac OS, open this page on a Mac machine.',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }
        }
    }
    openWindowsPWA = () => {
        var parser = new UAParser();
        console.dir(parser.getResult());
        let info = parser.getResult();
        if (info.os) {
            let osName = info.os.name || '';
            if (osName.toLowerCase().includes('window') && info.os.version && parseInt(info.os.version.split('.')[0]) >= 10) {
                this.handleDownload();
            } else {
                notification.open({
                    message: 'You are not on Windows 10',
                    description:
                        'To install this app on Windows 10, open this page on a Windows 10 machine.',
                    onClick: () => {
                        console.log('Notification Clicked!');
                    },
                });
            }
        }
    }

    closeDownload = () => {
        this.setState({ showDownload: false });
        saveLocally('HIDE_DOWNLOAD', true, 24 * 20);
    }

    onAppClick = (appType) => {
        console.log(location.pathname);
        logBlogAppClick(location.pathname, appType);
    }

    render() {
        const { showDownload, defaultVisible, loadingPage, animCSS } = this.state;
        const { settingsState } = this.props;
        console.dir(defaultVisible);
        return (
            <div className={`apps-card ${animCSS}`}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h4 className='title'></h4>
                    <p className='mb-0' onClick={this.closeDownload} style={{ cursor: 'pointer' }}><CloseOutlined /></p>
                </div>
                {/* <h4 className='title'>Limited Time Offer</h4> */}
                <p className='mb-0 serif' style={{ textAlign: 'center' }}>Learn Key Ideas from 9000+ <br />non-fiction books in 15min.</p>
                {/* {!loadingPage && defaultVisible && <Tooltip title={<p style={{ color: 'white', fontSize: '1.25em', margin: '2em 1.5em' }}>Click here to install app</p>} defaultVisible={defaultVisible} placement="top" >
                    <p>Available for <a onMouseDown={() => gtag.basicEvent('web-player-click')} href={webHomeUrl} target='_blank' rel="noopener noreferrer">Web</a>, <a onMouseDown={() => gtag.basicEvent('testflight_url_click')}
                        href={appleStoreUrl} target='_blank' rel="noopener noreferrer">
                        iOS</a>, <a onMouseDown={() => gtag.basicEvent('android_url_click')}
                            href={playStoreUrl} target='_blank' rel="noopener noreferrer">
                            Android</a>, <a onMouseDown={() => gtag.basicEvent('windows_url_click')}
                            href={windowsStoreUrl} target='_blank' rel="noopener noreferrer">Windows 10</a>, <a onMouseDown={() => gtag.basicEvent('mac_url_click')}
                                href={macStoreUrl} target='_blank' rel="noopener noreferrer">Mac OS</a>.
                        </p>
                </Tooltip>} */}
                {/* {!loadingPage && !defaultVisible &&
                    <p>Data sync between <a onMouseDown={() => gtag.basicEvent('web-player-click')} href={websiteUrlOutOfScope + '/home'} target='_blank' rel="noopener noreferrer">Web</a>, <a onMouseDown={() => gtag.basicEvent('testflight_url_click')}
                        href={appleStoreUrl} target='_blank' rel="noopener noreferrer">
                        iOS</a>, <a onMouseDown={() => gtag.basicEvent('android_url_click')}
                            href={playStoreUrl} target='_blank' rel="noopener noreferrer">
                            Android</a>, <a onMouseDown={() => gtag.basicEvent('windows_url_click')}
                                href={windowsStoreUrl} target='_blank' rel="noopener noreferrer">Windows 10</a> and <a onMouseDown={() => gtag.basicEvent('mac_url_click')}
                                    href={macStoreUrl} target='_blank' rel="noopener noreferrer">Mac OS</a> apps on unlimited devices.
                    </p>} */}
                {!loadingPage && !defaultVisible &&
                    <div className='app-stores' style={{ marginTop: '0' }}>
                        <div style={{ height: '180px', position: 'relative', marginBottom: '16px' }}>
                            <img style={{ width: 'auto' }} src={`${staticAssetsPrefix}/static/images/app-store/chart_v5.png`} />
                            {/* <img style={{ position: 'absolute', width: '80px', right: '2px', bottom: '0', transform: 'rotate(15deg)' }}
                                src={`${staticAssetsPrefix}/static/images/app-store/mobile-3-min-very-low.png`} /> */}
                        </div>
                        <div>
                            <a onClick={this.onAppClick.bind(this, 'ios')} style={{ marginRight: '1em' }} href={appleStoreUrl} target='_blank' rel="noopener noreferrer nofollow">
                                <img style={{ width: '90px' }} src={`${staticAssetsPrefix}/static/images/app-store/app_store.svg`} />
                            </a>
                            <a onClick={this.onAppClick.bind(this, 'android')} href={playStoreUrl} target='_blank' rel="noopener noreferrer">
                                <img style={{ height: '39px', maxWidth: '98px', transform: 'translateY(-5px' }} src={`${staticAssetsPrefix}/static/images/app-store/google-play-badge.svg`} />
                            </a>
                        </div>
                        {/* <div>
                            <a onClick={this.onAppClick.bind(this, 'windows')} href={windowsStoreUrl} style={{ marginTop: '8px', marginLeft: '0' }} target='_blank' rel="noopener noreferrer nofollow">
                                <img style={{ width: '90px', objectFit: 'cover', height: '42px', borderRadius: '8px' }} src={`${staticAssetsPrefix}/static/images/app-store/windows-badge.svg`} />
                            </a>
                            <a onClick={this.onAppClick.bind(this, 'macos')} href={macStoreUrl} style={{ marginTop: '10px', marginLeft: '12px' }} target='_blank' rel="noopener noreferrer nofollow">
                                <img style={{ width: '106px', height: '36px' }} src={`${staticAssetsPrefix}/static/images/app-store/mac-badge.svg`} />
                            </a>
                        </div> */}

                        {/* <div className='my-star'>
                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                            <FontAwesomeIcon className='' icon={['fas', 'star']} style={{ fontSize: '14px', color: `${colors.third}` }} />
                            &nbsp;
                            <h4 style={{ marginBottom: 0 }}>4.8 (3.9k+)</h4>
                        </div> */}
                    </div>
                }
                <style jsx>
                    {`
                        .my-star {
                            display: flex;
                            justify-content: center;
                            margin-top: 8px;
                            align-items: center;
                        }
                        h4.title {
                            color: ${colors.third} !important;
                            font-weight: 600 !important;
                        }
                        .app-stores > div {
                            display: flex;
                            justify-content: center;
                        }
                        .apps-card {
                            display: ${showDownload ? 'inherit' : 'none'};
                            background: ${settingsState.cardColor}; // white;
                            padding: 0.75em 0em;
                            padding-right: 0.75em;
                            // width: 270px;
                            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 20px;
                            border-radius: 12px;
                            // width: min(18vw, 300px); // ${rightSideBar};
                            opacity: 0.5;
                            transform: translate(240px, 0px);
                            transition: transform 1s, opacity 1s;
                        }
                        .apps-card.anim {
                            opacity: 1;
                            transform: translate(0, 0);
                            // transition: opacity 2s transform 2s;
                        }
                        .apps-card a {
                            color: ${colors.third}
                        }
                    `}
                </style>
            </div >
        )
    }
}

ShowApps.propTypes = {
    settingsState: PropTypes.any,
}

const mapStateToProps = (state) => {
    return {
        settingsState: state.settingsState,
    }
}
export default connect(mapStateToProps, null)(ShowApps);
