import { GET_AUTO_CHAPTERS_ACTION } from "../actions/types";

// REDUCERS
export const autoChaptersReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case GET_AUTO_CHAPTERS_ACTION: {
            return Object.assign({}, state, { [action.uuid]: action.payload });
            // const userId = action.payload.userID || '';
            // const reviewsMeta = action.payload.reviewsMeta || {};
            // return produce(state, draft => {
            //   draft[userId] = { ...draft[userId], reviewsMeta } // reviews;
            // })
        }
        default:
            return state;
    }
}