import { ADD_READING_CHALLENGES_USER, ADD_READING_CHALLENGES_USER_COMPLETED, DELETE_READING_CHALLENGES_USER, FETCH_READING_CHALLENGES, FETCH_READING_CHALLENGES_USER, FETCH_READING_CHALLENGES_USER_COMPLETED } from "../actions/types";



export const readingChallengesReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case FETCH_READING_CHALLENGES:
            return Object.assign({}, state, action.payload);
        // case ADD_TO_QUEUE_MAIN_ACTION: {
        //     const episodeNew = action.payload;
        //     return Object.assign({}, state, { [episodeNew.guid]: episodeNew });
        // }
        default:
            return state;
    }
};
export const readingChallengesUserReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case FETCH_READING_CHALLENGES_USER:
            return Object.assign({}, state, action.payload);
        case ADD_READING_CHALLENGES_USER: {
            const challengeIds = state[action.challengeId] || {};
            // const episodeNew = action.payload;
            // return Object.assign({}, state, { [action.collectionId]: true });
            return { ...state, [action.challengeId]: { ...challengeIds, [action.collectionId]: true } };
        }
        case DELETE_READING_CHALLENGES_USER: {
            const copy = Object.assign({}, state);
            if (copy[action.challengeId])
                if (copy[action.challengeId][action.collectionId])
                    delete copy[action.challengeId][action.collectionId];
            return copy;
        }
        default:
            return state;
    }
};
export const readingChallengesCompletedReducer = (state = {}, action) => {
    // console.log('recent : ' + action.type);
    switch (action.type) {
        case FETCH_READING_CHALLENGES_USER_COMPLETED:
            return Object.assign({}, state, action.payload);
        case ADD_READING_CHALLENGES_USER_COMPLETED: {
            // const episodeNew = action.payload;
            return Object.assign({}, state, { [action.challengeId]: true });
        }
        // case DELETE_READING_CHALLENGES_USER: {
        //     const copy = Object.assign({}, state);
        //     if (copy[action.collectionId])
        //         delete copy[action.collectionId];
        //     return copy;
        // }
        default:
            return state;
    }
};