import React, { Component } from 'react'
import dynamic from 'next/dynamic';

const LottieComponent = dynamic(() => import('../Podcast/LottieComponent'), {
    ssr: false, // Set to true if you want server-side rendering
    // loading: () => <p>Loading...</p>, // Optional loading component
});

export default class PremiumGift extends Component {

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         loading: true
    //     }
    // }
    // componentDidMount() {
    //     setTimeout(() => {
    //         this.setState({ loading: false })
    //     }, 100);
    // }

    render() {
        // const { loading } = this.state;
        // if (loading) {
        //     return <div></div>
        // }
        return (
            <div className='main'>
                <LottieComponent image={'gift2'} loop={false} />
                {/* <div className='parent'>
                    <div className="rectangle">50% OFF</div>
                </div> */}
                <style jsx>
                    {`
                    .main {
                        position: relative;
                        max-width: 500px;
                        margin: auto;
                    }
                    .parent {
                            position: absolute;
                        top: 60px;
                        right: 110px;
                    }
                    .rectangle {
                        position: relative;
                        background-color: #d82f43;
                        color: white;
                        font-size: 16px;
                        font-weight: bold;
                        text-align: center;
                        line-height: 50px; /* Align text vertically */
                        border-radius: 12px;
                        overflow: hidden;

                        width: 0;
                        height: 0;

                        animation: expand 1s forwards; /* Adjust duration as needed */
                    }

                    @keyframes expand {
                        0% {
                            width: 0;
                            height: 0;
                            // transform: translate(0, 0);
                        }
                        100% {
                            width: 100px;
                            height: 50px;
                            // transform: translate(-100px, 50px); /* Move to bottom-left */
                        }
                    }
                    
                    `}
                </style>
            </div>
        )
    }
}
