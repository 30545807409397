import { FETCH_HOME_RECOMMENDATIONS, FETCH_USER_BOOK_OF_DAY } from "../actions/types";

export const homeRecommendationsReducer = (state = [], action) => {
    // console.dir(state);
    switch (action.type) {
        case FETCH_HOME_RECOMMENDATIONS:
            return [...state, ...action.payload];
        // case ADD_REQUESTED_BOOKS:
        //     return Object.assign({}, state, { [action.key]: action.payload });
        default:
            return state;
    }
};

export const userBookOfDayReducer = (state = {}, action) => {
    // console.dir(state);
    switch (action.type) {
        case FETCH_USER_BOOK_OF_DAY:
            return Object.assign({}, state, action.payload);
        default:
            return state;
    }
};